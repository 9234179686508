import React, { useState } from 'react';
import { Plus, Minus } from 'react-feather';

import './pane.styles.scss';

/* usage analytics -- budget-report*/
const plusIcon = <Plus size="12" strokeWidth="5" />,
  minusIcon = <Minus size="12" strokeWidth="5" />;

const BudgetExpenseExpandable = ({
  children = <div></div>,
  title,
  percentageLabel = 'Percentage Used',
  expand = false,
  isTree = false,
  hideExpand = false,
  classNames,
}) => {
  const [toggle, setToggle] = useState(expand);
  if (isTree) {
    return (
      <div>
        <table
          className={`bx--data-table budget-expandable ${classNames} true `}>
          <thead>
            <tr className="table-row-bg-primary">
              <th colSpan={4}>
                <span className="text-20 font-bold">{title}</span>
              </th>
              <th>
                <span>Budgeted Amount</span>
              </th>
              <th>
                <span>Period Actual</span>
              </th>
              <th>
                <span>Variance</span>
              </th>
              <th>
                <span className="text-right">{percentageLabel}</span>
              </th>
              <th>
                <div
                  className="right"
                  onClick={() => setToggle(toggle ? false : true)}>
                  <span hidden={toggle}>{plusIcon}</span>
                  <span hidden={!toggle}>{minusIcon}</span>
                </div>
              </th>
            </tr>
          </thead>
        </table>
        <div hidden={!toggle}>{children}</div>
      </div>
    );
  } else {
    return (
      <table
        className={`bx--data-table table-white budget-expandable  ${classNames}`}>
        <thead>
          <tr>
            <th colSpan="4">
              <span className="text-20 font-bold">{title}</span>
            </th>
            <th>
              <div
                className="right"
                onClick={() => setToggle(toggle ? false : true)}>
                <span hidden={toggle}>{plusIcon}</span>
                <span hidden={!toggle}>{minusIcon}</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody hidden={!toggle}>{children}</tbody>
      </table>
    );
  }
};

const BudgetRevenueExpandable = ({
  children = <div></div>,
  title,
  expand = false,
  classNames,
}) => {
  const [toggle, setToggle] = useState(expand);
  return (
    <div>
      <table
        className={`bx--data-table table-white budget-expandable ${classNames} true `}>
        <thead>
          <tr className="table-row-bg-primary">
            <th>
              <span className="text-20 font-bold">{title}</span>
            </th>
            <th>
              <span>Budgeted Amount</span>
            </th>
            <th>
              <span>Period Actual</span>
            </th>
            <th>
              <span>Variance</span>
            </th>
            <th>
              <span className="text-right">Percentage Met</span>
            </th>
            <th>
              <div
                className="right"
                onClick={() => setToggle(toggle ? false : true)}>
                <span hidden={toggle}>{plusIcon}</span>
                <span hidden={!toggle}>{minusIcon}</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody hidden={!toggle}>{children}</tbody>
      </table>
    </div>
  );
};

export { BudgetExpenseExpandable, BudgetRevenueExpandable };
