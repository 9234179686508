import React from 'react';
import './custom-btn.styles.scss';

const CustomButton = ({ children, classes, ...otherProps }) => (
  <button
    type="button"
    className={`bx--btn bx--btn--primary custom-btn ${classes}`}
    {...otherProps}>
    {children}
  </button>
);

export default CustomButton;
