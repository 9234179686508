import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  TextInput,
  FormItem,
  Form,
  FormGroup,
  Select,
  SelectItem,
  Modal,
  Loading,
} from 'carbon-components-react';
import CustomButton from '../../custom-button/custom-btn.component';

// import redux stuffs
import { selectGroups } from '../../../redux/selectors/chart-of-accounts.selector';
import { selectCurrentUser } from '../../../redux/selectors/user.selectors';
import {
  setLedgerCategories,
  setGroups,
} from '../../../redux/actions/chart-of-accounts.actions';

// import api stuffs
import {
  addOrUpdateLedgerCategory,
  getGroups,
} from '../../../api/chart-of-accounts.service';

import './add-ledger-category.styles.scss';

class AddLedgerCategoryModal extends React.Component {
  constructor(props) {
    super(props);

    // set state
    this.state = {
      id: 0,
      loading: false,
      ledgerCategoryToEdit: this.props.ledgerCategory,
      btnText: 'ADD',
      message: null,
      messageClass: '',
      openModal: props.openModal,
      groups: props.groups,
      authToken: props.currentUser.authToken,
      description: '',
      groupId: -1,
      groupDescription: '',
      groupFinancialStatement: 'N/A',
    };
  }

  componentDidMount() {
    // go get groups if length is 0
    if (this.state.groups.length === 0) {
      this.fetchGroups();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.groups.length !== prevProps.groups.length) {
      this.setState({ groups: this.props.groups });
    }

    if (this.props.ledgerCategory !== prevProps.ledgerCategory) {
      if (this.props.ledgerCategory) {
        this.setState({
          id: this.props.ledgerCategory.id,
          ledgerCategoryToEdit: this.props.ledgerCategory,
          description: this.props.ledgerCategory.description,
          groupId: this.props.ledgerCategory.groupId,
          groupDescription: this.props.ledgerCategory.groupDescription,
          groupFinancialStatement:
            this.props.ledgerCategory.groupFinancialStatement,
          btnText: 'UPDATE',
        });
      } else {
        this.setState({
          id: 0,
          ledgerCategoryToEdit: null,
          description: '',
          groupId: -1,
          groupDescription: '',
          groupFinancialStatement: 'N/A',
          btnText: 'ADD',
        });
      }
    }
  }

  // handle onchange event handler for input fields
  handleChange = (event) => {
    // destructure value and name of event.target
    const { value, name } = event.target;

    let groupDescription = this.state.groupDescription;
    let groupFinancialStatement = this.state.groupFinancialStatement;
    if (name === 'groupId') {
      const group = this.state.groups.find(
        (group) => group.id === parseInt(value),
      );

      groupDescription = group.description;
      groupFinancialStatement = group.financialStatement;
    }

    // update state
    this.setState({ [name]: value, groupDescription, groupFinancialStatement });
  };

  // validate data entered
  validateData = () =>
    this.state.description.length > 0 && this.state.groupId !== -1;

  fetchGroups = async () => {
    const response = await getGroups(this.state.authToken);

    if (!response.success) {
      // TODO:

      return;
    }

    this.props.setGroups(response.groups);
  };

  onAddButtonClicked = async (event) => {
    event.preventDefault();

    // update state to set request in progress to true and error occurred to false
    this.setState({
      loading: true,
      message: null,
      btnText: null,
    });

    // build data
    const data = {
      id: this.state.id,
      description: this.state.description,
      groupId: parseInt(this.state.groupId),
      groupDescription: this.state.groupDescription,
      groupFinancialStatement: this.state.groupFinancialStatement.replace(
        ' ',
        '_',
      ),
    };

    // initiate request
    const response = await addOrUpdateLedgerCategory(
      this.state.authToken,
      data,
    );

    if (!response.success) {
      this.setState({
        loading: false,
        message: response.message,
        messageClass: 'text-danger',
        btnText: this.state.ledgerCategoryToEdit ? 'UPDATE' : 'ADD',
      });

      return;
    }

    // on success
    this.setState({
      loading: false,
      id: 0,
      description: '',
      message: this.state.ledgerCategoryToEdit
        ? null
        : `${response.message} Add more or close this dialog.`,
      messageClass: 'text-success',
      btnText: this.state.ledgerCategoryToEdit ? 'UPDATE' : 'ADD',
      groupFinancialStatement: 'N/A',
    });

    // update redux
    this.props.setLedgerCategories([response.ledgerCategory]);

    // close dialog if it was an update
    if (this.state.ledgerCategoryToEdit) {
      this.props.cancelAction();
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.openModal !== prevState.openModal) {
      return { openModal: nextProps.openModal };
    } else return null;
  }

  render() {
    return (
      <Modal
        id="add-ledger-category-modal"
        className="add-ledger-category-modal"
        hasScrollingContent
        passiveModal={true}
        open={this.state.openModal}
        modalHeading={
          this.state.ledgerCategoryToEdit ? 'Edit Category' : 'Add New Category'
        }
        primaryButtonDisabled={false}>
        <div>
          <Form action="post">
            <FormGroup legendText="">
              <FormItem>
                <TextInput
                  id="description"
                  name="description"
                  labelText="Category Name"
                  placeholder="E.g. Transportation"
                  value={this.state.description}
                  onChange={this.handleChange}
                />
              </FormItem>
            </FormGroup>

            <FormGroup className="nmb">
              <FormItem>
                <Select
                  id="group-select"
                  name="groupId"
                  labelText="Group Name"
                  onChange={this.handleChange}>
                  <SelectItem
                    selected={this.state.groupId == -1 ? true : false}
                    disabled
                    text="Select Group"
                    value=""
                  />
                  {this.state.groups.map((group) => (
                    <SelectItem
                      selected={group.id == this.state.groupId}
                      key={group.id}
                      text={group.description}
                      value={group.id}
                    />
                  ))}
                </Select>
              </FormItem>
            </FormGroup>

            <div>
              <p
                style={{
                  fontSize: '0.75rem',
                  letterSpacing: '0.32px',
                  color: '#AAB0C6',
                  fontWeight: '400',
                }}
                className="text-grey-dark text-14 mt-30">
                Financial Report
              </p>
              <p className="text-dark text-14">
                {this.state.groupFinancialStatement}
              </p>
            </div>

            <CustomButton
              type="submit"
              classes="primary f-width mt-30 font-bold"
              disabled={!this.validateData() || this.state.loading}
              onClick={this.onAddButtonClicked}>
              {this.state.btnText}
              <div hidden={this.state.btnText != null}>
                <div className="d-flex-center">
                  <Loading
                    withOverlay={false}
                    small={true}
                    description="Adding ledger category..."
                  />
                </div>
              </div>
            </CustomButton>

            <p
              className={`text-14 mt-10 ${this.state.messageClass}`}
              hidden={this.state.message === null}>
              {this.state.message}
            </p>

            <CustomButton
              classes="outline danger f-width font-bold mt-20"
              onClick={this.props.cancelAction}
              disabled={this.state.loading}>
              CLOSE
            </CustomButton>
          </Form>
        </div>
      </Modal>
    );
  }
}

// this will provide us with the state values we need
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  groups: selectGroups,
});

// this allows us to map our redux actions to our component
const mapDispatchToProps = (dispatch) => ({
  setGroups: (groups) => dispatch(setGroups(groups)),

  setLedgerCategories: (ledgerCategories) =>
    dispatch(setLedgerCategories(ledgerCategories)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddLedgerCategoryModal);
