import React, { useState } from 'react';
import { MoreVertical } from 'react-feather';

import { ReactComponent as Target } from '../../../assets/images/card-icons/invoice.svg';

import CardMenu, {
  CardMenuItem,
} from '../../menu/card-menu/card-menu.component';
import { Tag } from 'carbon-components-react';
import { getMonthName } from '../../../utils/common.util';

import './payroll-card.styles.scss';

const PayrollCard = (props) => {
  const { month, uniqueId, status } = props.payroll;

  const [showCardMenu, setShowCardMenu] = useState(false);
  return (
    <div className="payroll-card bg-white" onClick={props.clickAction}>
      <div>
        <div className="top-content">
          <div className="icon">
            <Target />

            <MoreVertical
              className="pointer"
              color="#7D819D"
              onClick={(e) => {
                e.stopPropagation();
                setShowCardMenu(showCardMenu ? false : true);
              }}
            />

            {showCardMenu ? (
              <CardMenu>
                <CardMenuItem
                  title="DELETE"
                  separator={false}
                  color="danger"
                  action={(event) => {
                    event.stopPropagation();
                    props.deleteAction();
                    setShowCardMenu(showCardMenu ? false : true);
                  }}
                />
              </CardMenu>
            ) : null}
          </div>

          <h3 className="title">
            <span className="text-truncate">{getMonthName(month)}</span>
            <Tag
              className={`status ${
                !props.payroll.dateVoided && !props.payroll.dateGenerated
                  ? props.payroll.status.toLowerCase()
                  : props.payroll.dateGenerated && !props.payroll.dateVoided
                    ? 'generated'
                    : props.payroll.datePaid
                      ? 'paid'
                      : 'voided'
              } fade-in`}
              type="red">
              {!props.payroll.dateVoided && !props.payroll.dateGenerated
                ? props.payroll.status.toUpperCase()
                : props.payroll.dateGenerated &&
                    !props.payroll.dateVoided &&
                    !props.payroll.datePaid
                  ? 'GENERATED'
                  : props.payroll.datePaid
                    ? 'PAID'
                    : 'VOIDED'}
            </Tag>
          </h3>
          <p className="subtitle text-truncate">{uniqueId}</p>
        </div>
      </div>
    </div>
  );
};

export default PayrollCard;
