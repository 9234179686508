// using reselect
import { createSelector } from 'reselect';

// create select academic year function
const selectAcademicYear = (state) => state.academicYear;

// create selectAcademicYears selector
export const selectAcademicYears = createSelector(
  [selectAcademicYear],
  (academicYear) => academicYear.academicYears,
);

// create selectTerms selector
export const selectTerms = createSelector(
  [selectAcademicYear],
  (academicYear) => academicYear.terms,
);

// create selectBudgets selector
export const selectBudgets = createSelector(
  [selectAcademicYear],
  (academicYear) => academicYear.budgets,
);
