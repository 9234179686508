import React from 'react';
import {
  TextInput,
  FormItem,
  Form,
  FormGroup,
  SelectItem,
  Select,
  Modal,
  Loading,
} from 'carbon-components-react';
import CustomButton from '../../custom-button/custom-btn.component';

import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../../redux/selectors/user.selectors';
import { selectStudentRevenueAccounts } from '../../../redux/selectors/chart-of-accounts.selector';
import { selectClasses } from '../../../redux/selectors/class.selectors';
import { setClasses } from '../../../redux/actions/class.actions';
import { setLedgerAccounts } from '../../../redux/actions/chart-of-accounts.actions';
import { connect } from 'react-redux';
import { getLedgerAccounts } from '../../../api/chart-of-accounts.service';
import { createBillingFee } from '../../../api/billing.service';
import { setBillingFees } from '../../../redux/actions/billing.actions';
import { getClasses } from '../../../api/class.service';

import './add-fees.styles.scss';

class AddFeesModal extends React.Component {
  constructor(props) {
    super(props);

    // set state
    this.state = {
      openModal: props.openModal,
      refreshPage: false,
      ledgerAccountId: '',
      amount: '',
      loading: false,
      message: null,
      messageClass: '',
      classId: props.classId,
      btnText: 'SUBMIT',
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.openModal !== prevState.openModal) {
      return { openModal: nextProps.openModal };
    } else return null;
  }

  componentDidMount() {
    this.fetchLedgerAccounts();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.ledgerAccounts.length !== prevProps.ledgerAccounts.length ||
      this.props.classes.length !== prevProps.classes.length
    ) {
      this.setState({ refreshPage: true });
    }

    if (this.props.id !== prevProps.id) {
      if (this.props.id === 0) {
        this.setState({
          btnText: 'SUBMIT',
          amount: '',
          ledgerAccountId: '',
          classId: -1,
          message: null,
        });
      } else {
        this.setState({
          btnText: 'SUBMIT',
          amount: this.props.feeAmount,
          ledgerAccountId: this.props.feeLedgerAccountId,
          classId: this.props.classId,
          message: null,
        });
      }
    }
  }

  fetchLedgerAccounts = async () => {
    // make request
    let response = await getLedgerAccounts(
      this.props.currentUser.authToken,
      0,
      500,
    );

    if (response.success) {
      // successful, save to redux
      this.props.setLedgerAccounts(response.ledgerAccounts);
    }

    if (this.props.classId === -1) {
      // initiate request
      response = await getClasses(this.props.currentUser.authToken);

      if (response.success) {
        // save to redux
        this.props.setClasses(response.classes);

        return;
      }
    }
  };

  onAddFeeBtnClicked = async (event) => {
    event.preventDefault();

    // update state to set request in progress to true and error occurred to false
    this.setState({
      loading: true,
      message: null,
      btnText: null,
    });

    const ledgerAccount = this.props.ledgerAccounts.find(
      (item) => item.id === parseInt(this.state.ledgerAccountId),
    );

    const class_ = this.props.classes.find(
      (item) => item.id == this.state.classId,
    );

    console.log(class_);

    // build data
    const data = {
      id: this.props.id,
      amount: parseFloat(this.state.amount),
      classId: class_ ? class_.id : undefined,
      classDescription: class_ ? class_.description : undefined,
      ledgerAccountId: ledgerAccount.id,
      ledgerAccountDescription: ledgerAccount.description,
    };

    // initiate request
    const response = await createBillingFee(
      this.props.currentUser.authToken,
      data,
    );

    if (!response.success) {
      this.setState({
        loading: false,
        message: response.message,
        messageClass: 'text-danger',
        btnText: 'SUBMIT',
      });

      return;
    }

    // on success
    this.setState({
      loading: false,
      description: '',
      message: `${response.message} Add more or close this dialog.`,
      messageClass: 'text-success',
      btnText: 'SUBMIT',
    });

    // update redux
    this.props.setBillingFees([response.billingFee]);
  };

  render() {
    return (
      <Modal
        id="add-fees-modal"
        className="add-fees-modal"
        hasScrollingContent
        passiveModal={true}
        open={this.state.openModal}
        modalHeading={`${this.props.id === 0 ? 'Add Fee' : 'Update Fee'}`}
        primaryButtonDisabled={false}>
        <div>
          <Form action="post">
            <p className="text-grey text-14 mb-10">
              Please make sure there is a ledger available for the fee
            </p>

            <FormGroup className="mb-10 mt-15">
              <FormItem>
                <Select
                  id="ledger-select"
                  className="f-width"
                  name="categoryId"
                  labelText="Select Fee/Ledger"
                  onChange={(e) =>
                    this.setState({
                      ledgerAccountId: e.target.value,
                    })
                  }>
                  <SelectItem
                    disabled
                    selected={this.state.ledgerAccountId === ''}
                    text="Select Ledger"
                    value=""
                  />

                  {this.props.ledgerAccounts.map((item) => (
                    <SelectItem
                      selected={
                        this.props.feeLedgerAccountId &&
                        item.id === this.props.feeLedgerAccountId
                      }
                      text={item.description}
                      value={item.id}
                    />
                  ))}
                </Select>
              </FormItem>
            </FormGroup>

            {this.props.general && (
              <FormGroup className="mb-10 mt-15">
                <FormItem>
                  <Select
                    id="ledger-select"
                    className="f-width"
                    name="categoryId"
                    labelText="Tie Fee to Class"
                    onChange={(e) =>
                      this.setState({
                        classId: e.target.value,
                      })
                    }>
                    <SelectItem
                      selected={this.state.classId == -1}
                      text="Select Class (All Classes)"
                      value="-1"
                    />

                    {this.props.classes.map((item) => (
                      <SelectItem
                        selected={this.state.classId == item.id}
                        text={item.description}
                        value={item.id}
                      />
                    ))}
                  </Select>
                </FormItem>
              </FormGroup>
            )}

            <FormGroup legendText="" className="mb-15">
              <FormItem>
                <TextInput
                  id="amount"
                  name="amount"
                  type="number"
                  step="0.01"
                  labelText="Enter amount"
                  value={this.state.amount}
                  onChange={(e) =>
                    this.setState({
                      amount: e.target.value,
                    })
                  }
                  placeholder="E.g 1000"
                />
              </FormItem>
            </FormGroup>

            <CustomButton
              classes="primary f-width mt-10 font-bold"
              disabled={
                this.state.loading ||
                this.state.amount.length === 0 ||
                this.state.ledgerAccountId.length === 0
              }
              onClick={this.onAddFeeBtnClicked}>
              {this.state.btnText}

              <div hidden={this.state.btnText != null}>
                <div className="d-flex-center">
                  <Loading
                    withOverlay={false}
                    small={true}
                    description="Adding fee..."
                  />
                </div>
              </div>
            </CustomButton>

            <p
              className={`text-14 mt-10 ${this.state.messageClass}`}
              hidden={this.state.message === null}>
              {this.state.message}
            </p>

            <CustomButton
              disabled={this.state.loading}
              classes="outline danger f-width mt-20 font-bold"
              onClick={this.props.cancelAction}>
              CLOSE
            </CustomButton>
          </Form>
        </div>
      </Modal>
    );
  }
}

// this will provide us with the state values we need
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  ledgerAccounts: selectStudentRevenueAccounts,
  classes: selectClasses,
});

// this allows us to map our redux actions to our component
const mapDispatchToProps = (dispatch) => ({
  setLedgerAccounts: (ledgerAccounts) =>
    dispatch(setLedgerAccounts(ledgerAccounts)),
  setBillingFees: (billingFees) => dispatch(setBillingFees(billingFees)),
  setClasses: (classes) => dispatch(setClasses(classes)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddFeesModal);
