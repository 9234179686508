import React from 'react';
import {
  TextInput,
  FormItem,
  Form,
  Select,
  SelectItem,
  DatePicker,
  DatePickerInput,
  TextArea,
  FormGroup,
  Modal,
  Tabs,
  Tab,
  Loading,
  FileUploaderButton,
} from 'carbon-components-react';
import { Download, Camera } from 'react-feather';
import CustomButton from '../../custom-button/custom-btn.component';

import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../../redux/selectors/user.selectors';
import { connect } from 'react-redux';
import { selectDepartments } from '../../../redux/selectors/employee.selectors';
import {
  createEmployee,
  uploadEmployeePhoto,
} from '../../../api/employee.service';
import { setEmployees } from '../../../redux/actions/employee.actions';

import './add-employee.styles.scss';

class AddEmployeeModal extends React.Component {
  constructor(props) {
    super(props);

    // set state
    this.state = {
      openModal: props.openModal,
      selectedTabIndex: 0,
      id: 0,
      employeeToEdit: null,
      firstName: '',
      lastName: '',
      gender: '',
      dateOfBirth: '',
      phoneNumber: '',
      emailAddress: '',
      ssnitNumber: '',
      tinNumber: '',
      departmentId: '',
      jobDescription: '',
      qualification: '',
      dateHired: '',
      bankName: '',
      bankBranch: '',
      accountNumber: '',
      bankSortCode: '',
      emergencyContactName: '',
      emergencyContactPhoneNumber: '',
      comments: '',
      loading: false,
      errorMessage: null,
      successMessage: null,
      addBtnText: 'ADD EMPLOYEE',
      refreshPage: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.openModal !== prevState.openModal) {
      return { openModal: nextProps.openModal };
    } else return null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.employee !== prevProps.employee) {
      if (this.props.employee) {
        const dateOfBirth = new Date(this.props.employee.dateOfBirth);
        const dateHired = new Date(this.props.employee.dateHired);

        const parsedDateOfBirth = [
          dateOfBirth.getFullYear(),
          ('0' + (dateOfBirth.getMonth() + 1)).slice(-2),
          ('0' + dateOfBirth.getDate()).slice(-2),
        ].join('-');

        const parsedDateHired = [
          dateHired.getFullYear(),
          ('0' + (dateHired.getMonth() + 1)).slice(-2),
          ('0' + dateHired.getDate()).slice(-2),
        ].join('-');

        this.setState({
          ...this.props.employee,
          selectedTabIndex: 0,
          dateOfBirth: parsedDateOfBirth,
          dateHired: parsedDateHired,
          employeeToEdit: this.props.employee,
          loading: false,
          errorMessage: null,
          successMessage: null,
          addBtnText: 'UPDATE EMPLOYEE',
        });
      } else {
        this.setState({
          selectedTabIndex: 0,
          id: 0,
          employeeToEdit: null,
          employeePhoto: null,
          firstName: '',
          lastName: '',
          gender: '',
          dateOfBirth: '',
          phoneNumber: '',
          emailAddress: '',
          ssnitNumber: '',
          tinNumber: '',
          departmentId: '',
          jobDescription: '',
          qualification: '',
          dateHired: '',
          bankName: '',
          bankBranch: '',
          accountNumber: '',
          bankSortCode: '',
          emergencyContactName: '',
          emergencyContactPhoneNumber: '',
          comments: '',
          loading: false,
          errorMessage: null,
          successMessage: null,
          addBtnText: 'ADD EMPLOYEE',
        });
      }
    }

    if (this.props.departments.length !== prevProps.departments.length) {
      this.setState({ refreshPage: true });
    }
  }

  handleChange = (event) => {
    // destructure values
    const { name, value } = event.target;

    // update state
    this.setState({ [name]: value, successMessage: null });
  };

  validateBasicInfo = () => {
    return (
      this.state.firstName.length > 0 &&
      this.state.lastName.length > 0 &&
      this.state.gender.length > 0 &&
      this.state.dateOfBirth.length > 0
    );
  };

  addEmployee = async (event) => {
    event.preventDefault();

    this.setState({ loading: true, errorMessage: null, addBtnText: null });

    // get department
    const selectedDepartment = this.props.departments.find(
      (item) => item.id == this.state.departmentId,
    );

    const data = {
      ...this.state,
      departmentId: selectedDepartment.id,
      departmentName: selectedDepartment.description,
      dateHired:
        this.state.dateHired.length === 0 ? undefined : this.state.dateHired,
      employeeToEdit: undefined,
      employeePhoto: undefined,
      addBtnText: undefined,
      loading: undefined,
      errorMessage: undefined,
      openModal: undefined,
      selectedTabIndex: undefined,
      successMessage: undefined,
      refreshPage: undefined,
    };

    const response = await createEmployee(
      data,
      this.props.currentUser.authToken,
    );

    if (!response.success) {
      this.setState({
        loading: false,
        errorMessage: response.message,
        addBtnText: data.id > 0 ? 'UPDATE EMPLOYEE' : 'ADD EMPLOYEE',
      });

      return;
    }

    let employee = response.employee;

    // upload photo if selected
    if (this.state.employeePhoto) {
      const formData = new FormData();
      formData.append('formFile', this.state.employeePhotoFile);
      formData.append('employeeId', response.employee.id);

      // upload photo
      const uploadResponse = await uploadEmployeePhoto(
        this.props.currentUser.authToken,
        formData,
      );

      if (uploadResponse.success) {
        employee = uploadResponse.employee;
      }
    }

    this.setState({
      loading: false,
      errorMessage: null,
      id: 0,
      employeeToEdit: null,
      firstName: '',
      lastName: '',
      gender: '',
      dateOfBirth: '',
      phoneNumber: '',
      emailAddress: '',
      ssnitNumber: '',
      tinNumber: '',
      departmentId: '',
      jobDescription: '',
      qualification: '',
      dateHired: '',
      bankName: '',
      bankBranch: '',
      accountNumber: '',
      bankSortCode: '',
      emergencyContactName: '',
      emergencyContactPhoneNumber: '',
      comments: '',
      addBtnText: 'ADD EMPLOYEE',
      refreshPage: false,
      successMessage: `Employee [${employee.firstName} ${employee.lastName}] added successfully.`,
    });

    // all good, add employee and close dialog
    this.props.setEmployees([employee]);

    // close dialog if it was edit
    if (data.id > 0) {
      this.props.closeAction();
    }
  };

  render() {
    return (
      <Modal
        id="add-employee-modal"
        className="add-employee-modal"
        hasScrollingContent
        passiveModal={true}
        open={this.state.openModal}
        modalHeading={this.state.id > 0 ? 'Edit Employee' : 'Add New Employee'}
        primaryButtonDisabled={false}
        onRequestClose={() => this.setState({ openModal: false })}
        focusTrap={false}
        iconDescription="Close the modal">
        <div>
          <Form action="post">
            <Tabs
              ariaLabel="listbox"
              role="navigation"
              selected={this.state.selectedTabIndex}
              tabContentClassName="tab-content"
              triggerHref="#"
              type="default">
              <Tab
                href="#"
                label="Upload Photo"
                role="presentation"
                tabIndex={0}>
                <div className="tab1">
                  <p
                    className="text-success text-center text-16 mt-10"
                    hidden={this.state.successMessage === null}>
                    <span>{this.state.successMessage}</span>
                    <br />
                    Add new employee or close this dialog.
                  </p>

                  <div
                    className="photo center pointer"
                    style={{
                      backgroundImage: `url(${this.state.employeePhoto})`,
                      backgroundSize: 'cover',
                    }}>
                    <Camera size="18" strokeWidth="2px" className="camera" />
                  </div>

                  <div className="upload-btn">
                    <FileUploaderButton
                      accept={['image/*']}
                      buttonKind="primary"
                      disableLabelChanges={false}
                      disabled={false}
                      labelText="Select Photo"
                      multiple={false}
                      onChange={(e) =>
                        this.setState({
                          employeePhoto: URL.createObjectURL(e.target.files[0]),
                          employeePhotoFile: e.target.files[0],
                        })
                      }
                      onClick={function noRefCheck() {}}
                      role="button"
                      tabIndex={0}
                    />
                  </div>

                  <div className="text-14 text-grey">
                    <p className="photo-text center text-center">
                      <span className="text-dark">.jpg </span> or{' '}
                      <span className="text-dark"> .png </span>
                      formats only.
                      <br />
                      Maximum file size is 1MB.
                    </p>
                  </div>

                  <div className="btn-actions mt-40">
                    <CustomButton
                      classes="secondary font-bold"
                      onClick={this.props.importAction}>
                      <Download size="11" strokeWidth="5px" />
                      &nbsp; IMPORT
                    </CustomButton>

                    <div className="">
                      <CustomButton
                        classes="outline danger  font-bold"
                        onClick={this.props.closeAction}>
                        CANCEL
                      </CustomButton>

                      <CustomButton
                        classes="primary font-bold ml-20"
                        onClick={() => this.setState({ selectedTabIndex: 1 })}>
                        NEXT
                      </CustomButton>
                    </div>
                  </div>
                </div>
              </Tab>

              <Tab
                href="#"
                label="Basic Information"
                role="presentation"
                tabIndex={1}>
                <div className="tab2 mt-30">
                  <div className="bx--row">
                    <FormGroup legendText="" className="bx--col-md-4">
                      <FormItem>
                        <TextInput
                          id="firstName"
                          name="firstName"
                          labelText="First Name"
                          placeholder="First name"
                          value={this.state.firstName}
                          onChange={this.handleChange}
                        />
                      </FormItem>
                    </FormGroup>

                    <FormGroup legendText="" className="bx--col-md-4">
                      <FormItem>
                        <TextInput
                          id="lastName"
                          name="lastName"
                          labelText="Last Name"
                          placeholder="Last name"
                          value={this.state.lastName}
                          onChange={this.handleChange}
                        />
                      </FormItem>
                    </FormGroup>

                    <FormGroup legendText="" className="bx--col-md-4">
                      <FormItem>
                        <Select
                          id="gender"
                          className="f-width"
                          name="gender"
                          labelText="Gender"
                          value={this.state.gender}
                          onChange={this.handleChange}>
                          <SelectItem
                            disabled
                            selected={this.state.employeeToEdit == null}
                            text="Select Gender"
                            value=""
                          />
                          <SelectItem
                            text="Male"
                            selected={this.state.gender === 'Male'}
                            value="Male"
                          />
                          <SelectItem
                            text="Female"
                            selected={this.state.gender === 'Female'}
                            value="Female"
                          />
                        </Select>
                      </FormItem>
                    </FormGroup>

                    <FormGroup className="bx--col-md-4">
                      <FormItem>
                        <DatePicker
                          dateFormat="Y-m-d"
                          datePickerType="single"
                          id="dtae-of-birth"
                          onClose={(value) => {
                            if (value.length > 0) {
                              this.setState({
                                dateOfBirth: [
                                  value[0].getFullYear(),
                                  ('0' + (value[0].getMonth() + 1)).slice(-2),
                                  ('0' + value[0].getDate()).slice(-2),
                                ].join('-'),
                              });
                            }
                          }}>
                          <DatePickerInput
                            className=""
                            id="date"
                            labelText="Date Of Birth"
                            value={this.state.dateOfBirth}
                            onChange={() => {}}
                            pattern="d{1,2}/d{4}"
                            placeholder="YYYY-MM-DD"
                          />
                        </DatePicker>
                      </FormItem>
                    </FormGroup>

                    <FormGroup legendText="" className="bx--col-md-4">
                      <FormItem>
                        <TextInput
                          id="phonenumber"
                          name="phoneNumber"
                          labelText="Phone Number"
                          placeholder="Phone number"
                          value={this.state.phoneNumber}
                          onChange={this.handleChange}
                        />
                      </FormItem>
                    </FormGroup>

                    <FormGroup legendText="" className="bx--col-md-4">
                      <FormItem>
                        <TextInput
                          id="emailAddress"
                          name="emailAddress"
                          type="email"
                          labelText="Email Address"
                          placeholder="Email Address"
                          value={this.state.emailAddress}
                          onChange={this.handleChange}
                        />
                      </FormItem>
                    </FormGroup>
                  </div>

                  <div className="btn-actions mt-40">
                    <CustomButton
                      classes="secondary font-bold"
                      onClick={this.props.importAction}>
                      <Download size="11" strokeWidth="5px" />
                      &nbsp; IMPORT
                    </CustomButton>

                    <div className="">
                      <CustomButton
                        classes="outline danger font-bold"
                        onClick={this.props.closeAction}>
                        CANCEL
                      </CustomButton>

                      <CustomButton
                        classes="outline secondary ml-20 font-bold"
                        onClick={() => this.setState({ selectedTabIndex: 0 })}>
                        BACK
                      </CustomButton>

                      <CustomButton
                        classes="primary font-bold ml-20"
                        disabled={!this.validateBasicInfo()}
                        onClick={() => this.setState({ selectedTabIndex: 2 })}>
                        NEXT
                      </CustomButton>
                    </div>
                  </div>
                </div>
              </Tab>

              <Tab
                href="#"
                label="Professional Info."
                role="presentation"
                tabIndex={2}>
                <div className="tab3 mt-30">
                  <div className="bx--row">
                    <FormGroup legendText="" className="bx--col-md-4">
                      <FormItem>
                        <Select
                          id="department"
                          className="f-width"
                          labelText="Department"
                          onChange={(e) =>
                            this.setState({ departmentId: e.target.value })
                          }>
                          <SelectItem
                            selected={this.state.departmentId == -''}
                            disabled
                            text="Select Department"
                            value=""
                          />
                          {this.props.departments.map((department) => (
                            <SelectItem
                              selected={
                                department.id == this.state.departmentId
                              }
                              text={department.description}
                              value={department.id}
                            />
                          ))}
                        </Select>
                      </FormItem>
                    </FormGroup>

                    <FormGroup legendText="" className="bx--col-md-4">
                      <FormItem>
                        <Select
                          id="jobDescription"
                          className="f-width"
                          name="jobDescription"
                          labelText="Select Description"
                          value={this.state.jobDescription}
                          onChange={this.handleChange}>
                          <SelectItem
                            disabled
                            selected={this.state.employeeToEdit == null}
                            text="Job Description"
                            value=""
                          />

                          <SelectItem
                            selected={this.state.jobDescription === 'Principal'}
                            value="Principal"
                            text="Principal"
                          />

                          <SelectItem
                            selected={
                              this.state.jobDescription ===
                              'Administrative Assistant'
                            }
                            value="Administrative Assistant"
                            text="Administrative Assistant"
                          />

                          <SelectItem
                            selected={
                              this.state.jobDescription === 'Account Officer'
                            }
                            value="Account Officer"
                            text="Account Officer"
                          />

                          <SelectItem
                            selected={
                              this.state.jobDescription === 'Accountant'
                            }
                            value="Accountant"
                            text="Accountant"
                          />

                          <SelectItem
                            selected={
                              this.state.jobDescription ===
                              'Procurement Officer'
                            }
                            value="Procurement Officer"
                            text="Procurement Officer"
                          />

                          <SelectItem
                            selected={this.state.jobDescription === 'Director'}
                            value="Director"
                            text="Director"
                          />

                          <SelectItem
                            selected={
                              this.state.jobDescription === 'Kitchen Staff'
                            }
                            value="Kitchen Staff"
                            text="Kitchen Staff"
                          />

                          <SelectItem
                            selected={this.state.jobDescription === 'Driver'}
                            value="Driver"
                            text="Driver"
                          />

                          <SelectItem
                            selected={
                              this.state.jobDescription === 'Class Teacher'
                            }
                            value="Class Teacher"
                            text="Class Teacher"
                          />

                          <SelectItem
                            selected={
                              this.state.jobDescription === 'Subject Teacher'
                            }
                            value="Subject Teacher"
                            text="Subject Teacher"
                          />

                          <SelectItem
                            selected={this.state.jobDescription === 'Attendant'}
                            value="Attendant"
                            text="Attendant"
                          />

                          <SelectItem
                            selected={
                              this.state.jobDescription === 'Academic Head'
                            }
                            value="Academic Head"
                            text="Academic Head"
                          />

                          <SelectItem
                            selected={this.state.jobDescription === 'Other'}
                            value="Other"
                            text="Other"
                          />
                        </Select>
                      </FormItem>
                    </FormGroup>

                    <FormGroup legendText="" className="bx--col-md-4">
                      <FormItem>
                        <Select
                          id="qualification"
                          className="f-width"
                          name="qualification"
                          labelText="Qualification"
                          value={this.state.qualification}
                          onChange={this.handleChange}>
                          <SelectItem
                            disabled
                            selected={this.state.employeeToEdit == null}
                            text="Select Qualification"
                            value=""
                          />

                          <SelectItem
                            selected={
                              this.state.qualification === 'First Degree'
                            }
                            value="First Degree"
                            text="First Degree"
                          />

                          <SelectItem
                            selected={
                              this.state.qualification === 'Masters Degree'
                            }
                            value="Masters Degree"
                            text="Masters Degree"
                          />

                          <SelectItem
                            selected={this.state.qualification === 'Doctorate'}
                            value="Doctorate"
                            text="Doctorate"
                          />

                          <SelectItem
                            selected={
                              this.state.qualification === 'High School Grad.'
                            }
                            value="High School Grad."
                            text="High School Grad."
                          />

                          <SelectItem
                            selected={
                              this.state.qualification === 'Trained Teacher'
                            }
                            value="Trained Teacher"
                            text="Trained Teacher"
                          />

                          <SelectItem
                            selected={this.state.qualification === 'HND'}
                            value="HND"
                            text="HND"
                          />

                          <SelectItem
                            selected={this.state.qualification === 'Diploma'}
                            value="Diploma"
                            text="Diploma"
                          />

                          <SelectItem
                            selected={
                              this.state.qualification === 'Certificate'
                            }
                            value="Certificate"
                            text="Certificate"
                          />

                          <SelectItem
                            selected={this.state.qualification === 'License'}
                            value="License"
                            text="License"
                          />
                        </Select>
                      </FormItem>
                    </FormGroup>

                    <FormGroup className="bx--col-md-4">
                      <FormItem>
                        <DatePicker
                          dateFormat="Y-m-d"
                          datePickerType="single"
                          id="employment-date"
                          onClose={(value) => {
                            if (value.length > 0) {
                              this.setState({
                                dateHired: [
                                  value[0].getFullYear(),
                                  ('0' + (value[0].getMonth() + 1)).slice(-2),
                                  ('0' + value[0].getDate()).slice(-2),
                                ].join('-'),
                              });
                            }
                          }}>
                          <DatePickerInput
                            className=""
                            id="date-hired"
                            name="dateHired"
                            labelText="Date Hired"
                            value={this.state.dateHired}
                            onChange={() => {}}
                            pattern="d{1,2}/d{4}"
                            placeholder="YYYY-MM-DD"
                          />
                        </DatePicker>
                      </FormItem>
                    </FormGroup>

                    <FormGroup legendText="" className="bx--col-md-4">
                      <FormItem>
                        <TextInput
                          id="ssnit"
                          name="ssnitNumber"
                          labelText="SSNIT Number"
                          placeholder="SSNIT Number"
                          value={this.state.ssnitNumber}
                          onChange={this.handleChange}
                        />
                      </FormItem>
                    </FormGroup>

                    <FormGroup legendText="" className="bx--col-md-4">
                      <FormItem>
                        <TextInput
                          id="tin"
                          name="tinNumber"
                          labelText="Ghana card/Tin Number"
                          placeholder="Ghana card/Tin Number"
                          value={this.state.tinNumber}
                          onChange={this.handleChange}
                        />
                      </FormItem>
                    </FormGroup>
                  </div>
                  <div className="btn-actions mt-40">
                    <CustomButton
                      classes="secondary font-bold"
                      onClick={this.props.importAction}>
                      <Download size="11" strokeWidth="5px" />
                      &nbsp; IMPORT
                    </CustomButton>

                    <div className="">
                      <CustomButton
                        classes="outline danger font-bold"
                        onClick={this.props.closeAction}>
                        CANCEL
                      </CustomButton>

                      <CustomButton
                        classes="outline secondary ml-20 font-bold"
                        onClick={() => this.setState({ selectedTabIndex: 1 })}>
                        BACK
                      </CustomButton>

                      <CustomButton
                        classes="primary font-bold ml-20"
                        disabled={
                          !this.validateBasicInfo() ||
                          this.state.departmentId.toString().length === 0
                        }
                        onClick={() => this.setState({ selectedTabIndex: 3 })}>
                        NEXT
                      </CustomButton>
                    </div>
                  </div>
                </div>
              </Tab>

              <Tab href="#" label="Bank Info." role="presentation" tabIndex={3}>
                <div className="tab3 mt-30">
                  <div className="bx--row">
                    <FormGroup legendText="" className="bx--col-md-4">
                      <FormItem>
                        <TextInput
                          id="bankName"
                          name="bankName"
                          labelText="Bank Name"
                          placeholder="N/A"
                          value={this.state.bankName}
                          onChange={this.handleChange}
                        />
                      </FormItem>
                    </FormGroup>

                    <FormGroup legendText="" className="bx--col-md-4">
                      <FormItem>
                        <TextInput
                          id="branch"
                          name="bankBranch"
                          labelText="Bank Branch"
                          placeholder="Bank Branch"
                          value={this.state.bankBranch}
                          onChange={this.handleChange}
                        />
                      </FormItem>
                    </FormGroup>

                    <FormGroup legendText="" className="bx--col-md-4">
                      <FormItem>
                        <TextInput
                          id="accountNumber"
                          name="accountNumber"
                          labelText="Account Number"
                          placeholder="Account Number"
                          value={this.state.accountNumber}
                          onChange={this.handleChange}
                        />
                      </FormItem>
                    </FormGroup>

                    <FormGroup legendText="" className="bx--col-md-4">
                      <FormItem>
                        <TextInput
                          id="sortCode"
                          name="bankSortCode"
                          labelText="Bank Sort Code"
                          placeholder="Bank Sort Code"
                          value={this.state.bankSortCode}
                          onChange={this.handleChange}
                        />
                      </FormItem>
                    </FormGroup>
                  </div>
                  <div className="btn-actions mt-40">
                    <CustomButton
                      classes="secondary font-bold"
                      onClick={this.props.importAction}>
                      <Download size="11" strokeWidth="5px" />
                      &nbsp; IMPORT
                    </CustomButton>

                    <div className="">
                      <CustomButton
                        classes="outline danger font-bold"
                        onClick={this.props.closeAction}>
                        CANCEL
                      </CustomButton>

                      <CustomButton
                        classes="outline secondary ml-20 font-bold"
                        onClick={() => this.setState({ selectedTabIndex: 2 })}>
                        BACK
                      </CustomButton>

                      <CustomButton
                        classes="primary font-bold ml-20"
                        onClick={() => this.setState({ selectedTabIndex: 4 })}>
                        NEXT
                      </CustomButton>
                    </div>
                  </div>
                </div>
              </Tab>

              <Tab href="#" label="Other Info" role="presentation" tabIndex={4}>
                <div className="tab4 mt-30">
                  <div className="bx--row">
                    <FormGroup legendText="" className="bx--col-md-4">
                      <FormItem>
                        <TextInput
                          id="emergencyname"
                          name="emergencyContactName"
                          labelText="Emergency Contact Name"
                          placeholder="Emergency Contact Name"
                          value={this.state.emergencyContactName}
                          onChange={this.handleChange}
                        />
                      </FormItem>
                    </FormGroup>

                    <FormGroup legendText="" className="bx--col-md-4">
                      <FormItem>
                        <TextInput
                          id="emergencyname"
                          name="emergencyContactPhoneNumber"
                          labelText="Emergency Contact Phone Number"
                          placeholder="Emergency Contact Phone Number"
                          value={this.state.emergencyContactPhoneNumber}
                          onChange={this.handleChange}
                        />
                      </FormItem>
                    </FormGroup>

                    <FormGroup legendText="" className="bx--col-md-8">
                      <FormItem>
                        <TextArea
                          id="comments"
                          name="comments"
                          className="f-width"
                          labelText="Comments"
                          cols={1}
                          rows={7}
                          value={this.state.comments}
                          onChange={this.handleChange}
                          placeholder="Add comments for employee"
                        />
                      </FormItem>
                    </FormGroup>
                  </div>

                  <div className="btn-actions mt-40">
                    <CustomButton classes="secondary font-bold">
                      <Download size="11" strokeWidth="5px" />
                      &nbsp; IMPORT
                    </CustomButton>

                    <div className="">
                      <CustomButton
                        classes="outline danger font-bold"
                        onClick={this.props.closeAction}>
                        CANCEL
                      </CustomButton>

                      <CustomButton
                        classes="outline secondary ml-20 font-bold"
                        onClick={() => this.setState({ selectedTabIndex: 3 })}>
                        BACK
                      </CustomButton>

                      <CustomButton
                        classes="primary font-bold ml-20"
                        disabled={
                          !this.validateBasicInfo() ||
                          this.state.departmentId.toString().length === 0 ||
                          this.state.loading
                        }
                        onClick={this.addEmployee}>
                        {this.state.addBtnText}
                        <div hidden={this.state.addBtnText != null}>
                          <div className="d-flex-center">
                            <Loading
                              withOverlay={false}
                              small={true}
                              description="Adding employee..."
                            />
                          </div>
                        </div>
                      </CustomButton>
                    </div>
                  </div>

                  <div
                    className="mt-30"
                    hidden={this.state.errorMessage == null}>
                    <span className="mt-10 text-danger">
                      {this.state.errorMessage}.
                    </span>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </Form>
        </div>
      </Modal>
    );
  }
}

// this will provide us with the state values we need
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  departments: selectDepartments,
});

const mapDispatchToProps = (dispatch) => ({
  setEmployees: (employees) => dispatch(setEmployees(employees)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEmployeeModal);
