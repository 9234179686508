import React from 'react';
import {
  Select,
  SelectItem,
  FormItem,
  Form,
  FormGroup,
  Modal,
} from 'carbon-components-react';
import CustomButton from '../../custom-button/custom-btn.component';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { selectCurrentUser } from '../../../redux/selectors/user.selectors';

import {
  selectAcademicYears,
  selectTerms,
} from '../../../redux/selectors/academic-year.selectors';
import { withRouter } from 'react-router-dom';
import { selectClasses } from '../../../redux/selectors/class.selectors';

import './bill-students.styles.scss';

class BillStudentsModal extends React.Component {
  constructor(props) {
    super(props);

    // set state
    this.state = {
      id: 0,
      loading: false,
      btnText: 'NEXT',
      heading: 'Bill Students',
      message: null,
      messageClass: '',
      openModal: props.openModal,
      selectedClassId: null,
      selectedClass: null,
      selectedAcademicYearId: null,
      selectedTermId: null,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.openModal !== prevState.openModal) {
      return { openModal: nextProps.openModal };
    } else return null;
  }

  render() {
    let terms = [];

    if (this.state.selectedAcademicYearId) {
      // find terms in academic year
      terms = this.props.terms.filter(
        (term) => term.academicYearId == this.state.selectedAcademicYearId,
      );

      // filter out valid terms
      terms = terms.filter((term) => new Date(term.endDate) > new Date());

      // filter based on class being a semester or not
      if (this.state.selectedClass) {
        terms = terms.filter(
          (term) => term.semester === this.state.selectedClass.semester,
        );
      }
    }

    return (
      <Modal
        id="bill-students-modal"
        className="bill-students-modal"
        hasScrollingContent
        passiveModal={true}
        open={this.state.openModal}
        modalHeading={this.state.heading}
        primaryButtonDisabled={false}>
        <div>
          <Form action="post">
            <p className="text-16 mb-20 font-500">
              Please select the class you want to bill, academic year and
              term/semester to proceed
            </p>

            <FormGroup>
              <FormItem>
                <Select
                  id="class-select"
                  className="f-width"
                  name="class"
                  labelText="Class"
                  onChange={(e) =>
                    this.setState({
                      selectedClassId: e.target.value,
                      selectedClass: this.props.classes.find(
                        (c) => c.id === parseInt(e.target.value),
                      ),
                    })
                  }>
                  <SelectItem disabled selected text="Select Class" value="" />

                  {this.props.classes
                    .filter((c) => c.totalStudents > 0)
                    .map((c, j) => (
                      <SelectItem
                        key={j}
                        value={c.id}
                        text={`${c.description} - ${c.totalStudents} Student(s)`}
                      />
                    ))}
                </Select>
              </FormItem>
            </FormGroup>

            <FormGroup>
              <FormItem>
                <Select
                  id="academic-year-select"
                  className="f-width"
                  name="academicYear"
                  labelText="Academic Year"
                  onChange={(e) =>
                    this.setState({
                      selectedAcademicYearId: e.target.value,
                      selectedTermId: null,
                    })
                  }>
                  <SelectItem
                    disabled
                    selected
                    text="Select Academic Year"
                    value=""
                  />

                  {this.props.academicYears
                    .filter((i) => i.status !== 'Closed')
                    .map((academicYear) => (
                      <SelectItem
                        value={academicYear.id}
                        text={academicYear.description}
                      />
                    ))}
                </Select>
              </FormItem>
            </FormGroup>

            <FormGroup className="nmb">
              <FormItem>
                <Select
                  id="term-select"
                  className="f-width"
                  name="term"
                  labelText="Term/Semester"
                  onChange={(e) =>
                    this.setState({ selectedTermId: e.target.value })
                  }>
                  <SelectItem
                    disabled
                    selected={!this.state.selectedTermId}
                    text="Select Term/Semester"
                    value=""
                  />

                  {terms.map((term) => (
                    <SelectItem
                      selected={this.state.selectedTermId == term.id}
                      value={term.id}
                      text={term.description}
                    />
                  ))}
                </Select>
              </FormItem>
            </FormGroup>

            <p
              className={`text-14 mt-10 ${this.state.messageClass}`}
              hidden={this.state.message === null}>
              {this.state.message}
            </p>

            <div className="bx--row mt-60">
              <div className="bx--col-md-4 pt-20">
                <span
                  className="text-danger ml-20  f-width pointer font-bold"
                  onClick={this.props.cancelAction}>
                  CANCEL
                </span>
              </div>
              <div className="bx--col-md-4">
                <CustomButton
                  classes="primary f-width  font-bold"
                  disabled={
                    !this.state.selectedTermId && !this.state.selectClassId
                  }
                  onClick={() =>
                    this.props.history.push(
                      `/billing/bill-students/${this.state.selectedClassId}/${this.state.selectedAcademicYearId}/${this.state.selectedTermId}`,
                    )
                  }>
                  NEXT
                </CustomButton>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    );
  }
}

// this will provide us with the state values we need
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  academicYears: selectAcademicYears,
  terms: selectTerms,
  classes: selectClasses,
});

export default withRouter(connect(mapStateToProps)(BillStudentsModal));
