// import types
import StudentActionTypes from '../types/student.types';
import { addItems, removeItem } from '../utils/common.util';

// set initial state
const INITIAL_STATE = {
  students: [],
  activeWard: {
    data: null,
    bills: [],
    payments: [],
  },
};

// create reducer with intial state if no state is received
const studentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case StudentActionTypes.SET_STUDENTS:
      return {
        // spread in the state
        ...state,
        students: addItems(state.students, action.payload),
      };
    case StudentActionTypes.REMOVE_STUDENT:
      return {
        // spread in the state
        ...state,
        students: removeItem(state.students, action.payload),
      };

    case StudentActionTypes.SET_WARD:
      return {
        // spread in the state
        ...state,
        activeWard: action.payload,
      };
    default:
      // no action matched, just return the state
      return state;
  }
};

// export out studentReducer as default
export default studentReducer;
