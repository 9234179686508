import React from 'react';
import { Plus } from 'react-feather';

// import components
import MainHeader from '../../components/main-header/main-header.component';
import AddAcademicYearModal from '../../components/modal/add-academic-year/add-academic-year.component';
import AddLedgerAccountModal from '../../components/modal/add-ledger-account/add-ledger-account.component';
import AddLedgerCategoryModal from '../../components/modal/add-ledger-category/add-ledger-category.component';
import AddBudgetModal from '../../components/modal/add-budget/add-budget.component';
import MainMenu from '../../components/menu/main-menu/main-menu.component';
import QuickActionCard from '../../components/card/quick-action-card/quick-action-card.component';
import Footer from '../../components/footer/footer.component';

// import svg icons
import { ReactComponent as Calculator } from '../../assets/images/card-icons/calculator.svg';
import { ReactComponent as Hierarchy } from '../../assets/images/card-icons/hierarchy.svg';
import { ReactComponent as Invoice } from '../../assets/images/card-icons/invoice.svg';
import { ReactComponent as Budget } from '../../assets/images/card-icons/budget.svg';

import { selectCurrentUser } from '../../redux/selectors/user.selectors';
import { createStructuredSelector } from 'reselect';
import {
  selectAcademicYears,
  selectBudgets,
} from '../../redux/selectors/academic-year.selectors';
import {
  selectCategories,
  selectGroups,
} from '../../redux/selectors/chart-of-accounts.selector';
import { connect } from 'react-redux';
import { Search, Loading } from 'carbon-components-react';
import BudgetCard from '../../components/card/budget-card/budget-card.component';
import { withRouter } from 'react-router-dom';
import { getBudgets, getAcademicYears } from '../../api/academic-year.service';

import './budget.styles.scss';

const plusIcon = <Plus size="25" strokeWidth="2px" />;

class BudgetPage extends React.Component {
  constructor(props) {
    super(props);

    // set state
    this.state = {
      query: '',
      loading: true,
      budgets: [],
      academicYears: [],
      errorMessage: null,
      openAddBudgetModal: false,
    };
  }

  componentDidMount() {
    this.fetchBudgets();
  }

  fetchBudgets = async () => {
    // show loading if only is not already showing
    if (!this.state.loadingBudget)
      this.setState({ loading: true, errorMessage: null });

    const { authToken } = this.props.currentUser;

    // initiate request
    let response = await getBudgets(authToken);

    // request failed, show error message
    if (!response.success) {
      this.setState({
        loading: false,
        errorMessage: response.message,
      });

      return;
    }

    // successful
    this.setState({ loading: false, budgets: response.budgets });

    if (this.state.academicYears.length === 0) {
      let response = await getAcademicYears(authToken, 0);

      if (response.success) {
        this.setState({
          academicYears: response.academicYears.filter((m) => m.canAddBudget),
        });
      }
    }
  };

  toggleAddBudgetModal = () => {
    this.setState({ openAddBudgetModal: !this.state.openAddBudgetModal });
  };

  render() {
    let budgets = this.state.budgets;

    if (this.state.query.length > 0) {
      budgets = this.state.budgets.filter((budget) =>
        budget.academicYearDescription
          .toLowerCase()
          .includes(this.state.query.toLowerCase()),
      );
    }

    return (
      <div>
        <MainHeader />

        <AddBudgetModal
          open={this.state.openAddBudgetModal}
          academicYears={this.state.academicYears}
          cancelAction={this.toggleAddBudgetModal}
        />

        <AddAcademicYearModal
          openModal={this.state.openAddAcademicYearModal}
          cancelAction={() =>
            this.setState({ openAddAcademicYearModal: false })
          }
        />

        <AddLedgerAccountModal
          openModal={this.state.openAddLedgerAccountModal}
          selectedLedgerCategory={null}
          selectedLedgerAccount={null}
          cancelAction={() =>
            this.setState({ openAddLedgerAccountModal: false })
          }
        />

        <AddLedgerCategoryModal
          openModal={this.state.openAddLedgerCategoryModal}
          cancelAction={() =>
            this.setState({ openAddLedgerCategoryModal: false })
          }
        />

        <div className="budget page-container">
          <div>
            <span className="h-18">Quick Actions</span>
            <span className="right">
              <MainMenu />
            </span>
          </div>

          <div className="bx--row mt-30">
            <div className="bx--col-md-2">
              <div
                className="add-budget-card pointer"
                onClick={this.toggleAddBudgetModal}>
                <div className="plus-icon">{plusIcon}</div>
                <div className="text-label">CREATE NEW BUDGET</div>
              </div>
            </div>

            <div className="bx--col-md-2">
              <QuickActionCard
                description="Academic Years"
                actionEvent1={() =>
                  this.setState({ openAddAcademicYearModal: true })
                }
                actionEvent2={() => this.props.history.push('/academic-year')}>
                <Invoice />
              </QuickActionCard>
            </div>

            <div className="bx--col-md-2">
              <QuickActionCard
                description="Ledgers"
                actionEvent1={() =>
                  this.setState({
                    openAddLedgerAccountModal: true,
                  })
                }
                actionEvent2={() =>
                  this.props.history.push('/chart-of-accounts')
                }>
                <Calculator />
              </QuickActionCard>
            </div>

            <div className="bx--col-md-2">
              <QuickActionCard
                description="Categories"
                actionEvent1={() =>
                  this.setState({
                    openAddLedgerCategoryModal: true,
                  })
                }
                actionEvent2={() =>
                  this.props.history.push('/chart-of-accounts')
                }>
                <Hierarchy />
              </QuickActionCard>
            </div>
          </div>

          <hr className="grey-hr mt-20" />

          <h3 className="h-20 mt-20">Budgets</h3>
          <div className="mt-10">
            <Search
              id="query"
              name="query"
              value={this.state.query}
              placeHolderText="Search by Academic year"
              onChange={(event) => this.setState({ query: event.target.value })}
            />
          </div>

          <div className="mt-20 fade-in" hidden={!this.state.loading}>
            <Loading
              small={true}
              withOverlay={false}
              description="Loading..."
            />
          </div>

          <div
            className="mt-30 fade-in"
            hidden={this.state.errorMessage == null}>
            <span className="mt-10 text-danger">
              {this.state.errorMessage}.
            </span>
            &nbsp;
            <span
              className="text-primary pointer font-bold"
              onClick={() => this.fetchBudgets()}>
              TRY AGAIN
            </span>
          </div>

          <p
            className="text-14 mt-30 text-grey fade-in"
            hidden={
              this.state.budgets.length > 0 ||
              this.state.loading ||
              this.state.errorMessage !== null
            }>
            There are no budgets
          </p>

          <div className="bx--row mb-40 mt-30">
            {budgets.map((budget) => (
              <div key={budget.id} className="bx--col-md-2 fade-in">
                <BudgetCard
                  budget={budget}
                  btnAction={() =>
                    this.props.history.push(`/budget/${budget.id}`)
                  }>
                  <Budget />
                </BudgetCard>
              </div>
            ))}
          </div>

          <Footer fixed={false} showLinks={false} />
        </div>
      </div>
    );
  }
}

// this will provide us with the state values we need
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  academicYears: selectAcademicYears,
  budgets: selectBudgets,
  groups: selectGroups,
  ledgerCategories: selectCategories,
});

export default withRouter(connect(mapStateToProps)(BudgetPage));
