import React from 'react';
import queryString from 'query-string';

import BorderedLogo from '../../../components/bordered-logo/bordered-logo.component';
import CustomButton from '../../../components/custom-button/custom-btn.component';
import Footer from '../../../components/footer/footer.component';
import Slider from '../../../components/slider/slider.component';

import image from '../../../assets/images/register_bg.png';
import { withRouter } from 'react-router-dom';

const AccountVerificationPage = ({ history: { location } }) => {
  let params = queryString.parse(location.search);
  const { email, failed } = params;

  return (
    <div className="account-verification">
      <div className="bx--row">
        <div className="bx--col-md-5">
          <div className="ml-85 mr-85">
            <div className="mt-60 mb-50">
              <BorderedLogo reduceVersionTextMargin={true} />
            </div>

            {failed ? (
              <div>
                <h3 className="text-danger font-bold text-24 mb-30">
                  Account Verification Failed
                </h3>

                <p className="text-16 text-grey-dark mt-20">
                  We could not verify your account.
                  <br />A new verification email have been sent to&nbsp;
                  <span className="text-primary">{email}</span>.<br />
                  Follow the link in the email to verify your account. If the
                  problem persists, please contact support.
                </p>
              </div>
            ) : (
              <div>
                <h3 className="text-primary font-bold text-24 mb-30">
                  Account Verified Successfully!
                </h3>

                <p className="text-16 text-grey-dark mt-20">
                  We have successfully verified your account
                  <br />
                  Our team will contact you shortly to complete the registration
                  process.
                </p>
              </div>
            )}

            <div className="bx--row">
              <div className="bx--col-md-4">
                <CustomButton classes="primary f-width mt-50 mb-40">
                  <a
                    href="https://www.epobssuite.com/"
                    className="no-decoration text-white">
                    VISIT OUR WEBSITE TO KNOW MORE ABOUT EPOBS CSS
                  </a>
                </CustomButton>
              </div>
            </div>

            <Footer />
          </div>
        </div>
        <div className="bx--col-md-3 fixed-right padding-0">
          <div className="bx--row">
            <Slider />
          </div>
          <img className="img-f-w-obj-cover" src={image} alt="" />
        </div>
      </div>
    </div>
  );
};

export default withRouter(AccountVerificationPage);
