import { call, put, all, takeLatest } from 'redux-saga/effects';
import { fetchData } from '../../api/api';
import { createAmountOwedEndpoint } from '../../api/endpoints';

function* calculateAmountOwedByStudents(action) {
  const response = yield call(
    fetchData,
    createAmountOwedEndpoint(-1),
    action.payload.authToken,
  );

  yield onResponse(
    response,
    'CALCULATE_AMOUNT_OWED_BY_STUDENTS_SUCCEEDED',
    'CALCULATE_AMOUNT_OWED_BY_STUDENTS_FAILED',
  );
}

function* onResponse(response, actionTypeSuccess, actionTypeFailed) {
  if (response.success) {
    yield put({
      type: actionTypeSuccess,
      payload: response.data,
    });
  } else {
    yield put({
      type: actionTypeFailed,
      payload: response.message,
    });
  }
}

function* onCalculateAmountOwedByStudents() {
  yield takeLatest(
    'CALCULATE_AMOUNT_OWED_BY_STUDENTS_REQUESTED',
    calculateAmountOwedByStudents,
  );
}

export default function* billingSagas() {
  yield all([call(onCalculateAmountOwedByStudents)]);
}
