// using reselect
import { createSelector } from 'reselect';

// create select class function
const selectClass = (state) => state.class_;

// create selectClasses selector
export const selectClasses = createSelector(
  [selectClass],
  (class_) => class_.classes,
);

export const selectClassLabels = createSelector(
  [selectClass],
  (class_) => class_.classLabels,
);
