import React from 'react';
import { DonutChart as CarbonDonutChart } from '@carbon/charts-react';

import './donut.styles.scss';

const DonutChart = ({ data, loading = true }) => {
  const options = {
    title: '',
    resizable: true,
    height: '250px',
    data: {
      loading,
    },
  };
  return <CarbonDonutChart data={data} options={options} />;
};

export default DonutChart;
