import React from 'react';
import {
  TextInput,
  Select,
  SelectItem,
  FormItem,
  Form,
  FormGroup,
  Modal,
  Loading,
} from 'carbon-components-react';
import CustomButton from '../../custom-button/custom-btn.component';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

// import our redux stuffs
import { selectCategories } from '../../../redux/selectors/chart-of-accounts.selector';
import {
  setLedgerAccounts,
  setLedgerCategories,
} from '../../../redux/actions/chart-of-accounts.actions';
import { selectCurrentUser } from '../../../redux/selectors/user.selectors';

// import api stuffs
import {
  addOrUpdateLedgerAccount,
  getLedgerCategories,
} from '../../../api/chart-of-accounts.service';

import './add-ledger-account.styles.scss';

class AddLedgerAccountModal extends React.Component {
  constructor(props) {
    super(props);

    // set state
    this.state = {
      id: 0,
      loading: false,
      btnText: 'ADD',
      heading: 'Add new ledger',
      message: null,
      messageClass: '',
      openModal: props.openModal,
      description: '',
      categoryId: -1,
      categoryGroupDescription: 'N/A',
      categoryGroupFinancialStatement: 'N/A',
      authToken: this.props.currentUser.authToken,
      ledgerCategories: this.props.ledgerCategories,
    };
  }

  componentDidMount() {
    // if ledger categories are empty, go fetch them
    if (this.state.ledgerCategories.length === 0) {
      getLedgerCategories(this.state.authToken, 0, 500).then((response) => {
        if (response.success) {
          // set ledger categories
          this.props.setLedgerCategories(response.ledgerCategories);
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.selectedLedgerCategory !== prevProps.selectedLedgerCategory
    ) {
      if (this.props.selectedLedgerCategory === null) {
        this.setState({
          categoryId: -1,
          description: '',
          message: null,
          btnText: 'ADD',
          heading: 'Add new ledger',
          categoryGroupDescription: 'N/A',
          categoryGroupFinancialStatement: 'N/A',
        });
      } else {
        const categoryId = this.props.selectedLedgerCategory.id;

        const category = this.props.ledgerCategories.find(
          (category) => category.id === categoryId,
        );

        this.setState({
          categoryId: categoryId,
          description: '',
          message: null,
          btnText: 'ADD',
          heading: 'Add new ledger',
          categoryGroupDescription: category.groupDescription,
          categoryGroupFinancialStatement: category.groupFinancialStatement,
        });
      }
    }

    if (this.props.selectedLedgerAccount !== prevProps.selectedLedgerAccount) {
      if (this.props.selectedLedgerAccount === null) {
        this.setState({
          id: 0,
          description: '',
          message: null,
          btnText: 'ADD',
          heading: 'Add new ledger',
        });
      } else {
        this.setState({
          id: this.props.selectedLedgerAccount.id,
          description: this.props.selectedLedgerAccount.description,
          message: null,
          btnText: 'UPDATE',
          heading: 'Update ledger',
        });
      }
    }

    if (
      this.props.ledgerCategories.length !== prevProps.ledgerCategories.length
    ) {
      this.setState({ ledgerCategories: this.props.ledgerCategories });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.openModal !== prevState.openModal) {
      return { openModal: nextProps.openModal };
    } else return null;
  }

  // handle onchange event handler for input fields
  handleChange = (event) => {
    // destructure value and name of event.target
    const { value, name } = event.target;

    let categoryGroupDescription = this.state.categoryGroupDescription;
    let categoryGroupFinancialStatement =
      this.state.categoryGroupFinancialStatement;
    if (name === 'categoryId') {
      const category = this.props.ledgerCategories.find(
        (category) => category.id === parseInt(value),
      );

      categoryGroupDescription = category.groupDescription;
      categoryGroupFinancialStatement = category.groupFinancialStatement;
    }

    // update state
    this.setState({
      [name]: value,
      categoryGroupDescription,
      categoryGroupFinancialStatement,
    });
  };

  // validate data entered
  validateData = () =>
    this.state.description.length > 0 && this.state.categoryId !== -1;

  onAddButtonClicked = async (event) => {
    event.preventDefault();

    // update state to set request in progress to true and error occurred to false
    this.setState({
      loading: true,
      message: null,
      btnText: null,
    });

    // build data
    const data = {
      id: this.state.id,
      categoryId: parseInt(this.state.categoryId),
      description: this.state.description,
    };

    // initiate request
    const response = await addOrUpdateLedgerAccount(this.state.authToken, data);

    if (!response.success) {
      this.setState({
        loading: false,
        message: response.message,
        messageClass: 'text-danger',
        btnText: this.state.id === 0 ? 'ADD' : 'UPDATE',
      });

      return;
    }

    // on success
    this.setState({
      loading: false,
      description: this.state.id === 0 ? '' : this.state.description,
      message: `${response.message} ${
        this.state.id === 0 ? 'Add more' : 'Continue editing'
      } or close this dialog.`,
      messageClass: 'text-success',
      btnText: this.state.id === 0 ? 'ADD' : 'UPDATE',
    });

    // update redux
    this.props.setLedgerAccounts([response.ledgerAccount]);
  };

  render() {
    const { selectedLedgerCategory } = this.props;

    return (
      <Modal
        id="add-ledger-account-modal"
        className="add-ledger-account-modal"
        hasScrollingContent
        passiveModal={true}
        open={this.state.openModal}
        modalHeading={this.state.heading}
        primaryButtonDisabled={false}>
        <div>
          <Form action="post">
            <FormGroup legendText="">
              <FormItem>
                <TextInput
                  id="description"
                  name="description"
                  labelText="Ledger Name"
                  placeholder="E.g. Fuel"
                  value={this.state.description}
                  onChange={this.handleChange}
                />
              </FormItem>

              {/*<p className="hint">
                Hint: This can be the name of an item for department. Keep
                simple and descriptive
    </p>*/}
            </FormGroup>

            <FormGroup className="nmb">
              <FormItem>
                <Select
                  id="category-select"
                  className="f-width"
                  name="categoryId"
                  labelText="Select Category"
                  onChange={this.handleChange}>
                  <SelectItem
                    disabled
                    selected={
                      selectedLedgerCategory === undefined ||
                      selectedLedgerCategory === null
                    }
                    text="Choose a category"
                    value=""
                  />
                  {this.state.ledgerCategories.map((category) => (
                    <SelectItem
                      key={category.id}
                      selected={
                        selectedLedgerCategory &&
                        selectedLedgerCategory.id === category.id
                      }
                      text={`${category.description} [LCAT-${category.code}]`}
                      value={category.id}
                    />
                  ))}
                </Select>
              </FormItem>
            </FormGroup>

            <div>
              <p
                style={{
                  fontSize: '0.75rem',
                  letterSpacing: '0.32px',
                  color: '#AAB0C6',
                  fontWeight: '400',
                }}
                className="text-grey-dark text-14 mt-30">
                Group
              </p>
              <p className="text-dark text-14">
                {this.state.categoryGroupDescription}
              </p>
            </div>

            <div>
              <p
                style={{
                  fontSize: '0.75rem',
                  letterSpacing: '0.32px',
                  color: '#AAB0C6',
                  fontWeight: '400',
                }}
                className="text-grey-dark text-14 mt-10">
                Financial Report
              </p>
              <p className="text-dark text-14">
                {this.state.categoryGroupFinancialStatement}
              </p>
            </div>

            <CustomButton
              type="submit"
              classes="primary f-width mt-30 font-bold"
              disabled={!this.validateData() || this.state.loading}
              onClick={this.onAddButtonClicked}>
              {this.state.btnText}
              <div hidden={this.state.btnText != null}>
                <div className="d-flex-center">
                  <Loading
                    withOverlay={false}
                    small={true}
                    description="Adding ledger account..."
                  />
                </div>
              </div>
            </CustomButton>

            <p
              className={`text-14 mt-10 ${this.state.messageClass}`}
              hidden={this.state.message === null}>
              {this.state.message}
            </p>

            <CustomButton
              classes="outline danger f-width font-bold mt-20"
              disabled={this.state.loading}
              onClick={this.props.cancelAction}>
              CLOSE
            </CustomButton>
          </Form>
        </div>
      </Modal>
    );
  }
}

// this will provide us with the state values we need
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  ledgerCategories: selectCategories,
});

// this allows us to map our redux actions to our component
const mapDispatchToProps = (dispatch) => ({
  setLedgerAccounts: (ledgerAccounts) =>
    dispatch(setLedgerAccounts(ledgerAccounts)),
  setLedgerCategories: (ledgerCategories) =>
    dispatch(setLedgerCategories(ledgerCategories)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddLedgerAccountModal);
