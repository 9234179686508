// import types
import BillingActionTypes from '../types/billing.types';
import { addItems, removeItem } from '../utils/common.util';

// set initial state
const INITIAL_STATE = {
  fees: [],
  billings: [],
  billedStudents: [],
};

// create reducer with intial state if not state is received
const billingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BillingActionTypes.SET_BILLING_FEES:
      return {
        // spread in the state
        ...state,
        fees: addItems(state.fees, action.payload),
      };
    case BillingActionTypes.REMOVE_BILLING_FEE:
      return {
        // spread in the state
        ...state,
        fees: removeItem(state.fees, action.payload),
      };
    case BillingActionTypes.SET_BILLINGS:
      return {
        // spread in the state
        ...state,
        billings: addItems(state.billings, action.payload, true),
      };
    case BillingActionTypes.SET_BILLED_STUDENTS:
      return {
        // spread in the state
        ...state,
        billedStudents: addItems(
          state.billedStudents,
          action.payload,
          false,
          true,
        ),
      };
    case BillingActionTypes.REMOVE_BILLING:
      return {
        // spread in the state
        ...state,
        billings: removeItem(state.billings, action.payload),
      };
    case BillingActionTypes.REMOVE_STUDENT_BILL:
      return {
        // spread in the state
        ...state,
        billedStudents: removeItem(state.billedStudents, action.payload),
      };
    default:
      // no action matched, just return the state
      return state;
  }
};

// export out billingReducer as default
export default billingReducer;
