import { getData, postData } from './common';

// 1. GET DATA
// 2. POST DATA

// function to get all employees
export const getEmployees = async (authToken) => {
  const response = await getData('employee/list', authToken);

  if (response.success) {
    return {
      success: true,
      employees: response.data,
    };
  }

  return response;
};

// function to create an employee
export const createEmployee = async (data, authToken) => {
  const response = await postData('employee/create-new', data, authToken);
  if (response.success) {
    return {
      success: true,
      employee: response.data.employee,
      message: `Employee ${data.id === 0 ? 'created' : 'updated'} successfully.`,
    };
  }

  return response;
};

export const deleteEmployee = async (authToken, data) => {
  return await postData('employee/delete', data, authToken);
};

export const terminateEmployee = async (authToken, data) => {
  const response = await postData('employee/terminate', data, authToken);

  if (response.success) return { success: true, data: response.data.employee };

  return response;
};

export const reinstateEmployee = async (authToken, data) => {
  const response = await postData('employee/reinstate', data, authToken);

  if (response.success) return { success: true, data: response.data.employee };

  return response;
};

export const uploadEmployeePhoto = async (authToken, data) => {
  const response = await postData('employee/upload-photo', data, authToken);

  if (response.success)
    return { success: true, employee: response.data.employee };

  return response;
};

export const importEmployees = async (authToken, data) => {
  return await postData('employee/import', data, authToken);
};

export const exportEmployees = async (authToken) => {
  return await getData('employee/export', authToken, true);
};
