import { postData, getData } from './common';

export const getPayments = async (
  authToken,
  skip,
  take,
  revised,
  reversed,
  studentId = -1,
) => {
  let response = await getData(
    `revenue/list/?skip=${skip}&take=${take}&revised=${revised}&reversed=${reversed}&studentId=${studentId}`,
    authToken,
  );
  if (response.success) {
    return {
      success: true,
      payments: response.data,
    };
  }

  return response;
};

export const newPayment = async (authToken, data, isBulkPayment = false) => {
  let url = isBulkPayment ? 'revenue/new/bulk' : 'revenue/new';
  let response = await postData(url, data, authToken);
  if (response.success) {
    return {
      success: true,
      payment: response.data.payment,
    };
  }

  return response;
};

export const revisePayment = async (authToken, data) => {
  let response = await postData('revenue/revise', data, authToken);
  if (response.success) {
    return {
      success: true,
      payment: response.data.payment,
    };
  }

  return response;
};

export const reversePayment = async (authToken, data) => {
  let response = await postData('revenue/reverse', data, authToken);
  if (response.success) {
    return {
      success: true,
      payment: response.data.payment,
    };
  }

  return response;
};
