import React from 'react';
import { Link } from 'react-router-dom';

import CustomButton from '../../custom-button/custom-btn.component';

import './navigation-card.styles.scss';

/*props=
image,
title,
textLabel
links={
    {icon,text,link},
    {icon,text,link}
}
buttonText

*/

const NavigationCard = ({
  children,
  title,
  subtitle = null,
  disabled = false,
  linkIcon1,
  linkIcon2,
  linkText1,
  linkText2,
  linkAction1 = null,
  linkAction2 = null,
  hideLink1 = false,
  hideLink2 = false,
  btnText,
  hideBtn = false,
  btnAction,
}) => {
  return (
    <div className={`navigation-card bg-white`}>
      <div>
        <div className="top-content">
          <div className="icon">{children}</div>
          <h3 className="title text-truncate">{title}</h3>
          <p className="subtitle text-truncate" hidden={subtitle == null}>
            {subtitle}
          </p>
        </div>

        <div>
          <div hidden={hideLink1}>
            <hr className="line" />
            <div className="text-truncate link pointer" onClick={linkAction1}>
              {linkIcon1}&nbsp;{linkText1}
            </div>
          </div>

          <div hidden={hideLink2}>
            <hr className="line line2" />
            <div className="text-truncate link pointer" onClick={linkAction2}>
              {linkIcon2}&nbsp;{linkText2}
            </div>
          </div>
        </div>

        <div className="bottom" hidden={hideBtn}>
          <CustomButton
            classes="btn text-truncate"
            disabled={disabled}
            onClick={btnAction}>
            {btnText}
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

/* const NavigationCard = ({ image, title, textLabel, links, buttonText, classes, ...otherProps }) => (
  <div className={`navigation-card-container ${classes}`}
    {...otherProps}
  >
      <div>
          <img className="card-image" src={image} alt=""/>
          <p className="text-12 text-grey mt-20"> </p>
          <h3 className="text-23 text-dark">

          </h3>
      </div>
  </div>
);
 */
export default NavigationCard;
