import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Plus } from 'react-feather';

import MainHeader from '../../../components/main-header/main-header.component';
import Mainmenu from '../../../components/menu/main-menu/main-menu.component';
import AddClassLabelModal from '../../../components/modal/add-class-label/add-class-label.component';
import Footer from '../../../components/footer/footer.component';
import ClassLabelCard from '../../../components/card/class-label-card/class-label-card.component';
import ConfirmDeleteModal from '../../../components/modal/confirm-delete/confirm-delete.component';
import { Search, Loading } from 'carbon-components-react';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../../redux/selectors/user.selectors';
import { selectClassLabels } from '../../../redux/selectors/class.selectors';
import {
  setClassLabels,
  removeClassLabel,
} from '../../../redux/actions/class.actions';
import { connect } from 'react-redux';
import {
  getClassLabels,
  deleteClassLabel,
  archiveClassLabel,
} from '../../../api/class.service';

import './labels.styles.scss';

class ClasseslabelPage extends React.Component {
  constructor(props) {
    super(props);

    // set state
    this.state = {
      query: '',
      loading: props.classLabels.length === 0,
      labels: props.classLabels,
      errorMessage: null,
      openAddClassLabelModal: false,
      openConfirmDeleteModal: false,
      selectedClassLabel: null,
      deleteDescription:
        'Please confirm class label deletion. This action cannot be reversed.',
      deleteAction: () => {},
      deleteSucessAction: () => {},
    };
  }

  componentDidMount() {
    // fetch classes only if length is 0
    if (this.state.labels.length === 0) {
      this.fetchLabels();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.classLabels.length !== prevProps.classLabels.length) {
      this.setState({ labels: this.props.classLabels });
    } else {
      // compare values
      this.props.classLabels.forEach((item) => {
        const oldItem = prevProps.classLabels.find((i) => i.id === item.id);

        if (item.description !== oldItem.description) {
          this.setState({ labels: this.props.classLabels });
          return;
        }
      });
    }
  }

  fetchLabels = async () => {
    // show loading if only is not already showing
    if (!this.state.loading)
      this.setState({ loading: true, errorMessage: null });

    // initiate request
    const response = await getClassLabels(this.props.currentUser.authToken);

    // request failed, show error message
    if (!response.success) {
      this.setState({ loading: false, errorMessage: response.message });

      return;
    }

    // successful
    this.setState({ loading: false });

    // save to redux
    this.props.setClassLabels(response.classLabels);
  };

  render() {
    const labels =
      this.state.query.length > 0
        ? this.state.labels.filter(
            (label) =>
              label.description
                .toLowerCase()
                .includes(this.state.query.toLowerCase()) ||
              label.uniqueId
                .toLowerCase()
                .includes(this.state.query.toLowerCase()),
          )
        : this.state.labels;

    return (
      <div>
        <MainHeader />

        <AddClassLabelModal
          openModal={this.state.openAddClassLabelModal}
          classLabel={this.state.selectedClassLabel}
          cancelAction={() => this.setState({ openAddClassLabelModal: false })}
        />

        <ConfirmDeleteModal
          openModal={this.state.openConfirmDeleteModal}
          description={this.state.deleteDescription}
          deleteAction={this.state.deleteAction}
          deleteSuccessAction={this.state.deleteSucessAction}
          cancelAction={() => this.setState({ openConfirmDeleteModal: false })}
        />

        <div className="classes-label page-container">
          <span className="right">
            <Mainmenu />
          </span>

          <p>
            <span className="text-12 font-bold text-grey">You are here</span>
            &nbsp;
            <Link to="/class" className="text-12 text-dark">
              Class
            </Link>
            &nbsp;<span className="text-12">&gt;</span>&nbsp;
            <span className="text-12 text-primary font-bold">Class Label</span>
          </p>

          <hr className="grey-hr mt-30" />

          <div className="mt-15">
            <span className="h-20">Class Labels</span>&nbsp; &nbsp;
            <span
              className="text-12 text-primary font-bold pointer"
              onClick={() =>
                this.setState({
                  openAddClassLabelModal: true,
                  selectedClassLabel: null,
                })
              }>
              <Plus size="11" strokeWidth="5px" />
              &nbsp; ADD NEW
            </span>
          </div>

          <div className="mt-20">
            <Search
              id="query"
              name="query"
              value={this.state.query}
              placeHolderText="Enter name of class label"
              onChange={(event) => this.setState({ query: event.target.value })}
            />
          </div>

          <div className="mt-20" hidden={!this.state.loading}>
            <Loading
              small={true}
              withOverlay={false}
              description="Loading Labels..."
            />
          </div>

          <div className="mt-30" hidden={this.state.errorMessage == null}>
            <span className="mt-10 text-danger">
              {this.state.errorMessage}.
            </span>
            &nbsp;
            <span
              className="text-primary pointer font-bold"
              onClick={() => this.fetchLabels()}>
              TRY AGAIN
            </span>
          </div>

          <p
            className="text-14 mt-30 text-grey"
            hidden={
              this.state.labels.length > 0 ||
              this.state.loading ||
              this.state.errorMessage !== null
            }>
            No labels added, please&nbsp;
            <span
              className="text-primary font-bold pointer"
              onClick={() => this.setState({ openAddClassLabelModal: true })}>
              add a new one
            </span>
          </p>

          <div className="bx--row">
            {labels.map((label) => (
              <div className="bx--col-lg-3 fade-in mt-30">
                <ClassLabelCard
                  label={label}
                  editAction={() =>
                    this.setState({
                      openAddClassLabelModal: true,
                      selectedClassLabel: label,
                    })
                  }
                  deleteAction={() =>
                    this.setState({
                      openConfirmDeleteModal: true,
                      deleteAction: async () => {
                        return await deleteClassLabel(
                          this.props.currentUser.authToken,
                          {
                            id: label.id,
                            description: label.description,
                          },
                        );
                      },
                      deleteSucessAction: () => {
                        this.props.removeClassLabel(label.id);
                      },
                      deleteDescription: `Confirm deletion of ${label.description} Class Label. This action cannot be reversed`,
                    })
                  }
                  archiveAction={() =>
                    this.setState({
                      openConfirmDeleteModal: true,
                      deleteAction: async () => {
                        return await archiveClassLabel(
                          this.props.currentUser.authToken,
                          {
                            ...label,
                            archived: true,
                          },
                        );
                      },
                      deleteSucessAction: () => {
                        this.props.removeClassLabel(label.id);
                        this.fetchLabels();
                      },
                      deleteDescription: `Confirm to archive this ${label.description} Class Label. This action cannot be reversed`,
                    })
                  }
                />
              </div>
            ))}
          </div>

          <Footer fixed={false} showLinks={false} />
        </div>
      </div>
    );
  }
}

// this will provide us with the state values we need
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  classLabels: selectClassLabels,
});

// this allows us to map our redux actions to our component
const mapDispatchToProps = (dispatch) => ({
  setClassLabels: (classLabels) => dispatch(setClassLabels(classLabels)),
  removeClassLabel: (classLabelId) => dispatch(removeClassLabel(classLabelId)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClasseslabelPage),
);
