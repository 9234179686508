import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Upload, Printer } from 'react-feather';
import { DatePicker, DatePickerInput } from 'carbon-components-react';
import fileDownload from 'js-file-download';

import MainHeader from '../../../components/main-header/main-header.component';
import Mainmenu from '../../../components/menu/main-menu/main-menu.component';
import CustomButton from '../../../components/custom-button/custom-btn.component';
import Footer from '../../../components/footer/footer.component';

import MainLoaderModal from '../../../components/modal/main-loader/main-loader.component';
import { selectCurrentUser } from '../../../redux/selectors/user.selectors';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import BalanceSheetPrintModal from '../../../components/modal/balance-sheet/balance-sheet.component';
import { ExpBalanceSheet } from '../../../components/expandable/expandable-pane/pane.component';
import { selectBalanceSheetReport } from '../../../redux/analytics/analytics.selectors';

import { formatCurrency, formatDate } from '../../../utils/common.util';
import { fetchService } from '../../../api/common';

import './balance-sheet.styles.scss';

const BalanceSheetReportPage = ({
  balanceSheetReport: { fetching, fetchError, data },
  fetchBalanceSheetReport,
  currentUser: { authToken, school },
}) => {
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [dateTo, setDateTo] = useState('');
  const [openLoaderModal, setOpenLoaderModal] = useState(fetching);

  const [openExportLoaderModal, setOpenExportLoaderModal] = useState(false);
  const [exportLoaderModalErrorMessage, setExportLoaderModalErrorMessage] =
    useState(null);

  const exportBalanceSheetReport = async () => {
    setExportLoaderModalErrorMessage(null);
    setOpenExportLoaderModal(true);

    const response = await fetchService(
      `analytics/balance-sheet-report/export?dateTo=${dateTo}`,
      authToken,
    );

    if (response.success) {
      setOpenExportLoaderModal(false);
      fileDownload(
        response.data.data,
        `balance-sheet-report-${new Date()}.xlsx`,
      );

      return;
    }

    setExportLoaderModalErrorMessage(response.message);
  };

  return (
    <div>
      <MainHeader />

      <MainLoaderModal
        openModal={openExportLoaderModal}
        description="Exporting Balance Sheet Report"
        errorMessage={exportLoaderModalErrorMessage}
        tryAgainAction={exportBalanceSheetReport}
        cancelAction={() => setOpenExportLoaderModal(false)}
      />

      <MainLoaderModal
        openModal={
          (openLoaderModal && fetching) || (openLoaderModal && fetchError)
        }
        loading={fetching}
        description="Generating balance sheet report..."
        errorMessage={fetchError}
        tryAgainAction={() => {
          setOpenLoaderModal(true);
          fetchBalanceSheetReport(dateTo, authToken);
        }}
        cancelAction={() => setOpenLoaderModal(false)}
      />

      <BalanceSheetPrintModal
        open={openPrintModal}
        data={data}
        school={school}
        cancelAction={() => setOpenPrintModal(false)}
      />

      <span className="right">
        <Mainmenu />
      </span>

      <div className="balance-sheet-page page-container">
        <div>
          <p>
            <span className="text-12 font-bold text-grey">You are here</span>
            &nbsp;&nbsp;
            <Link to="/analytics" className="text-12 text-dark">
              Analytics
            </Link>
            &nbsp;<span className="text-12">&gt;</span>&nbsp;
            <span className="text-12 text-primary font-bold">
              Balance Sheet
            </span>
          </p>
        </div>

        <hr className="grey-hr mt-20" />

        <div className="h-24 uppercase mt-10">
          Balance Sheet&nbsp;
          {data && `as at ${formatDate(new Date(data.dateTo))}`}
        </div>

        <div className="mt-20">
          <div className="bx--row">
            <div className="bx--col-lg-4">
              <DatePicker
                dateFormat="Y-m-d"
                datePickerType="single"
                id="start-date-picker"
                onClose={(value) => {
                  if (value.length > 0) {
                    setDateTo(
                      [
                        value[0].getFullYear(),
                        ('0' + (value[0].getMonth() + 1)).slice(-2),
                        ('0' + value[0].getDate()).slice(-2),
                      ].join('-'),
                    );
                  }
                }}>
                <DatePickerInput
                  className="date-to"
                  id="date-to"
                  name="dateTo"
                  labelText="Date to"
                  value={dateTo}
                  pattern="d{1,2}/d{4}"
                  placeholder="YYYY-MM-DD"
                  type="text"
                />
              </DatePicker>
            </div>

            <div className="bx--col-lg-2">
              <CustomButton
                classes="f-width primary mt-25 font-bold"
                disabled={dateTo.length === 0}
                onClick={() => {
                  setOpenLoaderModal(true);
                  fetchBalanceSheetReport(dateTo, authToken);
                }}>
                RUN REPORT
              </CustomButton>
            </div>
          </div>
        </div>

        <div className="mt-20">
          <div>
            <p className="h-20 text-primary">Assets</p>

            <ExpBalanceSheet
              headerData={{
                description: 'Fixed Assets',
                value: data
                  ? formatCurrency(data.assets.fixedAssets.total)
                  : 'N/A',
              }}>
              {data && (
                <div className="table-container">
                  <table className="bx--data-table table-transparent">
                    <thead>
                      <tr>
                        <th>
                          <span className="text-primary text-12">ID</span>
                        </th>
                        <th>
                          <span className="text-primary text-12">
                            DESCRIPTION
                          </span>
                        </th>
                        <th>
                          <span className="text-primary text-12 right">
                            TOTAL
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.assets.fixedAssets.ledgers.map((ledger) => (
                        <tr>
                          <td style={{ width: '300px' }}>{ledger.uniqueId}</td>
                          <td>{ledger.description}</td>
                          <td>
                            <span className="right">
                              {formatCurrency(ledger.total)}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </ExpBalanceSheet>

            <ExpBalanceSheet
              headerData={{
                description: 'Current Assets',
                value: data
                  ? formatCurrency(data.assets.currentAssets.total)
                  : 'N/A',
              }}>
              {data && (
                <div className="table-container">
                  <table className="bx--data-table table-transparent">
                    <thead>
                      <tr>
                        <th>
                          <span className="text-primary text-12">ID</span>
                        </th>
                        <th>
                          <span className="text-primary text-12">
                            DESCRIPTION
                          </span>
                        </th>
                        <th>
                          <span className="text-primary text-12 right">
                            TOTAL
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.assets.currentAssets.ledgers.map((ledger) => (
                        <tr>
                          <td style={{ width: '300px' }}>{ledger.uniqueId}</td>
                          <td>{ledger.description}</td>
                          <td>
                            <span className="right">
                              {formatCurrency(ledger.total)}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </ExpBalanceSheet>
          </div>

          <p className="text-dark h-20 text-dark mt-20">
            TOTAL ASSETS: {data ? formatCurrency(data.assets.total) : 'N/A'}
          </p>

          <div>
            <p className="h-20 text-primary mt-50">Equity & Liabilities</p>

            <ExpBalanceSheet
              headerData={{
                description: 'Short Term Liabilities',
                value: data
                  ? formatCurrency(data.liabilities.shortTermLiabilities.total)
                  : 'N/A',
              }}>
              {data && (
                <div className="table-container">
                  <table className="bx--data-table table-transparent">
                    <thead>
                      <tr>
                        <th>
                          <span className="text-primary text-12">ID</span>
                        </th>
                        <th>
                          <span className="text-primary text-12">
                            DESCRIPTION
                          </span>
                        </th>
                        <th>
                          <span className="text-primary text-12 right">
                            TOTAL
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.liabilities.shortTermLiabilities.ledgers.map(
                        (ledger) => (
                          <tr>
                            <td style={{ width: '300px' }}>
                              {ledger.uniqueId}
                            </td>
                            <td>{ledger.description}</td>
                            <td>
                              <span className="right">
                                {formatCurrency(ledger.total)}
                              </span>
                            </td>
                          </tr>
                        ),
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </ExpBalanceSheet>
            <ExpBalanceSheet
              headerData={{
                description: 'Long Term Liabilities',
                value: data
                  ? formatCurrency(data.liabilities.longTermLiabilities.total)
                  : 'N/A',
              }}>
              {data && (
                <div className="table-container">
                  <table className="bx--data-table table-transparent">
                    <thead>
                      <tr>
                        <th>
                          <span className="text-primary text-12">ID</span>
                        </th>
                        <th>
                          <span className="text-primary text-12">
                            DESCRIPTION
                          </span>
                        </th>
                        <th>
                          <span className="text-primary text-12 right">
                            TOTAL
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.liabilities.longTermLiabilities.ledgers.map(
                        (ledger) => (
                          <tr>
                            <td style={{ width: '300px' }}>
                              {ledger.uniqueId}
                            </td>
                            <td>{ledger.description}</td>
                            <td>
                              <span className="right">
                                {formatCurrency(ledger.total)}
                              </span>
                            </td>
                          </tr>
                        ),
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </ExpBalanceSheet>
          </div>

          <div>
            <ExpBalanceSheet
              headerData={{
                description: 'Capital & Reserves',
                value: data ? formatCurrency(data.equity.capital.total) : 'N/A',
              }}>
              {data && (
                <div className="table-container">
                  <table className="bx--data-table table-transparent">
                    <thead>
                      <tr>
                        <th>
                          <span className="text-primary text-12">ID</span>
                        </th>
                        <th>
                          <span className="text-primary text-12">
                            DESCRIPTION
                          </span>
                        </th>
                        <th>
                          <span className="text-primary text-12 right">
                            TOTAL
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.equity.capital.ledgers.map((ledger) => (
                        <tr>
                          <td style={{ width: '300px' }}>{ledger.uniqueId}</td>
                          <td>{ledger.description}</td>
                          <td>
                            <span className="right">
                              {formatCurrency(ledger.total)}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </ExpBalanceSheet>
            <ExpBalanceSheet
              headerData={{
                description: 'Retained Profit',
                value: data
                  ? formatCurrency(data.incomeStatement.variance)
                  : 'N/A',
              }}></ExpBalanceSheet>
          </div>

          <p className="text-dark h-20 text-dark mt-20">
            TOTAL EQUITY & LIABILITIES:{' '}
            {data
              ? formatCurrency(
                  data.liabilities.total +
                    data.equity.total +
                    data.incomeStatement.variance,
                )
              : 'N/A'}
          </p>

          {data && (
            <div className="bx--row mt-35">
              <div className="bx--col-lg-2">
                <CustomButton
                  classes="secondary f-width font-bold"
                  onClick={exportBalanceSheetReport}>
                  <Upload size="11" strokeWidth="5px" />
                  &nbsp; EXPORT TO EXCEL
                </CustomButton>
              </div>
              <div className="bx--col-lg-2">
                <CustomButton
                  classes="primary f-width font-bold"
                  onClick={() => setOpenPrintModal(true)}>
                  <Printer size="11" strokeWidth="5px" /> &nbsp; PRINT
                </CustomButton>
              </div>
            </div>
          )}
        </div>

        <Footer fixed={false} showLinks={false} />
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  balanceSheetReport: selectBalanceSheetReport,
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  fetchBalanceSheetReport: (dateTo, authToken) =>
    dispatch({
      type: 'FETCH_BALANCE_SHEET_REPORT_REQUESTED',
      payload: { dateTo, authToken },
    }),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BalanceSheetReportPage),
);
