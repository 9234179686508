import React, { useState } from 'react';

import { MoreVertical } from 'react-feather';
import CardMenu, {
  CardMenuItem,
} from '../../menu/card-menu/card-menu.component';

import { Tag } from 'carbon-components-react';

import './budget-card.styles.scss';

const BudgetCard = ({
  children,
  budget: { budgetStatus, academicYearDescription, uniqueId },
  btnAction = null,
}) => {
  const [showCardMenu, setShowCardMenu] = useState(false);

  return (
    <div className={`budget-card bg-white`} onClick={btnAction}>
      <div>
        <div className="top-content">
          <div className="icon">
            {children}
            {/*<MoreVertical
							className="pointer"
							color="#7D819D"
							onClick={e => {
								e.stopPropagation();
								setShowCardMenu(showCardMenu ? false : true);
							}}
						/>

						{showCardMenu ? (
							<CardMenu>
								<CardMenuItem color="danger" title="DELETE" separator={false} />
							</CardMenu>
            ) : null}*/}
          </div>
          <h3 className="title">
            <span className="text-truncate">{academicYearDescription}</span>
            <Tag
              className={`status-tag ${budgetStatus.toLowerCase()} fade-in`}
              type="red">
              {budgetStatus.toUpperCase()}
            </Tag>
          </h3>
          <p className="subtitle text-truncate">{uniqueId}</p>
        </div>

        {/* <div className="middle-content">
            <hr className="line" />

            <p className="text-label">Total Revenue</p>
            <h4 className="text-value">N/A</h4>

            <p className="text-label">Total Expense</p>
            <h4 className="text-value">N/A</h4>

            <p className="text-label">Variance</p>
            <h4 className="text-value variance">N/A</h4>
              </div> */}

        {/*<CustomButton classes="view-btn" onClick={btnAction}>
            {btnText}
              </CustomButton>*/}
      </div>
    </div>
  );
};

export default BudgetCard;
