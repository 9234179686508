import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  Select,
  SelectItem,
  TextInput,
  Form,
  Modal,
  Loading,
  DatePicker,
  DatePickerInput,
  TextArea,
} from 'carbon-components-react';
import CustomButton from '../../custom-button/custom-btn.component';

// import our redux stuffs
import { selectCurrentUser } from '../../../redux/selectors/user.selectors';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

// import api stuffs
import { getLedgerAccounts } from '../../../api/chart-of-accounts.service';
import { newFixedAsset } from '../../../api/fixed-asset.service';

import './register-asset.styles.scss';

class RegisterAssetModal extends React.Component {
  constructor(props) {
    super(props);

    // set state
    this.state = {
      id: 0,
      loading: false,
      errorMessage: null,
      creditAccounts: [],
      debitAccounts: [],
      description: '',
      debitAccount: '',
      creditAccount: '',
      purchasePrice: '',
      purchaseDate: '',
      quantity: '',
      residualValue: '0',
      usefulLife: '',
      physicalLocation: '',
      assetType: '',
      notes: '',
      openModal: props.openModal,
    };
  }

  componentDidMount() {
    // fetch credit accounts
    getLedgerAccounts(this.props.currentUser.authToken, 0).then((response) => {
      if (response.success) {
        this.setState({
          creditAccounts: response.ledgerAccounts.filter(
            (item) =>
              item.categoryDescription === 'Bank Accounts' ||
              item.categoryDescription === 'Cash Accounts',
          ),
          debitAccounts: response.ledgerAccounts.filter(
            (item) => item.groupDescription === 'Fixed Assets',
          ),
        });
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.fixedAsset !== prevProps.fixedAsset) {
      if (this.props.fixedAsset) {
        const purchaseDate = new Date(this.props.fixedAsset.purchaseDate);
        this.setState({
          id: this.props.fixedAsset.id,
          description: this.props.fixedAsset.description,
          debitAccount: this.props.fixedAsset.debitAccount.id.toString(),
          creditAccount: this.props.fixedAsset.creditAccount.id.toString(),
          purchasePrice: this.props.fixedAsset.purchasePrice.toString(),
          purchaseDate: [
            purchaseDate.getFullYear(),
            ('0' + (purchaseDate.getMonth() + 1)).slice(-2),
            ('0' + purchaseDate.getDate()).slice(-2),
          ].join('-'),
          quantity: this.props.fixedAsset.quantity.toString(),
          residualValue: this.props.fixedAsset.residualValue.toString(),
          usefulLife: this.props.fixedAsset.usefulLife.toString(),
          physicalLocation: this.props.fixedAsset.physicalLocation,
          assetType: this.props.fixedAsset.assetType,
          notes: this.props.fixedAsset.notes,
        });
      } else {
        this.resetState();
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.openModal !== prevState.openModal) {
      return { openModal: nextProps.openModal };
    } else return null;
  }

  resetState = () => {
    this.setState({
      id: 0,
      description: '',
      debitAccount: '',
      creditAccount: '',
      purchasePrice: '',
      purchaseDate: '',
      quantity: '',
      residualValue: '0',
      usefulLife: '',
      physicalLocation: '',
      assetType: '',
      notes: '',
      loading: false,
    });
  };

  // handle onchange event handler for input fields
  handleChange = (event) => {
    // destructure values
    const { name, value } = event.target;

    // update state
    this.setState({ [name]: value });
  };

  validate = () =>
    this.state.description.length > 0 &&
    this.state.purchaseDate.length > 0 &&
    this.state.debitAccount.length > 0 &&
    this.state.creditAccount.length > 0 &&
    this.state.purchasePrice.length > 0 &&
    this.state.quantity.length > 0 &&
    this.state.residualValue.length > 0 &&
    this.state.physicalLocation.length > 0 &&
    this.state.assetType.length > 0;

  onSubmit = async (submitForApproval) => {
    this.setState({ loading: true, errorMessage: null, btnText: null });

    const data = {
      ...this.props.fixedAsset,
      id: this.state.id,
      description: this.state.description,
      purchaseDate: this.state.purchaseDate,
      physicalLocation: this.state.physicalLocation,
      assetType: this.state.assetType,
      residualValue: parseFloat(this.state.residualValue),
      creditAccount: this.state.creditAccounts.find(
        (account) => account.id == this.state.creditAccount,
      ),
      debitAccount: this.state.debitAccounts.find(
        (account) => account.id == this.state.debitAccount,
      ),
      purchasePrice: parseFloat(this.state.purchasePrice),
      quantity: parseInt(this.state.quantity),
      usefulLife:
        this.state.usefulLife.length > 0
          ? parseInt(this.state.usefulLife)
          : undefined,
      notes: this.state.notes,
      submitForApproval: submitForApproval,
    };

    // make request
    const response = await newFixedAsset(
      this.props.currentUser.authToken,
      data,
    );

    // failed
    if (!response.success) {
      this.setState({
        loading: false,
        errorMessage: response.message,
      });

      return;
    }

    // success
    this.resetState();

    this.props.refreshPage();
  };

  render() {
    return (
      <Modal
        id="register-asset-modal"
        className="register-asset-modal"
        hasScrollingContent
        passiveModal={true}
        open={this.state.openModal}
        modalHeading="Register Asset"
        primaryButtonDisabled={false}>
        <div>
          <Form action="post">
            <div>
              <TextInput
                id="description"
                name="description"
                labelText="Description"
                placeholder="Asset name or description"
                value={this.state.description}
                onChange={this.handleChange}
              />
            </div>

            <div className="bx--row mt-20">
              <div className="bx--col-lg-4">
                <DatePicker
                  dateFormat="Y-m-d"
                  datePickerType="single"
                  id="purchase-date-picker"
                  onClose={(value) => {
                    if (value.length > 0) {
                      this.setState({
                        purchaseDate: [
                          value[0].getFullYear(),
                          ('0' + (value[0].getMonth() + 1)).slice(-2),
                          ('0' + value[0].getDate()).slice(-2),
                        ].join('-'),
                      });
                    }
                  }}>
                  <DatePickerInput
                    className="purchase-date"
                    id="purchase-date"
                    labelText="Date Purchased"
                    name="purchaseDate"
                    value={this.state.purchaseDate}
                    onChange={this.handleChange}
                    pattern="d{1,2}/d{4}"
                    placeholder="YYYY-MM-DD"
                  />
                </DatePicker>
              </div>

              <div className="bx--col-lg-4">
                <Select
                  labelText="Debit Account"
                  name="debitAccount"
                  onChange={this.handleChange}>
                  <SelectItem
                    text="Select Debit Account"
                    value=""
                    selected={this.state.debitAccount === ''}
                    disabled
                  />
                  {this.state.debitAccounts.map((account) => (
                    <SelectItem
                      text={account.description}
                      value={account.id}
                      selected={account.id == this.state.debitAccount}
                    />
                  ))}
                </Select>
              </div>
              <div className="bx--col-lg-4">
                <Select
                  labelText="Credit Account"
                  name="creditAccount"
                  onChange={this.handleChange}>
                  <SelectItem
                    text="Select Credit Account"
                    value=""
                    selected={this.state.creditAccount === ''}
                    disabled
                  />

                  {this.state.creditAccounts.map((account) => (
                    <SelectItem
                      text={account.description}
                      value={account.id}
                      selected={account.id == this.state.creditAccount}
                    />
                  ))}
                </Select>
              </div>
            </div>
            <div className="bx--row mt-20">
              <div className="bx--col-lg-4">
                <TextInput
                  id="purchasePrice"
                  name="purchasePrice"
                  type="number"
                  labelText="Purchase price"
                  placeholder="0.00"
                  value={this.state.purchasePrice}
                  onChange={this.handleChange}
                />
              </div>
              <div className="bx--col-lg-2">
                <TextInput
                  id="quantity"
                  name="quantity"
                  type="number"
                  labelText="Quantity"
                  placeholder="0"
                  value={this.state.quantity}
                  onChange={this.handleChange}
                />
              </div>
              <div className="bx--col-lg-3">
                <TextInput
                  id="residualValue"
                  name="residualValue"
                  type="number"
                  labelText="Residual Value/ELV"
                  placeholder="0.00"
                  value={this.state.residualValue}
                  onChange={this.handleChange}
                />
              </div>
              <div className="bx--col-lg-3">
                <TextInput
                  id="usefulLife"
                  name="usefulLife"
                  labelText="Useful Life"
                  type="number"
                  placeholder="0"
                  value={this.state.usefulLife}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="bx--row mt-20">
              <div className="bx--col-md-5">
                <TextInput
                  id="physicalLocation"
                  name="physicalLocation"
                  labelText="Physical Location"
                  placeholder="Location of Asset"
                  value={this.state.physicalLocation}
                  onChange={this.handleChange}
                />
              </div>
              <div className="bx--col-md-3">
                <Select
                  labelText="Asset Type"
                  name="assetType"
                  onChange={this.handleChange}>
                  <SelectItem
                    text="Select Asset Type"
                    selected={this.state.assetType === ''}
                    disabled
                    value=""
                  />

                  <SelectItem
                    text="Tangible"
                    selected={this.state.assetType === 'Tangible'}
                    value="Tangible"
                  />
                  <SelectItem
                    text="Intangible"
                    selected={this.state.assetType === 'Intangible'}
                    value="Intangible"
                  />
                </Select>
              </div>
            </div>

            <div className="bx--row mt-20">
              <div className="bx--col-md-8">
                <TextArea
                  id="notes"
                  name="notes"
                  labelText="Notes"
                  placeholder="Add Notes"
                  value={this.state.notes}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="bx--row mt-60">
              <div className="bx--col-md-3 ">
                <CustomButton
                  classes="outline danger no-border no-hover ml-20 font-bold"
                  onClick={this.props.cancelAction}>
                  CANCEL
                </CustomButton>
              </div>
              <div className="bx--col-md-2">
                <CustomButton
                  classes="secondary f-width  font-bold"
                  disabled={this.state.loading || !this.validate()}
                  onClick={() => this.onSubmit(false)}>
                  {!this.state.loading && 'SAVE TO DRAFT'}

                  <div hidden={!this.state.loading}>
                    <div className="d-flex-center">
                      <Loading
                        withOverlay={false}
                        small={true}
                        description="Saving to Draft"
                      />
                    </div>
                  </div>
                </CustomButton>
              </div>
              <div className="bx--col-md-3">
                <CustomButton
                  classes="primary f-width  font-bold"
                  disabled={this.state.loading || !this.validate()}
                  onClick={() => this.onSubmit(true)}>
                  {!this.state.loading && 'SUBMIT FOR APPROVAL'}

                  <div hidden={!this.state.loading}>
                    <div className="d-flex-center">
                      <Loading
                        withOverlay={false}
                        small={true}
                        description="Submitting Fixed Asset for approval..."
                      />
                    </div>
                  </div>
                </CustomButton>
              </div>
            </div>

            <p
              className="text-14 mt-10 text-danger"
              hidden={this.state.errorMessage === null}>
              {this.state.errorMessage}
            </p>
          </Form>
        </div>
      </Modal>
    );
  }
}

// this will provide us with the state values we need
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default withRouter(connect(mapStateToProps)(RegisterAssetModal));
