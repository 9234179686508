import { getData, postData, deleteData } from './common';

export const getFixedAssets = async (authToken) => {
  let response = await getData(`fixed-asset/list`, authToken);
  if (response.success) {
    return {
      success: true,
      fixedAssets: response.data,
    };
  }

  return response;
};

export const newFixedAsset = async (authToken, data) => {
  return await post('new', data, authToken);
};

export const disposeAsset = async (authToken, data) => {
  return await post('dispose', data, authToken);
};

export const deleteAsset = async (authToken, assetId) => {
  return await deleteData(`fixed-asset/delete/${assetId}`, authToken);
};

const post = async (url, data, authToken) => {
  let response = await postData(`fixed-asset/${url}`, data, authToken);
  if (response.success) {
    return {
      success: true,
      fixedAsset: response.data,
    };
  }

  return response;
};
