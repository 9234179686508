import React, { useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Upload, Printer } from 'react-feather';
import fileDownload from 'js-file-download';
import {
  DatePicker,
  DatePickerInput,
  Select,
  SelectItem,
} from 'carbon-components-react';

import MainHeader from '../../../components/main-header/main-header.component';
import Mainmenu from '../../../components/menu/main-menu/main-menu.component';
import CustomButton from '../../../components/custom-button/custom-btn.component';
import Footer from '../../../components/footer/footer.component';

import MainLoaderModal from '../../../components/modal/main-loader/main-loader.component';
import PaymentReceivedModal from '../../../components/modal/payment-received/payment-received.component';

import { selectCurrentUser } from '../../../redux/selectors/user.selectors';
import { selectRevenueReport } from '../../../redux/analytics/analytics.selectors';
import {
  selectLedgerAccounts,
  selectRevenueLedgerAccounts,
} from '../../../redux/ledger/ledger.selectors';

import { formatCurrency, formatDate } from '../../../utils/common.util';

import './cash-received.styles.scss';
import { fetchService } from '../../../api/common';
import useSWR from 'swr';
import LoaderPane from '../../../components/loader-pane/loader-pane.component';

const RevenueReportPage = ({
  revenueReport: { fetching, fetchError, data },
  fetchRevenueReport,
  ledgerAccounts,
  revenueLedgerAccounts,
  fetchLedgerAccounts,
  currentUser: { authToken, school },
}) => {
  const [openPrintOutModal, setOpenPrintOutModal] = useState(false);
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [mode, setMode] = useState(null);
  const [ledger, setLedger] = useState('-1');
  const [type, setType] = useState('&reversed=false&revised=false'); //reversed or revised [true,false]

  const [openExportLoaderModal, setOpenExportLoaderModal] = useState(false);
  const [exportLoaderModalErrorMessage, setExportLoaderModalErrorMessage] =
    useState(null);

  const {
    data: reportData,
    error: reportError,
    isValidating,
  } = useSWR(
    dateFrom &&
      dateTo &&
      `analytics/revenue-report/?dateFrom=${dateFrom}&dateTo=${dateTo}&ledgerId=${ledger}${type}${
        mode ? `&paymentMode=${mode}` : ''
      }`,
  );

  const exportReport = async () => {
    setExportLoaderModalErrorMessage(null);
    setOpenExportLoaderModal(true);

    const response = await fetchService(
      `analytics/revenue-report/export?dateTo=${dateTo}&dateFrom=${dateFrom}&ledgerId=${ledger}${type}${
        mode ? `&paymentMode=${mode}` : ''
      }`,
      authToken,
    );

    if (response.success) {
      setOpenExportLoaderModal(false);
      fileDownload(
        response.data.data,
        `cash-received-report-${new Date()}.xlsx`,
      );

      return;
    }

    setExportLoaderModalErrorMessage(response.message);
  };

  if (!ledgerAccounts.fetching && ledgerAccounts.data.length === 0) {
    fetchLedgerAccounts(authToken);
  }

  const total = () => {
    let t = 0;
    reportData?.forEach((item) => {
      t += item?.amountPaid;
    });
    return t;
  };

  let ledgerAccount = revenueLedgerAccounts.find((i) => i.id == ledger);

  return (
    <div>
      <MainHeader />
      <MainLoaderModal
        openModal={openExportLoaderModal}
        description="Exporting Cash Received Report"
        errorMessage={exportLoaderModalErrorMessage}
        tryAgainAction={exportReport}
        cancelAction={() => setOpenExportLoaderModal(false)}
      />

      {/*    <MainLoaderModal
        openModal={(openLoaderModal && fetching) || (openLoaderModal && fetchError)}
        loading={fetching}
        description={"Generating cash received report..."}
        errorMessage={fetchError}
        tryAgainAction={() => {
          setOpenLoaderModal(true);
          fetchRevenueReport(dateFrom, dateTo, ledger, authToken);
        }}
        cancelAction={() => setOpenLoaderModal(false)}
      /> */}

      <PaymentReceivedModal
        openModal={openPrintOutModal}
        data={reportData}
        total={total()}
        dateFrom={dateFrom}
        dateTo={dateTo}
        school={school}
        closeAction={() => setOpenPrintOutModal(false)}
      />

      <span className="right">
        <Mainmenu />
      </span>

      <div className="cash-received-page page-container">
        <div>
          <p>
            <span className="text-12 font-bold text-grey">You are here</span>
            &nbsp;&nbsp;
            <Link to="/analytics" className="text-12 text-dark">
              Analytics
            </Link>
            &nbsp;<span className="text-12">&gt;</span>&nbsp;
            <span className="text-12 text-primary font-bold">
              Cash Received Report
            </span>
          </p>
        </div>

        <hr className="grey-hr mt-20" />

        <div className="h-24 uppercase mt-10">
          Cash Received Report{' '}
          {ledgerAccount && `on ${ledgerAccount.description}`} &nbsp;:&nbsp;
          {dateFrom &&
            dateTo &&
            `${formatDate(dateFrom)} - ${formatDate(dateTo)}`}
        </div>

        <div className="mt-20">
          <div className="bx--row mt-20">
            <div className="bx--col-md-2 npr">
              <Select
                labelText="Ledger"
                onChange={(e) => setLedger(e.target.value)}>
                <SelectItem disabled selected value="" text="Ledger" />
                <SelectItem value="-1" text="All" />
                {revenueLedgerAccounts.map((account) => (
                  <SelectItem value={account.id} text={account.description} />
                ))}
              </Select>
            </div>

            <div className="bx--col-md-2">
              <Select
                labelText="Filter"
                onChange={(e) => setType(e.target.value)}>
                <SelectItem
                  selected
                  value="&reversed=false&revised=false"
                  text="Valid only"
                />
                <SelectItem value="" text="All" />
                {/* <SelectItem value="&reversed=true" text="Include Reversed" />
                <SelectItem value="&revised=true" text="Include Revised" /> */}
                <SelectItem
                  value="&reversed=true&revised=false"
                  text="Reversed only"
                />
                <SelectItem
                  value="&revised=true&reversed=false"
                  text="Revised only"
                />
                <SelectItem
                  value="&reversed=true&revised=true"
                  text="Reversed/Revised"
                />
              </Select>
            </div>

            <div className="bx--col-md-2">
              <DatePicker
                dateFormat="Y-m-d"
                datePickerType="single"
                id="start-date-picker"
                onClose={(value) => {
                  if (value.length > 0) {
                    setDateFrom(
                      [
                        value[0].getFullYear(),
                        ('0' + (value[0].getMonth() + 1)).slice(-2),
                        ('0' + value[0].getDate()).slice(-2),
                      ].join('-'),
                    );
                  }
                }}>
                <DatePickerInput
                  className="start-date"
                  id="start-date"
                  name="startDate"
                  labelText="Start date"
                  value={dateFrom}
                  pattern="d{1,2}/d{4}"
                  placeholder="YYYY-MM-DD"
                  type="text"
                />
              </DatePicker>
            </div>
            <div className="bx--col-md-2 npl">
              <DatePicker
                dateFormat="Y-m-d"
                datePickerType="single"
                id="end-date-picker"
                onClose={(value) => {
                  if (value.length > 0) {
                    setDateTo(
                      [
                        value[0].getFullYear(),
                        ('0' + (value[0].getMonth() + 1)).slice(-2),
                        ('0' + value[0].getDate()).slice(-2),
                      ].join('-'),
                    );
                  }
                }}>
                <DatePickerInput
                  className="end-date"
                  id="end-date"
                  name="endDate"
                  labelText="End date"
                  value={dateTo}
                  pattern="d{1,2}/d{4}"
                  placeholder="YYYY-MM-DD"
                  type="text"
                />
              </DatePicker>
            </div>

            <div className="bx--col-md-2 mt-10 npr">
              <Select
                labelText="Payment Mode"
                onChange={(e) => setMode(e.target.value)}>
                <SelectItem selected value="" text="Mode of Payment" />
                <SelectItem text="Cash" value="0" />
                <SelectItem text="Cheque" value="1" />
                <SelectItem text="Bank Transfer" value="4" />
                <SelectItem text="Bank Draft" value="6" />
                <SelectItem text="Mobile Money" value="2" />
              </Select>
            </div>

            {/* <div className="bx--col-md-2">
              <CustomButton
                classes="f-width primary mt-25"
                disabled={dateFrom.length === 0 || dateTo.length === 0}
                onClick={() => {
                  setOpenLoaderModal(true);
                  fetchRevenueReport(dateFrom, dateTo, parseInt(ledger), authToken);
                }}>
                RUN REPORT
              </CustomButton>
            </div> */}
          </div>
        </div>
        <LoaderPane
          loading={
            (dateFrom && dateTo && !reportData && !reportError) || isValidating
          }
          noRecords={reportData && reportData?.length === 0}
          error={!reportData && reportError?.message}
        />

        {reportData && (
          <div className="mt-20">
            <table className="bx--data-table table-white">
              <thead>
                <tr>
                  <th>Date Recorded</th>
                  <th>Payment Date</th>
                  <th>Payment ID</th>
                  <th>Credit Account</th>
                  <th>Debit Account</th>
                  <th>Amount</th>
                  <th>Received from</th>
                  <th>Payment Mode</th>
                  <th>Reversed / Revised</th>
                </tr>
              </thead>
              <tbody>
                {reportData.map((revenue) => (
                  <tr>
                    <td>
                      {formatDate(
                        revenue.payment.dateCreated ??
                          revenue.payment.paymentDate,
                      )}
                    </td>
                    <td>{formatDate(revenue.payment.paymentDate)}</td>
                    <td className="font-bold">{revenue.payment.uniqueId}</td>
                    <td>{revenue.ledgerAccount.description}</td>
                    <td>{revenue.payment.debitAccount.description}</td>
                    <td className="font-bold">
                      {formatCurrency(revenue.amountPaid)}
                    </td>
                    <td>
                      {revenue.payment.student
                        ? revenue.payment.student.fullName
                        : revenue.payment.receivedFrom}
                    </td>
                    <td>{revenue.payment?.paymentMode || 'N/A'}</td>
                    <td>
                      {revenue.payment.dateReversed ? (
                        <span className="text-danger font-bold">
                          {`REVERSED ON ${formatDate(
                            revenue.payment.dateReversed,
                          )}`.toUpperCase()}
                        </span>
                      ) : revenue.payment.revisedPayment ? (
                        <span
                          style={{ color: '#FF7F00' }}
                          className="font-bold">
                          {`REVISED ON ${formatDate(
                            revenue.payment.revisedPayment.paymentDate,
                          )} WITH ${
                            revenue.payment.revisedPayment.uniqueId
                          }`.toUpperCase()}
                        </span>
                      ) : (
                        'NO'
                      )}
                    </td>
                  </tr>
                ))}
                {reportData && (
                  <tr>
                    <td colSpan={5}>
                      <div className="text-primary font-bold">Total</div>
                    </td>
                    <td colSpan={4}>
                      <div className="text-primary font-bold text-20">
                        {formatCurrency(total())}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <div className="bx--row mt-35">
              <div className="bx--col-lg-2">
                <CustomButton
                  classes="secondary  f-width font-bold"
                  onClick={exportReport}>
                  <Upload size="11" strokeWidth="5px" />
                  &nbsp; EXPORT TO EXCEL
                </CustomButton>
              </div>
              <div className="bx--col-lg-2">
                <CustomButton
                  classes="primary f-width font-bold"
                  onClick={() => setOpenPrintOutModal(true)}>
                  <Printer size="11" strokeWidth="5px" /> &nbsp; PRINT
                </CustomButton>
              </div>
              <div className="bx--col-lg-8"></div>
            </div>
          </div>
        )}
        <Footer fixed={false} showLinks={false} />
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  revenueReport: selectRevenueReport,
  ledgerAccounts: selectLedgerAccounts,
  revenueLedgerAccounts: selectRevenueLedgerAccounts,
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  fetchRevenueReport: (dateFrom, dateTo, ledger, authToken) =>
    dispatch({
      type: 'FETCH_REVENUE_REPORT_REQUESTED',
      payload: { dateFrom, dateTo, authToken, ledger },
    }),
  fetchLedgerAccounts: (authToken) =>
    dispatch({
      type: 'FETCH_LEDGER_ACCOUNTS_REQUESTED',
      payload: { authToken },
    }),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RevenueReportPage),
);
