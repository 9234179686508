import React from 'react';

import './slider.styles.scss';

const Slider = ({ text1, text2, text3, children, classes }) => {
  return (
    <div className="slide-container">
      <div id="slider">
        <div className="container">
          <div className="slide">
            <p>
              A single system for Finance (Budget, Expenses, Revenue, Fixed
              Assets), HR (Payroll) and Academics.
            </p>
          </div>

          <div className="slide">
            <p>
              We are a team of dedicated and creative individuals with a single
              aim of offering nothing but the best value to our customers.
            </p>
          </div>

          <div className="slide">
            <p>
              EPOBS Team is helping institutions around the world solve their
              biggest challenges.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
