import React from 'react';
import { Form, Modal } from 'carbon-components-react';
import CustomButton from '../../custom-button/custom-btn.component';

import './reverse-payment.styles.scss';

const ReversePaymentModal = ({
  openModal,
  heading = 'Reverse Payment',
  description,
  message = `A reversed payment will be treated like it never happened and will
  not reflect in the credit account or debit account. This action
  cannot be reversed.`,
  reverseAction,
  cancelAction,
}) => {
  return (
    <Modal
      id="reverse-payment-modal"
      className="reverse-payment-modal"
      hasScrollingContent
      passiveModal={true}
      open={openModal}
      modalHeading={heading}
      primaryButtonDisabled={false}>
      <div>
        <Form action="post">
          <hr className="grey-hr" />

          <p className="h-18 mt-20 mb-20">{description}</p>

          <p className="h-18 font-500">{message}</p>

          <p className="text-danger text-14 mt-10">
            NB: Each user on the system will receive a notification of this
            action.
          </p>

          <div className="bx--row mt-60">
            <div className="bx--col-md-4 pt-20">
              <span
                className="text-primary ml-20 f-width pointer font-bold"
                onClick={cancelAction}>
                CANCEL
              </span>
            </div>
            <div className="bx--col-md-4">
              <CustomButton
                classes="danger f-width  font-bold"
                onClick={reverseAction}>
                REVERSE
              </CustomButton>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default ReversePaymentModal;
