import AcademicYearActionTypes from '../types/academic-year.types';

// set academic years action
export const setAcademicYears = (academicYears) => ({
  type: AcademicYearActionTypes.SET_ACADEMIC_YEARS,
  payload: academicYears,
});

export const removeAcademicYear = (academicYearId) => ({
  type: AcademicYearActionTypes.REMOVE_ACADEMIC_YEAR,
  payload: academicYearId,
});

// set terms action
export const setTerms = (terms) => ({
  type: AcademicYearActionTypes.SET_TERMS,
  payload: terms,
});

export const removeTerm = (termId) => ({
  type: AcademicYearActionTypes.REMOVE_TERM,
  payload: termId,
});

// set budget action
export const setBudget = (budget) => ({
  type: AcademicYearActionTypes.SET_BUDGET,
  payload: budget,
});

export const setBudgets = (budgets) => ({
  type: AcademicYearActionTypes.SET_BUDGETS,
  payload: budgets,
});

// set budget item action
export const setBudgetItem = (budgetItem) => ({
  type: AcademicYearActionTypes.SET_BUDGET_ITEM,
  payload: budgetItem,
});

// set budget item action
export const setBudgetStatus = (budgetStatus) => ({
  type: AcademicYearActionTypes.SET_BUDGET_STATUS,
  payload: budgetStatus,
});

export const removeBudgetItem = (params) => ({
  type: AcademicYearActionTypes.REMOVE_BUDGET_ITEM,
  payload: params,
});

export const removeBudgetItems = (params) => ({
  type: AcademicYearActionTypes.REMOVE_BUDGET_ITEMS,
  payload: params,
});
