import React from 'react';
import { withRouter } from 'react-router-dom';
import { Plus, Printer, Upload } from 'react-feather';
import queryString from 'query-string';
import fileDownload from 'js-file-download';

import MainHeader from '../../components/main-header/main-header.component';
import Mainmenu from '../../components/menu/main-menu/main-menu.component';
import EmployeeCard from '../../components/card/employee-card/employee-card.component';
import AddEmployeeModal from '../../components/modal/add-employee/add-employee.component';
import PrintOutModal from '../../components/modal/print-outs/employees/employees-print-out.component';
import QuickActionCard from '../../components/card/quick-action-card/quick-action-card.component';
import ImportDataModal from '../../components/modal/import-data/import-data.component';
import ConfirmDeleteModal from '../../components/modal/confirm-delete/confirm-delete.component';

import Footer from '../../components/footer/footer.component';

import { Search, Select, SelectItem, Loading } from 'carbon-components-react';

//import svg icon
import { ReactComponent as Briefcase } from '../../assets/images/card-icons/briefcase.svg';
import { ReactComponent as BriefcaseRed } from '../../assets/images/card-icons/briefcase-red.svg';

import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../redux/selectors/user.selectors';

import { connect } from 'react-redux';

import { getDepartments } from '../../api/school.service';
import {
  getEmployees,
  deleteEmployee,
  importEmployees,
  exportEmployees,
} from '../../api/employee.service';

import './employee.styles.scss';
import {
  selectAllEmployees,
  selectActiveEmployees,
  selectTerminatedEmployees,
  selectDepartments,
} from '../../redux/selectors/employee.selectors';
import {
  setEmployees,
  removeEmployee,
  setDepartments,
} from '../../redux/actions/employee.actions';
import MainLoaderModal from '../../components/modal/main-loader/main-loader.component';

class EmployeePage extends React.Component {
  constructor(props) {
    super(props);

    let params = queryString.parse(props.history.location.search);
    const employeesToView = params.v === 'Terminated' ? params.v : 'Active';

    // set state
    this.state = {
      query: '',
      filter: '',
      departmentId: '',
      departments: this.props.departments,
      allEmployees: this.props.allEmployees,
      activeEmployees: this.props.activeEmployees,
      terminatedEmployees: this.props.terminatedEmployees,
      loading: false,
      errorMessage: null,
      employeesToView: employeesToView,
      openAddNewModal: false,
      openTerminateModal: false,
      openEmployeesListModal: false,
      openImportModal: false,
      selectedEmployee: null,
      openConfirmDeleteModal: false,
      deleteDescription:
        'Please confirm Employee deletion. This action cannot be reversed.',
      deleteAction: () => {},
      deleteSucessAction: () => {},
      openPrintOutModal: false,
      openImportDataModal: false,
      openLoaderModal: false,
      loaderModalDescription: 'Importing Employees...',
      loaderModalErrorMessage: null,
      loaderModaltryAgainAction: () => {},
    };
  }

  componentDidMount() {
    this.fetchEmployees();

    getDepartments(this.props.currentUser.authToken).then((response) => {
      if (response.success) {
        this.props.setDepartments(response.departments);
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.departments.length !== prevProps.departments.length) {
      this.setState({ departments: this.props.departments });
    }

    if (this.props.allEmployees.length !== prevProps.allEmployees.length) {
      this.setState({ allEmployees: this.props.allEmployees });
      this.setState({ activeEmployees: this.props.activeEmployees });
      this.setState({ terminatedEmployees: this.props.terminatedEmployees });
    } else {
      // compare values
      this.props.allEmployees.forEach((newEmployee) => {
        // find old
        const oldEmployee = prevProps.allEmployees.find(
          (item) => item.id === newEmployee.id,
        );

        if (
          newEmployee.firstName !== oldEmployee.firstName ||
          newEmployee.lastName !== oldEmployee.lastName ||
          newEmployee.gender !== oldEmployee.gender ||
          newEmployee.departmentId !== oldEmployee.departmentId ||
          newEmployee.status !== oldEmployee.status ||
          newEmployee.phoneNumber !== oldEmployee.phoneNumber ||
          newEmployee.emailAddress !== oldEmployee.emailAddress
        ) {
          this.setState({ allEmployees: this.props.allEmployees });
          this.setState({ activeEmployees: this.props.activeEmployees });
          this.setState({
            terminatedEmployees: this.props.terminatedEmployees,
          });
        }
      });
    }
  }

  fetchEmployees = async () => {
    this.setState({ loading: true, errorMessage: null });

    // get auth token
    const { authToken } = this.props.currentUser;

    const response = await getEmployees(authToken);

    if (!response.success) {
      // if request failed but redux has a list of employees for us already,
      // take first 100 and show that
      const employees = this.props.allEmployees.slice(0, 100);

      if (employees.length > 0) {
        this.setState({ loading: false });
      } else {
        // show error message
        this.setState({
          loading: false,
          errorMessage: response.message,
        });
      }

      return;
    }

    // all good, update state with employees
    this.setState({ loading: false });

    // update redux
    this.props.setEmployees(response.employees);
  };

  togglePrintOutModal = () => {
    this.setState({
      openPrintOutModal: !this.state.openPrintOutModal,
    });
  };

  toggleImportModal = () => {
    this.setState({
      openImportDataModal: !this.state.openImportDataModal,
      openAddNewModal: this.state.openImportDataModal,
    });
  };

  onImportStudents = async (csvFile) => {
    this.setState({
      openImportDataModal: false,
      openLoaderModal: true,
      loaderModalErrorMessage: null,
      loaderModalDescription: 'Importing Employees...',
    });

    const formData = new FormData();
    formData.append('formFile', csvFile);

    // import students
    const response = await importEmployees(
      this.props.currentUser.authToken,
      formData,
    );

    if (!response.success) {
      this.setState({
        loaderModalErrorMessage: response.message,
        loaderModaltryAgainAction: () => {
          this.setState({ openLoaderModal: false, openImportDataModal: true });
        },
      });

      return;
    }

    this.setState({ openLoaderModal: false });

    this.fetchEmployees();
  };

  exportStudents = async () => {
    this.setState({
      openLoaderModal: true,
      loaderModalErrorMessage: null,
      loaderModalDescription: 'Exporting Employees...',
    });

    const response = await exportEmployees(this.props.currentUser.authToken);

    if (response.success) {
      this.setState({
        openLoaderModal: false,
      });
      console.log(response.data);
      fileDownload(response.data.data, `students-${new Date()}.xlsx`);
    }

    this.setState({
      loaderModalErrorMessage: response.message,
      loaderModaltryAgainAction: this.exportStudents,
    });
  };

  filter = () => {
    let employees =
      this.state.employeesToView === 'Active'
        ? [...this.state.activeEmployees]
        : [...this.state.terminatedEmployees];

    // if department is not empty, filter by department
    if (this.state.departmentId.length > 0) {
      employees = employees.filter(
        (item) => item.departmentId == this.state.departmentId,
      );
    }

    // perform filter, if is male or female
    if (this.state.filter === 'Male' || this.state.filter === 'Female') {
      // filter based on gender
      employees = employees.filter((item) => item.gender === this.state.filter);
    }

    // perform query if any
    if (this.state.query.length > 0) {
      const query = this.state.query.toLowerCase();
      employees = employees.filter(
        (item) =>
          item.uniqueId.toLowerCase().includes(query) ||
          item.firstName.toLowerCase().includes(query) ||
          item.lastName.toLowerCase().includes(query),
      );
    }

    return employees;
  };

  exportEmployees = async () => {
    this.setState({
      openLoaderModal: true,
      loaderModalErrorMessage: null,
      loaderModalDescription: 'Exporting Employees...',
    });

    const response = await exportEmployees(this.props.currentUser.authToken);

    if (response.success) {
      this.setState({
        openLoaderModal: false,
      });
      console.log(response.data);
      fileDownload(response.data.data, `employees-${new Date()}.xlsx`);
    }

    this.setState({
      loaderModalErrorMessage: response.message,
      loaderModaltryAgainAction: this.exportEmployees,
    });
  };

  render() {
    const employees = this.filter();

    return (
      <div>
        <MainHeader />

        <MainLoaderModal
          openModal={this.state.openLoaderModal}
          loading={this.state.loaderModalLoadingStatus}
          description={this.state.loaderModalDescription}
          errorMessage={this.state.loaderModalErrorMessage}
          tryAgainAction={this.state.loaderModaltryAgainAction}
          cancelAction={() => this.setState({ openLoaderModal: false })}
        />

        <AddEmployeeModal
          openModal={this.state.openAddNewModal}
          employee={this.state.selectedEmployee}
          importAction={this.toggleImportModal}
          closeAction={() => this.setState({ openAddNewModal: false })}
        />

        <ImportDataModal
          openModal={this.state.openImportDataModal}
          heading="Import Employees"
          description="Add Employees in bulk through a CSV file. "
          importAction={(csvFile) => this.onImportStudents(csvFile)}
          cancelAction={this.toggleImportModal}
        />

        <ConfirmDeleteModal
          openModal={this.state.openConfirmDeleteModal}
          description={this.state.deleteDescription}
          deleteAction={this.state.deleteAction}
          deleteSuccessAction={this.state.deleteSucessAction}
          cancelAction={() => this.setState({ openConfirmDeleteModal: false })}
        />

        <PrintOutModal
          open={this.state.openPrintOutModal}
          department={
            this.state.departmentId.length > 0
              ? this.state.departments.find(
                  (dpt) => dpt.id == this.state.departmentId,
                ).description
              : 'ALL'
          }
          status={this.state.employeesToView}
          employees={this.filter()}
          school={this.props.currentUser.school}
          closeAction={this.togglePrintOutModal}
        />

        <ImportDataModal
          openModal={this.state.openImportModal}
          cancelAction={() => this.setState({ openImportModal: false })}
        />

        <div className="employee page-container">
          <div>
            <span className="h-18">Employees</span>

            <span className="right">
              <Mainmenu />
            </span>
          </div>

          <div className="mt-20">
            <div className="bx--row">
              <div className="bx--col-md-2">
                <div
                  className="card-add-employee pointer"
                  onClick={() => {
                    this.setState({ openAddNewModal: true });
                  }}>
                  <div className="plus-icon mt-10">
                    <Plus size="18" strokeWidth="3" />
                  </div>
                  <div className="text-label">ADD NEW EMPLOYEE</div>
                </div>
              </div>

              <div className="bx--col-md-2">
                <QuickActionCard
                  description="Active"
                  hideAction2={true}
                  leftActionText="SHOW"
                  actionEvent1={() => {
                    this.setState({ employeesToView: 'Active' });
                  }}>
                  <Briefcase color="#fff" />
                </QuickActionCard>
              </div>
              <div className="bx--col-md-2">
                <QuickActionCard
                  description="Terminated"
                  hideAction2={true}
                  leftActionText="SHOW"
                  actionEvent1={() => {
                    this.setState({ employeesToView: 'Terminated' });
                  }}>
                  <BriefcaseRed color="#fff" />
                </QuickActionCard>
              </div>
              <div className="bx--col-md-2">
                <QuickActionCard
                  description="Export Employees"
                  hideAction2={true}
                  leftActionText="EXPORT"
                  actionEvent1={this.exportEmployees}>
                  <Upload />
                </QuickActionCard>
              </div>
            </div>
          </div>

          <hr className="grey-hr mt-30" />

          <div className="mt-20">
            <span
              className="h-20"
              hidden={
                this.state.loading ||
                this.state.errorMessage != null ||
                this.state.allEmployees.length === 0
              }>
              {this.state.employeesToView}
            </span>

            <span
              className="right text-primary text-12 font-bold pointer"
              onClick={this.togglePrintOutModal}>
              <Printer size="12" strokeWidth="5px" /> &nbsp; PRINT
            </span>
          </div>

          <div
            className="mt-10"
            hidden={
              this.state.loading ||
              this.state.errorMessage != null ||
              this.state.allEmployees.length === 0
            }>
            <div className="bx--row">
              <div className="bx--col-lg-3">
                <Select
                  noLabel
                  onChange={(e) =>
                    this.setState({ departmentId: e.target.value })
                  }>
                  <SelectItem selected disabled text="Filter by department" />
                  <SelectItem text="All" value="" />

                  {this.props.departments.map((department) => (
                    <SelectItem
                      text={department.description}
                      value={department.id}
                    />
                  ))}
                </Select>
              </div>
              <div className="bx--col-lg-9">
                <Search
                  id="search"
                  name="search"
                  labelText=""
                  value={this.state.query}
                  onChange={(event) =>
                    this.setState({ query: event.target.value })
                  }
                  placeHolderText="Enter first name or last name to search"
                />
              </div>
            </div>
          </div>

          <div className="mt-20" hidden={!this.state.loading}>
            <Loading
              small={true}
              withOverlay={false}
              description="Loading..."
            />
          </div>

          <div className="mt-30" hidden={this.state.errorMessage == null}>
            <span className="mt-10 text-danger">
              {this.state.errorMessage}.
            </span>
            &nbsp;
            <span
              className="text-primary pointer font-bold"
              onClick={() => this.fetchEmployees()}>
              TRY AGAIN
            </span>
          </div>

          <p
            className="text-14 mt-30 text-grey"
            hidden={
              this.state.allEmployees.length > 0 ||
              this.state.loading ||
              this.state.errorMessage !== null
            }>
            There are no employees
          </p>

          <div className="bx--row mt-30" hidden={this.state.loading === true}>
            {employees.map((employee) => (
              <div className="bx--col-lg-3 fade-in">
                <EmployeeCard
                  editAction={() =>
                    this.setState({
                      openAddNewModal: true,
                      selectedEmployee: employee,
                    })
                  }
                  deleteAction={() =>
                    this.setState({
                      openConfirmDeleteModal: true,
                      deleteAction: async () => {
                        return await deleteEmployee(
                          this.props.currentUser.authToken,
                          {
                            ...employee,
                          },
                        );
                      },
                      deleteSucessAction: () => {
                        this.props.removeEmployee(employee.id);
                      },
                      deleteDescription: `Confirm deletion of Employee ${employee.firstName} ${employee.lastName} [${employee.uniqueId}]. This action cannot be reversed`,
                    })
                  }
                  employee={employee}
                />
              </div>
            ))}
          </div>

          <Footer fixed={false} showLinks={false} />
        </div>
      </div>
    );
  }
}

// this will provide us with the state values we need
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  departments: selectDepartments,
  allEmployees: selectAllEmployees,
  activeEmployees: selectActiveEmployees,
  terminatedEmployees: selectTerminatedEmployees,
});

// this allows us to map our redux actions to our component
const mapDispatchToProps = (dispatch) => ({
  setDepartments: (departments) => dispatch(setDepartments(departments)),
  setEmployees: (employees) => dispatch(setEmployees(employees)),
  removeEmployee: (employeeId) => dispatch(removeEmployee(employeeId)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmployeePage),
);
