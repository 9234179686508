import { apiBaseUrl, handleGenericError, postData } from './common';

// import axios
const axios = require('axios').default;

const baseUrl = `${apiBaseUrl}chart-of-accounts/`;

export const getGroups = async (authToken) => {
  // build url
  const url = `${baseUrl}groups`;

  // set config
  const config = { headers: { Authorization: `Bearer ${authToken}` } };

  // initiate request
  try {
    let response = await axios.get(url, config);
    return { success: true, groups: response.data };
  } catch (error) {
    return handleGenericError(error);
  }
};

export const getLedgerCategories = async (
  authToken,
  skip,
  take = 50,
  query = '',
  groupId = null,
) => {
  // build url
  const url = `${baseUrl}ledger-categories/?skip=${skip}&take=${take}&query=${query}${
    groupId ? '&groupId' : ''
  }`;

  // set config
  const config = { headers: { Authorization: `Bearer ${authToken}` } };

  // initiate request
  try {
    let response = await axios.get(url, config);
    return { success: true, ledgerCategories: response.data };
  } catch (error) {
    return handleGenericError(error);
  }
};

export const getLedgerAccounts = async (
  authToken,
  skip,
  take = 50,
  query = '',
  categoryId = -1,
) => {
  // build url
  const url = `${baseUrl}ledger-accounts/?skip=${skip}&take=${take}&query=${query}&categoryId=${categoryId}`;

  // set config
  const config = { headers: { Authorization: `Bearer ${authToken}` } };

  // initiate request
  try {
    let response = await axios.get(url, config);
    return { success: true, ledgerAccounts: response.data };
  } catch (error) {
    return handleGenericError(error);
  }
};

export const addOrUpdateLedgerCategory = async (authToken, data) => {
  // build url
  const url = `${baseUrl}new-ledger-category`;

  // set config
  const config = { headers: { Authorization: `Bearer ${authToken}` } };

  // initiate request
  try {
    let response = await axios.post(url, data, config);
    return {
      success: true,
      ledgerCategory: response.data.ledgerCategory,
      message: `Category ${data.id === 0 ? 'added' : 'updated'} successfully.`,
    };
  } catch (error) {
    return handleGenericError(error);
  }
};

export const addOrUpdateLedgerAccount = async (authToken, data) => {
  // build url
  const url = `${baseUrl}new-ledger-account`;

  // set config
  const config = { headers: { Authorization: `Bearer ${authToken}` } };

  // initiate request
  try {
    let response = await axios.post(url, data, config);
    return {
      success: true,
      ledgerAccount: response.data.ledgerAccount,
      message: `Ledger ${data.id === 0 ? 'added' : 'updated'} successfully.`,
    };
  } catch (error) {
    return handleGenericError(error);
  }
};

export const deleteLedgerCategory = async (authToken, data) => {
  return await postData(
    'chart-of-accounts/delete-ledger-category',
    data,
    authToken,
  );
};

export const deleteLedgerAccount = async (authToken, data) => {
  return await postData(
    'chart-of-accounts/delete-ledger-account',
    data,
    authToken,
  );
};
