import { call, put, all, takeLatest } from 'redux-saga/effects';
import { fetchData } from '../../api/api';
import ENDPOINTS from '../../api/endpoints';

function* calculateRevenueInMonth(action) {
  const response = yield call(
    fetchData,
    `${ENDPOINTS.REVENUE}revenue-in-month`,
    action.payload.authToken,
  );

  yield onResponse(
    response,
    'CALCULATE_REVENUE_IN_MONTH_SUCCEEDED',
    'CALCULATE_REVENUE_IN_MONTH_FAILED',
  );
}

function* onResponse(response, actionTypeSuccess, actionTypeFailed) {
  if (response.success) {
    yield put({
      type: actionTypeSuccess,
      payload: response.data,
    });
  } else {
    yield put({
      type: actionTypeFailed,
      payload: response.message,
    });
  }
}

function* onCalculateRevenueInMonth() {
  yield takeLatest(
    'CALCULATE_REVENUE_IN_MONTH_REQUESTED',
    calculateRevenueInMonth,
  );
}

export default function* revenueSagas() {
  yield all([call(onCalculateRevenueInMonth)]);
}
