// add new items to existing ones
export const addItems = (
  existingItems,
  newItems,
  isBilling = false,
  isBilledStudents = false,
) => {
  // could be an update, make sure there is no duplication

  // create a copy of existing terms
  const copyExistingItems = [...existingItems];

  if (!newItems) return copyExistingItems;

  newItems.forEach((newItem) => {
    let itemIndex;
    if (isBilling) {
      itemIndex = existingItems.findIndex(
        (existingItem) => existingItem.uniqueId === newItem.uniqueId,
      );
    } else if (isBilledStudents) {
      itemIndex = existingItems.findIndex(
        (existingItem) =>
          existingItem.billing.uniqueId === newItem.billing.uniqueId,
      );
    } else {
      itemIndex = existingItems.findIndex(
        (existingItem) => existingItem.id === newItem.id,
      );
    }

    if (itemIndex !== -1) {
      // update
      copyExistingItems.splice(itemIndex, 1, newItem);
    } else {
      copyExistingItems.push(newItem);
    }
  });

  // return modified existing items
  return copyExistingItems;
};

export const findItemById = (itemId, items) => {
  return items.find((item) => item.id === itemId);
};

export const removeItem = (items, itemToRemoveId) => {
  // create a copy of items
  const copyItems = [...items];

  // find item
  const itemToRemoveIndex = copyItems.findIndex(
    (item) => item.id === itemToRemoveId,
  );

  if (itemToRemoveIndex === -1) return copyItems;

  // remove item
  copyItems.splice(itemToRemoveIndex, 1);

  return copyItems;
};
