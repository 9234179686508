import React from 'react';
import { Modal } from 'carbon-components-react';
import CustomButton from '../../custom-button/custom-btn.component';
import { Printer } from 'react-feather';
import SubFooter from '../../footer/sub-footer/sub-footer.component';

import { formatCurrency, formatDate } from '../../../utils/common.util';

import './balance-sheet.styles.scss';

const BalanceSheetPrintModal = ({
  open,
  data,
  school: { name, location, logoUrl },
  cancelAction,
}) => {
  return (
    <Modal
      id="balance-sheet-modal"
      className="balance-sheet-modal"
      hasScrollingContent
      passiveModal={true}
      open={open}
      primaryButtonDisabled={false}>
      {data && (
        <div className="modal-inner">
          <div className="inner-content">
            <div style={{ breakAfter: 'avoid' }}>
              <div className="mt-15 bx--col--auto mb-10">
                <div
                  className="school-logo center"
                  style={{ backgroundImage: `url(${logoUrl})` }}></div>
                <h3 className="h-24 text-primary text-center">{name}</h3>
                <p className="text-14 text-grey-dark text-center">{location}</p>

                <p className="text-center text-16 text-primary font-bold mt-20 text-uppercase">
                  BALANCE SHEET AS AT {formatDate(data.dateTo)}
                </p>
              </div>

              <hr className="hr-grey" />

              <div>
                <table className="bx--data-table table-transparent mt-20">
                  <thead>
                    <tr>
                      <th colSpan="3">
                        <span className="text-primary text-12 font-bold">
                          FIXED ASSETS
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.assets.fixedAssets.ledgers.map((ledger) => (
                      <tr>
                        <td style={{ width: '200px' }}>{ledger.uniqueId}</td>
                        <td>{ledger.description}</td>
                        <td>
                          <span className="right">
                            {formatCurrency(ledger.total)}
                          </span>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="2">
                        <span className="text-primary font-bold">TOTAL</span>
                      </td>
                      <td>
                        <span className="text-primary font-bold right">
                          {formatCurrency(data.assets.fixedAssets.total)}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="bx--data-table table-transparent mt-20">
                  <thead>
                    <tr>
                      <th colSpan="3">
                        <span className="text-primary text-12 font-bold">
                          CURRENT ASSETS
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.assets.currentAssets.ledgers.map((ledger) => (
                      <tr>
                        <td style={{ width: '200px' }}>{ledger.uniqueId}</td>
                        <td>{ledger.description}</td>
                        <td>
                          <span className="right">
                            {formatCurrency(ledger.total)}
                          </span>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="2">
                        <span className="text-primary font-bold">TOTAL</span>
                      </td>
                      <td>
                        <span className="text-primary font-bold right">
                          {formatCurrency(data.assets.currentAssets.total)}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="summary-bar">
                  <span>TOTAL ASSETS</span>
                  <span>{formatCurrency(data.assets.total)}</span>
                </div>

                <table className="bx--data-table table-transparent mt-40">
                  <thead>
                    <tr>
                      <th colSpan="3">
                        <span className="text-primary text-12 font-bold">
                          SHORT TERM LIABILITIES
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.liabilities.shortTermLiabilities.ledgers.map(
                      (ledger) => (
                        <tr>
                          <td style={{ width: '200px' }}>{ledger.uniqueId}</td>
                          <td>{ledger.description}</td>
                          <td>
                            <span className="right">
                              {formatCurrency(ledger.total)}
                            </span>
                          </td>
                        </tr>
                      ),
                    )}
                    <tr>
                      <td colSpan="2">
                        <span className="text-primary font-bold">TOTAL</span>
                      </td>
                      <td>
                        <span className="text-primary font-bold right">
                          {formatCurrency(
                            data.liabilities.shortTermLiabilities.total,
                          )}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="bx--data-table table-transparent mt-20">
                  <thead>
                    <tr>
                      <th colSpan="3">
                        <span className="text-primary text-12 font-bold">
                          LONG TERM LIABILITIES
                        </span>
                      </th>
                    </tr>
                  </thead>
                </table>

                <table className="bx--data-table table-transparent mt-20">
                  <thead>
                    <tr>
                      <th colSpan="3">
                        <span className="text-primary text-12 font-bold">
                          CAPITAL &amp; RESERVES
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.equity.capital.ledgers.map((ledger) => (
                      <tr>
                        <td style={{ width: '200px' }}>{ledger.uniqueId}</td>
                        <td>{ledger.description}</td>
                        <td>
                          <span className="right font-600">
                            {formatCurrency(ledger.total)}
                          </span>
                        </td>
                      </tr>
                    ))}

                    <tr>
                      <td colSpan="2">
                        <span className="text-primary font-bold">TOTAL</span>
                      </td>
                      <td>
                        <span className="text-primary font-bold right">
                          {formatCurrency(data.equity.capital.total)}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="bx--data-table table-transparent mt-20">
                  <thead>
                    <tr>
                      <th colSpan="3">
                        <span className="text-primary text-12 font-bold">
                          RETAINED PROFIT
                        </span>
                      </th>

                      <th style={{ textAlign: 'right' }}>
                        <span className="text-primary text-12 font-bold">
                          {formatCurrency(data.incomeStatement.variance)}
                        </span>
                      </th>
                    </tr>
                  </thead>
                </table>

                <div className="summary-bar">
                  <span>TOTAL EQUITY &amp; LIABILITIES</span>
                  <span>
                    {formatCurrency(
                      data.liabilities.total +
                        data.equity.total +
                        data.incomeStatement.variance,
                    )}
                  </span>
                </div>
              </div>

              <div className="mt-25">
                <div className="mt-20">
                  <SubFooter />
                </div>

                <div className="bx--row mt-35">
                  <div className="bx--col-lg-3">
                    <CustomButton
                      classes="outline secondary f-width font-bold"
                      onClick={cancelAction}>
                      CLOSE
                    </CustomButton>
                  </div>
                  <div className="bx--col-lg-3">
                    <CustomButton
                      classes="primary f-width font-bold"
                      onClick={() => window.print()}>
                      <Printer size="11" strokeWidth="5px" /> &nbsp; PRINT
                    </CustomButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default BalanceSheetPrintModal;
