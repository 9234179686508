import React, { useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Upload, Printer } from 'react-feather';
import { DatePicker, DatePickerInput } from 'carbon-components-react';
import fileDownload from 'js-file-download';

import MainHeader from '../../../components/main-header/main-header.component';
import Mainmenu from '../../../components/menu/main-menu/main-menu.component';
import CustomButton from '../../../components/custom-button/custom-btn.component';
import Footer from '../../../components/footer/footer.component';

import MainLoaderModal from '../../../components/modal/main-loader/main-loader.component';
import { selectCurrentUser } from '../../../redux/selectors/user.selectors';
import IncomeStatementPrintModal from '../../../components/modal/income-statement/income-statement.component';
import { ExpBalanceSheet } from '../../../components/expandable/expandable-pane/pane.component';

import { selectIncomeStatementReport } from '../../../redux/analytics/analytics.selectors';
import { formatCurrency, formatDate } from '../../../utils/common.util';
import { fetchService } from '../../../api/common';

import './income-statement.styles.scss';

const IncomeStatementReportPage = ({
  incomeStatementReport: { fetching, fetchError, data },
  fetchIncomeStatementReport,
  currentUser: { authToken, school },
}) => {
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [openLoaderModal, setOpenLoaderModal] = useState(fetching);

  const [openExportLoaderModal, setOpenExportLoaderModal] = useState(false);
  const [exportLoaderModalErrorMessage, setExportLoaderModalErrorMessage] =
    useState(null);

  const exportIncomeStatementReport = async () => {
    setExportLoaderModalErrorMessage(null);
    setOpenExportLoaderModal(true);

    const response = await fetchService(
      `analytics/income-statement-report/export?dateFrom=${dateFrom}&dateTo=${dateTo}`,
      authToken,
    );

    if (response.success) {
      setOpenExportLoaderModal(false);
      fileDownload(
        response.data.data,
        `income-statement-report-${new Date()}.xlsx`,
      );

      return;
    }

    setExportLoaderModalErrorMessage(response.message);
  };

  return (
    <div>
      <MainHeader />

      <MainLoaderModal
        openModal={openExportLoaderModal}
        description="Exporting Income Statement Report"
        errorMessage={exportLoaderModalErrorMessage}
        tryAgainAction={() => exportIncomeStatementReport}
        cancelAction={() => setOpenExportLoaderModal(false)}
      />

      <MainLoaderModal
        openModal={
          (openLoaderModal && fetching) || (openLoaderModal && fetchError)
        }
        loading={fetching}
        description={'Generating income statement report...'}
        errorMessage={fetchError}
        tryAgainAction={() => {
          setOpenLoaderModal(true);
          fetchIncomeStatementReport(dateFrom, dateTo, authToken);
        }}
        cancelAction={() => setOpenLoaderModal(false)}
      />

      <IncomeStatementPrintModal
        open={openPrintModal}
        school={school}
        data={data}
        cancelAction={() => setOpenPrintModal(false)}
      />

      <span className="right">
        <Mainmenu />
      </span>

      <div className="income-statement-page page-container">
        <div>
          <p>
            <span className="text-12 font-bold text-grey">You are here</span>
            &nbsp;&nbsp;
            <Link to="/analytics" className="text-12 text-dark">
              Analytics
            </Link>
            &nbsp;<span className="text-12">&gt;</span>&nbsp;
            <span className="text-12 text-primary font-bold">
              Income Statement
            </span>
          </p>
        </div>

        <hr className="grey-hr mt-20" />

        <div className="h-24 uppercase mt-15">
          Income Statement&nbsp;:&nbsp;
          {data &&
            `${formatDate(new Date(data.dateFrom))} - ${formatDate(
              new Date(data.dateTo),
            )}`}
        </div>

        <div className="mt-20">
          <div className="bx--row">
            <div className="bx--col-lg-3">
              <DatePicker
                dateFormat="Y-m-d"
                datePickerType="single"
                id="start-date-picker"
                onClose={(value) => {
                  if (value.length > 0) {
                    setDateFrom(
                      [
                        value[0].getFullYear(),
                        ('0' + (value[0].getMonth() + 1)).slice(-2),
                        ('0' + value[0].getDate()).slice(-2),
                      ].join('-'),
                    );
                  }
                }}>
                <DatePickerInput
                  className="start-date"
                  id="start-date"
                  name="startDate"
                  labelText="Date from"
                  value={dateFrom}
                  pattern="d{1,2}/d{4}"
                  placeholder="YYYY-MM-DD"
                  type="text"
                />
              </DatePicker>
            </div>
            <div className="bx--col-lg-3 npl npr">
              <DatePicker
                dateFormat="Y-m-d"
                datePickerType="single"
                id="end-date-picker"
                onClose={(value) => {
                  if (value.length > 0) {
                    setDateTo(
                      [
                        value[0].getFullYear(),
                        ('0' + (value[0].getMonth() + 1)).slice(-2),
                        ('0' + value[0].getDate()).slice(-2),
                      ].join('-'),
                    );
                  }
                }}>
                <DatePickerInput
                  className="end-date"
                  id="end-date"
                  name="endDate"
                  labelText="Date to"
                  value={dateTo}
                  pattern="d{1,2}/d{4}"
                  placeholder="YYYY-MM-DD"
                  type="text"
                />
              </DatePicker>
            </div>
            <div className="bx--col-lg-2">
              <CustomButton
                classes="f-width primary mt-25 font-bold"
                disabled={dateFrom.length === 0 || dateTo.length === 0}
                onClick={() => {
                  setOpenLoaderModal(true);
                  fetchIncomeStatementReport(dateFrom, dateTo, authToken);
                }}>
                RUN REPORT
              </CustomButton>
            </div>
          </div>
        </div>

        <div className="mt-30">
          <ExpBalanceSheet
            hideIcon
            hideDescription
            headerData={{
              title: 'REVENUE',
              value: data ? formatCurrency(data.revenue.total) : 'N/A',
            }}>
            {data &&
              data.revenue.ledgers.map((ledger) => (
                <ExpBalanceSheet
                  classNames="exp-subcomponent"
                  headerData={{
                    description: ledger.description,
                    value: formatCurrency(ledger.total),
                  }}>
                  <table className="bx--data-table table-transparent">
                    <thead>
                      <tr>
                        <th>
                          <span className="text-primary text-12">ID</span>
                        </th>
                        <th>
                          <span className="text-primary text-12">Ledger</span>
                        </th>
                        <th>
                          <span className="text-primary text-12 right">
                            TOTAL
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {ledger.accounts.map((account) => (
                        <tr>
                          <td style={{ width: '300px' }}>{account.uniqueId}</td>
                          <td>{account.description}</td>
                          <td>
                            <span className="right">
                              {formatCurrency(account.total)}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </ExpBalanceSheet>
              ))}
          </ExpBalanceSheet>

          <ExpBalanceSheet
            hideIcon
            hideDescription
            headerData={{
              title: 'EXPENSE',
              value: data ? formatCurrency(data.expenses.total) : 'N/A',
            }}>
            {data &&
              data.expenses.ledgers.map((ledger) => (
                <ExpBalanceSheet
                  classNames="exp-subcomponent"
                  headerData={{
                    description: ledger.description,
                    value: formatCurrency(ledger.total),
                  }}>
                  <table className="bx--data-table table-transparent">
                    <thead>
                      <tr>
                        <th>
                          <span className="text-primary text-12">ID</span>
                        </th>
                        <th>
                          <span className="text-primary text-12">Ledger</span>
                        </th>
                        <th>
                          <span className="text-primary text-12 right">
                            TOTAL
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {ledger.accounts.map((account) => (
                        <tr>
                          <td style={{ width: '300px' }}>{account.uniqueId}</td>
                          <td>{account.description}</td>
                          <td>
                            <span className="right">
                              {formatCurrency(account.total)}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </ExpBalanceSheet>
              ))}
          </ExpBalanceSheet>

          <div
            className={`variance-bar mt-30 d-flex-space-between text-20 font-bold text-white bg-primary ${
              data
                ? data.variance > 0
                  ? 'profit'
                  : data.variance < 0
                    ? 'loss'
                    : ''
                : ''
            }`}>
            <span>
              {data
                ? data.variance > 0
                  ? 'PROFIT'
                  : data.variance < 0
                    ? 'LOSS'
                    : 'VARIANCE'
                : 'VARIANCE'}
            </span>
            <span>{data ? formatCurrency(data.variance) : 'N/A'}</span>
          </div>
        </div>

        {data && (
          <div className="bx--row mt-35">
            <div className="bx--col-lg-2">
              <CustomButton
                classes="secondary f-width font-bold"
                onClick={exportIncomeStatementReport}>
                <Upload size="11" strokeWidth="5px" />
                &nbsp; EXPORT TO EXCEL
              </CustomButton>
            </div>
            <div className="bx--col-lg-2">
              <CustomButton
                classes="primary f-width font-bold"
                onClick={() => setOpenPrintModal(true)}>
                <Printer size="11" strokeWidth="5px" /> &nbsp; PRINT
              </CustomButton>
            </div>
          </div>
        )}

        <Footer fixed={false} showLinks={false} />
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  incomeStatementReport: selectIncomeStatementReport,
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  fetchIncomeStatementReport: (dateFrom, dateTo, authToken) =>
    dispatch({
      type: 'FETCH_INCOME_STATEMENT_REPORT_REQUESTED',
      payload: { dateFrom, dateTo, authToken },
    }),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(IncomeStatementReportPage),
);
