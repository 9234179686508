import React from 'react';

import { Modal } from 'carbon-components-react';

import FeesMiniBar from '../../bar/fees-mini-bar/fees-mini-bar.component';

import './billing-fees.styles.scss';
import { removeBillingFee } from '../../../redux/actions/billing.actions';
import { connect } from 'react-redux';
import { selectBillingFees } from '../../../redux/selectors/billing.selectors';
import { createStructuredSelector } from 'reselect';

const BillingFeesModal = ({
  billingFees,
  openModal = false,
  editAction,
  deleteAction,
  cancelAction,
}) => {
  return (
    <Modal
      id="billing-fees-modal"
      className="billing-fees-modal"
      hasScrollingContent
      passiveModal={true}
      open={openModal}
      modalHeading="Billing Fees"
      primaryButtonDisabled={false}>
      <div>
        {billingFees.map((fee) => (
          <FeesMiniBar
            fee={fee}
            editAction={() => {
              cancelAction();
              editAction(fee);
            }}
            deleteAction={() => {
              cancelAction();
              deleteAction(fee);
            }}
          />
        ))}

        <p
          className="text-danger pointer font-bold text-14 mt-40"
          onClick={cancelAction}>
          CLOSE
        </p>
      </div>
    </Modal>
  );
};

// this will provide us with the state values we need
const mapStateToProps = createStructuredSelector({
  billingFees: selectBillingFees,
});

// this allows us to map our redux actions to our component
const mapDispatchToProps = (dispatch) => ({
  removeBillingFee: (billingFeeId) => dispatch(removeBillingFee(billingFeeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingFeesModal);
