import UserActionTypes from './user.types';
import { addItems } from '../utils/common.util';

// initial state
const INITIAL_STATE = {
  currentUser: null,
  error: null,
  authenticating: false,
  authenticatingError: null,
};

// user reducer
const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.AUTHENTICATE_REQUESTED:
      return {
        ...state,
        authenticating: true,
        authenticatingError: null,
      };
    case UserActionTypes.AUTHENTICATE_SUCCEEDED:
      return {
        ...state,
        currentUser: action.payload,
        authenticating: false,
      };
    case UserActionTypes.AUTHENTICATE_FAILED:
      return {
        ...state,
        authenticating: false,
        authenticatingError: action.payload,
      };
    case UserActionTypes.SET_USERS:
      return {
        ...state,
        users: addItems(state.users, action.payload),
      };
    default:
      return state;
  }
};

export default userReducer;
