import { all, call } from 'redux-saga/effects';

import userSagas from './user/user.sagas';
import analyticsSaga from './analytics/analytics.sagas';
import ledgerSagas from './ledger/ledger.sagas';
import billingSagas from './billing/billing.sagas';
import expenseSagas from './expense/expense.sagas';
import revenueSagas from './revenue/revenue.sagas';
import academicYearSagas from './academic-year/academic-year.sagas';

export default function* rootSaga() {
  yield all([
    call(userSagas),
    call(analyticsSaga),
    call(ledgerSagas),
    call(billingSagas),
    call(expenseSagas),
    call(revenueSagas),
    call(academicYearSagas),
  ]);
}
