import { createSelector } from 'reselect';

const selectAnalytics = (state) => state.analytics;

export const selectStudentCount = createSelector(
  [selectAnalytics],
  (analytics) => analytics.studentCount,
);

export const selectStudentGenderChartData = createSelector(
  [selectStudentCount],
  (studentCount) =>
    studentCount.data
      ? [
          {
            group: 'Enrolled Males',
            value: studentCount.data.totalEnrolledMales,
          },
          {
            group: 'Enrolled Females',
            value: studentCount.data.totalEnrolledFemales,
          },
        ]
      : [],
);

export const selectStudentStatusChartData = createSelector(
  [selectStudentCount],
  (studentCount) =>
    studentCount.data
      ? [
          {
            group: 'Enrolled Students',
            value: studentCount.data.totalEnrolled,
          },
          {
            group: 'Graduated Students',
            value: studentCount.data.totalGraduated,
          },
          {
            group: 'Terminated Students',
            value: studentCount.data.totalTerminated,
          },
        ]
      : [],
);

export const selectEmployeeCount = createSelector(
  [selectAnalytics],
  (analytics) => analytics.employeeCount,
);

export const selectEmployeeGenderChartData = createSelector(
  [selectEmployeeCount],
  (employeeCount) =>
    employeeCount.data
      ? [
          {
            group: 'Active Males',
            value: employeeCount.data.totalActiveMales,
          },
          {
            group: 'Active Females',
            value: employeeCount.data.totalActiveFemales,
          },
        ]
      : [],
);

export const selectEmployeeStatusChartData = createSelector(
  [selectEmployeeCount],
  (employeeCount) =>
    employeeCount.data
      ? [
          {
            group: 'Active Employees',
            value: employeeCount.data.totalActive,
          },
          {
            group: 'Terminated Employees',
            value: employeeCount.data.totalTerminated,
          },
        ]
      : [],
);

export const selectBalanceSheetReport = createSelector(
  [selectAnalytics],
  (analytics) => analytics.balanceSheetReport,
);

export const selectIncomeStatementReport = createSelector(
  [selectAnalytics],
  (analytics) => analytics.incomeStatementReport,
);

export const selectTrialBalanceReport = createSelector(
  [selectAnalytics],
  (analytics) => analytics.trialBalanceReport,
);

export const selectExpensesReport = createSelector(
  [selectAnalytics],
  (analytics) => analytics.expensesReport,
);

export const selectRevenueReport = createSelector(
  [selectAnalytics],
  (analytics) => analytics.revenueReport,
);

export const selectTransactionsReport = createSelector(
  [selectAnalytics],
  (analytics) => analytics.transactionsReport,
);

export const selectBudgetReport = createSelector(
  [selectAnalytics],
  (analytics) => analytics.budgetReport,
);
