import SupplierActionTypes from '../types/supplier.types';

export const setSuppliers = (suppliers) => ({
  type: SupplierActionTypes.SET_SUPPLIERS,
  payload: suppliers,
});

export const removeSupplier = (supplierId) => ({
  type: SupplierActionTypes.REMOVE_SUPPLIER,
  payload: supplierId,
});
