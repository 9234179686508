import React from 'react';

import './student-summary-card.styles.scss';

const StudentSummaryCard = ({ children, title, value, action, active }) => (
  <div className={`student-summary-card pointer ${active}`} onClick={action}>
    <div className="icon">{children}</div>

    <div className="info">
      <p className="title nmb">{title}</p>
      <p className="value nmb">{value}</p>
    </div>
  </div>
);

export default StudentSummaryCard;
