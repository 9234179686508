import React, { useState } from 'react';
import { ReactComponent as Hierarchy } from '../../../assets/images/card-icons/hierarchy.svg';
import { Plus } from 'react-feather';

import './pane.styles.scss';

/* usage analytics -- balance-sheet, trial-balance, income-statement*/
const plusIcon = <Plus size="10" strokeWidth="5px" />;

const ExpandablePane = ({
  hideIcon = false,
  icon = (
    <div className="icon" hidden={hideIcon}>
      <Hierarchy />
    </div>
  ),
  title,
  stretchTitle = false,
  expand = false,
  headerContent,
  paneContent,
  isTree = false,
  hideExpand = false,
  classNames,
}) => {
  const [expandPane, setExpandPane] = useState(expand);

  return (
    <div className={`expandable-pane ${isTree} ${classNames}`}>
      <div className="exp-pane-header  d-flex align-items-center">
        {icon}
        <h3 className={`h-20 text-primary exp-title ${stretchTitle}`}>
          {title}
        </h3>
        <div className="exp-hearder-content">{headerContent}</div>
        <div className="">
          {paneContent ? (
            <span
              className="link"
              hidden={hideExpand}
              onClick={() => {
                setExpandPane(expandPane ? false : true);
              }}>
              {expandPane ? (
                <span className="text-grey">- COLLAPSE</span>
              ) : (
                <span>{plusIcon} EXPAND</span>
              )}
            </span>
          ) : (
            <span hidden={hideExpand} className="text-grey-light link">
              {plusIcon} EXPAND
            </span>
          )}
        </div>
      </div>

      <div className={`exp-inner-pane ${isTree}`} hidden={!expandPane}>
        <div className={`exp-inner-container ${isTree}`}>{paneContent}</div>
      </div>
    </div>
  );
};

//same component for balancesheet/income statement expandable
const ExpBalanceSheet = ({
  children,
  hideIcon = false,
  hideDescription = false,
  isTree = false,
  headerData = { description: '', value: '' },
  classNames,
}) => {
  return (
    <ExpandablePane
      classNames={classNames}
      title={headerData.title}
      hideIcon={hideIcon}
      isTree={isTree}
      headerContent={
        <div className="d-flex align-items-center">
          <div className="flex-2">
            <span className="text-grey text-14" hidden={hideDescription}>
              Description
            </span>
            <h3 className="text-20 text-dark font-500 font-bold">
              {headerData.description}
            </h3>
          </div>
          <div className="flex-1">
            <span className="text-grey text-14">Total</span>
            <h3 className="text-20 text-dark font-bold">{headerData.value}</h3>
          </div>
        </div>
      }
      paneContent={children}
    />
  );
};

// component for trial balance expandable
const ExpTrialBalance = ({
  children,
  isTree = false,
  debitLabel = 'Debit',
  creditLabel = 'Credit',
  headerData,
  hideExpand,
  classNames,
}) => {
  return (
    <ExpandablePane
      classNames={classNames}
      hideIcon
      title={headerData.title}
      isTree={isTree}
      stretchTitle={true}
      hideExpand={hideExpand}
      headerContent={
        <div className="d-flex align-items-center trial-header">
          <div className="flex-1">
            <span className="text-grey text-14">Opening</span>
            <h3 className="text-20 text-dark font-bold">
              {headerData.opening}
            </h3>
          </div>
          <div className="flex-1">
            <span className="text-grey text-14">{debitLabel}</span>
            <h3 className="text-20 text-dark font-bold">{headerData.debit}</h3>
          </div>
          <div className="flex-1">
            <span className="text-grey text-14">{creditLabel}</span>
            <h3 className="text-20 text-dark font-bold">{headerData.credit}</h3>
          </div>
          <div className="flex-1">
            <span className="text-grey text-14">Closing</span>
            <h3 className="text-20 text-dark font-bold">
              {headerData.closing}
            </h3>
          </div>
        </div>
      }
      paneContent={children}
    />
  );
};

export default ExpandablePane;
export { ExpBalanceSheet, ExpTrialBalance };
