import { call, put, all, takeLatest } from 'redux-saga/effects';
import { fetchData } from '../../api/api';

import { createAcademicYearFetchEndpoint } from '../../api/endpoints';

function* fetchAcademicYears(action) {
  const response = yield call(
    fetchData,
    createAcademicYearFetchEndpoint(),
    action.payload.token,
  );

  yield onResponse(
    response,
    'FETCH_ACADEMIC_YEARS_SUCCEEDED',
    'FETCH_ACADEMIC_YEARS_FAILED',
  );
}

function* onResponse(response, actionTypeSuccess, actionTypeFailed) {
  if (response.success) {
    yield put({
      type: actionTypeSuccess,
      payload: response.data,
    });
  } else {
    yield put({
      type: actionTypeFailed,
      payload: response.message,
    });
  }
}

function* onFetchAcademicYears() {
  yield takeLatest('FETCH_ACADEMIC_YEARS_REQUESTED', fetchAcademicYears);
}

export default function* academicYearSagas() {
  yield all([call(onFetchAcademicYears)]);
}
