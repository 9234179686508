import React from 'react';
import { Link } from 'react-router-dom';
import {
  TextInput,
  FormItem,
  Form,
  FormGroup,
  Tag,
  Loading,
} from 'carbon-components-react';

import CustomButton from '../../../components/custom-button/custom-btn.component';
import BorderedLogo from '../../../components/bordered-logo/bordered-logo.component';
import Footer from '../../../components/footer/footer.component';

import { resetPassword } from '../../../api/account.service';

import forgotPasswordBg from '../../../assets/images/forgot_password_bg.png';

// import our redux stuffs
//import { setCurrentUser } from "../../../redux/actions/user.actions";

import './forgot-password.styles.scss';

class ForgotPasswordPage extends React.Component {
  constructor(props) {
    super(props);

    // set state
    this.state = {
      emailAddress: '',
      requestInProgress: false,
      errorMessage: null,
      showSuccess: false,
      btnText: 'SUBMIT',
    };
  }

  // handle onchange event handler for input fields
  handleChange = (event) => {
    // destructure value and name of event.target
    const { value, name } = event.target;
    // update state
    this.setState({ [name]: value });
  };

  // validate data entered
  validateData = () =>
    this.state.emailAddress.length > 2 &&
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
      this.state.emailAddress,
    );

  // reset password event handler
  resetPassword = async (event) => {
    event.preventDefault();

    // update state to set request in progress to true and error occurred to false
    this.setState({
      requestInProgress: true,
      errorMessage: null,
      btnText: null,
    });

    // initiate request
    const response = await resetPassword(this.state.emailAddress);

    // check response
    if (!response.success) {
      // failed, change state to show error message
      this.setState({
        requestInProgress: false,
        btnText: 'SUBMIT',
        errorMessage: response.message,
      });

      return;
    }

    // success
    this.setState({ showSuccess: true });
  };

  render() {
    return (
      <div className="forgot-password">
        <div className="bx--row">
          <div className="bx--col-md-3">
            <div className="ml-85 mr-85">
              <div className="mt-60 mb-50">
                <BorderedLogo />
              </div>

              <div hidden={this.state.showSuccess}>
                <h3 className="text-primary font-bold text-24">
                  Forgot your password
                </h3>
                <p className="text-grey-dark text-12 mb-30">
                  This is only for the School's Administrator. If you are not
                  the School's administrator, please contact your School
                  administrator to reset your password.
                </p>

                <Form action="get">
                  <FormGroup legendText="">
                    <FormItem>
                      <TextInput
                        id="email"
                        name="emailAddress"
                        labelText="Email address"
                        value={this.state.emailAddress}
                        placeholder="Enter Email address"
                        onChange={this.handleChange}
                      />
                    </FormItem>
                    <p className="text-grey-dark text-12">
                      This should be the email used during school registration
                    </p>
                  </FormGroup>

                  <CustomButton
                    classes="primary f-width mt-20 mb-40"
                    onClick={this.resetPassword}
                    type="submit"
                    disabled={
                      !this.validateData() || this.state.requestInProgress
                    }>
                    {this.state.btnText}

                    <div hidden={this.state.btnText != null}>
                      <div className="d-flex-center">
                        <Loading withOverlay={false} small={true} />
                      </div>
                    </div>
                  </CustomButton>

                  <span>
                    Back to&nbsp;
                    <Link
                      to="/account/login"
                      className="text-primary text-14 font-bold">
                      Login
                    </Link>
                  </span>

                  <div hidden={this.state.errorMessage == null}>
                    <Tag className="tag-f-w mt-30" type="red">
                      {this.state.errorMessage}
                    </Tag>
                  </div>
                </Form>
              </div>

              <div hidden={!this.state.showSuccess}>
                <h3 className="text-primary font-bold text-24 mb-30">
                  Password Reset Instructions Sent!
                </h3>
                <p className="text-16 text-grey-dark mt-20">
                  <span className="text-14">
                    We have sent an email to&nbsp;
                    <span className="text-primary">
                      {this.state.emailAddress}
                    </span>
                    &nbsp;containing instructions to reset your password.
                  </span>
                </p>
                <CustomButton
                  classes="primary f-width mt-50 mb-40"
                  onClick={() => this.props.history.push('/account/login')}>
                  BACK TO LOGIN
                </CustomButton>
              </div>

              <Footer />
            </div>
          </div>
          <div className="bx--col-md-5 fixed-right padding-0">
            <img className="img-f-w-obj-cover" src={forgotPasswordBg} alt="" />
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPasswordPage;
