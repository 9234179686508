import RevenueActionTypes from '../types/revenue.types';

export const setPayments = (payments) => ({
  type: RevenueActionTypes.SET_PAYMENTS,
  payload: payments,
});

export const removePayment = (paymentId) => ({
  type: RevenueActionTypes.REMOVE_PAYMENT,
  payload: paymentId,
});

export const setReversedPayments = (reversedPayments) => ({
  type: RevenueActionTypes.SET_REVERSED_PAYMENTS,
  payload: reversedPayments,
});

export const setRevisedPayments = (revisedPayments) => ({
  type: RevenueActionTypes.SET_REVISED_PAYMENTS,
  payload: revisedPayments,
});
