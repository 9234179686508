import React, { useEffect, useState } from 'react';
import {
  TextInput,
  FormItem,
  Form,
  FormGroup,
  Modal,
  Loading,
  FormLabel,
} from 'carbon-components-react';
import CustomButton from '../../custom-button/custom-btn.component';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../../redux/selectors/user.selectors';
import { connect } from 'react-redux';

import './register-sms.styles.scss';
import { postData } from '../../../api/common';

const RegisterSMSModal = ({
  open,
  currentUser: { authToken },
  cancelAction,
}) => {
  const [state, setState] = useState({
    isConfigured: null,
    loading: false,
    message: null,
    errorMessage: null,
    senderId: '',
    userName: '',
    phoneNumber: '',
    emailAddress: '',
  });

  const register = async (event) => {
    event.preventDefault();

    // update state to set request in progress to true and error occurred to false
    setState({
      ...state,
      loading: true,
      errorMessage: null,
      message: null,
    });

    const data = {
      senderId: state?.senderId,
    };

    postData('sms/register', data, authToken).then((resp) => {
      if (resp.success) {
        setState({ ...state, loading: false });
        cancelAction(true);
        return;
      }

      setState({
        ...state,
        loading: false,
        errorMessage: resp?.message || 'An Error occured',
      });
    });
  };

  useEffect(() => {
    setState({ ...state, errorMessage: null });
  }, [open]);

  // const { data, error } = useSWR("sms/isSmsConfigured");

  return (
    <Modal
      id="register-sms-modal"
      className="register-sms-modal"
      hasScrollingContent
      passiveModal={true}
      open={open}
      modalHeading="SMS"
      primaryButtonDisabled={false}>
      <div>
        {/* <div className="mb-10">
          {!data && !error
            ? "Please wait..."
            : data?.message && "SMS Configured! no need to configure again."}
        </div> */}
        <Form action="post">
          <FormGroup legendText="">
            <FormItem>
              <TextInput
                id="name"
                name="name"
                labelText="Sender Identity (maximum 10 letters)"
                value={state.senderId}
                onChange={(e) => {
                  setState({
                    ...state,
                    senderId: e.target.value.substring(0, 10),
                  });
                }}
                placeholder="Sender identity"
              />
              <FormLabel className="mt-10">
                this will appear in messages you send
              </FormLabel>
            </FormItem>
            {/* <FormItem>
              <TextInput
                id="userName"
                name="userName"
                labelText="Username "
                value={state.userName}
                onChange={(e) => {
                  let v = e.target.value;
                  v = v.toLowerCase().trim();
                  if (v.length == 0) {
                    setState({ ...state, userName: "" });
                  } else if (v.length > 15 || !v.match(/^[0-9a-z]+$/)) {
                    return;
                  }
                  setState({ ...state, userName: v });
                }}
                placeholder="Enter name"
              />
              <FormLabel className="mb-20"></FormLabel>
            </FormItem> */}
            {/* <FormItem>
              <TextInput
                id="phoneNumber"
                name="phoneNumber"
                labelText="Phone number "
                value={state.phoneNumber}
                onChange={(e) =>
                  setState({ ...state, phoneNumber: e.target.value })
                }
                placeholder="Enter Phone number"
              />
              <FormLabel className="mb-20"></FormLabel>
            </FormItem> */}
            {/* <FormItem>
              <TextInput
                id="emailAddress"
                name="emailAddress"
                labelText="Email Address"
                value={state.emailAddress}
                onChange={(e) =>
                  setState({ ...state, emailAddress: e.target.value })
                }
                placeholder="Enter Email address"
              />
              <FormLabel className=""></FormLabel>
            </FormItem> */}
          </FormGroup>

          {/* <div className="text-grey">
            This registeration is between you and EPOBS approved Third-party SMS
            service provider
          </div> */}

          <CustomButton
            classes="primary f-width mt-10 font-bold"
            disabled={state.senderId.length < 2 || state.senderId.length > 10}
            onClick={register}>
            {!state.loading && 'SUBMIT'}
            <div hidden={!state.loading}>
              <div className="d-flex-center">
                <Loading
                  withOverlay={false}
                  small={true}
                  description="Please wait..."
                />
              </div>
            </div>
          </CustomButton>

          <p
            className="text-14 mt-10 text-danger"
            hidden={state.errorMessage === null}>
            {state.errorMessage}
          </p>

          <CustomButton
            classes="outline danger f-width font-bold mt-20"
            onClick={cancelAction}>
            CLOSE
          </CustomButton>
        </Form>
      </div>
    </Modal>
  );
};

// this will provide us with the state values we need
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

// this allows us to map our redux actions to our component
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterSMSModal);
