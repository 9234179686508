import React from 'react';
import { Edit, Trash2 } from 'react-feather';
import { Loading } from 'carbon-components-react';

import './ledger-account.styles.scss';

const LedgerAccountCard = ({
  ledgerAccount,
  openAddLedgerAccountModal,
  deleteAction,
}) => {
  return (
    <div>
      {ledgerAccount ? (
        <div className="ledger-account fade-in">
          <span className="name text-truncate">
            {ledgerAccount.description}
          </span>
          {ledgerAccount.canManage && (
            <div className="edit pointer" onClick={openAddLedgerAccountModal}>
              <Edit color="#164D9D" size={15} />
            </div>
          )}

          {ledgerAccount.canManage && (
            <div className="delete pointer" onClick={deleteAction}>
              <Trash2 color="#DA1E28" size={15} />
            </div>
          )}
        </div>
      ) : (
        <div className="ledger-account fade-in loading">
          <div className="loader">
            <Loading small={true} withOverlay={false} hidden={true} />
          </div>
        </div>
      )}
    </div>
  );
};

export default LedgerAccountCard;
