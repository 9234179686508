// import types
import ChartOfAccountsActionTypes from '../types/chart-of-accounts.types';
import { addItems, removeItem } from '../utils/common.util';
import {
  removeCategoryAccounts,
  removeCategory,
} from '../utils/academic-year.util';

// set initial state
const INITIAL_STATE = {
  groups: [],
  ledgerCategories: [],
  ledgerAccounts: [],
};

// create reducer with intial state if not state is received
const chartOfAccountsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ChartOfAccountsActionTypes.SET_GROUPS:
      return {
        // spread in the state
        ...state,
        groups: action.payload,
      };
    case ChartOfAccountsActionTypes.SET_LEDGER_CATEGORIES:
      return {
        ...state,
        // ledgerCategories: action.payload
        ledgerCategories: addItems(state.ledgerCategories, action.payload),
      };
    case ChartOfAccountsActionTypes.SET_LEDGER_ACCOUNTS:
      return {
        ...state,
        // ledgerAccounts: action.payload
        ledgerAccounts: addItems(state.ledgerAccounts, action.payload),
      };
    case ChartOfAccountsActionTypes.REMOVE_LEDGER_CATEGORY:
      return {
        ...state,
        ledgerAccounts: removeCategoryAccounts(
          state.ledgerAccounts,
          action.payload,
        ),
        ledgerCategories: removeCategory(
          state.ledgerCategories,
          action.payload,
        ),
      };
    case ChartOfAccountsActionTypes.REMOVE_LEDGER_ACCOUNT:
      return {
        ...state,
        // ledgerAccounts: action.payload
        ledgerAccounts: removeItem(state.ledgerAccounts, action.payload),
      };
    default:
      // no action matched, just return the state
      return state;
  }
};

// export out chartOfAccountsReducer as default
export default chartOfAccountsReducer;
