import { postData, getData } from './common';

export const getPayments = async (authToken, skip, take, reversed) => {
  let response = await getData(
    `expenditure/list/?skip=${skip}&take=${take}&reversed=${reversed}`,
    authToken,
  );
  if (response.success) {
    return {
      success: true,
      payments: response.data,
    };
  }

  return response;
};

export const newPayment = async (authToken, data) => {
  let response = await postData('expenditure/new', data, authToken);
  if (response.success) {
    return {
      success: true,
      payment: response.data.expenditure,
    };
  }

  return response;
};

export const reversePayment = async (authToken, data) => {
  let response = await postData('expenditure/reverse', data, authToken);
  if (response.success) {
    return {
      success: true,
      payment: response.data.expenditure,
    };
  }

  return response;
};

export const setPaymentStatus = async (authToken, data) => {
  let response = await postData('expenditure/set-status', data, authToken);
  if (response.success) {
    return {
      success: true,
      payment: response.data.expenditure,
    };
  }

  return response;
};

// function to delete a payment
export const deletePayment = async (authToken, data) => {
  return await postData('expenditure/delete', data, authToken);
};
