import React from 'react';
import { setGlobal } from 'reactn';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import ScrollToTop from './components/scroll-to-top/scroll-to-top.component';

// import store and persistor
import { store, persistor } from './redux/store';
import initReactnPersist from 'reactn-persist';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

// to keep track of global variables
setGlobal({
  academic: {
    grading: {
      list: [],
      active: null,
      activeStudentReport: null,
    },
    subject: {
      list: [],
      active: null,
    },
  },

  birthDays: {
    student: {
      checking: false,
      dateChecked: null,
      data: null,
    },
  },
  dashboardMetrics: {
    totalRevenuesForTheMonth: 0,
    totalExpensesForTheMonth: 0,
    studentsCount: 0,
    totalStudentsBalanceDue: 0,
  },
});

initReactnPersist({
  key: '@epobs-fms',
  storage: localStorage,
  whitelist: ['birthDays', 'dashboardMetrics'],
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ScrollToTop>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </ScrollToTop>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
