import React, { useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Upload, Printer } from 'react-feather';
import fileDownload from 'js-file-download';
import {
  DatePicker,
  DatePickerInput,
  Select,
  SelectItem,
} from 'carbon-components-react';

import MainHeader from '../../../components/main-header/main-header.component';
import Mainmenu from '../../../components/menu/main-menu/main-menu.component';
import CustomButton from '../../../components/custom-button/custom-btn.component';
import Footer from '../../../components/footer/footer.component';

import MainLoaderModal from '../../../components/modal/main-loader/main-loader.component';
import TransactionReportModal from '../../../components/modal/transaction-report-printout-modal/transaction-report.modal';

import { selectCurrentUser } from '../../../redux/selectors/user.selectors';
import { selectTransactionsReport } from '../../../redux/analytics/analytics.selectors';
import {
  selectLedgerAccounts,
  selectNonExpenseAndRevenueLedgerAccounts,
} from '../../../redux/ledger/ledger.selectors';

import { formatCurrency, formatDate } from '../../../utils/common.util';

import './transactions-report.styles.scss';
import { fetchService } from '../../../api/common';

const TransactionsReportPage = ({
  transactionsReport: { fetching, fetchError, data },
  fetchTransactionsReport,
  ledgerAccounts,
  nonExpenseAndRevenueLedgerAccounts,
  fetchLedgerAccounts,
  currentUser: { authToken, school },
}) => {
  const [openPrintOutModal, setOpenPrintOutModal] = useState(false);
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [ledger, setLedger] = useState('-1');
  const [openLoaderModal, setOpenLoaderModal] = useState(fetching);

  const [openExportLoaderModal, setOpenExportLoaderModal] = useState(false);
  const [exportLoaderModalErrorMessage, setExportLoaderModalErrorMessage] =
    useState(null);

  const exportReport = async () => {
    setExportLoaderModalErrorMessage(null);
    setOpenExportLoaderModal(true);

    const response = await fetchService(
      `analytics/transactions-report/export?dateTo=${dateTo}&dateFrom=${dateFrom}&ledgerId=${ledger}`,
      authToken,
    );

    if (response.success) {
      setOpenExportLoaderModal(false);
      fileDownload(
        response.data.data,
        `journal-voucher-report-${new Date()}.xlsx`,
      );

      return;
    }

    setExportLoaderModalErrorMessage(response.message);
  };

  if (!ledgerAccounts.fetching && ledgerAccounts.data.length === 0) {
    fetchLedgerAccounts(authToken);
  }

  let ledgerAccount = nonExpenseAndRevenueLedgerAccounts.find(
    (i) => i.id == ledger,
  );

  return (
    <div>
      <MainHeader />

      <MainLoaderModal
        openModal={openExportLoaderModal}
        description="Exporting Transactions Report"
        errorMessage={exportLoaderModalErrorMessage}
        tryAgainAction={exportReport}
        cancelAction={() => setOpenExportLoaderModal(false)}
      />

      <MainLoaderModal
        openModal={
          (openLoaderModal && fetching) || (openLoaderModal && fetchError)
        }
        loading={fetching}
        description={'Generating report...'}
        errorMessage={fetchError}
        tryAgainAction={() => {
          setOpenLoaderModal(true);
          fetchTransactionsReport(dateFrom, dateTo, ledger, authToken);
        }}
        cancelAction={() => setOpenLoaderModal(false)}
      />

      <TransactionReportModal
        openModal={openPrintOutModal}
        data={data}
        dateFrom={dateFrom}
        dateTo={dateTo}
        school={school}
        ledgerAccount={ledgerAccount}
        closeAction={() => setOpenPrintOutModal(false)}
      />

      <span className="right">
        <Mainmenu />
      </span>

      <div className="transactions-report-page page-container">
        <div>
          <p>
            <span className="text-12 font-bold text-grey">You are here</span>
            &nbsp;&nbsp;
            <Link to="/analytics" className="text-12 text-dark">
              Analytics
            </Link>
            &nbsp;<span className="text-12">&gt;</span>&nbsp;
            <span className="text-12 text-primary font-bold">
              Journal Voucher Report
            </span>
          </p>
        </div>

        <hr className="grey-hr mt-20" />

        <div className="h-24 uppercase mt-10">
          Journal Voucher Report{' '}
          {ledgerAccount && `on ${ledgerAccount.description}`} &nbsp;:&nbsp;
          {dateFrom &&
            dateTo &&
            `${formatDate(new Date(dateFrom))} - ${formatDate(
              new Date(dateTo),
            )}`}
        </div>

        <div className="mt-20">
          <div className="bx--row mt-20">
            <div className="bx--col-md-2">
              <DatePicker
                dateFormat="Y-m-d"
                datePickerType="single"
                id="start-date-picker"
                onClose={(value) => {
                  if (value.length > 0) {
                    setDateFrom(
                      [
                        value[0].getFullYear(),
                        ('0' + (value[0].getMonth() + 1)).slice(-2),
                        ('0' + value[0].getDate()).slice(-2),
                      ].join('-'),
                    );
                  }
                }}>
                <DatePickerInput
                  className="start-date"
                  id="start-date"
                  name="startDate"
                  labelText="Start date"
                  value={dateFrom}
                  pattern="d{1,2}/d{4}"
                  placeholder="YYYY-MM-DD"
                  type="text"
                />
              </DatePicker>
            </div>
            <div className="bx--col-md-2 npl">
              <DatePicker
                dateFormat="Y-m-d"
                datePickerType="single"
                id="end-date-picker"
                onClose={(value) => {
                  if (value.length > 0) {
                    setDateTo(
                      [
                        value[0].getFullYear(),
                        ('0' + (value[0].getMonth() + 1)).slice(-2),
                        ('0' + value[0].getDate()).slice(-2),
                      ].join('-'),
                    );
                  }
                }}>
                <DatePickerInput
                  className="end-date"
                  id="end-date"
                  name="endDate"
                  labelText="End date"
                  value={dateTo}
                  pattern="d{1,2}/d{4}"
                  placeholder="YYYY-MM-DD"
                  type="text"
                />
              </DatePicker>
            </div>

            <div className="bx--col-md-2 npr">
              <Select
                labelText="Ledger"
                onChange={(e) => setLedger(e.target.value)}>
                <SelectItem disabled selected value="" text="Ledger" />
                <SelectItem value="-1" text="All" />
                {nonExpenseAndRevenueLedgerAccounts.map((account) => (
                  <SelectItem value={account.id} text={account.description} />
                ))}
              </Select>
            </div>

            <div className="bx--col-md-2">
              <CustomButton
                classes="f-width primary mt-25"
                disabled={dateFrom.length === 0 || dateTo.length === 0}
                onClick={() => {
                  setOpenLoaderModal(true);
                  fetchTransactionsReport(
                    dateFrom,
                    dateTo,
                    parseInt(ledger),
                    authToken,
                  );
                }}>
                RUN REPORT
              </CustomButton>
            </div>
          </div>
        </div>

        {data && (
          <div className="mt-20">
            <table className="bx--data-table table-white">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Credit Account</th>
                  <th>Debit Account</th>
                  <th>Description</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {data.map((transaction) => (
                  <tr>
                    <td>{formatDate(transaction.dateRecorded)}</td>
                    <td>{transaction.creditAccount.description}</td>
                    <td>{transaction.debitAccount.description}</td>
                    <td>{transaction.description}</td>
                    <td className="font-bold">
                      {formatCurrency(transaction.amount)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="bx--row mt-35">
              <div className="bx--col-lg-2">
                <CustomButton
                  classes="secondary f-width font-bold"
                  onClick={exportReport}>
                  <Upload size="11" strokeWidth="5px" />
                  &nbsp; EXPORT TO EXCEL
                </CustomButton>
              </div>
              <div className="bx--col-lg-2">
                <CustomButton
                  classes="primary f-width font-bold"
                  onClick={() => setOpenPrintOutModal(true)}>
                  <Printer size="11" strokeWidth="5px" /> &nbsp; PRINT
                </CustomButton>
              </div>
              <div className="bx--col-lg-8"></div>
            </div>
          </div>
        )}
        <Footer fixed={false} showLinks={false} />
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  transactionsReport: selectTransactionsReport,
  ledgerAccounts: selectLedgerAccounts,
  nonExpenseAndRevenueLedgerAccounts: selectNonExpenseAndRevenueLedgerAccounts,
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTransactionsReport: (dateFrom, dateTo, ledger, authToken) =>
    dispatch({
      type: 'FETCH_TRANSACTIONS_REPORT_REQUESTED',
      payload: { dateFrom, dateTo, authToken, ledger },
    }),
  fetchLedgerAccounts: (authToken) =>
    dispatch({
      type: 'FETCH_LEDGER_ACCOUNTS_REQUESTED',
      payload: { authToken },
    }),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TransactionsReportPage),
);
