import React from 'react';
import { Link } from 'react-router-dom';
import './quick-action-card.styles.scss';

const QuickActionCard = ({
  children,
  description,
  iconBackgroundColor,
  leftActionText = 'ADD',
  rightActionText = 'VIEW',
  addColor = false,
  hideActions = false,
  hideAction2 = false,
  transform = false,
  actionEvent1 /* use ev.preventDefault() */,
  actionEvent2,
}) => {
  return (
    <div className={`quick-action-card ${addColor}`}>
      <div className="heading">
        <div className="icon">{children}</div>
        <h3 className="description text-truncate">{description}</h3>
      </div>

      <div className={`actions`} hidden={hideActions}>
        <Link
          to=""
          className="link"
          hidden={hideActions}
          onClick={(e) => {
            e.preventDefault();
            actionEvent1();
          }}>
          {leftActionText}
        </Link>

        <div
          hidden={hideAction2 ? hideAction2 : hideActions}
          className="line"></div>

        <Link
          hidden={hideAction2 ? hideAction2 : hideActions}
          to=""
          className="link"
          onClick={(e) => {
            e.preventDefault();
            actionEvent2();
          }}>
          {rightActionText}
        </Link>
      </div>
    </div>
  );
};

export default QuickActionCard;
