import React from 'react';
import { Plus } from 'react-feather';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { Loading, Select, SelectItem } from 'carbon-components-react';

import MainHeader from '../../components/main-header/main-header.component';

import AddLedgerAccountModal from '../../components/modal/add-ledger-account/add-ledger-account.component';
import AddLedgerCategoryModal from '../../components/modal/add-ledger-category/add-ledger-category.component';
import QuickActionCard from '../../components/card/quick-action-card/quick-action-card.component';
import LedgerCard from '../../components/card/ledger-card/ledger-card.component';
import Footer from '../../components/footer/footer.component';
import ConfirmDeleteModal from '../../components/modal/confirm-delete/confirm-delete.component';

import { Search } from 'carbon-components-react';

// import our redux stuffs
import {
  selectCategories,
  selectLedgerAccounts,
  selectGroups,
} from '../../redux/selectors/chart-of-accounts.selector';

import {
  setLedgerCategories,
  setLedgerAccounts,
  setGroups,
  removeLedgerCategory,
  removeLedgerAccount,
} from '../../redux/actions/chart-of-accounts.actions';

import { selectCurrentUser } from '../../redux/selectors/user.selectors';

//import svg icon
import { ReactComponent as Calculator } from '../../assets/images/card-icons/calculator.svg';

import {
  getLedgerCategories,
  getLedgerAccounts,
  getGroups,
  deleteLedgerCategory,
} from '../../api/chart-of-accounts.service';

import './ledger.styles.scss';
import MainMenu from '../../components/menu/main-menu/main-menu.component';

const plusIcon = <Plus size="25" strokeWidth="2px" />;

class LedgerPage extends React.Component {
  constructor(props) {
    super(props);

    // set state
    this.state = {
      query: '',
      groupId: '',
      authToken: props.currentUser.authToken,
      loading: props.ledgerCategories.length === 0,
      errorMessage: null,
      openLedgerAccountModal: false,
      openLedgerCategoryModal: false,
      openConfirmDeleteModal: false,
      ledgerCategories: props.ledgerCategories,
      ledgerAccounts: props.ledgerAccounts,
      groups: props.groups,
      selectedLedgerCategory: null,
      selectedLedgerAccount: null,
      deleteDescription:
        'Please confirm Category deletion. This action cannot be reversed.',
      deleteAction: () => {},
      deleteSucessAction: () => {},
    };

    // listen for route changes
    /* this.props.history.listen((location, action) => {
      if (location.pathname !== "/chart-of-accounts") {
        // destructure ledger categories
        const { ledgerCategories } = this.state;

        // save the first 50 items of ledger categories to redux
        // we are also assuming, a school can not have more than
        // 50 ledger categories, in fact, this restriction will
        // be enforced at server side
        this.props.setLedgerCategories(ledgerCategories.slice(0, 50));
      }
    });*/
  }

  componentDidMount() {
    // fetch groups only if length is 0
    if (this.state.groups.length === 0) {
      this.fetchGroups();
    }

    if (this.state.ledgerCategories.length === 0) {
      this.fetchLedgerCategories().then(() => {
        // fetch ledger accounts only if length is 0
        if (this.state.ledgerAccounts.length === 0) {
          this.fetchLedgerAccounts();
        }
      });
    } else {
      // fetch ledger accounts only if length is 0
      if (this.state.ledgerAccounts.length === 0) {
        this.fetchLedgerAccounts();
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.groups.length !== prevProps.groups.length) {
      this.setState({ groups: this.props.groups });
    }

    if (
      this.props.ledgerCategories.length !== prevProps.ledgerCategories.length
    ) {
      this.setState({ ledgerCategories: this.props.ledgerCategories });
    } else {
      // compare values
      this.props.ledgerCategories.forEach((newCategory) => {
        const oldCategory = prevProps.ledgerCategories.find(
          (item) => item.id === newCategory.id,
        );

        if (
          newCategory.description !== oldCategory.description ||
          newCategory.groupId !== oldCategory.groupId
        ) {
          // update state and return
          this.setState({ ledgerCategories: this.props.ledgerCategories });
        }
      });
    }
  }

  fetchGroups = async () => {
    // destructure needed values from state
    const { authToken } = this.state;

    // initiate request
    const response = await getGroups(authToken);

    if (response.success) {
      // save to redux
      this.props.setGroups(response.groups);
    }
  };

  fetchLedgerCategories = async () => {
    // show loading if only is not already showing
    if (!this.state.loading)
      this.setState({ loading: true, errorMessage: null });

    // destructure needed values from state
    const { authToken, query, ledgerCategories } = this.state;

    // set skip and sate values
    let take = 100;
    let skip = ledgerCategories.length;

    // initiate request
    const response = await getLedgerCategories(
      authToken,
      skip,
      take,
      query,
      null,
    );

    // request failed, show error message
    if (!response.success) {
      this.setState({ loading: false, errorMessage: response.message });

      return;
    }

    // successful
    this.setState({
      loading: false,
    });

    // save to redux
    this.props.setLedgerCategories(response.ledgerCategories);
  };

  // let's fetch all ledger accounts from here
  // and save it to redux, that way,
  // we won't have to be making a request to the server
  // for each ledger category
  fetchLedgerAccounts = async () => {
    // get auth token from props
    const authToken = this.state.authToken;

    // set skip and take values
    let take = 500;
    let skip = 0;

    // make request
    const response = await getLedgerAccounts(authToken, skip, take);

    // request failed
    if (!response.success) {
      // this.setState({ errorMessage: response.message });

      return;
    }

    // successful, save to redux
    this.props.setLedgerAccounts(response.ledgerAccounts);
  };

  render() {
    let ledgerCategories = this.state.ledgerCategories;

    // filter by group id
    if (this.state.groupId.length > 0) {
      ledgerCategories = this.state.ledgerCategories.filter(
        (i) => i.groupId === parseInt(this.state.groupId),
      );
    }

    // filter by query
    if (this.state.query.length > 0) {
      ledgerCategories = ledgerCategories.filter((item) =>
        item.description
          .toLowerCase()
          .includes(this.state.query.toLocaleLowerCase()),
      );
    }

    return (
      <div>
        <MainHeader />

        <AddLedgerAccountModal
          openModal={this.state.openLedgerAccountModal}
          selectedLedgerCategory={this.state.selectedLedgerCategory}
          selectedLedgerAccount={this.state.selectedLedgerAccount}
          cancelAction={() => this.setState({ openLedgerAccountModal: false })}
        />

        <AddLedgerCategoryModal
          openModal={this.state.openLedgerCategoryModal}
          ledgerCategory={this.state.selectedLedgerCategory}
          cancelAction={() => this.setState({ openLedgerCategoryModal: false })}
        />

        <ConfirmDeleteModal
          openModal={this.state.openConfirmDeleteModal}
          description={this.state.deleteDescription}
          deleteAction={this.state.deleteAction}
          deleteSuccessAction={this.state.deleteSucessAction}
          cancelAction={() => this.setState({ openConfirmDeleteModal: false })}
        />

        <div className="ledger page-container">
          <div>
            <span className="h-18">Quick Actions</span>

            <span className="right">
              <MainMenu />
            </span>
          </div>

          <div className="bx--row mt-30">
            <div className="bx--col-md-2">
              <div
                className="card-add-ledger-category pointer"
                ledgerCategory={this.state.selectedLedgerCategory}
                onClick={() =>
                  this.setState({
                    openLedgerCategoryModal: true,
                    selectedLedgerCategory: null,
                  })
                }>
                <div className="plus-icon">{plusIcon}</div>
                <div className="text-label">ADD NEW CATEGORY</div>
              </div>
            </div>

            <div className="bx--col-md-2">
              <QuickActionCard
                description="Ledgers"
                actionEvent1={() => {
                  this.setState({
                    openLedgerAccountModal: true,
                    selectedLedgerCategory: null,
                    selectedLedgerAccount: null,
                  });
                }}
                hideAction2={true}>
                <Calculator />
              </QuickActionCard>
            </div>
          </div>

          <hr className="grey-hr mt-20" />

          <h3 className="h-24 mt-30">Categories & Ledgers</h3>
          <div className="mt-10">
            <div className="bx--row">
              <div className="bx--col-md-2">
                <Select
                  id="category-select"
                  className="f-width"
                  name="groupId"
                  hideLabel={true}
                  onChange={(e) => this.setState({ groupId: e.target.value })}>
                  <SelectItem
                    disabled
                    selected={true}
                    text="Filter by Group"
                    value=""
                  />
                  <SelectItem selected={false} text="All" value="" />
                  {this.state.groups.map((group) => (
                    <SelectItem
                      key={group.id}
                      text={group.description}
                      value={group.id}
                    />
                  ))}
                </Select>
              </div>
              <div className="bx--col-md-6">
                <Search
                  id="search"
                  name="search"
                  labelText=""
                  value={this.state.query}
                  onChange={(event) =>
                    this.setState({ query: event.target.value })
                  }
                  placeHolderText="Search by name of category"
                />
              </div>
            </div>
          </div>

          <div className="mt-20" hidden={!this.state.loading}>
            <Loading
              small={true}
              withOverlay={false}
              description="Loading..."
            />
          </div>

          <div className="mt-30" hidden={this.state.errorMessage == null}>
            <span className="mt-10 text-danger">
              {this.state.errorMessage}.
            </span>
            &nbsp;
            <span
              className="text-primary pointer font-bold"
              onClick={() => this.fetchLedgerCategories()}>
              TRY AGAIN
            </span>
          </div>

          <p
            className="text-14 mt-30 text-grey"
            hidden={
              ledgerCategories.length > 0 ||
              this.state.loading ||
              this.state.errorMessage !== null
            }>
            No category or ledgers added, please&nbsp;
            <span
              className="text-primary font-bold pointer"
              onClick={() => this.setState({ openLedgerCategoryModal: true })}>
              add a new one
            </span>
          </p>
          <div className="bx--row mb-40 mt-30">
            {ledgerCategories.map((item) => (
              <div key={item.id} className="bx--col-lg-4">
                <LedgerCard
                  ledgerCategory={item}
                  openAddLedgerAccountModal={(selectedLedgerAccount) =>
                    this.setState({
                      openLedgerAccountModal: true,
                      selectedLedgerCategory: item,
                      selectedLedgerAccount: selectedLedgerAccount,
                    })
                  }
                  editAction={() =>
                    this.setState({
                      openLedgerCategoryModal: true,
                      selectedLedgerCategory: item,
                    })
                  }
                  deleteAction={() =>
                    this.setState({
                      openConfirmDeleteModal: true,
                      deleteAction: async () => {
                        return await deleteLedgerCategory(
                          this.props.currentUser.authToken,
                          {
                            id: item.id,
                            description: item.description,
                            groupDescription: item.groupDescription,
                            groupFinancialStatement:
                              item.groupFinancialStatement,
                          },
                        );
                      },
                      deleteSucessAction: () => {
                        this.props.removeLedgerCategory(item.id);
                      },
                      deleteDescription: `Confirm deletion of ${item.description} Category. This action will also delete all of it's Ledger's and cannot be reversed`,
                    })
                  }
                />
              </div>
            ))}
          </div>

          <Footer fixed={false} showLinks={false} />
        </div>
      </div>
    );
  }
}

// this will provide us with the state values we need
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  groups: selectGroups,
  ledgerCategories: selectCategories,
  ledgerAccounts: selectLedgerAccounts,
});

// this allows us to map our redux actions to our component
const mapDispatchToProps = (dispatch) => ({
  setGroups: (groups) => dispatch(setGroups(groups)),

  setLedgerCategories: (ledgerCategories) =>
    dispatch(setLedgerCategories(ledgerCategories)),

  setLedgerAccounts: (ledgerAccounts) =>
    dispatch(setLedgerAccounts(ledgerAccounts)),

  removeLedgerCategory: (categoryId) =>
    dispatch(removeLedgerCategory(categoryId)),

  removeLedgerAccount: (accountId) => dispatch(removeLedgerAccount(accountId)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LedgerPage),
);
