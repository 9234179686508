// using reselect
import { createSelector } from 'reselect';

// create select revenue function
const selectRevenue = (state) => state.revenue;

export const selectPayments = createSelector(
  [selectRevenue],
  (revenue) => revenue.payments,
);

export const selectReversedPayments = createSelector(
  [selectRevenue],
  (revenue) => revenue.reversedPayments,
);

export const selectRevisedPayments = createSelector(
  [selectRevenue],
  (revenue) => revenue.revisedPayments,
);
