import React, { useState } from 'react';
import { MoreVertical } from 'react-feather';
import { Tag } from 'carbon-components-react';

import CardMenu, {
  CardMenuItem,
} from '../../menu/card-menu/card-menu.component';

import './academic-year-card.styles.scss';

const AcademicYearCard = (props) => {
  const [showCardMenu, setShowCardMenu] = useState(false);

  const {
    children,
    academicYear: {
      description,
      uniqueId,
      startDate,
      endDate,
      ended,
      canEdit,
      canDelete,
      status,
    },
    editAction = null,
    deleteAction = null,
    btnAction = null,
  } = props;

  let parseStartDate = new Date(startDate);
  let parseEndDate = new Date(endDate);
  let active = parseStartDate <= new Date() && parseEndDate > new Date();

  return (
    <div className={`academic-year-card bg-white`} onClick={btnAction}>
      <div>
        <div className="top-content">
          <div className="icon">
            {children}
            <MoreVertical
              className="pointer"
              color="#7D819D"
              onClick={(event) => {
                event.stopPropagation();
                setShowCardMenu(showCardMenu ? false : true);
              }}
            />

            {showCardMenu ? (
              <CardMenu>
                <CardMenuItem
                  title="EDIT"
                  color="primary"
                  action={(event) => {
                    event.stopPropagation();
                    editAction();
                    setShowCardMenu(showCardMenu ? false : true);
                  }}
                />

                {canDelete ? (
                  <CardMenuItem
                    title="DELETE"
                    color="danger"
                    separator={false}
                    action={(event) => {
                      event.stopPropagation();
                      deleteAction();
                      setShowCardMenu(showCardMenu ? false : true);
                    }}
                  />
                ) : null}
              </CardMenu>
            ) : null}
          </div>
          <h3 className="title">
            <span className="text-truncate">{description}</span>
            <Tag
              className={`status-tag ${active} ${status} fade-in`}
              type="red">
              {ended && status !== 'CLOSED'
                ? 'ENDED'
                : active && status !== 'Closed'
                  ? 'ACTIVE'
                  : status === 'Closed'
                    ? 'CLOSED'
                    : 'PENDING'}
            </Tag>
          </h3>
          <p className="subtitle text-truncate">{uniqueId}</p>
        </div>

        <div className="middle-content">
          <hr className="line" />

          <p className="text-label">Start date</p>
          <h4 className="text-value">
            {parseStartDate.toDateString().replace(/^\S+\s/, '')}
          </h4>

          <p className="text-label">End date</p>
          <h4 className="text-value">
            {parseEndDate.toDateString().replace(/^\S+\s/, '')}
          </h4>
        </div>
      </div>
    </div>
  );
};

export default AcademicYearCard;
