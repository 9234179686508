import React from 'react';
import { Modal, Loading } from 'carbon-components-react';

import './main-loader.styles.scss';

const MainLoaderModal = ({
  openModal,
  description = 'Processing...',
  errorMessage = null,
  cancelAction,
  tryAgainAction,
}) => {
  return (
    <Modal
      id="main-loader-modal"
      className="main-loader-modal"
      hasScrollingContent={false}
      passiveModal={true}
      open={openModal}
      modalHeading=""
      primaryButtonDisabled={false}>
      <div>
        <div className="loader center" hidden={errorMessage}>
          <Loading small={true} withOverlay={false} description="Loading..." />
        </div>
        <p hidden={errorMessage} className="text-center text-14 text-grey">
          {description}
        </p>
        <p className="text-center text-danger text-14" hidden={!errorMessage}>
          {errorMessage}
        </p>
        <div className="mt-20" hidden={!errorMessage}>
          <span className="text-14 pointer text-danger" onClick={cancelAction}>
            CANCEL
          </span>
          <span
            className="text-14 text-primary pointer right font-bold"
            onClick={tryAgainAction}>
            TRY AGAIN
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default MainLoaderModal;
