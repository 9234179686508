import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

// import local storage from redux-persist
import storage from 'redux-persist/lib/storage';

// import all reducers
import userReducer from '../user/user.reducer';
import chartOfAccountsReducer from './chart-of-accounts.reducer';
import academicYearReducer from './academic-year.reducer';
import classReducer from './class.reducer';
import studentReducer from './student.reducer';
import notificationReducer from './notification.reducer';
import billingReducer from './billing.reducer';
import employeeReducer from './employee.reducer';
import revenueReducer from './revenue.reducer';
import supplierReducer from './supplier.reducer';
import expenditureReducer from './expense.reducer';
import purchaseOrderReducer from './purchase-order.reducer';
import analyticsReducer from '../analytics/analytics.reducer';
import ledgerReducer from '../ledger/ledger.reducer';
import billingReducerNew from '../billing/billing.reducer';
import expenseReducerNew from '../expense/expense.reducer';
import revenueReducerNew from '../revenue/revenue.reducer';
import academicYearReducerNew from '../academic-year/academic-year.reducer';
import subjectReducer from './subject.reducer';

// configuration for redux persists
const persistConfig = {
  key: 'root', // key
  storage, // storage
  // things we really want to persist, for now just the user
  whitelist: ['user'],
};

// This combines all the reducers into one giant one for user by redux
const appReducer = combineReducers({
  user: userReducer,
  notification: notificationReducer,
  chartOfAccounts: chartOfAccountsReducer,
  academicYear: academicYearReducer,
  class_: classReducer,
  student: studentReducer,
  billing: billingReducer,
  employee: employeeReducer,
  supplier: supplierReducer,
  revenue: revenueReducer,
  expenditure: expenditureReducer,
  purchaseOrder: purchaseOrderReducer,
  ledger: ledgerReducer,
  analytics: analyticsReducer,
  billingNew: billingReducerNew,
  expenseNew: expenseReducerNew,
  revenueNew: revenueReducerNew,
  academicYearNew: academicYearReducerNew,
  subject: subjectReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_USER') {
    storage.removeItem('persist:root');
    state = undefined;
  }

  return appReducer(state, action);
};

// export out our rootReducer with redux-persist
export default persistReducer(persistConfig, rootReducer);
