import React from 'react';
import {
  TextInput,
  Form,
  Modal,
  Loading,
  Select,
  SelectItem,
  TextArea,
  DatePickerInput,
  DatePicker,
} from 'carbon-components-react';
import CustomButton from '../../custom-button/custom-btn.component';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

// import our redux stuffs
import { selectCurrentUser } from '../../../redux/selectors/user.selectors';

// import api stuffs

import './receive-other-payment.styles.scss';
import {
  selectCurrentAssetsLedgers,
  selectRevenueLedgerAccounts,
} from '../../../redux/selectors/chart-of-accounts.selector';
import {
  setPayments,
  removePayment,
} from '../../../redux/actions/revenue.actions';
import { newPayment, revisePayment } from '../../../api/revenue.service';
import { setLedgerAccounts } from '../../../redux/actions/chart-of-accounts.actions';
import { getLedgerAccounts } from '../../../api/chart-of-accounts.service';

//same component for revise other payment -- isRevise set to true
class ReceiveOtherPaymentModal extends React.Component {
  constructor(props) {
    super(props);

    // set state
    this.state = {
      id: 0,
      loading: false,
      btnText: 'SUBMIT PAYMENT',
      heading: 'Receive Other Payment',
      errorMessage: null,
      openModal: props.openModal,
      paymentDate: '',
      creditAccount: '',
      debitAccount: '',
      amount: '',
      paymentMode: '',
      chequeNumber: '',
      receivedFrom: '',
      description: '',
      refreshPage: false,
    };
  }

  componentDidMount() {
    // fetch ledger accounts
    getLedgerAccounts(this.props.currentUser.authToken, 0, 2000).then(
      (response) => {
        if (response.success) {
          this.props.setLedgerAccounts(response.ledgerAccounts);
        }
      },
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentAssets.length !== prevProps.currentAssets.length) {
      this.setState({ refreshPage: true });
    }

    if (this.props.paymentToRevise !== prevProps.paymentToRevise) {
      if (this.props.paymentToRevise) {
        this.setState({
          id: this.props.paymentToRevise.id,
          btnText: 'REVISE PAYMENT',
          heading: `Revise Other Payment`,
          receivedFrom: this.props.paymentToRevise.receivedFrom,
          paymentMode: this.props.paymentToRevise.paymentMode,
          chequeNumber: this.props.paymentToRevise.chequeNumber,
          description: this.props.paymentToRevise.description,
          paymentDate: this.props.paymentToRevise.paymentDate,
          debitAccount: this.props.paymentToRevise.debitAccount.id,
          amount: this.props.paymentToRevise.revenues[0].amountPaid,
          creditAccount:
            this.props.paymentToRevise.revenues[0].ledgerAccount.id,
        });
      } else {
        this.setState({
          id: 0,
          btnText: 'SUBMIT PAYMENT',
          heading: 'Receive Other Payment',
          receivedFrom: '',
          paymentMode: '',
          chequeNumber: '',
          description: '',
          paymentDate: '',
          debitAccount: '',
          creditAccount: '',
          amount: '',
        });
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.openModal !== prevState.openModal) {
      return { openModal: nextProps.openModal };
    } else return null;
  }

  submitPayment = async () => {
    // show loader
    this.setState({ loading: true, errorMessage: null, btnText: null });

    // build data
    const data = {
      paymentDate: this.state.paymentDate,
      paymentMode: this.state.paymentMode,
      chequeNumber: this.state.chequeNumber,
      description: this.state.description,
      debitAccount: this.props.currentAssets.find(
        (asset) => asset.id == this.state.debitAccount,
      ),
      receivedFrom: this.state.receivedFrom,
      revenues: [
        {
          ledgerAccount: this.props.revenueLedgers.find(
            (ledger) => ledger.id == this.state.creditAccount,
          ),
          //amountOwed: parseFloat(this.state.amount),
          amountPaid: parseFloat(this.state.amount),
        },
      ],
    };

    // make payment
    const response = await newPayment(this.props.currentUser.authToken, data);

    if (!response.success) {
      this.setState({
        loading: false,
        errorMessage: response.message,
        btnText: 'SUBMIT PAYMENT',
      });

      return;
    }

    // all good

    // save payment to redux
    this.props.setPayments([response.payment]);

    this.setState({
      loading: false,
      btnText: 'SUBMIT PAYMENT',
      receivedFrom: '',
      paymentDate: '',
      paymentMode: '',
      chequeNumber: '',
      description: '',
      debitAccount: '',
      creditAccount: '',
      amount: '',
    });

    // and show receipt
    this.props.showReceipt(response.payment);
  };

  revisePayment = async () => {
    // show loader
    this.setState({ loading: true, errorMessage: null, btnText: null });

    // build data
    const data = {
      id: this.state.id,
      paymentMode: this.state.paymentMode,
      chequeNumber: this.state.chequeNumber,
      description: this.state.description,
      debitAccount: this.props.currentAssets.find(
        (asset) => asset.id == this.state.debitAccount,
      ),
      receivedFrom: this.state.receivedFrom,
      revenues: [
        {
          ledgerAccount: this.props.revenueLedgers.find(
            (ledger) => ledger.id == this.state.creditAccount,
          ),
          //amountOwed: parseFloat(this.state.amount),
          amountPaid: parseFloat(this.state.amount),
        },
      ],
    };

    // make payment
    const response = await revisePayment(
      this.props.currentUser.authToken,
      data,
    );

    if (!response.success) {
      this.setState({
        loading: false,
        errorMessage: response.message,
        btnText: 'REVISE PAYMENT',
      });

      return;
    }

    // all good

    // save new payment to redux
    this.props.setPayments([response.payment]);

    // and remove revised one, for now
    this.props.removePayment(this.state.id);

    this.setState({
      id: 0,
      receivedFrom: '',
      paymentMode: '',
      paymentDate: '',
      chequeNumber: '',
      description: '',
      debitAccount: '',
      creditAccount: '',
      amount: '',
    });

    // and show receipt
    this.props.showReceipt(response.payment);
  };

  render() {
    return (
      <Modal
        id="receive-other-payment-modal"
        className="receive-other-payment-modal"
        hasScrollingContent
        passiveModal={true}
        open={this.state.openModal}
        modalHeading={this.state.heading}
        primaryButtonDisabled={false}>
        <div>
          <Form action="post">
            <div className="bx--row">
              <div className="mt-10 bx--col-md-8">
                <DatePicker
                  dateFormat="Y-m-d"
                  datePickerType="single"
                  id="start-date-picker"
                  onClose={(value) => {
                    if (value.length > 0) {
                      this.setState({
                        paymentDate: [
                          value[0].getFullYear(),
                          ('0' + (value[0].getMonth() + 1)).slice(-2),
                          ('0' + value[0].getDate()).slice(-2),
                        ].join('-'),
                      });
                    }
                  }}>
                  <DatePickerInput
                    className="payment-date"
                    id="payment-date"
                    name="paymentDate"
                    value={this.state.paymentDate}
                    onChange={(e) =>
                      this.setState({ paymentDate: e.target.value })
                    }
                    labelText="Payment Date"
                    pattern="d{1,2}/d{4}"
                    placeholder="YYYY-MM-DD"
                  />
                </DatePicker>
              </div>
              <div className="bx--col-md-8 mt-35">
                <Select
                  id="creditAccount"
                  labelText="Credit Account (Source of Income)"
                  onChange={(e) =>
                    this.setState({ creditAccount: e.target.value })
                  }>
                  <SelectItem
                    selected={this.state.creditAccount === ''}
                    disabled
                    text="Select Account to Credit"
                    value=""
                  />
                  {this.props.revenueLedgers
                    .filter(
                      (ledger) =>
                        ledger.categoryDescription !== 'Student Fees' &&
                        ledger.categoryDescription !== 'Student Revenue',
                    )
                    .map((ledger, i) => (
                      <SelectItem
                        key={i}
                        selected={ledger.id == this.state.creditAccount}
                        text={ledger.description}
                        value={ledger.id}
                      />
                    ))}
                </Select>
              </div>
              <div className="bx--col-md-8 mt-35">
                <Select
                  id="debitAccount"
                  labelText="Debit Account (Cash or Bank Account)"
                  onChange={(e) =>
                    this.setState({ debitAccount: e.target.value })
                  }>
                  <SelectItem
                    selected={this.state.debitAccount === ''}
                    disabled
                    text="Select Account to Debit"
                    value=""
                  />
                  {this.props.currentAssets
                    .filter(
                      (currentAsset) =>
                        currentAsset.categoryDescription === 'Bank Accounts' ||
                        currentAsset.categoryDescription === 'Cash Accounts',
                    )
                    .map((currentAsset, i) => (
                      <SelectItem
                        key={i}
                        selected={currentAsset.id == this.state.debitAccount}
                        text={currentAsset.description}
                        value={currentAsset.id}
                      />
                    ))}
                </Select>
              </div>
              <div className="bx--col-md-8 mt-35">
                <TextInput
                  labelText="Amount"
                  type="number"
                  step="0.01"
                  value={this.state.amount}
                  placeholder="Please enter amount"
                  onChange={(e) => this.setState({ amount: e.target.value })}
                />
              </div>
              <div className="bx--col-md-8 mt-35">
                <Select
                  id="payment"
                  labelText="Payment Mode"
                  onChange={(e) =>
                    this.setState({ paymentMode: e.target.value })
                  }>
                  <SelectItem
                    selected={this.state.paymentMode === ''}
                    disabled
                    text="Select payment mode"
                    value=""
                  />
                  <SelectItem
                    selected={this.state.paymentMode === 'Cash'}
                    text="Cash"
                    value="Cash"
                  />
                  <SelectItem
                    selected={this.state.paymentMode === 'Cheque'}
                    text="Cheque"
                    value="Cheque"
                  />
                  <SelectItem
                    selected={this.state.paymentMode === 'Bank_Transfer'}
                    text="Bank Transfer"
                    value="Bank_Transfer"
                  />
                  <SelectItem
                    selected={this.state.paymentMode === 'BankDraft'}
                    text="Bank Draft"
                    value="BankDraft"
                  />
                  <SelectItem
                    selected={this.state.paymentMode === 'MobileMoney'}
                    text="Mobile Money"
                    value="MobileMoney"
                  />
                </Select>
              </div>

              <div
                className="bx--col-md-8 mt-35"
                hidden={this.state.paymentMode !== 'Cheque'}>
                <TextInput
                  id="cheque-number"
                  labelText="Cheque Number"
                  value={this.state.chequeNumber}
                  onChange={(e) =>
                    this.setState({ chequeNumber: e.target.value })
                  }
                  placeholder="Enter Cheque Number"
                />
              </div>

              <div className="bx--col-md-8 mt-35">
                <div className="f-width">
                  <TextInput
                    labelText="Received From"
                    value={this.state.receivedFrom}
                    placeholder="Who are you receiving the payment from ?"
                    onChange={(e) =>
                      this.setState({ receivedFrom: e.target.value })
                    }
                  />
                </div>
              </div>

              <div className="bx--col-md-8 mt-35">
                <div className="f-width">
                  <TextInput
                    labelText="Description"
                    value={this.state.description}
                    placeholder="Add a description"
                    onChange={(e) =>
                      this.setState({ description: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>

            <p
              className={`text-14 mt-10 ${this.state.messageClass}`}
              hidden={this.state.message === null}>
              {this.state.message}
            </p>

            {/**show if receive payment */}
            <div className="bx--row mt-60" hidden={!this.state.isReceive}>
              <div className="bx--col-md-3">
                <CustomButton
                  classes="outline danger no-border ml-10 no-hover font-bold"
                  disabled={this.state.loading}
                  onClick={(e) => this.props.cancelAction()}>
                  CANCEL
                </CustomButton>
              </div>
              <div className="bx--col-md-5">
                <CustomButton
                  classes="primary f-width font-bold"
                  onClick={
                    this.state.id === 0
                      ? this.submitPayment
                      : this.revisePayment
                  }
                  disabled={
                    this.state.loading ||
                    this.state.amount.length === 0 ||
                    this.state.creditAccount.length === 0 ||
                    this.state.debitAccount.length === 0 ||
                    this.state.paymentMode.length === 0 ||
                    this.state.receivedFrom.length === 0
                  }>
                  {this.state.btnText}

                  <div hidden={this.state.btnText != null}>
                    <div className="d-flex-center">
                      <Loading
                        withOverlay={false}
                        small={true}
                        description="Submitting payment..."
                      />
                    </div>
                  </div>
                </CustomButton>
              </div>

              <p
                className={`text-14 mt-10 text-danger`}
                hidden={this.state.errorMessage === null}>
                {this.state.errorMessage}
              </p>
            </div>

            {/**show if revise */}
            {/*<div className="bx--row mt-60" hidden={this.state.isReceive}>
              <div className="bx--col-md-5">
                <CustomButton
                  classes="outline danger no-border ml-10 no-hover font-bold"
                  disabled={this.state.loading}
                  onClick={e => this.props.cancelAction()}
                >
                  REVERSE TRANSACTION
                </CustomButton>
              </div>
              <div className="bx--col-md-3">
                <CustomButton
                  classes="primary f-width font-bold"
                  disabled={this.state.loading}
                >
                  UPDATE
                </CustomButton>
              </div>
                  </div> */}
          </Form>
        </div>
      </Modal>
    );
  }
}

// this will provide us with the state values we need
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  currentAssets: selectCurrentAssetsLedgers,
  revenueLedgers: selectRevenueLedgerAccounts,
});

// this allows us to map our redux actions to our component
const mapDispatchToProps = (dispatch) => ({
  setPayments: (payments) => dispatch(setPayments(payments)),
  removePayment: (paymentId) => dispatch(removePayment(paymentId)),
  setLedgerAccounts: (ledgers) => dispatch(setLedgerAccounts(ledgers)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReceiveOtherPaymentModal);
