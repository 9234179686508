import React from 'react';
import { Form, Modal } from 'carbon-components-react';

import './import-data.styles.scss';

const ImportEmployeesModal = ({
  openModal,
  heading,
  description,
  downloadSampleUrl,
  cancelAction,
  importAction,
}) => {
  return (
    <Modal
      id="import-data"
      className="import-data"
      hasScrollingContent
      passiveModal={true}
      open={openModal}
      modalHeading={heading}
      primaryButtonDisabled={false}>
      <div>
        <Form action="post">
          <div className="text-14 text-grey">
            {description}Your CSV File Format must follow that of the sample
            template. We strongly recommended you request for the sample
            template from EPOBS if you do not have it already.
          </div>
          <div></div>
          {/*<p className="mt-20 text-14 link pointer">
            <span>Download sample CSV template here</span>
  </p>*/}

          <p className="text-14 text-grey mt-20">Upload file here</p>
          <div className="">
            <input
              id="import-file"
              name="import-file"
              type="file"
              hidden={true}
              accept=".csv, text/csv"
              onChange={(e) => {
                const csvFile = e.target.files[0];
                if (csvFile) importAction(csvFile);
              }}
            />
            <label for="import-file">
              <div className="drop-file f-width text-14 text-primary text-center">
                Click to select CSV file to import
              </div>
            </label>
          </div>

          <div className="bx--row mt-40">
            <span
              className="font-bold text-danger ml-20 pointer"
              onClick={cancelAction}>
              CANCEL
            </span>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default ImportEmployeesModal;
