import BillingActionTypes from '../types/billing.types';

export const setBillings = (billings) => ({
  type: BillingActionTypes.SET_BILLINGS,
  payload: billings,
});

export const setBilledStudents = (billedStudents) => ({
  type: BillingActionTypes.SET_BILLED_STUDENTS,
  payload: billedStudents,
});

export const setBillingFees = (billingFees) => ({
  type: BillingActionTypes.SET_BILLING_FEES,
  payload: billingFees,
});

export const removeBilling = (billingId) => ({
  type: BillingActionTypes.REMOVE_BILLING,
  payload: billingId,
});

export const removeStudentBill = (studentBillId) => ({
  type: BillingActionTypes.REMOVE_STUDENT_BILL,
  payload: studentBillId,
});

export const removeBillingFee = (billingFeeId) => ({
  type: BillingActionTypes.REMOVE_BILLING_FEE,
  payload: billingFeeId,
});
