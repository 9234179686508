import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { Plus, Camera } from 'react-feather';

import { Select, SelectItem, ToggleSmall } from 'carbon-components-react';

import MainHeader from '../../../components/main-header/main-header.component';
import Mainmenu from '../../../components/menu/main-menu/main-menu.component';
import CustomButton from '../../../components/custom-button/custom-btn.component';
import Footer from '../../../components/footer/footer.component';

import './notifications.styles.scss';

const plusIcon = <Plus size="12" strokeWidth="5px" />;

const NotificationsPage = () => {
  const [isAdminView, setIsAdminView] = useState(true);

  return (
    <div>
      <MainHeader />
      <div className="notifications-page page-container">
        <div>
          <p>
            <span className="text-12 font-bold text-grey">You are here</span>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <Link to="/settings" className="text-12 text-dark">
              Settings
            </Link>
            &nbsp;<span className="text-12">&gt;</span>&nbsp;
            <span className="text-12 text-primary font-bold">
              Notifications
            </span>
          </p>

          <span className="right">
            <Mainmenu />
          </span>
        </div>

        <hr className="grey-hr mt-35 mb-10" />

        <div className="mt-15">
          <span className="h-24">Notifications (Admin view)</span> &nbsp; &nbsp;
          &nbsp;
        </div>

        <div className="mt-25 bg-white pane" hidden={!isAdminView}>
          <div className="bx--row">
            <div className="bx--col-md-3">
              <Select labelText="User roles">
                <SelectItem selected text="Administrator" value="" />
              </Select>
            </div>
            <div className="activate">
              <span className="text-14">Activate all notifications</span>
              <span className="span-toggle">
                <ToggleSmall
                  aria-label=""
                  className="toggle"
                  defaultToggled
                  id="toggle-1"
                  labelA=""
                  labelB=""
                  labelText=""
                  onChange={function noRefCheck() {}}
                  onToggle={function noRefCheck() {}}
                />
              </span>
            </div>
          </div>
          <div className="">
            <table className="bx--data-table mt-40 f-width">
              <thead>
                <tr>
                  <th>
                    Activity Type <br />
                    <span className="text-14 text-grey">
                      Send me notification when
                    </span>
                  </th>
                  <th>System Notification</th>
                  <th>Email Notification</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>New student is added, edited or terminated</td>
                  <td>
                    <ToggleSmall
                      className="toggle"
                      id="toggle-2"
                      defaultToggled
                      labelA=""
                      labelB=""
                      labelText=""
                      onChange={function noRefCheck() {}}
                      onToggle={function noRefCheck() {}}
                    />
                  </td>
                  <td>
                    <ToggleSmall
                      className="toggle"
                      defaultToggled
                      id="toggle-3"
                      labelA=""
                      labelB=""
                      labelText=""
                      onChange={function noRefCheck() {}}
                      onToggle={function noRefCheck() {}}
                    />
                  </td>
                </tr>
                <tr>
                  <td>New employee is added, edited or terminated</td>
                  <td>
                    <ToggleSmall
                      className="toggle"
                      id="toggle-4"
                      defaultToggled
                      labelA=""
                      labelB=""
                      labelText=""
                      onChange={function noRefCheck() {}}
                      onToggle={function noRefCheck() {}}
                    />
                  </td>
                  <td>
                    <ToggleSmall
                      className="toggle"
                      defaultToggled
                      id="toggle-5"
                      labelA=""
                      labelB=""
                      labelText=""
                      onChange={function noRefCheck() {}}
                      onToggle={function noRefCheck() {}}
                    />
                  </td>
                </tr>
                <tr>
                  <td>New user is added, edited or terminated</td>
                  <td>
                    <ToggleSmall
                      className="toggle"
                      id="toggle-6"
                      defaultToggled
                      labelA=""
                      labelB=""
                      labelText=""
                      onChange={function noRefCheck() {}}
                      onToggle={function noRefCheck() {}}
                    />
                  </td>
                  <td>
                    <ToggleSmall
                      className="toggle"
                      defaultToggled
                      id="toggle-7"
                      labelA=""
                      labelB=""
                      labelText=""
                      onChange={function noRefCheck() {}}
                      onToggle={function noRefCheck() {}}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Revenue, Expense or Inventory is modified or updated</td>
                  <td>
                    <ToggleSmall
                      className="toggle"
                      id="toggle-8"
                      defaultToggled
                      labelA=""
                      labelB=""
                      labelText=""
                      onChange={function noRefCheck() {}}
                      onToggle={function noRefCheck() {}}
                    />
                  </td>
                  <td>
                    <ToggleSmall
                      className="toggle"
                      defaultToggled
                      id="toggle-9"
                      labelA=""
                      labelB=""
                      labelText=""
                      onChange={function noRefCheck() {}}
                      onToggle={function noRefCheck() {}}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Ledgers, categories or accounts are added or edited</td>
                  <td>
                    <ToggleSmall
                      className="toggle"
                      id="toggle-10"
                      defaultToggled
                      labelA=""
                      labelB=""
                      labelText=""
                      onChange={function noRefCheck() {}}
                      onToggle={function noRefCheck() {}}
                    />
                  </td>
                  <td>
                    <ToggleSmall
                      className="toggle"
                      defaultToggled
                      id="toggle-11"
                      labelA=""
                      labelB=""
                      labelText=""
                      onChange={function noRefCheck() {}}
                      onToggle={function noRefCheck() {}}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Supplier is added, edited or terminated</td>
                  <td>
                    <ToggleSmall
                      className="toggle"
                      id="toggle-12"
                      defaultToggled
                      labelA=""
                      labelB=""
                      labelText=""
                      onChange={function noRefCheck() {}}
                      onToggle={function noRefCheck() {}}
                    />
                  </td>
                  <td>
                    <ToggleSmall
                      className="toggle"
                      defaultToggled
                      id="toggle-13"
                      labelA=""
                      labelB=""
                      labelText=""
                      onChange={function noRefCheck() {}}
                      onToggle={function noRefCheck() {}}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Reports are run</td>
                  <td>
                    <ToggleSmall
                      className="toggle"
                      id="toggle-14"
                      defaultToggled
                      labelA=""
                      labelB=""
                      labelText=""
                      onChange={function noRefCheck() {}}
                      onToggle={function noRefCheck() {}}
                    />
                  </td>
                  <td>
                    <ToggleSmall
                      className="toggle"
                      defaultToggled
                      id="toggle-15"
                      labelA=""
                      labelB=""
                      labelText=""
                      onChange={function noRefCheck() {}}
                      onToggle={function noRefCheck() {}}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Unusual Login detected (login off school hours) and
                    sometimes <br />
                    when a new computer login (or IP) is detected
                  </td>
                  <td>
                    <ToggleSmall
                      className="toggle"
                      defaultToggled
                      id="toggle-16"
                      labelA=""
                      labelB=""
                      labelText=""
                      onChange={function noRefCheck() {}}
                      onToggle={function noRefCheck() {}}
                    />
                  </td>
                  <td>
                    <ToggleSmall
                      className="toggle"
                      defaultToggled
                      id="toggle-17"
                      labelA=""
                      labelB=""
                      labelText=""
                      onChange={function noRefCheck() {}}
                      onToggle={function noRefCheck() {}}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="pane bg-white mt-25" hidden={isAdminView}>
          <table className="bx--data-table  f-width">
            <thead>
              <tr>
                <th>
                  Activity Type <br />
                  <span className="text-14 text-grey">
                    Send me notification when
                  </span>
                </th>
                <th>System Notification</th>
                <th>Email Notification</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>New student is added, edited or terminated</td>
                <td>
                  <ToggleSmall
                    className="toggle"
                    id="toggle-18"
                    defaultToggled
                    labelA=""
                    labelB=""
                    labelText=""
                    onChange={function noRefCheck() {}}
                    onToggle={function noRefCheck() {}}
                  />
                </td>
                <td>
                  <ToggleSmall
                    className="toggle"
                    defaultToggled
                    id="toggle-19"
                    labelA=""
                    labelB=""
                    labelText=""
                    onChange={function noRefCheck() {}}
                    onToggle={function noRefCheck() {}}
                  />
                </td>
              </tr>
              <tr>
                <td>New employee is added, edited or terminated</td>
                <td>
                  <ToggleSmall
                    className="toggle"
                    id="toggle-20"
                    defaultToggled
                    labelA=""
                    labelB=""
                    labelText=""
                    onChange={function noRefCheck() {}}
                    onToggle={function noRefCheck() {}}
                  />
                </td>
                <td>
                  <ToggleSmall
                    className="toggle"
                    defaultToggled
                    id="toggle-21"
                    labelA=""
                    labelB=""
                    labelText=""
                    onChange={function noRefCheck() {}}
                    onToggle={function noRefCheck() {}}
                  />
                </td>
              </tr>
              <tr>
                <td>New user is added, edited or terminated</td>
                <td>
                  <ToggleSmall
                    className="toggle"
                    id="toggle-22"
                    defaultToggled
                    labelA=""
                    labelB=""
                    labelText=""
                    onChange={function noRefCheck() {}}
                    onToggle={function noRefCheck() {}}
                  />
                </td>
                <td>
                  <ToggleSmall
                    className="toggle"
                    defaultToggled
                    id="toggle-23"
                    labelA=""
                    labelB=""
                    labelText=""
                    onChange={function noRefCheck() {}}
                    onToggle={function noRefCheck() {}}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <CustomButton classes="primary btn font-bold right">
            SAVE CHANGES
          </CustomButton>
        </div>

        <Footer fixed={false} showLinks={false} />
      </div>
    </div>
  );
};

export default NotificationsPage;
