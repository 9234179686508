import React from 'reactn';
import { Search, Loading, Pagination } from 'carbon-components-react';

const LoaderPane = ({
  loading,
  error = null,
  canReload = true,
  noRecords = false,
  onReload = () => {},
}) => {
  return (
    <div className="d-flex-center mt-20">
      {loading && (
        <div className="">
          <Loading small={true} withOverlay={false} description="Loading..." />
        </div>
      )}

      {error && !loading && <span className="text-danger">{error}</span>}

      {noRecords && !loading && !error && (
        <div className="text-grey text-18"> No&nbsp;records.</div>
      )}

      {error && canReload && (
        <span>
          &nbsp;
          <span
            className="text-primary pointer font-bold"
            onClick={() => onReload()}>
            TRY AGAIN
          </span>
        </span>
      )}
    </div>
  );
};

export default LoaderPane;
