import React from 'react';
import {
  TextInput,
  FormItem,
  Form,
  FormGroup,
  DatePicker,
  DatePickerInput,
  Modal,
  Loading,
} from 'carbon-components-react';
import CustomButton from '../../custom-button/custom-btn.component';

import { isDateValid } from '../../../utils/common.util';
import { addOrUpdateAcademicYear } from '../../../api/academic-year.service';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../../redux/selectors/user.selectors';
import { setAcademicYears } from '../../../redux/actions/academic-year.actions';
import { connect } from 'react-redux';

import './add-academic-year.styles.scss';

class AddAcademicYearModal extends React.Component {
  constructor(props) {
    super(props);

    // set state
    this.state = {
      openModal: props.openModal,
      id: 0,
      academicYearToEdit: this.props.academicYear,
      description: '',
      startDate: '',
      endDate: '',
      canEdit: true,
      loading: false,
      errorMessage: null,
      btnText: 'SUBMIT',
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.openModal !== prevState.openModal) {
      return { openModal: nextProps.openModal };
    } else return null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.academicYear !== prevProps.academicYear) {
      if (this.props.academicYear) {
        const startDate = new Date(this.props.academicYear.startDate);
        const endDate = new Date(this.props.academicYear.endDate);

        const parsedStartDate = [
          startDate.getFullYear(),
          ('0' + (startDate.getMonth() + 1))?.slice(-2),
          ('0' + startDate.getDate())?.slice(-2),
        ].join('-');

        const parsedEndDate = [
          endDate.getFullYear(),
          ('0' + (endDate.getMonth() + 1)).slice(-2),
          ('0' + endDate.getDate()).slice(-2),
        ].join('-');

        this.setState({
          id: this.props.academicYear.id,
          academicYearToEdit: this.props.academicYear,
          description: this.props.academicYear.description,
          startDate: parsedStartDate,
          endDate: parsedEndDate,
          canEdit: this.props.academicYear.canEdit,
        });
      } else {
        this.setState({
          id: 0,
          academicYearToEdit: null,
          description: '',
          startDate: '',
          endDate: '',
          canEdit: true,
        });
      }
    }
  }

  // handle onchange event handler for input fields
  handleChange = (event) => {
    // destructure value and name of event.target
    const { value, name } = event.target;
    // update state
    this.setState({ [name]: value });
  };

  // validate data entered
  validateData = () => {
    return (
      this.state.description.length > 0 &&
      this.state.startDate.length > 0 &&
      this.state.endDate.length > 0 &&
      isDateValid(this.state.startDate) &&
      isDateValid(this.state.endDate)
    );
  };

  createAcademicYear = async (event) => {
    event.preventDefault();

    // update state to set request in progress to true and error occurred to false
    this.setState({
      loading: true,
      errorMessage: null,
      btnText: null,
    });

    // build data
    const data = {
      id: this.state.id,
      description: this.state.description,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    };

    // initiate request
    const response = await addOrUpdateAcademicYear(
      this.props.currentUser.authToken,
      data,
    );

    if (!response.success) {
      this.setState({
        loading: false,
        errorMessage: response.message,
        btnText: 'SUBMIT',
      });

      return;
    }

    // on success
    this.setState({
      loading: false,
      description: '',
      startDate: '',
      endDate: '',
      btnText: 'SUBMIT',
    });

    // update redux
    this.props.setAcademicYears([response.academicYear]);

    // close dialog
    this.props.cancelAction();
  };

  render() {
    return (
      <Modal
        id="add-academic-year-modal"
        className="add-academic-year-modal"
        hasScrollingContent
        passiveModal={true}
        open={this.state.openModal}
        modalHeading={
          this.state.academicYearToEdit
            ? 'Edit Academic Year'
            : 'Create New Academic Year'
        }
        primaryButtonDisabled={false}>
        <div>
          <Form action="post">
            <FormItem>
              <TextInput
                id="description"
                name="description"
                labelText="Description"
                placeholder="E.g. 2019/2020"
                value={this.state.description}
                onChange={this.handleChange}
              />
            </FormItem>
            {/*  TODO: academic year */}
            <FormItem className="mt-20">
              <DatePicker
                dateFormat="Y-m-d"
                datePickerType="single"
                id="start-date-picker"
                readOnly={!this.state.canEdit}
                onClose={(value) => {
                  console.log('the date', value);
                  if (value.length > 0) {
                    this.setState({
                      startDate: [
                        value[0].getFullYear(),
                        ('0' + (value[0].getMonth() + 1))?.slice(-2),
                        ('0' + value[0].getDate())?.slice(-2),
                      ].join('-'),
                    });
                  }
                }}>
                <DatePickerInput
                  className="start-date"
                  id="start-date"
                  name="startDate"
                  //disabled={!this.state.canEdit}
                  value={this.state.startDate}
                  onChange={this.handleChange}
                  labelText="Start Date"
                  pattern="d{1,2}/d{4}"
                  placeholder="YYYY-MM-DD"
                />
              </DatePicker>
            </FormItem>

            <FormGroup className="mt-20 nmb">
              <FormItem>
                <DatePicker
                  dateFormat="Y-m-d"
                  datePickerType="single"
                  id="end-date-picker"
                  readOnly={!this.state.canEdit}
                  onClose={(value) => {
                    if (value.length > 0) {
                      this.setState({
                        endDate: [
                          value[0].getFullYear(),
                          ('0' + (value[0].getMonth() + 1)).slice(-2),
                          ('0' + value[0].getDate()).slice(-2),
                        ].join('-'),
                      });
                    }
                  }}>
                  <DatePickerInput
                    className="end-date"
                    id="end-date"
                    labelText="End Date"
                    //disabled={!this.state.canEdit}
                    name="endDate"
                    value={this.state.endDate}
                    onChange={this.handleChange}
                    pattern="d{1,2}/d{4}"
                    placeholder="YYYY-MM-DD"
                  />
                </DatePicker>
              </FormItem>
            </FormGroup>

            <p className="text-grey mt-10 text-14">
              Note: Duration must be at least 7 months and should cover the
              period of all terms and semesters for the academic year
            </p>

            <CustomButton
              classes="primary f-width mt-30 font-bold"
              disabled={!this.validateData() || this.state.loading}
              onClick={this.createAcademicYear}>
              {this.state.btnText}

              <div hidden={this.state.btnText != null}>
                <div className="d-flex-center">
                  <Loading
                    withOverlay={false}
                    small={true}
                    description="Creating academic year..."
                  />
                </div>
              </div>
            </CustomButton>

            <p
              className="text-14 mt-10 text-danger"
              hidden={this.state.errorMessage === null}
              disabled={this.state.loading}>
              {this.state.errorMessage}
            </p>

            <CustomButton
              classes="outline danger f-width mt-20 font-bold"
              onClick={() => {
                this.setState({
                  loading: false,
                  errorMessage: null,
                });

                this.props.cancelAction();
              }}>
              CANCEL
            </CustomButton>
          </Form>
        </div>
      </Modal>
    );
  }
}

// this will provide us with the state values we need
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

// this allows us to map our redux actions to our component
const mapDispatchToProps = (dispatch) => ({
  setAcademicYears: (academicYears) =>
    dispatch(setAcademicYears(academicYears)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddAcademicYearModal);
