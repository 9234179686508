import ChartOfAccountsActionTypes from '../types/chart-of-accounts.types';

// set funds action
export const setGroups = (groups) => ({
  type: ChartOfAccountsActionTypes.SET_GROUPS,
  payload: groups,
});

// set ledger categories action
export const setLedgerCategories = (ledgerCategories) => ({
  type: ChartOfAccountsActionTypes.SET_LEDGER_CATEGORIES,
  payload: ledgerCategories,
});

// set ledger accounts action
export const setLedgerAccounts = (ledgerAccounts) => ({
  type: ChartOfAccountsActionTypes.SET_LEDGER_ACCOUNTS,
  payload: ledgerAccounts,
});

export const removeLedgerCategory = (categoryId) => ({
  type: ChartOfAccountsActionTypes.REMOVE_LEDGER_CATEGORY,
  payload: categoryId,
});

export const removeLedgerAccount = (accountId) => ({
  type: ChartOfAccountsActionTypes.REMOVE_LEDGER_ACCOUNT,
  payload: accountId,
});
