import React from 'react';
import { Modal } from 'carbon-components-react';
import CustomButton from '../../custom-button/custom-btn.component';
import { Printer } from 'react-feather';
import SubFooter from '../../footer/sub-footer/sub-footer.component';
import { formatCurrency, formatDate } from '../../../utils/common.util';

import './transaction-report.styles.scss';

const TransactionReportPrintOutModal = ({
  openModal,
  data,
  dateFrom,
  dateTo,
  ledgerAccount,
  school,
  closeAction,
}) => {
  return (
    <Modal
      id="transaction-report-modal"
      className="transaction-report-modal"
      hasScrollingContent
      passiveModal={true}
      open={openModal}
      primaryButtonDisabled={false}>
      <div style={{ pageBreakAfter: 'always' }}>
        <div className="mt-15 bx--col--auto mb-10">
          <div
            className="school-logo center"
            style={{ backgroundImage: `url(${school.logoUrl})` }}></div>
          <h3 className="h-24 text-primary text-center">{school.name}</h3>
          <p className="text-14 text-grey-dark text-center">
            {school.location}
          </p>

          <p className="text-center text-16 text-primary font-bold mt-10 text-uppercase">
            JOURNAL VOUCHER REPORT
          </p>
        </div>

        <div className="info d-flex-space-between mt-10">
          <div>
            <p className="text-14 text-grey-light">Ledger Account</p>
            <h3 className="h-14">
              {ledgerAccount ? ledgerAccount.description : 'ALL'}
            </h3>
          </div>

          <div>
            <p className="text-14 text-grey-light">Start Date</p>
            <h3 className="h-14">
              {dateFrom && formatDate(new Date(dateFrom))}
            </h3>
          </div>
          <div>
            <p className="text-14 text-grey-light">End Date</p>
            <h3 className="h-14">{dateTo && formatDate(new Date(dateTo))}</h3>
          </div>
        </div>

        {data && (
          <div className="mt-15">
            <table className="bx--data-table table-white">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Credit Account</th>
                  <th>Debit Account</th>
                  <th>Description</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {data.map((transaction) => (
                  <tr>
                    <td>{formatDate(transaction.dateRecorded)}</td>
                    <td>{transaction.creditAccount.description}</td>
                    <td>{transaction.debitAccount.description}</td>
                    <td>{transaction.description}</td>
                    <td className="font-bold">
                      {formatCurrency(transaction.amount)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="bx--row">
              <div className="bx--col-lg-8"></div>

              <div className="bx--col-lg-2">
                <CustomButton
                  classes="secondary  f-width font-bold  mt-35"
                  onClick={closeAction}>
                  CLOSE
                </CustomButton>
              </div>
              <div className="bx--col-lg-2">
                <CustomButton
                  classes="primary f-width font-bold mt-35"
                  onClick={() => window.print()}>
                  <Printer size="11" strokeWidth="5px" /> &nbsp; PRINT
                </CustomButton>
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default TransactionReportPrintOutModal;
