import React from 'react';
import {
  Form,
  FormGroup,
  FormItem,
  TextInput,
  TextArea,
  Modal,
  Loading,
} from 'carbon-components-react';
import CustomButton from '../../custom-button/custom-btn.component';

import './add-class-label.styles.scss';
import { createLabel } from '../../../api/class.service';
import { setClassLabels } from '../../../redux/actions/class.actions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../../redux/selectors/user.selectors';

class AddClassLabelModal extends React.Component {
  constructor(props) {
    super(props);

    // set state
    this.state = {
      openModal: props.openModal,
      id: 0,
      classLabelToEdit: props.classLabel,
      description: '',
      loading: false,
      message: null,
      messageClass: '',
      btnText: 'ADD',
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.openModal !== prevState.openModal) {
      return { openModal: nextProps.openModal };
    } else return null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.classLabel !== prevProps.classLabel) {
      if (this.props.classLabel) {
        this.setState({
          id: this.props.classLabel.id,
          classLabelToEdit: this.props.classLabel,
          description: this.props.classLabel.description,
          message: null,
          messageClass: '',
          btnText: 'UPDATE',
        });
      } else {
        this.setState({
          id: 0,
          classLabelToEdit: null,
          description: '',
          message: null,
          messageClass: '',
          btnText: 'ADD',
        });
      }
    }
  }

  onAddClassLabelBtnClicked = async (event) => {
    event.preventDefault();

    // update state to set request in progress to true and error occurred to false
    this.setState({
      loading: true,
      message: null,
      btnText: null,
    });

    // build data
    const data = { id: this.state.id, description: this.state.description };

    // initiate request
    const response = await createLabel(this.props.currentUser.authToken, data);

    if (!response.success) {
      this.setState({
        loading: false,
        message: response.message,
        messageClass: 'text-danger',
        btnText: this.state.classLabelToEdit ? 'UPDATE' : 'ADD',
      });

      return;
    }

    // on success
    this.setState({
      loading: false,
      description: '',
      message: `${response.message} Add more or close this dialog.`,
      messageClass: 'text-success',
      btnText: 'ADD',
    });

    // close dialog if it was an update
    if (this.state.classLabelToEdit) {
      this.props.cancelAction();
    }

    // update redux
    this.props.setClassLabels([response.classLabel]);
  };

  render() {
    return (
      <Modal
        id="add-class-label-modal"
        className="add-class-label-modal"
        hasScrollingContent
        passiveModal={true}
        open={this.state.openModal}
        modalHeading={
          this.state.classLabelToEdit
            ? 'Edit Class Label'
            : 'Add New Class Label'
        }
        primaryButtonDisabled={false}>
        <div>
          <Form action="post">
            <FormGroup legendText="" className="nmb">
              <FormItem>
                <TextInput
                  id="description"
                  name="description"
                  labelText="Description"
                  value={this.state.description}
                  onChange={(e) =>
                    this.setState({ description: e.target.value })
                  }
                  placeholder="E.g A or B or C or HEROES"
                />
              </FormItem>
            </FormGroup>

            {/* <FormGroup className="mb-10 mt-10">
              <TextArea 
                labelText="Comment"
                placeholder="Add comment to label"
               />
    </FormGroup> */}

            <CustomButton
              classes="primary f-width mt-30 font-bold"
              disabled={
                this.state.description.length === 0 || this.state.loading
              }
              onClick={this.onAddClassLabelBtnClicked}>
              {this.state.btnText}

              <div hidden={this.state.btnText != null}>
                <div className="d-flex-center">
                  <Loading
                    withOverlay={false}
                    small={true}
                    description="Creating Label..."
                  />
                </div>
              </div>
            </CustomButton>

            <p
              className={`text-14 mt-10 ${this.state.messageClass}`}
              hidden={this.state.message === null}>
              {this.state.message}
            </p>

            <CustomButton
              classes="outline secondary mt-20 f-width font-bold"
              onClick={this.props.cancelAction}>
              CLOSE
            </CustomButton>
          </Form>
        </div>
      </Modal>
    );
  }
}

// this will provide us with the state values we need
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

// this allows us to map our redux actions to our component
const mapDispatchToProps = (dispatch) => ({
  setClassLabels: (classLabels) => dispatch(setClassLabels(classLabels)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddClassLabelModal);
