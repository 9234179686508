const INITIAL_STATE = {
  expensesInMonth: {
    error: null,
    calculating: false,
    data: null,
  },
};

const expenseReducerNew = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CALCULATE_EXPENSES_IN_MONTH_REQUESTED':
      return {
        ...state,
        expensesInMonth: {
          ...state.expensesInMonth,
          calculating: true,
          error: null,
        },
      };
    case 'CALCULATE_EXPENSES_IN_MONTH_SUCCEEDED':
      return {
        ...state,
        expensesInMonth: {
          ...state.expensesInMonth,
          calculating: false,
          data: action.payload,
        },
      };

    case 'CALCULATE_EXPENSES_IN_MONTH_FAILED':
      return {
        ...state,
        expensesInMonth: {
          ...state.expensesInMonth,
          calculating: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};

export default expenseReducerNew;
