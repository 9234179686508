const INITIAL_STATE = {
  accounts: {
    fetching: false,
    fetchError: null,
    data: [],
  },
  categories: {
    fetching: false,
    fetchError: null,
    data: [],
  },
  groups: {
    fetching: false,
    fetchError: null,
    data: [],
  },
};

const ledgerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'FETCH_LEDGER_ACCOUNTS_REQUESTED':
      return {
        ...state,
        accounts: {
          ...state.accounts,
          fetching: true,
          fetchError: null,
        },
      };
    case 'FETCH_LEDGER_ACCOUNTS_SUCCEEDED':
      return {
        ...state,
        accounts: {
          ...state.accounts,
          fetching: false,
          data: action.payload,
        },
      };
    case 'FETCH_LEDGER_ACCOUNTS_FAILED':
      return {
        ...state,
        accounts: {
          ...state.accounts,
          fetching: false,
          fetchError: action.payload,
        },
      };
    case 'FETCH_LEDGER_CATEGORIES_REQUESTED':
      return {
        ...state,
        categories: {
          ...state.categories,
          fetching: true,
          fetchError: null,
        },
      };
    case 'FETCH_LEDGER_CATEGORIES_SUCCEEDED':
      return {
        ...state,
        categories: {
          ...state.categories,
          fetching: false,
          data: action.payload,
        },
      };
    case 'FETCH_LEDGER_CATEGORIES_FAILED':
      return {
        ...state,
        categories: {
          ...state.categories,
          fetching: false,
          fetchError: action.payload,
        },
      };
    case 'FETCH_LEDGER_GROUPS_REQUESTED':
      return {
        ...state,
        groups: {
          ...state.groups,
          fetching: true,
          fetchError: null,
        },
      };
    case 'FETCH_LEDGER_GROUPS_SUCCEEDED':
      return {
        ...state,
        groups: {
          ...state.groups,
          fetching: false,
          data: action.payload,
        },
      };
    case 'FETCH_LEDGER_GROUPS_FAILED':
      return {
        ...state,
        groups: {
          ...state.groups,
          fetching: false,
          fetchError: action.payload,
        },
      };
    default:
      return state;
  }
};

export default ledgerReducer;
