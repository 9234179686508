import React, { useState } from 'react';
import { MoreVertical } from 'react-feather';

import { ReactComponent as Target } from '../../../assets/images/card-icons/target.svg';
import CardMenu, {
  CardMenuItem,
} from '../../menu/card-menu/card-menu.component';

import './class-label-card.styles.scss';

const ClassLabelCard = ({ label, editAction, deleteAction, archiveAction }) => {
  const [showCardMenu, setShowCardMenu] = useState(false);

  return (
    <div className="class-label-card bg-white">
      <div>
        <div className="top-content">
          <div className="icon">
            <Target />

            <MoreVertical
              className="pointer"
              color="#7D819D"
              onClick={() => setShowCardMenu(showCardMenu ? false : true)}
            />

            {showCardMenu ? (
              <CardMenu>
                <CardMenuItem
                  title="EDIT"
                  color="primary"
                  action={(event) => {
                    event.stopPropagation();
                    editAction();
                    setShowCardMenu(showCardMenu ? false : true);
                  }}
                />
                <CardMenuItem
                  title="DELETE"
                  separator={true}
                  color="danger"
                  action={(event) => {
                    event.stopPropagation();
                    deleteAction();
                    setShowCardMenu(showCardMenu ? false : true);
                  }}
                />
                <CardMenuItem
                  title="ARCHIVE"
                  separator={false}
                  color=""
                  action={(event) => {
                    event.stopPropagation();
                    archiveAction();
                    setShowCardMenu(showCardMenu ? false : true);
                  }}
                />
              </CardMenu>
            ) : null}
          </div>

          <div className=" bx--row">
            <div className="bx--col">
              <h3 className="title text-truncate">{label.description}</h3>
              Import ID: <span className="font-bold">{label.id}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassLabelCard;
