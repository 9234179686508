import React from 'react';
import {
  TextInput,
  Modal,
  Loading,
  Select,
  SelectItem,
  TextArea,
  DatePickerInput,
  DatePicker,
} from 'carbon-components-react';
import CustomButton from '../../custom-button/custom-btn.component';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { selectCurrentUser } from '../../../redux/selectors/user.selectors';
import { selectClasses } from '../../../redux/selectors/class.selectors';
import { selectEnrolledStudents } from '../../../redux/selectors/student.selectors';
import { getBilledStudents } from '../../../api/billing.service';
import { formatCurrency } from '../../../utils/common.util';
import { setBilledStudents } from '../../../redux/actions/billing.actions';
import { selectCurrentAssetsLedgers } from '../../../redux/selectors/chart-of-accounts.selector';

import { newPayment, revisePayment } from '../../../api/revenue.service';
import {
  setPayments,
  removePayment,
} from '../../../redux/actions/revenue.actions';
import { setLedgerAccounts } from '../../../redux/actions/chart-of-accounts.actions';
import { getLedgerAccounts } from '../../../api/chart-of-accounts.service';

import './receive-payment.styles.scss';

class ReceivePaymentModal extends React.Component {
  constructor(props) {
    super(props);

    // set state
    this.state = {
      id: 0,
      payInBulk: false,
      bulkAmount: 0,
      loading: false,
      loadingBills: false,
      billsErrorMessage: null,
      btnText: 'SUBMIT PAYMENT',
      heading: 'Receive Student Payment',
      errorMessage: null,
      openModal: props.openModal,
      selectedClass: '',
      selectedStudent: null,
      studentName: '',
      studentBills: [],
      totalBalanceDue: 0.0,
      paymentMode: '',
      paymentDate: '',
      chequeNumber: '',
      description: '',
      debitAccount: '',
      revenues: [],
      currentRevenues: [],
      refreshPage: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.openModal !== prevState.openModal) {
      return { openModal: nextProps.openModal };
    } else return null;
  }

  componentDidMount() {
    if (this.props.student) {
      let heading;
      if (this.props.fromParent) {
        heading = `Make Payment for your ward ${this.props.student.firstName} ${this.props.student.lastName}, student of ${this.props.student.school.name}`;
      } else {
        heading = `Receive Payment from Student ${this.props.student.firstName} 
          ${this.props.student.lastName}`;
      }

      this.setState(
        {
          heading,
          selectedStudent: this.props.student,
          studentName: `${this.props.student.fullName} [${this.props.student.studentId}]`,
        },
        () => {
          // load bills
          this.loadStudentBills();
        },
      );
    }

    // fetch ledger accounts
    getLedgerAccounts(this.props.currentUser.authToken, 0, 2000).then(
      (response) => {
        if (response.success) {
          this.props.setLedgerAccounts(response.ledgerAccounts);
        }
      },
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.student !== prevProps.student) {
      if (this.props.student) {
        let heading;
        if (this.props.fromParent) {
          heading = `Make Payment for your ward ${this.props.student.firstName} ${this.props.student.lastName}, student of ${this.props.student.school.name}`;
        } else {
          heading = `Receive Payment from Student ${this.props.student.firstName} 
          ${this.props.student.lastName}`;
        }

        this.setState(
          {
            heading,
            selectedStudent: this.props.student,
            studentName: `${this.props.student.fullName} [${this.props.student.studentId}]`,
          },
          () => {
            // load bills
            this.loadStudentBills();
          },
        );
      }
    }

    if (this.props.paymentToRevise !== prevProps.paymentToRevise) {
      if (this.props.paymentToRevise) {
        const currentRevenues = [];

        this.props.paymentToRevise.revenues.forEach((revenue) => {
          currentRevenues.push({
            ...revenue,
            amountPaidInputValue: revenue.amountPaid,
          });
        });

        const revenues = [];
        this.props.paymentToRevise.revenues.forEach((revenue) => {
          revenues.push({
            ...revenue,
          });
        });

        this.setState({
          id: this.props.paymentToRevise.id,
          btnText: 'REVISE PAYMENT',
          heading: `Revise Payment from Student ${this.props.paymentToRevise.student.firstName} 
          ${this.props.paymentToRevise.student.lastName} 
          [${this.props.paymentToRevise.student.studentId}]`,
          paymentMode: this.props.paymentToRevise.paymentMode,
          chequeNumber: this.props.paymentToRevise.chequeNumber,
          description: this.props.paymentToRevise.description,
          debitAccount: this.props.paymentToRevise.debitAccount.id,
          currentRevenues: currentRevenues,
          revenues: revenues,
          selectedStudent: this.props.paymentToRevise.student,
          studentName: `${this.props.paymentToRevise.student.fullName} 
          [${this.props.paymentToRevise.student.studentId}]`,
          totalBalanceDue: this.props.paymentToRevise.amountOwed,
          payment: this.props.paymentToRevise,
          dirty: false,
          loading: false,
        });
      } else {
        this.setState({
          id: 0,
          btnText: 'SUBMIT PAYMENT',
          heading: 'Receive Student Payment',
          selectedClass: '',
          selectedStudent: null,
          studentName: '',
          studentBills: [],
          totalBalanceDue: 0.0,
          paymentMode: '',
          paymentDate: '',
          chequeNumber: '',
          description: '',
          debitAccount: '',
          currentRevenues: [],
          revenues: [],
          payment: null,
          loading: false,
        });
      }
    }
  }

  loadStudentBills = async () => {
    this.setState({ loadingBills: true, billsErrorMessage: null });

    // make request
    const response = await getBilledStudents(
      -1,
      this.props.currentUser.authToken,
      this.state.selectedStudent.id,
    );

    if (!response.success) {
      this.setState({
        loadingBills: false,
        billsErrorMessage: response.message,
      });

      return;
    }

    const currentBillsItems = [];
    let totalBalanceDue = 0.0;

    response.billedStudents
      // .filter((bill) => bill.current === true)
      .forEach((bill) => {
        totalBalanceDue += bill.totalBalanceDue;
        bill.billItems?.forEach((billItem) => {
          if (billItem.balanceDue > 0) {
            currentBillsItems.push(billItem);
          }
        });
      });

    this.setState({
      loadingBills: false,
      studentBills: currentBillsItems,
      totalBalanceDue: totalBalanceDue,
    });

    // update redux
    this.props.setBilledStudents(response.billedStudents);
  };

  submitPayment = async (event) => {
    if (event) event.preventDefault();

    // show loader
    this.setState({ loading: true, errorMessage: null, btnText: null });

    // build data
    let data = {
      student: this.state.selectedStudent,
      paymentDate: this.state.paymentDate,
      paymentMode: this.state.paymentMode,
      chequeNumber: this.state.chequeNumber,
      description: this.state.description,
      amountOwed: this.state.totalBalanceDue,
      debitAccount: this.props.fromParent
        ? null
        : this.props.currentAssets.find(
            (asset) => asset.id == this.state.debitAccount,
          ),
      revenues: this.state.revenues,
    };

    if (this.state.payInBulk) {
      //const studentBillItem =  this.state.id === 0 ? this.state.studentBills[0] : this.state.currentRevenues[0]
      const bills =
        this.state.id === 0
          ? this.state.studentBills
          : this.state.currentRevenues;
      const billIds = [];
      bills?.map((b) => billIds.push(b.studentBillId));
      delete data['revenues'];

      data = {
        ...data,
        studentBillId: billIds, //studentBillItem?.studentBillId,
        amount: this.state?.bulkAmount,
      };
    }

    // make payment
    const response = await newPayment(
      this.props.currentUser.authToken,
      data,
      this.state?.payInBulk,
    );

    if (!response.success) {
      this.setState({
        loading: false,
        errorMessage: response.message,
        btnText: 'MAKE PAYMENT',
      });

      return;
    }

    // all good

    // save payment to redux
    this.props.setPayments([response.payment]);

    this.setState({
      selectedClass: '',
      selectedStudent: null,
      studentBills: [],
      paymentMode: '',
      paymentDate: '',
      chequeNumber: '',
      description: '',
      debitAccount: '',
      revenues: [],
      studentName: '',
      loading: false,
      btnText: 'SUBMIT PAYMENT',
      totalBalanceDue: 0.0,
    });

    // and show receipt
    this.props.showReceipt(response.payment);
  };

  revisePayment = async (event) => {
    if (event) event.preventDefault();

    // show loader
    this.setState({ loading: true, errorMessage: null, btnText: null });

    // build data
    const data = {
      id: this.state.id,
      student: this.state.selectedStudent,
      paymentMode: this.state.paymentMode,
      paymentDate: this.state.paymentDate,
      chequeNumber: this.state.chequeNumber,
      description: this.state.description,
      amountOwed: this.state.totalBalanceDue,
      debitAccount: this.props.currentAssets.find(
        (asset) => asset.id == this.state.debitAccount,
      ),
      revenues: this.state.revenues,
    };

    // make payment
    const response = await revisePayment(
      this.props.currentUser.authToken,
      data,
    );

    if (!response.success) {
      this.setState({
        loading: false,
        errorMessage: response.message,
        btnText: 'MAKE PAYMENT',
      });

      return;
    }

    // all good

    // save revised payment to redux
    this.props.setPayments([response.payment]);

    // remove revised payment
    this.props.removePayment(this.state.id);

    // update state
    this.setState({
      selectedClass: '',
      selectedStudent: null,
      studentBills: [],
      paymentMode: '',
      paymentDate: '',
      chequeNumber: '',
      description: '',
      debitAccount: '',
      revenues: [],
      currentRevenues: [],
      payment: null,
      studentName: '',
      btnText: 'MAKE PAYMENT',
      totalBalanceDue: 0.0,
    });

    // and show receipt
    this.props.showReceipt(response.payment);
  };

  render() {
    let students = [];

    if (
      this.state.studentName.length > 0 &&
      this.state.selectedClass.length > 0 &&
      this.state.selectedStudent === null
    ) {
      // filter off students

      const filter = this.state.studentName.toLowerCase();
      students = this.props.students.filter(
        (student) =>
          student.classId == this.state.selectedClass &&
          (student.firstName.toLowerCase().includes(filter) ||
            student.lastName.toLowerCase().includes(filter) ||
            student.studentId.toLowerCase().includes(filter)),
      );
    }

    const bulkTotal = () => {
      let i = 0;
      let j = 0;
      if (this.state?.id === 0) {
        this.state?.studentBills?.forEach((bill) => {
          i += parseFloat(bill?.amountBilled);
          j += parseFloat(bill?.balanceDue);
          //return
        });
      } else {
        this.state?.currentRevenues?.forEach((rev) => {
          i += parseFloat(rev?.studentBillItem?.amountBilled);
          j += parseFloat(rev?.amountOwed);
        });
      }
      return { amountBilled: i, balanceDue: j };
    };

    return (
      <Modal
        id="receive-payment-modal"
        className="receive-payment-modal"
        hasScrollingContent
        passiveModal={true}
        open={this.state.openModal}
        modalHeading={this.state.heading}
        primaryButtonDisabled={false}>
        <div>
          {!this.props.student && (
            <div className="bx--row">
              <div className="bx--col-md-4 mt-20">
                <Select
                  id="class"
                  labelText="Student Class"
                  onChange={(e) =>
                    this.setState({
                      selectedClass: e.target.value,
                      studentName: '',
                      selectedStudent: null,
                      studentBills: [],
                    })
                  }>
                  <SelectItem
                    selected={this.state.selectedClass === ''}
                    disabled
                    text="Select Student Class"
                    value=""
                  />
                  {this.props.classes.map((item) => (
                    <SelectItem
                      text={`${item.description} - ${item.totalStudents} Student(s)`}
                      value={item.id}
                    />
                  ))}
                </Select>
              </div>
              <div className="bx--col-md-4 mt-20">
                <TextInput
                  id="student"
                  labelText="Student"
                  autoComplete="off"
                  value={
                    this.state.selectedStudent
                      ? `${this.state.selectedStudent.firstName} ${this.state.selectedStudent.lastName} [${this.state.selectedStudent.studentId}]`
                      : this.state.studentName
                  }
                  onChange={(e) =>
                    this.setState({
                      studentName: e.target.value,
                      selectedStudent: null,
                    })
                  }
                  readOnly={this.state.selectedClass.length === 0}
                  placeholder="Start typing student name"
                />

                {this.state.studentName.length > 0 &&
                  !this.state.selectedStudent && (
                    <div className="student-result-box fade-in">
                      {students.map((student) => (
                        <div
                          className="student-item"
                          onClick={() => {
                            this.setState(
                              {
                                selectedStudent: student,
                                studentBills: [],
                              },
                              () => {
                                this.loadStudentBills();
                              },
                            );
                          }}>
                          <p className="name">{`${student.firstName} ${student.lastName}`}</p>
                          <span className="text-grey text-14">
                            {student.studentId}
                          </span>

                          <hr className="grey-hr" />
                        </div>
                      ))}

                      {students.length === 0 && (
                        <p>
                          No student found with the name{' '}
                          {this.state.studentName}
                        </p>
                      )}
                    </div>
                  )}
              </div>
            </div>
          )}
          <div className="mt-20">
            <p
              className="text-success"
              hidden={this.state.loadingBills || this.state.selectedStudent}>
              Select a student to continue
            </p>

            <div>
              <Loading
                hidden={!this.state.loadingBills}
                small={true}
                withOverlay={false}
                description="Loading..."
              />
            </div>

            <div
              className="mt-30"
              hidden={this.state.billsErrorMessage == null}>
              <span className="mt-10 text-danger">
                {this.state.billsErrorMessage}.
              </span>
              &nbsp;
              <span
                className="text-primary pointer font-bold"
                onClick={this.loadStudentBills}>
                TRY AGAIN
              </span>
            </div>

            <p
              className="text-14 mt-30 text-grey"
              hidden={
                this.state.loadingBills ||
                this.state.billsErrorMessage !== null ||
                !this.state.selectedStudent ||
                this.state.studentBills.length > 0 ||
                this.state.currentRevenues.length > 0
              }>
              Student have no current bills
            </p>

            <div
              className="text-primary text-18 pointer font-bold mb-20"
              hidden={
                this.state.studentBills.length === 0 &&
                this.state.currentRevenues.length === 0
              }
              onClick={() => {
                this.setState({ payInBulk: !this.state?.payInBulk });
              }}>
              {this.state?.payInBulk ? 'Pay individual' : 'Pay in-bulk'}
            </div>

            <table
              className="bx--data-table"
              hidden={
                this.state.studentBills.length === 0 &&
                this.state.currentRevenues.length === 0
              }>
              <thead>
                <tr>
                  <th>Bill Item</th>
                  <th>Amount Billed</th>
                  <th>Balance Due</th>
                  <th>Amount to Pay</th>
                </tr>
              </thead>
              <tbody>
                {this.state.payInBulk && (
                  <tr>
                    <td>Bulk Pay</td>
                    <td>{formatCurrency(bulkTotal()?.amountBilled)}</td>
                    <td className="font-bold">
                      {formatCurrency(bulkTotal()?.balanceDue)}
                    </td>
                    <td>
                      <TextInput
                        type="number"
                        step="0.01"
                        value={this.state?.bulkAmount}
                        placeholder="eg 100"
                        onChange={(e) => {
                          let amt = parseFloat(e.target.value);
                          if (amt > bulkTotal()?.balanceDue) {
                            amt = bulkTotal()?.balanceDue;
                          }
                          this.setState({
                            bulkAmount: amt,
                          });
                        }}
                      />
                    </td>
                  </tr>
                )}

                {!this.state?.payInBulk && this.state.id === 0
                  ? this.state.studentBills.map((bill) => (
                      <tr>
                        <td>{bill.ledgerAccount.description}</td>
                        <td>{formatCurrency(bill.amountBilled)}</td>
                        <td className="font-bold">
                          {formatCurrency(bill.balanceDue)}
                        </td>
                        <td>
                          <TextInput
                            type="number"
                            step="0.01"
                            max={bill.balanceDue}
                            value={
                              this.state?.revenues.find(
                                (revenue) =>
                                  revenue.studentBillItem.id === bill.id,
                              )?.amountPaid || ''
                            }
                            placeholder="eg 100"
                            onChange={(e) => {
                              let amt = e.target.value;
                              if (
                                parseFloat(amt) > parseFloat(bill.balanceDue)
                              ) {
                                amt = `${bill.balanceDue}`;
                              }

                              // check to see if bill payment have been recorded
                              const revenues = [...this.state.revenues];
                              const payment = revenues.find(
                                (revenue) =>
                                  revenue.studentBillItem.id === bill.id,
                              );

                              if (amt?.length > 0) {
                                // add or update
                                if (payment) {
                                  // update
                                  payment.amountPaid = parseFloat(amt);
                                } else {
                                  // add
                                  revenues.push({
                                    amountOwed: bill.balanceDue,
                                    amountPaid: parseFloat(amt),
                                    ledgerAccount: bill.ledgerAccount,
                                    studentBillItem: bill,
                                  });
                                }
                              } else {
                                // remove
                                if (payment) {
                                  // find index and remove
                                  const paymentIndex = revenues.findIndex(
                                    (revenue) =>
                                      revenue.studentBillItem.id === bill.id,
                                  );

                                  revenues.splice(paymentIndex, 1);
                                }
                              }

                              this.setState({ revenues: revenues });
                            }}
                          />
                        </td>
                      </tr>
                    ))
                  : !this.state?.payInBulk &&
                    this.state.currentRevenues.map((revenue) => (
                      <tr>
                        <td>{revenue.ledgerAccount.description}</td>
                        <td>
                          {formatCurrency(revenue.studentBillItem.amountBilled)}
                        </td>
                        <td className="font-bold">
                          {formatCurrency(revenue.amountOwed)}
                        </td>
                        <td>
                          <TextInput
                            type="number"
                            step="0.01"
                            //max={revenue.amountOwed}
                            placeholder="eg 100"
                            value={revenue.amountPaidInputValue}
                            onChange={(e) => {
                              let amt = e.target.value;
                              if (
                                parseFloat(amt) > parseFloat(revenue.amountOwed)
                              ) {
                                amt = `${revenue.amountOwed}`;
                              }
                              revenue.amountPaidInputValue = amt;

                              // check to see if bill payment have been recorded
                              const revenues = [...this.state.revenues];
                              const payment = revenues.find(
                                (existingRevenue) =>
                                  existingRevenue.id === revenue.id,
                              );

                              if (amt?.length > 0) {
                                // add or update
                                if (payment) {
                                  // update
                                  payment.amountPaid = parseFloat(amt);
                                } else {
                                  // add
                                  revenues.push({
                                    id: revenue.id,
                                    amountOwed: revenue.amountOwed,
                                    amountPaid: parseFloat(amt),
                                    ledgerAccount: revenue.ledgerAccount,
                                    studentBillItem: revenue.studentBillItem,
                                  });
                                }
                              } else {
                                // remove
                                if (payment) {
                                  payment.amountPaid = 0.0;

                                  // find index and remove
                                  const paymentIndex = revenues.findIndex(
                                    (existingRevenue) =>
                                      existingRevenue.id === revenue.id,
                                  );

                                  revenues.splice(paymentIndex, 1);
                                }
                              }

                              this.setState({
                                revenues: revenues,
                                dirty: true,
                              });
                            }}
                          />
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>

          <hr className="grey-hr mt-20" />

          <div
            hidden={
              this.state.revenues.length === 0 &&
              (!this.state.selectedStudent ||
                this.state.studentBills.length === 0)
            }>
            {this.props.fromParent && (
              <>
                <div className="mt-20 bx--col-md-8">
                  <div className="f-width">
                    <TextArea
                      labelText="Note"
                      value={this.state.description}
                      placeholder="Add a note for the school"
                      rows={3}
                      onChange={(e) =>
                        this.setState({ description: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="mt-20 bx--col-md-8">
                  <Select
                    id="payment"
                    labelText="Select your payment Mode"
                    onChange={(e) =>
                      this.setState({ paymentMode: e.target.value })
                    }>
                    <SelectItem
                      disabled
                      selected={this.state.paymentMode === ''}
                      text="Select payment mode"
                      value=""
                    />
                    <SelectItem
                      selected={this.state.paymentMode === 'Card'}
                      text="Card"
                      value="Card"
                    />
                    <SelectItem
                      selected={this.state.paymentMode === 'MobileMoney'}
                      text="Mobile Money"
                      value="MobileMoney"
                    />
                  </Select>
                </div>
              </>
            )}

            {!this.props.fromParent && (
              <div className="bx--row mt-20">
                <div className="mt-20 bx--col-md-4">
                  <DatePicker
                    dateFormat="Y-m-d"
                    datePickerType="single"
                    id="start-date-picker"
                    onClose={(value) => {
                      if (value.length > 0) {
                        this.setState({
                          paymentDate: [
                            value[0].getFullYear(),
                            ('0' + (value[0].getMonth() + 1)).slice(-2),
                            ('0' + value[0].getDate()).slice(-2),
                          ].join('-'),
                        });
                      }
                    }}>
                    <DatePickerInput
                      className="payment-date"
                      id="payment-date"
                      name="paymentDate"
                      value={this.state.paymentDate}
                      onChange={(e) =>
                        this.setState({ paymentDate: e.target.value })
                      }
                      labelText="Payment Date"
                      pattern="d{1,2}/d{4}"
                      placeholder="YYYY-MM-DD"
                    />
                  </DatePicker>
                </div>
                <div className="bx--col-md-4 mt-20">
                  <Select
                    id="debitAccount"
                    labelText="Debit Account (Cash or Bank Account)"
                    onChange={(e) =>
                      this.setState({ debitAccount: e.target.value })
                    }>
                    <SelectItem
                      selected={this.state.debitAccount === ''}
                      disabled
                      text="Select Account to Debit"
                      value=""
                    />
                    {this.props.currentAssets
                      .filter(
                        (currentAsset) =>
                          currentAsset.categoryDescription ===
                            'Bank Accounts' ||
                          currentAsset.categoryDescription === 'Cash Accounts',
                      )
                      .map((currentAsset) => (
                        <SelectItem
                          selected={this.state.debitAccount == currentAsset.id}
                          text={currentAsset.description}
                          value={currentAsset.id}
                        />
                      ))}
                  </Select>
                </div>

                <div className="bx--col-md-4 mt-20">
                  <div className="f-width">
                    <TextInput
                      labelText="Description"
                      value={this.state.description}
                      placeholder="Add a description"
                      onChange={(e) =>
                        this.setState({ description: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
            )}

            {!this.props.fromParent && (
              <div className="bx--row mt-20">
                <div className="bx--col-md-4">
                  <Select
                    id="payment"
                    labelText="Payment Mode/Source"
                    onChange={(e) =>
                      this.setState({ paymentMode: e.target.value })
                    }>
                    <SelectItem
                      disabled
                      selected={this.state.paymentMode === ''}
                      text="Select payment mode or source"
                      value=""
                    />
                    <SelectItem
                      selected={this.state.paymentMode === 'Cash'}
                      text="Cash"
                      value="Cash"
                    />
                    <SelectItem
                      selected={this.state.paymentMode === 'Cheque'}
                      text="Cheque"
                      value="Cheque"
                    />
                    <SelectItem
                      selected={this.state.paymentMode === 'Bank_Transfer'}
                      text="Bank Transfer"
                      value="Bank_Transfer"
                    />
                    <SelectItem
                      selected={this.state.paymentMode === 'BankersDraft'}
                      text="Bankers Draft"
                      value="BankersDraft"
                    />
                    <SelectItem
                      selected={this.state.paymentMode === 'MobileMoney'}
                      text="Mobile Money"
                      value="MobileMoney"
                    />
                    <SelectItem
                      selected={this.state.paymentMode === 'StudentAccount'}
                      text="Student Deposit Account"
                      value="StudentAccount"
                    />
                  </Select>
                </div>
                <div
                  className="bx--col-md-4"
                  hidden={this.state.paymentMode !== 'Cheque'}>
                  <TextInput
                    id="cheque-number"
                    labelText="Cheque Number"
                    value={this.state.chequeNumber}
                    onChange={(e) =>
                      this.setState({ chequeNumber: e.target.value })
                    }
                    placeholder="Enter Cheque Number"
                  />
                </div>
              </div>
            )}
          </div>

          <div className="bx--row mt-60">
            <div className="bx--col-md-3">
              {this.state.id === 0 ? (
                <CustomButton
                  classes="outline danger no-border ml-10 no-hover font-bold"
                  disabled={this.state.loading}
                  onClick={(e) => this.props.cancelAction()}>
                  CANCEL
                </CustomButton>
              ) : (
                <CustomButton
                  classes="outline danger no-border ml-10 no-hover font-bold"
                  disabled={this.state.loading}
                  onClick={(e) =>
                    this.props.reversePayment(this.state.payment)
                  }>
                  REVERSE PAYMENT
                </CustomButton>
              )}
            </div>
            <div className="bx--col-md-5">
              <div className="bx--row">
                <div className="bx--col-md-3">
                  {this.state.id > 0 && (
                    <CustomButton
                      classes="outline secondary no-border ml-10 no-hover font-bold"
                      disabled={this.state.loading}
                      onClick={(e) => this.props.cancelAction()}>
                      CANCEL
                    </CustomButton>
                  )}
                </div>

                <div className="bx--col-md-5">
                  <CustomButton
                    classes="primary f-width font-bold"
                    onClick={() => {
                      this.props.fromParent
                        ? this.props.initiatePaystackPayment(
                            this.state.revenues.reduce(
                              (prev, current) => prev + current.amountPaid,
                              0,
                            ),
                            this.submitPayment,
                          )
                        : this.state.id === 0
                          ? this.submitPayment()
                          : this.revisePayment();
                    }}
                    disabled={
                      this.props.fromParent
                        ? this.state.loading || this.state.revenues.length === 0
                        : this.state.loading ||
                          (this.state?.payInBulk
                            ? this.state?.bulkAmount?.length < 1
                            : this.state.revenues.length === 0) ||
                          this.state.debitAccount.length === 0 ||
                          this.state.paymentMode.length === 0 ||
                          this.state.paymentDate === 0
                    }>
                    {this.state.btnText}

                    <div hidden={this.state.btnText != null}>
                      <div className="d-flex-center">
                        <Loading
                          withOverlay={false}
                          small={true}
                          description="Making payment..."
                        />
                      </div>
                    </div>
                  </CustomButton>
                </div>
              </div>
            </div>
          </div>

          <p
            className={`text-14 mt-10 text-danger`}
            hidden={this.state.errorMessage === null}>
            {this.state.errorMessage}
          </p>
        </div>
      </Modal>
    );
  }
}

// this will provide us with the state values we need
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  classes: selectClasses,
  students: selectEnrolledStudents,
  currentAssets: selectCurrentAssetsLedgers,
});

// this allows us to map our redux actions to our component
const mapDispatchToProps = (dispatch) => ({
  setBilledStudents: (billedStudents) =>
    dispatch(setBilledStudents(billedStudents)),
  setPayments: (payments) => dispatch(setPayments(payments)),
  removePayment: (paymentId) => dispatch(removePayment(paymentId)),
  setLedgerAccounts: (ledgers) => dispatch(setLedgerAccounts(ledgers)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReceivePaymentModal);
