const INITIAL_STATE = {
  studentCount: {
    fetching: false,
    fetchError: null,
    data: null,
  },
  employeeCount: {
    fetching: false,
    fetchError: null,
    data: null,
  },
  balanceSheetReport: {
    fetching: false,
    fetchError: null,
    data: null,
  },
  incomeStatementReport: {
    fetching: false,
    fetchError: null,
    data: null,
  },
  trialBalanceReport: {
    fetching: false,
    fetchError: null,
    data: null,
  },
  expensesReport: {
    fetching: false,
    fetchError: null,
    data: null,
  },
  revenueReport: {
    fetching: false,
    fetchError: null,
    data: null,
  },
  transactionsReport: {
    fetching: false,
    fetchError: null,
    data: null,
  },
  budgetReport: {
    fetching: false,
    fetchError: null,
    data: null,
  },
};

const analyticsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'FETCH_STUDENT_COUNT_REQUESTED':
      return {
        ...state,
        studentCount: {
          ...state.studentCount,
          fetching: true,
          fetchError: null,
        },
      };
    case 'FETCH_STUDENT_COUNT_SUCCEEDED':
      return {
        ...state,
        studentCount: {
          ...state.studentCount,
          fetching: false,
          data: action.payload,
        },
      };
    case 'FETCH_STUDENT_COUNT_FAILED':
      return {
        ...state,
        studentCount: {
          ...state.studentCount,
          fetching: false,
          fetchError: action.payload,
        },
      };
    case 'FETCH_EMPLOYEE_COUNT_REQUESTED':
      return {
        ...state,
        employeeCount: {
          ...state.employeeCount,
          fetching: true,
          fetchError: null,
        },
      };
    case 'FETCH_EMPLOYEE_COUNT_SUCCEEDED':
      return {
        ...state,
        employeeCount: {
          ...state.employeeCount,
          fetching: false,
          data: action.payload,
        },
      };
    case 'FETCH_EMPLOYEE_COUNT_FAILED':
      return {
        ...state,
        employeeCount: {
          ...state.employeeCount,
          fetching: false,
          fetchError: action.payload,
        },
      };
    case 'FETCH_BALANCE_SHEET_REPORT_REQUESTED':
      return {
        ...state,
        balanceSheetReport: {
          ...state.balanceSheetReport,
          fetching: true,
          fetchError: null,
        },
      };
    case 'FETCH_BALANCE_SHEET_REPORT_SUCCEEDED':
      return {
        ...state,
        balanceSheetReport: {
          ...state.balanceSheetReport,
          fetching: false,
          data: action.payload,
        },
      };
    case 'FETCH_BALANCE_SHEET_REPORT_FAILED':
      return {
        ...state,
        balanceSheetReport: {
          ...state.balanceSheetReport,
          fetching: false,
          fetchError: action.payload,
        },
      };
    case 'FETCH_INCOME_STATEMENT_REPORT_REQUESTED':
      return {
        ...state,
        incomeStatementReport: {
          ...state.incomeStatementReport,
          fetching: true,
          fetchError: null,
        },
      };
    case 'FETCH_INCOME_STATEMENT_REPORT_SUCCEEDED':
      return {
        ...state,
        incomeStatementReport: {
          ...state.incomeStatementReport,
          fetching: false,
          data: action.payload,
        },
      };
    case 'FETCH_INCOME_STATEMENT_REPORT_FAILED':
      return {
        ...state,
        incomeStatementReport: {
          ...state.incomeStatementReport,
          fetching: false,
          fetchError: action.payload,
        },
      };

    case 'FETCH_TRIAL_BALANCE_REPORT_REQUESTED':
      return {
        ...state,
        trialBalanceReport: {
          ...state.trialBalanceReport,
          fetching: true,
          fetchError: null,
        },
      };
    case 'FETCH_TRIAL_BALANCE_REPORT_SUCCEEDED':
      return {
        ...state,
        trialBalanceReport: {
          ...state.trialBalanceReport,
          fetching: false,
          data: action.payload,
        },
      };
    case 'FETCH_TRIAL_BALANCE_REPORT_FAILED':
      return {
        ...state,
        trialBalanceReport: {
          ...state.trialBalanceReport,
          fetching: false,
          fetchError: action.payload,
        },
      };

    case 'FETCH_EXPENSES_REPORT_REQUESTED':
      return {
        ...state,
        expensesReport: {
          ...state.expensesReport,
          fetching: true,
          fetchError: null,
        },
      };
    case 'FETCH_EXPENSES_REPORT_SUCCEEDED':
      return {
        ...state,
        expensesReport: {
          ...state.expensesReport,
          fetching: false,
          data: action.payload,
        },
      };
    case 'FETCH_EXPENSES_REPORT_FAILED':
      return {
        ...state,
        expensesReport: {
          ...state.expensesReport,
          fetching: false,
          fetchError: action.payload,
        },
      };
    case 'FETCH_REVENUE_REPORT_REQUESTED':
      return {
        ...state,
        revenueReport: {
          ...state.revenueReport,
          fetching: true,
          fetchError: null,
        },
      };
    case 'FETCH_REVENUE_REPORT_SUCCEEDED':
      return {
        ...state,
        revenueReport: {
          ...state.revenueReport,
          fetching: false,
          data: action.payload,
        },
      };
    case 'FETCH_REVENUE_REPORT_FAILED':
      return {
        ...state,
        revenueReport: {
          ...state.revenueReport,
          fetching: false,
          fetchError: action.payload,
        },
      };
    case 'FETCH_TRANSACTIONS_REPORT_REQUESTED':
      return {
        ...state,
        transactionsReport: {
          ...state.transactionsReport,
          fetching: true,
          fetchError: null,
        },
      };
    case 'FETCH_TRANSACTIONS_REPORT_SUCCEEDED':
      return {
        ...state,
        transactionsReport: {
          ...state.transactionsReport,
          fetching: false,
          data: action.payload,
        },
      };
    case 'FETCH_TRANSACTIONS_REPORT_FAILED':
      return {
        ...state,
        transactionsReport: {
          ...state.transactionsReport,
          fetching: false,
          fetchError: action.payload,
        },
      };

    case 'FETCH_BUDGET_REPORT_REQUESTED':
      return {
        ...state,
        budgetReport: {
          ...state.budgetReport,
          fetching: true,
          fetchError: null,
        },
      };
    case 'FETCH_BUDGET_REPORT_SUCCEEDED':
      return {
        ...state,
        budgetReport: {
          ...state.budgetReport,
          fetching: false,
          data: action.payload,
        },
      };
    case 'FETCH_BUDGET_REPORT_FAILED':
      return {
        ...state,
        budgetReport: {
          ...state.budgetReport,
          fetching: false,
          fetchError: action.payload,
        },
      };
    default:
      return state;
  }
};

export default analyticsReducer;
