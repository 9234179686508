import ExpenditureActionTypes from '../types/expense.types';

export const setPayments = (payments) => ({
  type: ExpenditureActionTypes.SET_PAYMENTS,
  payload: payments,
});

export const removePayment = (paymentId) => ({
  type: ExpenditureActionTypes.REMOVE_PAYMENT,
  payload: paymentId,
});

export const setReversedPayments = (reversedPayments) => ({
  type: ExpenditureActionTypes.SET_REVERSED_PAYMENTS,
  payload: reversedPayments,
});
