import NotificationActionTypes from '../types/notification.types';

export const setHubConnection = (hubConnection) => ({
  type: NotificationActionTypes.SET_HUB_CONNECTION,
  payload: hubConnection,
});

export const setNotifications = (notifications) => ({
  type: NotificationActionTypes.SET_NOTIFICATIONS,
  payload: notifications,
});

export const deleteNotification = (dateGenerated) => ({
  type: NotificationActionTypes.DELETE_NOTIFICATION,
  payload: dateGenerated,
});
