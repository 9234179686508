import AcademicYearActionTypes from '../types/academic-year.types';
import { addItems, removeItem } from '../utils/common.util';
import {
  updateBudgets,
  updateBudgetItems,
  setBudgetStatus,
  removeCategoryBudgetItems,
  removeBudgetItem,
} from '../utils/academic-year.util';

// set initial state
const INITIAL_STATE = {
  academicYears: [],
  terms: [],
  budgets: [],
};

// create reducer with intial state if not state is received
const academicYearReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AcademicYearActionTypes.SET_ACADEMIC_YEARS:
      return {
        ...state,
        // academicYears: action.payload
        academicYears: addItems(state.academicYears, action.payload),
      };

    case AcademicYearActionTypes.REMOVE_ACADEMIC_YEAR:
      return {
        ...state,
        academicYears: removeItem(state.academicYears, action.payload),
      };

    case AcademicYearActionTypes.SET_TERMS:
      return {
        ...state,
        // terms: action.payload
        terms: addItems(state.terms, action.payload),
      };

    case AcademicYearActionTypes.REMOVE_TERM:
      return {
        ...state,
        // terms: action.payload
        terms: removeItem(state.terms, action.payload),
      };
    case AcademicYearActionTypes.SET_BUDGET:
      return {
        ...state,
        // budgets: action.payload
        budgets: updateBudgets(state.budgets, action.payload),
      };
    case AcademicYearActionTypes.SET_BUDGETS:
      return {
        ...state,
        // budgets: action.payload
        budgets: addItems(state.budgets, action.payload),
      };
    case AcademicYearActionTypes.SET_BUDGET_ITEM:
      return {
        ...state,
        // budgets: action.payload
        budgets: updateBudgetItems(state.budgets, action.payload),
      };
    case AcademicYearActionTypes.SET_BUDGET_STATUS:
      return {
        ...state,
        // budgets: action.payload
        budgets: setBudgetStatus(state.budgets, action.payload),
      };
    case AcademicYearActionTypes.REMOVE_BUDGET_ITEM:
      return {
        ...state,
        // budgets: action.payload
        budgets: removeBudgetItem(
          state.budgets,
          action.payload.budgetId,
          action.payload.budgetItemId,
        ),
      };
    case AcademicYearActionTypes.REMOVE_BUDGET_ITEMS:
      return {
        ...state,
        // budgets: action.payload
        budgets: removeCategoryBudgetItems(
          state.budgets,
          action.payload.budgetId,
          action.payload.categoryId,
        ),
      };
    default:
      // no action matched, just return the state
      return state;
  }
};

// export out academicYearReducer as default
export default academicYearReducer;
