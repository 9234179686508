const AcademicYearActionTypes = {
  SET_ACADEMIC_YEARS: 'SET_ACADEMIC_YEARS',
  REMOVE_ACADEMIC_YEAR: 'REMOVE_ACADEMIC_YEAR',
  SET_TERMS: 'SET_TERMS',
  REMOVE_TERM: 'REMOVE_TERM',
  SET_BUDGET: 'SET_BUDGET',
  SET_BUDGETS: 'SET_BUDGETS',
  SET_BUDGET_ITEM: 'SET_BUDGET_ITEM',
  SET_BUDGET_STATUS: 'SET_BUDGET_STATUS',
  REMOVE_BUDGET_ITEM: 'REMOVE_BUDGET_ITEM',
  REMOVE_BUDGET_ITEMS: 'REMOVE_BUDGET_ITEMS',
};

export default AcademicYearActionTypes;
