// import types
import ClassActionTypes from '../types/class.types';
import { addItems, removeItem } from '../utils/common.util';

// set initial state
const INITIAL_STATE = {
  classes: [],
  classLabels: [],
};

// create reducer with intial state if not state is received
const classReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ClassActionTypes.SET_CLASSES:
      return {
        // spread in the state
        ...state,
        classes: addItems(state.classes, action.payload),
      };
    case ClassActionTypes.SET_CLASS_LABELS:
      return {
        ...state,
        classLabels: addItems(state.classLabels, action.payload),
      };
    case ClassActionTypes.REMOVE_CLASS:
      return {
        ...state,
        classes: removeItem(state.classes, action.payload),
      };
    case ClassActionTypes.REMOVE_CLASS_LABEL:
      return {
        ...state,
        classLabels: removeItem(state.classLabels, action.payload),
      };
    default:
      // no action matched, just return the state
      return state;
  }
};

// export out classReducer as default
export default classReducer;
