// import error messages
import ERROR_MESSAGES from './error-messages';

import { apiBaseUrl, postData, getData } from './common';

// import axios
const axios = require('axios').default;

const schoolBaseUrl = `${apiBaseUrl}school/`;

export const registerSchool = async (data) => {
  // build url
  const url = `${schoolBaseUrl}register`;

  // destructure
  const { name, location, emailAddress, firstName, lastName } = data;

  // create request data
  const requestData = {
    name,
    location,
    emailAddress,
    firstName,
    lastName,
  };

  // initiate request
  try {
    let response = await axios.post(url, requestData);
    return {
      success: true,
      user: response.data.user,
      message: response.data.message,
    };
  } catch (error) {
    if (error.response) {
      if (error.response.status === 409) {
        return { success: false, message: error.response.data.message };
      }

      if (error.response.status === 400) {
        return {
          success: false,
          message: ERROR_MESSAGES.REQUIRED_INFO_MISSING,
        };
      }

      return { success: false, message: ERROR_MESSAGES.UNKNOWN_SERVER_ERROR };
    }

    // could not contact server or could not receive a response
    return { success: false, message: ERROR_MESSAGES.UNKNOWN_ERROR };
  }
};

export const confirmActivation = async (email, code, password, pathName) => {
  // build url
  const url =
    pathName === '/account/reset-password/'
      ? apiBaseUrl + 'account/confirm-password-reset'
      : schoolBaseUrl + 'confirm-activation';

  // create request data
  const requestData = { email, code, password };

  // initiate request
  try {
    let response = await axios.post(url, requestData);
    return {
      success: response.data.success,
      message: response.data.message,
    };
  } catch (error) {
    if (error.response) {
      if (error.response.status === 400) {
        return {
          success: false,
          message: ERROR_MESSAGES.REQUIRED_INFO_MISSING,
        };
      }

      return { success: false, message: ERROR_MESSAGES.UNKNOWN_SERVER_ERROR };
    }

    // could not contact server or could not receive a response
    return { success: false, message: ERROR_MESSAGES.UNKNOWN_ERROR };
  }
};

export const updateProfileInfo = async (authToken, data) => {
  const response = await postData('school/update-profile', data, authToken);

  if (response.success) {
    return {
      success: true,
      user: response.data.user,
    };
  }

  return response;
};

export const updateRegistrarInfo = async (authToken, data) => {
  const response = await postData(
    'school/update-registrar-info',
    data,
    authToken,
  );

  if (response.success) {
    return {
      success: true,
      user: response.data.user,
    };
  }

  return response;
};

export const getUsers = async (authToken) => {
  const response = await getData('school/users', authToken);

  if (response.success) {
    return { success: true, users: response.data.users };
  }

  return response;
};

export const createNewUser = async (authToken, data) => {
  const response = await postData('account/new-user', data, authToken);

  if (response.success) {
    return {
      success: true,
      user: response.data.user,
    };
  }

  return response;
};

export const getDepartments = async (authToken) => {
  const response = await getData('school/departments', authToken);

  if (response.success) {
    return { success: true, departments: response.data.departments };
  }

  return response;
};

export const createNewDepartment = async (authToken, data) => {
  const response = await postData('school/department/new', data, authToken);

  if (response.success) {
    return {
      success: true,
      department: response.data.department,
    };
  }

  return response;
};

export const deleteDepartment = async (authToken, data) => {
  return await postData('school/department/delete', data, authToken);
};

export const uploadSchoolLogo = async (authToken, data) => {
  const response = await postData('school/upload-photo', data, authToken);

  if (response.success)
    return { success: true, student: response.data.student };

  return response;
};
