import React from 'react';
import {
  TextInput,
  Form,
  Modal,
  Loading,
  SelectItem,
  Select,
  Checkbox,
} from 'carbon-components-react';
import CustomButton from '../../custom-button/custom-btn.component';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

// import our redux stuffs
import { selectCurrentUser } from '../../../redux/selectors/user.selectors';

// import api stuffs
import { getLedgerAccounts } from '../../../api/chart-of-accounts.service';
import { newDeduction } from '../../../api/payroll.service';

import './create-deduction.styles.scss';

class CreateDeductionModal extends React.Component {
  constructor(props) {
    super(props);

    // set state
    this.state = {
      id: 0,
      loading: false,
      btnText: 'CREATE',
      errorMessage: null,
      openModal: props.openModal,
      amount: '',
      description: '',
      ledgerAccount: '',
      periodic: false,
      ledgerAccounts: [],
      disableSelect: false,
    };
  }

  componentDidMount() {
    // fetch ledger accounts
    if (this.state.ledgerAccounts.length === 0) {
      getLedgerAccounts(this.props.currentUser.authToken, 0).then(
        (response) => {
          if (response.success)
            this.setState({
              ledgerAccounts: response.ledgerAccounts.filter(
                (exp) =>
                  exp.groupDescription === 'Short Term Liabilities' &&
                  exp.categoryDescription === 'Accounts Payable',
              ),
            });
        },
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.deduction !== prevProps.deduction) {
      if (this.props.deduction) {
        this.setState({
          id: this.props.deduction.id,
          btnText: 'EDIT',
          description: this.props.deduction.description,
          ledgerAccount: this.props.deduction.ledgerAccount
            ? this.props.deduction.ledgerAccount.id.toString()
            : '',
          amount: this.props.deduction.defaultAmount.toString(),
          periodic: this.props.deduction.periodic,
          disableSelect: true,
        });
      } else {
        this.setState({
          id: 0,
          loading: false,
          btnText: 'CREATE',
          description: '',
          ledgerAccount: '',
          amount: '',
          periodic: false,
          disableSelect: false,
        });
      }
    }
  }

  createDeduction = async (event) => {
    event.preventDefault();

    this.setState({ loading: true, btnText: null, errorMessage: null });

    const data = {
      id: this.state.id,
      description: this.state.description,
      defaultAmount: parseFloat(this.state.amount),
      ledgerAccount:
        this.state.ledgerAccount.length > 0
          ? this.state.ledgerAccounts.find(
              (ledger) => ledger.id == this.state.ledgerAccount,
            )
          : undefined,
      periodic: this.state.periodic,
    };

    const respone = await newDeduction(this.props.currentUser.authToken, data);

    if (!respone.success) {
      this.setState({
        loading: false,
        errorMessage: respone.message,
        btnText: this.state.id === 0 ? 'CREATE' : 'EDIT',
      });

      return;
    }

    // all good, update state
    this.setState({
      loading: false,
      id: 0,
      btnText: 'CREATE',
      description: '',
      ledgerAccount: '',
      amount: '',
      periodic: false,
    });

    if (this.props.refreshPage) {
      this.props.refreshPage();
    }

    // close dialog
    this.props.cancelAction();
  };

  render() {
    return (
      <Modal
        id="create-deduction-modal"
        className="create-deduction-modal"
        hasScrollingContent
        passiveModal={true}
        open={this.props.openModal}
        modalHeading={
          this.state.id === 0 ? 'Create New Deduction' : 'Edit Deduction'
        }
        primaryButtonDisabled={false}>
        <div>
          <Form action="post">
            <div className="bx--row">
              <div className="bx--col-md-8 mt-10">
                <div className="f-width">
                  <TextInput
                    labelText="Description"
                    value={this.state.description}
                    placeholder="Add a description"
                    onChange={(e) =>
                      this.setState({ description: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="bx--col-md-8 mt-30">
                <Select
                  id="ledger"
                  labelText="Payables Ledger Account"
                  onChange={(e) => {
                    this.setState({ ledgerAccount: e.target.value });
                  }}
                  disabled={this.state.disableSelect}>
                  <SelectItem
                    selected={this.state.ledgerAccount.length === 0}
                    disabled
                    text="Select Account Payable"
                    value=""
                  />
                  {this.state.ledgerAccounts.map((ledger) => (
                    <SelectItem
                      selected={ledger.id == this.state.ledgerAccount}
                      text={ledger.description}
                      value={ledger.id}
                    />
                  ))}
                </Select>
              </div>

              <div className="bx--col-md-8 mt-30">
                <TextInput
                  labelText="Enter Amount"
                  placeholder="0.00"
                  type="number"
                  value={this.state.amount}
                  onChange={(e) => this.setState({ amount: e.target.value })}
                />
              </div>

              <div className="bx--col-md-4 mt-30">
                <Checkbox
                  id="periodicCheckbox"
                  labelText="Periodic"
                  checked={this.state.periodic}
                  onChange={(value) => this.setState({ periodic: value })}
                />
              </div>
            </div>

            <div className="bx--row mt-30">
              <div className="bx--col-md-8">
                <CustomButton
                  classes="primary f-width font-bold"
                  disabled={
                    this.state.loading ||
                    this.state.description.length === 0 ||
                    this.state.ledgerAccount.length === 0 ||
                    this.state.amount.length === 0
                  }
                  onClick={this.createDeduction}>
                  {this.state.btnText}

                  <div hidden={this.state.btnText != null}>
                    <div className="d-flex-center">
                      <Loading
                        withOverlay={false}
                        small={true}
                        description="Creating..."
                      />
                    </div>
                  </div>
                </CustomButton>
              </div>
              <div className="bx--col-md-8 mt-20">
                <CustomButton
                  classes="outline danger f-width font-bold"
                  disabled={this.state.loading}
                  onClick={(e) => this.props.cancelAction()}>
                  CANCEL
                </CustomButton>

                <p
                  className={`text-14 mt-10 text-danger`}
                  hidden={this.state.errorMessage === null}>
                  {this.state.errorMessage}
                </p>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    );
  }
}

// this will provide us with the state values we need
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(CreateDeductionModal);
