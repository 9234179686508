import React from 'react';
import { Modal } from 'carbon-components-react';
import CustomButton from '../../custom-button/custom-btn.component';
import { Printer } from 'react-feather';
import SubFooter from '../../footer/sub-footer/sub-footer.component';

import './budget-report.styles.scss';

/* Usage Analytics module/budget report */

const BudgetReportPrintOutModal = ({
  openModal,
  data,
  academicYear,
  school,
  closeAction,
}) => {
  return (
    <Modal
      id="budget-report-modal"
      className="budget-report-modal"
      hasScrollingContent
      passiveModal={true}
      open={openModal}
      primaryButtonDisabled={false}>
      <div style={{ pageBreakAfter: 'always' }}>
        <div className="mt-15 bx--col--auto mb-10">
          <div
            className="school-logo center"
            style={{ backgroundImage: `url(${school.logoUrl})` }}></div>
          <h3 className="h-24 text-primary text-center">{school.name}</h3>
          <p className="text-14 text-grey-dark text-center">
            {school.location}
          </p>

          <p className="text-center text-16 text-primary font-bold mt-20 text-uppercase">
            BUDGET REPORT : {academicYear ? academicYear.description : ''}
          </p>
        </div>

        {data && (
          <div>
            <div>
              <table className="bx--data-table table-transparent mt-10">
                <thead>
                  <tr className="table-row-bg-primary">
                    <th>
                      <span className="text-20 font-bold">Revenue</span>
                    </th>
                    <th>Budgeted Amount</th>
                    <th>Period Actual</th>
                    <th>Variance</th>
                    <th>Percentage Met</th>
                  </tr>
                </thead>
              </table>

              {data.revenues.data.map((category) => (
                <table className="bx--data-table table-transparent mt-10">
                  <thead>
                    <tr>
                      <th colSpan="5">
                        <span className="text-20 text-dark font-bold">
                          {category.description}
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {category.accounts.map((account) => (
                      <tr>
                        <td>{account.ledgerAccountDescription}</td>
                        <td>{account.totalBudgetAmount}</td>
                        <td>{account.periodActual}</td>
                        <td>{account.variance}</td>
                        {Math.round(
                          (account.periodActual / account.totalBudgetAmount) *
                            100,
                        )}
                        %
                      </tr>
                    ))}
                  </tbody>
                </table>
              ))}

              <table className="bx--data-table budget-expandable mt-10">
                <tbody>
                  <tr className="total-expense">
                    <td className="text-16">Total</td>
                    <td>{data.revenues.totalBudgetAmount}</td>
                    <td>{data.revenues.totalPeriodActual}</td>
                    <td>{data.revenues.totalVariance}</td>
                    <td colSpan="2">
                      {Math.round(
                        (data.revenues.totalPeriodActual /
                          data.revenues.totalBudgetAmount) *
                          100,
                      )}
                      %
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* revenue table */}
            <div className="mt-20">
              <table className="bx--data-table table-transparent mt-10">
                <thead>
                  <tr className="table-row-bg-primary">
                    <th>
                      <span className="text-20 font-bold">Expenses</span>
                    </th>
                    <th>Budgeted Amount</th>
                    <th>Period Actual</th>
                    <th>Variance</th>
                    <th>Percentage Used</th>
                  </tr>
                </thead>
              </table>

              {data.expenses.data.map((category) => (
                <table className="bx--data-table table-transparent mt-10">
                  <thead>
                    <tr>
                      <th colSpan="5">
                        <span className="text-20 text-dark font-bold">
                          {category.description}
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {category.accounts.map((account) => (
                      <tr>
                        <td>{account.ledgerAccountDescription}</td>
                        <td>{account.totalBudgetAmount}</td>
                        <td>{account.periodActual}</td>
                        <td>{account.variance}</td>
                        {Math.round(
                          (account.periodActual / account.totalBudgetAmount) *
                            100,
                        )}
                        %
                      </tr>
                    ))}
                  </tbody>
                </table>
              ))}

              <table className="bx--data-table budget-expandable mt-10">
                <tbody>
                  <tr className="total-expense">
                    <td className="text-16">Total</td>
                    <td>{data.expenses.totalBudgetAmount}</td>
                    <td>{data.expenses.totalPeriodActual}</td>
                    <td>{data.expenses.totalVariance}</td>
                    <td colSpan="2">
                      {Math.round(
                        (data.expenses.totalPeriodActual /
                          data.expenses.totalBudgetAmount) *
                          100,
                      )}
                      %
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="mt-20">
              <table className="bx--data-table table-transparent mt-10">
                <thead>
                  <tr className="table-row-bg-primary">
                    <th>
                      <span className="text-20 font-bold">Fixed Assets</span>
                    </th>
                    <th>Budgeted Amount</th>
                    <th>Period Actual</th>
                    <th>Variance</th>
                    <th>Percentage Used</th>
                  </tr>
                </thead>
              </table>

              {data.fixedAssets.data.map((category) => (
                <table className="bx--data-table table-transparent mt-10">
                  <thead>
                    <tr>
                      <th colSpan="5">
                        <span className="text-20 text-dark font-bold">
                          {category.description}
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {category.accounts.map((account) => (
                      <tr>
                        <td>{account.ledgerAccountDescription}</td>
                        <td>{account.totalBudgetAmount}</td>
                        <td>{account.periodActual}</td>
                        <td>{account.variance}</td>
                        {Math.round(
                          (account.periodActual / account.totalBudgetAmount) *
                            100,
                        )}
                        %
                      </tr>
                    ))}
                  </tbody>
                </table>
              ))}

              <table className="bx--data-table budget-expandable mt-10">
                <tbody>
                  <tr className="total-expense">
                    <td className="text-16">Total</td>
                    <td>{data.fixedAssets.totalBudgetAmount}</td>
                    <td>{data.fixedAssets.totalPeriodActual}</td>
                    <td>{data.fixedAssets.totalVariance}</td>
                    <td colSpan="2">
                      {Math.round(
                        (data.fixedAssets.totalPeriodActual /
                          data.fixedAssets.totalBudgetAmount) *
                          100,
                      )}
                      %
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="mt-20 p-l-r-61">
              <SubFooter />
            </div>

            <div className="bx--row mt-35">
              <div className="bx--col-lg-8"></div>

              <div className="bx--col-lg-2">
                <CustomButton
                  classes="secondary  f-width font-bold"
                  onClick={closeAction}>
                  CLOSE
                </CustomButton>
              </div>
              <div className="bx--col-lg-2">
                <CustomButton
                  classes="primary f-width font-bold"
                  onClick={() => window.print()}>
                  <Printer size="11" strokeWidth="5px" /> &nbsp; PRINT
                </CustomButton>
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default BudgetReportPrintOutModal;
