import { call, put, all, takeLatest } from 'redux-saga/effects';
import { fetchData } from '../../api/api';
import ENDPOINTS, {
  createBalanceSheetReportFetchEndpoint,
  createIncomeStatementReportFetchEndpoint,
  createTrialBalanceReportFetchEndpoint,
  createExpenseReportFetchEndpoint,
  createRevenueReportFetchEndpoint,
  createTransactionsReportFetchEndpoint,
  createBudgetReportFetchEndpoint,
} from '../../api/endpoints';

function* fetchStudentCount(action) {
  const response = yield call(
    fetchData,
    `${ENDPOINTS.STUDENT}count`,
    action.payload.authToken,
  );

  yield onResponse(
    response,
    'FETCH_STUDENT_COUNT_SUCCEEDED',
    'FETCH_STUDENT_COUNT_FAILED',
  );
}

function* fetchEmployeeCount(action) {
  const response = yield call(
    fetchData,
    `${ENDPOINTS.EMPLOYEE}count`,
    action.payload.authToken,
  );

  yield onResponse(
    response,
    'FETCH_EMPLOYEE_COUNT_SUCCEEDED',
    'FETCH_EMPLOYEE_COUNT_FAILED',
  );
}

function* fetchBalanceSheetReport(action) {
  const response = yield call(
    fetchData,
    createBalanceSheetReportFetchEndpoint(action.payload.dateTo),
    action.payload.authToken,
  );

  yield onResponse(
    response,
    'FETCH_BALANCE_SHEET_REPORT_SUCCEEDED',
    'FETCH_BALANCE_SHEET_REPORT_FAILED',
  );
}

function* fetchIncomeStatementReport(action) {
  const response = yield call(
    fetchData,
    createIncomeStatementReportFetchEndpoint(
      action.payload.dateFrom,
      action.payload.dateTo,
    ),
    action.payload.authToken,
  );

  yield onResponse(
    response,
    'FETCH_INCOME_STATEMENT_REPORT_SUCCEEDED',
    'FETCH_INCOME_STATEMENT_REPORT_FAILED',
  );
}

function* fetchTrialBalanceReport(action) {
  const response = yield call(
    fetchData,
    createTrialBalanceReportFetchEndpoint(
      action.payload.dateFrom,
      action.payload.dateTo,
    ),
    action.payload.authToken,
  );

  yield onResponse(
    response,
    'FETCH_TRIAL_BALANCE_REPORT_SUCCEEDED',
    'FETCH_TRIAL_BALANCE_REPORT_FAILED',
  );
}

function* fetchExpenseReport(action) {
  const response = yield call(
    fetchData,
    createExpenseReportFetchEndpoint(
      action.payload.dateFrom,
      action.payload.dateTo,
      action.payload.ledger,
    ),
    action.payload.authToken,
  );

  yield onResponse(
    response,
    'FETCH_EXPENSES_REPORT_SUCCEEDED',
    'FETCH_EXPENSES_REPORT_FAILED',
  );
}

function* fetchRevenueReport(action) {
  const response = yield call(
    fetchData,
    createRevenueReportFetchEndpoint(
      action.payload.dateFrom,
      action.payload.dateTo,
      action.payload.ledger,
    ),
    action.payload.authToken,
  );

  yield onResponse(
    response,
    'FETCH_REVENUE_REPORT_SUCCEEDED',
    'FETCH_REVENUE_REPORT_FAILED',
  );
}

function* fetchTransactionsReport(action) {
  const response = yield call(
    fetchData,
    createTransactionsReportFetchEndpoint(
      action.payload.dateFrom,
      action.payload.dateTo,
      action.payload.ledger,
    ),
    action.payload.authToken,
  );

  yield onResponse(
    response,
    'FETCH_TRANSACTIONS_REPORT_SUCCEEDED',
    'FETCH_TRANSACTIONS_REPORT_FAILED',
  );
}

function* fetchBudgetReport(action) {
  const response = yield call(
    fetchData,
    createBudgetReportFetchEndpoint(action.payload.academicYearId),
    action.payload.authToken,
  );

  yield onResponse(
    response,
    'FETCH_BUDGET_REPORT_SUCCEEDED',
    'FETCH_BUDGET_REPORT_FAILED',
  );
}

function* onResponse(response, actionTypeSuccess, actionTypeFailed) {
  if (response.success) {
    yield put({
      type: actionTypeSuccess,
      payload: response.data,
    });
  } else {
    yield put({
      type: actionTypeFailed,
      payload: response.message,
    });
  }
}

function* onFetchStudentCount() {
  yield takeLatest('FETCH_STUDENT_COUNT_REQUESTED', fetchStudentCount);
}

function* onFetchEmployeeCount() {
  yield takeLatest('FETCH_EMPLOYEE_COUNT_REQUESTED', fetchEmployeeCount);
}

function* onFetchBalanceSheetReport() {
  yield takeLatest(
    'FETCH_BALANCE_SHEET_REPORT_REQUESTED',
    fetchBalanceSheetReport,
  );
}

function* onFetchIncomeStatementReport() {
  yield takeLatest(
    'FETCH_INCOME_STATEMENT_REPORT_REQUESTED',
    fetchIncomeStatementReport,
  );
}

function* onFetchTrialBalanceReport() {
  yield takeLatest(
    'FETCH_TRIAL_BALANCE_REPORT_REQUESTED',
    fetchTrialBalanceReport,
  );
}

function* onFetchExpenseReport() {
  yield takeLatest('FETCH_EXPENSES_REPORT_REQUESTED', fetchExpenseReport);
}

function* onFetchRevenueReport() {
  yield takeLatest('FETCH_REVENUE_REPORT_REQUESTED', fetchRevenueReport);
}

function* onFetchTransactionsReport() {
  yield takeLatest(
    'FETCH_TRANSACTIONS_REPORT_REQUESTED',
    fetchTransactionsReport,
  );
}

function* onFetchBudgetReport() {
  yield takeLatest('FETCH_BUDGET_REPORT_REQUESTED', fetchBudgetReport);
}

export default function* analyticsSagas() {
  yield all([
    call(onFetchStudentCount),
    call(onFetchEmployeeCount),
    call(onFetchBalanceSheetReport),
    call(onFetchIncomeStatementReport),
    call(onFetchTrialBalanceReport),
    call(onFetchExpenseReport),
    call(onFetchRevenueReport),
    call(onFetchTransactionsReport),
    call(onFetchBudgetReport),
  ]);
}
