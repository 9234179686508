import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { SWRConfig } from 'swr';
import { SWRFetcher } from './api/api';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectCurrentUser } from './redux/selectors/user.selectors';
import { selectHubConnection } from './redux/selectors/notification.selectors';
import { setHubConnection } from './redux/actions/notification.actions';
import { authenticate, logoutUser } from './redux/user/user.actions';

import ErrorBoundary from './components/error-boundary/error-boundary.component';
import NotFoundPage from './pages/not-found/not-found.page';
import DashboardPage from './pages/dashboard/dashboard.page';
import LoginPage from './pages/account/login/login.page';
import RegisterPage from './pages/account/register/register.page';
import AcademicYearPage from './pages/academic-year/academic-year.page';
import LedgerPage from './pages/ledger/ledger.page';
import BudgetPage from './pages/budget/budget.page';
import StudentPage from './pages/student/student.page';
import ClassesPage from './pages/classes/classes.page';
import ClasseslabelPage from './pages/classes/labels/labels.page';
import NotificationsPage from './pages/settings/notifications/notifications.page';
import EmployeePage from './pages/employee/employee.page';
import BillingPage from './pages/billing/billing.page';
import AccountVerificationPage from './pages/account/account-verification/account-verification.page';
import ForgotPasswordPage from './pages/account/forgot-password/forgot-password.page';
import ActivateSchoolPage from './pages/account/activate-school/activate-school.page';
import RevenuePage from './pages/revenue/revenue.page';
import PayrollPage from './pages/payroll/payroll.page';
import AnalyticsPage from './pages/analytics/analytics.page';
import BalanceSheetPage from './pages/analytics/balance-sheet/balance-sheet.page';
import IncomeStatementPage from './pages/analytics/income-statement/income-statement.page';
import TrialBalancePage from './pages/analytics/trial-balance/trial-balance.page';
import CashReceivedPage from './pages/analytics/cash-received/cash-received.page';
import CashSpentPage from './pages/analytics/cash-spent/cash-spent.page';
import BudgetReportPage from './pages/analytics/budget-report/budget-report.page';
import TransactionsReportPage from './pages/analytics/transactions-report/transactions-report.page';

import './app.scss';

import SuspenseLoader from './components/suspense-loader/loader.component';

//lazy loading pages, this is done to improve performance
const AcademicYearDetailsPage = lazy(
  () => import('./pages/academic-year/details/details.page'),
);
const BudgetDetailsPage = lazy(
  () => import('./pages/budget/details/budget-details.page'),
);
const AddBudgetPage = lazy(
  () => import('./pages/academic-year/budget/budget.page'),
);
const StudentInformationPage = lazy(
  () => import('./pages/student/information/student-information.page'),
);
const StudentBillsPage = lazy(
  () => import('./pages/student/bills/student-bills.page'),
);
const StudentPaymentsPage = lazy(
  () => import('./pages/student/payments/student-payments.page'),
);
const StudentsAttendancePage = lazy(
  () => import('./pages/academic/students-attendance/students-attendance.page'),
);
const AddAttendancePage = lazy(
  () => import('./pages/academic/students-attendance/add/add-attendance.page'),
);
const BillStudentsPage = lazy(
  () => import('./pages/billing/bill-students/bill-students.page'),
);
const BillDetailsPage = lazy(
  () => import('./pages/billing/details/bill-details.page'),
);
const ClassDetailPage = lazy(
  () => import('./pages/classes/information/classes-information.page'),
);
const DepartmentsPage = lazy(
  () => import('./pages/settings/departments/departments.page'),
);
const UsersPage = lazy(() => import('./pages/settings/users/users.page'));
const RolesPage = lazy(() => import('./pages/settings/roles/roles.page'));
const EmployeeInformationPage = lazy(
  () => import('./pages/employee/information/employee-information.page'),
);
const TransactionPage = lazy(
  () => import('./pages/transaction/transaction.page'),
);
const OtherPaymentsPage = lazy(
  () => import('./pages/revenue/other-payments/other-payments.page'),
);
const CashTransferPage = lazy(
  () => import('./pages/revenue/cash-transfer/cash-transfer.page'),
);
const NewCashTransferPage = lazy(
  () => import('./pages/revenue/cash-transfer/new/new-transfer.page'),
);
const RevisedPaymentsPage = lazy(
  () => import('./pages/revenue/revised-payments/revised-payments.components'),
);
const ReversedTransactionsPage = lazy(
  () =>
    import('./pages/revenue/reversed-transactions/reversed-transactions.page'),
);

const SuppliersPaymentPage = lazy(
  () => import('./pages/suppliers/payments/payments.page'),
);
const ReversedPurchasesPage = lazy(
  () => import('./pages/suppliers/purchases/reversed/reversed-purchases.page'),
);
const SupplierInformationPage = lazy(
  () => import('./pages/suppliers/information/supplier-information.page'),
);
const SuppliersPage = lazy(() => import('./pages/suppliers/suppliers.page'));
const PurchasesPage = lazy(
  () => import('./pages/suppliers/purchases/purchases.page'),
);
const ExpensesPage = lazy(() => import('./pages/expense/expenses.page'));

const ReversedPaymentsPage = lazy(
  () => import('./pages/expense/reversed/reversed-payments.page'),
);
const FixedAssetPage = lazy(
  () => import('./pages/fixed-asset/fixed-asset.page'),
);
const PayrollDetailsPage = lazy(
  () => import('./pages/payroll/details/payroll-details.page'),
);
const PayrollAllowancePage = lazy(
  () => import('./pages/payroll/allowances/allowances.page'),
);
const PayrollDeductionPage = lazy(
  () => import('./pages/payroll/deductions/deductions.page'),
);
const ReviewSalaryPage = lazy(
  () => import('./pages/payroll/review-salary/review.page'),
);
const SettingsPage = lazy(() => import('./pages/settings/settings.page'));
const MessagingPage = lazy(() => import('./pages/messaging/messaging.page'));
const StudentStatementPage = lazy(
  () => import('./pages/student-statement/student-statement.page'),
);
//Epobs Admin
const AdminDashboardPage = lazy(() => import('./pages/admin/admin.page'));

//parent portal
const ParentLoginPage = lazy(() => import('./pages/parent/login/login.page'));
const ParentPortalRegisterPage = lazy(
  () => import('./pages/parent/register/register.page'),
);
const ParentPortalPage = lazy(
  () => import('./pages/parent/parent-portal.page'),
);
const WardInformationPage = lazy(
  () => import('./pages/parent/ward-information/ward-information.page'),
);
const WardPaymentsPage = lazy(
  () => import('./pages/parent/ward-payments/ward-payments.page'),
);
const WardBillsPage = lazy(
  () => import('./pages/parent/bills/ward-bills.page'),
);
const WardReportsPage = lazy(
  () => import('./pages/parent/reports/ward-reports.page'),
);

//docs
const UserDocumentationPage = lazy(
  () => import('./pages/docs/user-documentation/user-documentation.page'),
);

//Academic
const AttendancePage = lazy(
  () => import('./pages/academic/attendance/attendance.page'),
);
const SubjectPage = lazy(() => import('./pages/academic/subject/subject.page'));

const GradingPage = lazy(() => import('./pages/academic/grading/grading.page'));
const GradingSubjectsPage = lazy(
  () => import('./pages/academic/grading/records/records.page'),
);
const RecordMarksPage = lazy(
  () => import('./pages/academic/grading/record-marks/record-marks.page'),
);
const ViewReportPage = lazy(
  () => import('./pages/academic/grading/view-report/view-report.page'),
);
const ReportsPage = lazy(
  () => import('./pages/academic/grading/view-reports/view-reports.page'),
);
const SchoolWalletPage = lazy(
  () => import('./pages/wallet/school/school-wallet.page'),
);
const ChartOfAccountsPage = lazy(
  () => import('./pages/analytics/chart-of-accounts/chart-of-accounts.page'),
);
const LedgerAccountsReportPage = lazy(
  () =>
    import(
      './pages/analytics/ledger-account-report/ledger-account-report.page'
    ),
);
const AdminWalletPage = lazy(
  () => import('./pages/wallet/admin/admin-wallet.page'),
);
const JournalPage = lazy(() => import('./pages/journal/journal.page'));
const NewJournalPage = lazy(
  () => import('./pages/journal/new-journal/new-journal.page'),
);
const DebtorsListPage = lazy(
  () => import('./pages/analytics/debtors-list/debtors-list.page'),
);
const GradingScalePage = lazy(
  () => import('./pages/academic/grading/grading-scale/grading-scale.page'),
);
const NewGradingScalePage = lazy(
  () =>
    import('./pages/academic/grading/new-grading-scale/new-grading-scale.page'),
);
const BirthdaysPage = lazy(() => import('./pages/birthdays/birthdays.page'));
const PayrollReportsPage = lazy(
  () => import('./pages/payroll/reports/reports.page'),
);
const UnBilledStudentsReportPage = lazy(
  () =>
    import(
      './pages/analytics/unbilled-students-report/unbilled-students-report.page'
    ),
);
const BilledStudentsReportPage = lazy(
  () =>
    import(
      './pages/analytics/billed-students-report/billed-students-report.page'
    ),
);

const PreSchoolGrading = lazy(
  () => import('./pages/pre-school/grading/grading.page'),
);
const PreSchoolGradingScalePage = lazy(
  () => import('./pages/pre-school/grading-scale/grading-scale.page'),
);
const PreSchoolPersonalityReports = lazy(
  () =>
    import('./pages/pre-school/personality-reports/personality-reports.page'),
);
const PreSchoolRecordPersonalityPage = lazy(
  () => import('./pages/pre-school/record-personality/record-personality.page'),
);

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      refreshPage: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentUser !== prevProps.currentUser) {
      this.setState({ refreshPage: true });
    }
  }

  render() {
    return (
      <SWRConfig
        value={{
          refreshInterval: 2000000, //33.3 minutes
          revalidateOnFocus: false,
          errorRetryCount: 3,
          errorRetryInterval: 10000,
          fetcher: SWRFetcher(this.props.currentUser?.authToken),
          onError: (error, key) => {
            if (error?.message == 'Request failed with status code 401') {
              logoutUser();
            }
          },
        }}>
        <div>
          <ErrorBoundary>
            <Switch>
              <Route
                exact
                path="/"
                render={() =>
                  this.props.currentUser &&
                  this.props.currentUser.school &&
                  this.props.currentUser.school.name.toUpperCase() ===
                    'TESTING EPOBS' ? (
                    <Redirect to="/academic/grading" />
                  ) : this.props.currentUser &&
                    this.props.currentUser.school ? (
                    <DashboardPage />
                  ) : (
                    <Redirect to="/account/login" />
                  )
                }
              />
              <Route
                exact
                path="/account/login"
                render={() =>
                  this.props.currentUser && this.props.currentUser.school ? (
                    <Redirect to="/" />
                  ) : this.props.currentUser &&
                    this.props.currentUser.parent ? (
                    <Redirect to="/parent-portal" />
                  ) : (
                    <LoginPage />
                  )
                }
              />
              <Route
                exact
                path="/account/verify"
                component={AccountVerificationPage}
              />

              <Route
                exact
                path="/account/forgot-password"
                component={ForgotPasswordPage}
              />

              <Route
                exact
                path="/account/reset-password"
                component={ActivateSchoolPage}
              />

              <Route
                exact
                path="/school/activate"
                component={ActivateSchoolPage}
              />

              <Route exact path="/school/register" component={RegisterPage} />

              <Suspense fallback={<SuspenseLoader />}>
                <Route
                  exact
                  path="/academic-year"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <AcademicYearPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/academic-year/:academicYearId"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <AcademicYearDetailsPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/chart-of-accounts"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <LedgerPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/academic-year/:academicYearId/budget"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <AddBudgetPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/budget"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <BudgetPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/budget/:budgetId"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <BudgetDetailsPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/student"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <StudentPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/student/:studentId"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <StudentInformationPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/students/attendance"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <StudentsAttendancePage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/students/attendance/add"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <AddAttendancePage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/student/:studentId/bills"
                  component={StudentBillsPage}
                />
                <Route
                  exact
                  path="/student/:studentId/payments"
                  component={StudentPaymentsPage}
                />
                <Route
                  exact
                  path="/class"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <ClassesPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/class/:classId"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <ClassDetailPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/class-label"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <ClasseslabelPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/settings/users"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <UsersPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/settings/departments"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <DepartmentsPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route exact path="/settings/roles" component={RolesPage} />

                <Route
                  exact
                  path="/settings/notifications"
                  component={NotificationsPage}
                />
                <Route
                  exact
                  path="/employees"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <EmployeePage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/employees/:employeeId"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <EmployeeInformationPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/billing"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <BillingPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/billing/bill-students/:classId/:academicYearId/:termId"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <BillStudentsPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/billing/edit/:billingId"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <BillStudentsPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/billing/:billId"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <BillDetailsPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/payments-received"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <RevenuePage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/payments-received/other"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <OtherPaymentsPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/payments-received/transfer"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <CashTransferPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/payments-received/transfer/new"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <NewCashTransferPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/payments-received/reversed"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <ReversedTransactionsPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/payments-received/revised"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <RevisedPaymentsPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/transaction"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <TransactionPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/suppliers"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <SuppliersPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/suppliers/payments"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <SuppliersPaymentPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/suppliers/purchases"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <PurchasesPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/suppliers/purchases/reversed"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <ReversedPurchasesPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/suppliers/info/:supplierId"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <SupplierInformationPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/payments-made"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <ExpensesPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/payments-made/reversed"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <ReversedPaymentsPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/fixed-asset"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <FixedAssetPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/payroll"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <PayrollPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/payroll/details/:payrollId"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <PayrollDetailsPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/payroll/review-salary"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <ReviewSalaryPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/payroll/allowances"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <PayrollAllowancePage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/payroll/deductions"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <PayrollDeductionPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/analytics"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <AnalyticsPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/analytics/balance-sheet"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <BalanceSheetPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/analytics/income-statement"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <IncomeStatementPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/analytics/trial-balance"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <TrialBalancePage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/analytics/cash-received"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <CashReceivedPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/analytics/cash-spent"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <CashSpentPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/analytics/budget-report"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <BudgetReportPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/analytics/transactions-report"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <TransactionsReportPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/analytics/unbilled-students-report"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <UnBilledStudentsReportPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/analytics/billed-students-report"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <BilledStudentsReportPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/settings"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <SettingsPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/messaging"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <MessagingPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/analytics/student-statement"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <StudentStatementPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                {/* Epobs Admin pages */}

                <Route
                  exact
                  path="/admin"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <AdminDashboardPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                {/* Parent pages */}
                <Route
                  exact
                  path="/parent-portal/login"
                  render={() =>
                    !this.props.currentUser ? (
                      <ParentLoginPage />
                    ) : this.props.currentUser &&
                      this.props.currentUser.parent ? (
                      <Redirect to="/parent-portal" />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />

                <Route
                  exact
                  path="/parent-portal/register"
                  render={() =>
                    !this.props.currentUser ? (
                      <ParentPortalRegisterPage />
                    ) : (
                      <Redirect to="/parent-portal/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/parent-portal"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.parent ? (
                      <ParentPortalPage />
                    ) : (
                      <Redirect to="/parent-portal/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/parent-portal/ward-info"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.parent ? (
                      <WardInformationPage />
                    ) : (
                      <Redirect to="/parent-portal/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/parent-portal/ward-info/bills"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.parent ? (
                      <WardBillsPage />
                    ) : (
                      <Redirect to="/parent-portal/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/parent-portal/ward-info/payments"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.parent ? (
                      <WardPaymentsPage />
                    ) : (
                      <Redirect to="/parent-portal/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/parent-portal/ward-info/reports"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.parent ? (
                      <WardReportsPage />
                    ) : (
                      <Redirect to="/parent-portal/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/user-documentation"
                  component={UserDocumentationPage}
                />

                <Route
                  exact
                  path="/academic/subject"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <SubjectPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/academic/grading"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <GradingPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/academic/grading/:gradingId/subjects"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <GradingSubjectsPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/academic/grading/:gradingId"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <RecordMarksPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/academic/grading/:gradingId/attendance"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <AttendancePage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/academic/grading/:gradingId/reports"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <ReportsPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/academic/grading/:gradingId/reports/view"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <ViewReportPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/academic/grading-scale"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <GradingScalePage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/academic/grading-scale/:gradeScaleId"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <NewGradingScalePage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                {/* <Route
                  exact
                  path="/academic/grading-scale/:gradingScaleId"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <NewGradingScalePage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                /> */}

                <Route
                  exact
                  path="/analytics/chart-of-accounts"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <ChartOfAccountsPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/analytics/ledger-account-report"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <LedgerAccountsReportPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/analytics/debtors-list"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <DebtorsListPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/wallet"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <SchoolWalletPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/admin/wallet"
                  render={() =>
                    this.props.currentUser &&
                    this.props.currentUser.school &&
                    (this.props.currentUser.userName ===
                      'iamranchojr@gmail.com' ||
                      this.props.currentUser.userName ===
                        'epobee@epobssolutions.com') ? (
                      <AdminWalletPage />
                    ) : this.props.currentUser &&
                      this.props.currentUser.school ? (
                      <Redirect to="/" />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/journal"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <JournalPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                {/* <Route
                  exact
                  path="/journal/new"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <NewJournalPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                /> */}

                <Route
                  exact
                  path="/journal/:journalId"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <NewJournalPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/birthdays/"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <BirthdaysPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/payroll/reports"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <PayrollReportsPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/pre-school/grading"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <PreSchoolGrading />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/pre-school/grading-scale"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <PreSchoolGradingScalePage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/pre-school/grading/:gradingId/reports"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <PreSchoolPersonalityReports />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/pre-school/grading/:gradingId/record-personality"
                  render={() =>
                    this.props.currentUser && this.props.currentUser.school ? (
                      <PreSchoolRecordPersonalityPage />
                    ) : (
                      <Redirect to="/account/login" />
                    )
                  }
                />
                {/* <Route exact path="/wallet" component={WalletPage} /> */}
              </Suspense>

              <Route component={NotFoundPage} />
            </Switch>
          </ErrorBoundary>
        </div>
      </SWRConfig>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  hubConnection: selectHubConnection,
});

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logoutUser),
  authenticate: (data) => dispatch(authenticate(data)),
  setHubConnection: (hubConnection) =>
    dispatch(setHubConnection(hubConnection)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
