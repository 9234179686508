// import types
import ExpenditureActionTypes from '../types/expense.types';
import { addItems, removeItem } from '../utils/common.util';

// set initial state
const INITIAL_STATE = {
  payments: [],
  reversedPayments: [],
};

// create reducer with intial state if not state is received
const expenditureReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ExpenditureActionTypes.SET_PAYMENTS:
      return {
        // spread in the state
        ...state,
        payments: addItems(state.payments, action.payload),
      };
    case ExpenditureActionTypes.REMOVE_PAYMENT:
      return {
        // spread in the state
        ...state,
        payments: removeItem(state.payments, action.payload),
      };
    case ExpenditureActionTypes.SET_REVERSED_PAYMENTS:
      return {
        // spread in the state
        ...state,
        reversedPayments: addItems(state.reversedPayments, action.payload),
      };
    default:
      // no action matched, just return the state
      return state;
  }
};

// export out expenditureReducer as default
export default expenditureReducer;
