// using reselect
import { createSelector } from 'reselect';

// create select employee function
const selectEmployee = (state) => state.employee;

// create selectEmployees selector
export const selectAllEmployees = createSelector(
  [selectEmployee],
  (employee) => employee.employees,
);

export const selectActiveEmployees = createSelector(
  [selectEmployee],
  (employee) => employee.employees.filter((e) => e.status === 'Active'),
);

export const selectTerminatedEmployees = createSelector(
  [selectEmployee],
  (employee) => employee.employees.filter((e) => e.status === 'Terminated'),
);

// create selectDepartments selector
export const selectDepartments = createSelector(
  [selectEmployee],
  (employee) => employee.departments,
);

// HINT: Add additional selectors below
