export const updateBudgets = (existingBudgets, newBudget) => {
  // create a copy of existing terms
  const copyExistingBudgets = [...existingBudgets];

  const index = copyExistingBudgets.findIndex(
    (existingBudget) => existingBudget.id === newBudget.id,
  );

  if (index !== -1) {
    // update
    copyExistingBudgets.splice(index, 1, newBudget);
  } else {
    copyExistingBudgets.push(newBudget);
  }

  // return modified existing budgets
  return copyExistingBudgets;
};

export const updateBudgetItems = (budgets, newBudgetItem) => {
  // create a copy of budgets
  const copyBudgets = [...budgets];
  const budgetToUpdate = copyBudgets.find(
    (budget) => budget.id === newBudgetItem.budgetId,
  );

  // find budget item
  const budgetItemIndex = budgetToUpdate.budgetItems.findIndex(
    (budgetItem) => budgetItem.id === newBudgetItem.id,
  );

  if (budgetItemIndex !== -1) {
    // update
    budgetToUpdate.budgetItems.splice(budgetItemIndex, 1, newBudgetItem);
  } else {
    budgetToUpdate.budgetItems.push(newBudgetItem);
  }

  return copyBudgets;
};

export const setBudgetStatus = (budgets, budgetStatus) => {
  // create a copy of budgets
  const copyBudgets = [...budgets];
  const budgetToUpdate = copyBudgets.find(
    (budget) => budget.id === budgetStatus.budgetId,
  );

  // find budget index
  const budgetToUpdateIndex = copyBudgets.findIndex(
    (budget) => budget.id === budgetStatus.budgetId,
  );

  // it's not supposed to be undefined, but let's check either way
  if (budgetToUpdateIndex !== -1) {
    let takenByObject;

    if (budgetStatus.actionType === 'Draft') {
      takenByObject = {
        revertedBy: budgetStatus.user,
        dateReverted: budgetStatus.date,
        comment: budgetStatus.comment,
      };
    } else if (budgetStatus.actionType === 'Pending') {
      takenByObject = {
        dateSubmitted: budgetStatus.date,
        comment: budgetStatus.comment,
        dateReverted: null,
      };
    } else {
      takenByObject = {
        dateApproved: budgetStatus.date,
        approvedBy: budgetStatus.user,
        comment: budgetStatus.comment,
      };
    }

    // save update
    const newBudget = {
      ...budgetToUpdate,
      ...takenByObject,
      budgetStatus: budgetStatus.actionType,
    };

    // replace existing budget with updated one
    copyBudgets.splice(budgetToUpdateIndex, 1, newBudget);

    return copyBudgets;
  }
};

export const removeCategory = (categories, categoryId) => {
  // create a copy of categories
  const copyCategories = [...categories];

  // find category indesx
  const categoryToRemoveIndex = copyCategories.findIndex(
    (item) => item.id === categoryId,
  );

  // remove item
  copyCategories.splice(categoryToRemoveIndex, 1);

  return copyCategories;
};

export const removeCategoryAccounts = (accounts, categoryId) => {
  // first remove all it's ledger accounts

  // create a copy
  const ledgerAccounts = [...accounts];

  accounts
    .filter((item) => item.categoryId === categoryId)
    .forEach((item) => {
      // find index in copied
      const itemIndex = ledgerAccounts.findIndex(
        (account) => account.id === item.id,
      );

      // remove item
      ledgerAccounts.splice(itemIndex, 1);
    });

  return ledgerAccounts;
};

export const removeBudgetItem = (budgets, budgetId, budgetItemId) => {
  // first remove all it's ledger accounts

  // create a copy
  const items = [...budgets];

  // find budget
  const budget = items.find((b) => b.id === budgetId);

  // find budget index
  const budgetIndex = items.findIndex((b) => b.id === budgetId);

  const budgetItemIndex = budget.budgetItems.findIndex(
    (item) => item.id === budgetItemId,
  );

  // remove item
  budget.budgetItems.splice(budgetItemIndex, 1);

  // update budget
  items.splice(budgetIndex, 1, budget);

  return items;
};

export const removeCategoryBudgetItems = (budgets, budgetId, categoryId) => {
  // first remove all it's ledger accounts

  // create a copy
  const items = [...budgets];

  // find budget
  const budget = budgets.find((b) => b.id === budgetId);

  // find budget index
  const budgetIndex = items.findIndex((b) => b.id === budgetId);

  // create a copy of budget items
  const budgetItems = [...budget.budgetItems];

  budgetItems
    .filter((item) => item.ledgerCategoryId === categoryId)
    .forEach((item) => {
      const itemIndex = budget.budgetItems.findIndex(
        (budgetItem) => budgetItem.id === item.id,
      );

      // remove item
      budget.budgetItems.splice(itemIndex, 1);
    });

  // update budget
  items.splice(budgetIndex, 1, budget);

  return items;
};
