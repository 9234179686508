import { getData, postData } from './common';

// 1. GET DATA
// 2. POST DATA

// function to get all suppliers
export const getSuppliers = async (authToken) => {
  const response = await getData('supplier/list', authToken);

  if (response.success) {
    return {
      success: true,
      suppliers: response.data,
    };
  }

  return response;
};

// function to create a supplier
export const createSupplier = async (data, authToken) => {
  const response = await postData('supplier/create-new', data, authToken);
  if (response.success) {
    return {
      success: true,
      supplier: response.data.supplier,
      message: `Supplier ${data.id === 0 ? 'created' : 'updated'} successfully.`,
    };
  }

  return response;
};

// function to delete a supplier
export const deleteSupplier = async (authToken, data) => {
  return await postData('supplier/delete', data, authToken);
};

export const terminateSupplier = async (authToken, data) => {
  const response = await postData('supplier/terminate', data, authToken);

  if (response.success) return { success: true, data: response.data.supplier };

  return response;
};

export const reinstateSupplier = async (authToken, data) => {
  const response = await postData('supplier/reinstate', data, authToken);

  if (response.success) return { success: true, data: response.data.supplier };

  return response;
};
