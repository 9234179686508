import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Upload, Printer } from 'react-feather';
import fileDownload from 'js-file-download';
import {
  DatePicker,
  DatePickerInput,
  Select,
  SelectItem,
} from 'carbon-components-react';

import MainHeader from '../../../components/main-header/main-header.component';
import Mainmenu from '../../../components/menu/main-menu/main-menu.component';
import CustomButton from '../../../components/custom-button/custom-btn.component';
import Footer from '../../../components/footer/footer.component';

import MainLoaderModal from '../../../components/modal/main-loader/main-loader.component';
import CashSpentModal from '../../../components/modal/cash-spent/cash-spent.component';
import { selectCurrentUser } from '../../../redux/selectors/user.selectors';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { selectExpensesReport } from '../../../redux/analytics/analytics.selectors';
import {
  selectLedgerAccounts,
  selectExpenseLedgerAccounts,
} from '../../../redux/ledger/ledger.selectors';
import { formatCurrency, formatDate } from '../../../utils/common.util';

import './cash-spent.styles.scss';
import { fetchService } from '../../../api/common';
import { useMemo } from 'reactn';

const ExpensesReportPage = ({
  expenseReport: { fetching, fetchError, data },
  fetchExpenseReport,
  ledgerAccounts,
  expenseLedgerAccounts,
  fetchLedgerAccounts,
  currentUser: { authToken, school },
}) => {
  const [openPrintOutModal, setOpenPrintOutModal] = useState(false);
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [ledger, setLedger] = useState('-1');
  const [openLoaderModal, setOpenLoaderModal] = useState(fetching);

  const [openExportLoaderModal, setOpenExportLoaderModal] = useState(false);
  const [exportLoaderModalErrorMessage, setExportLoaderModalErrorMessage] =
    useState(null);

  const exportReport = async () => {
    setExportLoaderModalErrorMessage(null);
    setOpenExportLoaderModal(true);

    const response = await fetchService(
      `analytics/expense-report/export?dateTo=${dateTo}&dateFrom=${dateFrom}&ledgerId=${ledger}`,
      authToken,
    );

    if (response.success) {
      setOpenExportLoaderModal(false);
      fileDownload(response.data.data, `expense-report-${new Date()}.xlsx`);

      return;
    }

    setExportLoaderModalErrorMessage(response.message);
  };

  if (!ledgerAccounts.fetching && ledgerAccounts.data.length === 0) {
    fetchLedgerAccounts(authToken);
  }

  let ledgerAccount = expenseLedgerAccounts.find((i) => i.id == ledger);

  const total = () => {
    let t = 0;
    data?.forEach((item) => {
      t += item?.amount;
    });
    return t;
  };

  return (
    <div>
      <MainHeader />

      <MainLoaderModal
        openModal={openExportLoaderModal}
        description="Exporting Expenses Report"
        errorMessage={exportLoaderModalErrorMessage}
        tryAgainAction={exportReport}
        cancelAction={() => setOpenExportLoaderModal(false)}
      />

      <MainLoaderModal
        openModal={
          (openLoaderModal && fetching) || (openLoaderModal && fetchError)
        }
        loading={fetching}
        description={'Generating expenditure report...'}
        errorMessage={fetchError}
        tryAgainAction={() => {
          setOpenLoaderModal(true);
          fetchExpenseReport(dateFrom, dateTo, ledger, authToken);
        }}
        cancelAction={() => setOpenLoaderModal(false)}
      />

      <CashSpentModal
        open={openPrintOutModal}
        data={data}
        total={total()}
        dateFrom={dateFrom}
        dateTo={dateTo}
        school={school}
        closeModal={() => setOpenPrintOutModal(false)}
      />

      <span className="right">
        <Mainmenu />
      </span>

      <div className="cash-received-page page-container">
        <div>
          <p>
            <span className="text-12 font-bold text-grey">You are here</span>
            &nbsp;&nbsp;
            <Link to="/analytics" className="text-12 text-dark">
              Analytics
            </Link>
            &nbsp;<span className="text-12">&gt;</span>&nbsp;
            <span className="text-12 text-primary font-bold">
              Expense Report
            </span>
          </p>
        </div>

        <hr className="grey-hr mt-20" />

        <div className="h-24 uppercase mt-15">
          Expenses Report {ledgerAccount && `on ${ledgerAccount.description}`}{' '}
          &nbsp;:&nbsp;
          {dateFrom &&
            dateTo &&
            `${formatDate(new Date(dateFrom))} - ${formatDate(
              new Date(dateTo),
            )}`}
        </div>

        <div className="mt-20">
          <div className="bx--row mt-20">
            <div className="bx--col-md-2">
              <DatePicker
                dateFormat="Y-m-d"
                datePickerType="single"
                id="start-date-picker"
                onClose={(value) => {
                  if (value.length > 0) {
                    setDateFrom(
                      [
                        value[0].getFullYear(),
                        ('0' + (value[0].getMonth() + 1)).slice(-2),
                        ('0' + value[0].getDate()).slice(-2),
                      ].join('-'),
                    );
                  }
                }}>
                <DatePickerInput
                  className="start-date"
                  id="start-date"
                  name="startDate"
                  labelText="Start date"
                  value={dateFrom}
                  pattern="d{1,2}/d{4}"
                  placeholder="YYYY-MM-DD"
                  type="text"
                />
              </DatePicker>
            </div>
            <div className="bx--col-md-2 npl ">
              <DatePicker
                dateFormat="Y-m-d"
                datePickerType="single"
                id="end-date-picker"
                onClose={(value) => {
                  if (value.length > 0) {
                    setDateTo(
                      [
                        value[0].getFullYear(),
                        ('0' + (value[0].getMonth() + 1)).slice(-2),
                        ('0' + value[0].getDate()).slice(-2),
                      ].join('-'),
                    );
                  }
                }}>
                <DatePickerInput
                  className="end-date"
                  id="end-date"
                  name="endDate"
                  labelText="End date"
                  value={dateTo}
                  pattern="d{1,2}/d{4}"
                  placeholder="YYYY-MM-DD"
                  type="text"
                />
              </DatePicker>
            </div>

            <div className="bx--col-md-2 npr">
              <Select
                labelText="Ledger"
                onChange={(e) => setLedger(e.target.value)}>
                <SelectItem disabled selected value="" text="Ledger" />
                <SelectItem value="-1" text="All" />
                {expenseLedgerAccounts.map((account) => (
                  <SelectItem value={account.id} text={account.description} />
                ))}
              </Select>
            </div>

            <div className="bx--col-md-2">
              <CustomButton
                classes="f-width primary mt-25"
                disabled={dateFrom.length === 0 || dateTo.length === 0}
                onClick={() => {
                  setOpenLoaderModal(true);
                  fetchExpenseReport(
                    dateFrom,
                    dateTo,
                    parseInt(ledger),
                    authToken,
                  );
                }}>
                RUN REPORT
              </CustomButton>
            </div>
          </div>
        </div>

        {data && (
          <div className="mt-20">
            <table className="bx--data-table table-white">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Debit Account</th>
                  <th>Credit Account</th>
                  <th>Description</th>
                  <th>Amount</th>
                  <th>Paid To</th>
                  <th>Reversed</th>
                </tr>
              </thead>
              <tbody>
                {data.map((expense) => (
                  <tr>
                    <td>{formatDate(expense.dateCreated)}</td>
                    <td>{expense.debitAccount.description}</td>
                    <td>{expense.creditAccount.description}</td>
                    <td>{expense.description}</td>
                    <td className="font-bold">
                      {formatCurrency(expense.amount)}
                    </td>
                    <td>{expense.paidTo}</td>
                    <td>
                      {expense.dateReversed ? (
                        <span className="text-danger font-bold">
                          {`REVERSED ON ${formatDate(
                            expense.dateReversed,
                          )}`.toUpperCase()}
                        </span>
                      ) : (
                        'NO'
                      )}
                    </td>
                  </tr>
                ))}
                {data && (
                  <tr>
                    <td colSpan={4}>
                      <div className="text-primary font-bold">Total</div>
                    </td>
                    <td colSpan={3}>
                      <div className="text-primary font-bold text-20">
                        {formatCurrency(total())}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <div className="bx--row mt-35">
              <div className="bx--col-lg-2">
                <CustomButton
                  classes="secondary  f-width font-bold"
                  onClick={exportReport}>
                  <Upload size="11" strokeWidth="5px" />
                  &nbsp; EXPORT
                </CustomButton>
              </div>
              <div className="bx--col-lg-2">
                <CustomButton
                  classes="primary f-width font-bold"
                  onClick={() => setOpenPrintOutModal(true)}>
                  <Printer size="11" strokeWidth="5px" /> &nbsp; PRINT
                </CustomButton>
              </div>
              <div className="bx--col-lg-8"></div>
            </div>
          </div>
        )}
        <Footer fixed={false} showLinks={false} />
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  expenseReport: selectExpensesReport,
  ledgerAccounts: selectLedgerAccounts,
  expenseLedgerAccounts: selectExpenseLedgerAccounts,
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  fetchExpenseReport: (dateFrom, dateTo, ledger, authToken) =>
    dispatch({
      type: 'FETCH_EXPENSES_REPORT_REQUESTED',
      payload: { dateFrom, dateTo, authToken, ledger },
    }),
  fetchLedgerAccounts: (authToken) =>
    dispatch({
      type: 'FETCH_LEDGER_ACCOUNTS_REQUESTED',
      payload: { authToken },
    }),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ExpensesReportPage),
);
