const ENDPOINTS = {
  STUDENT: 'student/',
  EMPLOYEE: 'employee/',
  LEDGER: 'chart-of-accounts/',
  ANALYTICS: 'analytics/',
  BILLING: 'billing/',
  ACADEMIC_YEAR: 'academic-year/',
  EXPENDITURE: 'expenditure/',
  REVENUE: 'revenue/',
};

export const createBalanceSheetReportFetchEndpoint = (dateTo) =>
  `${ENDPOINTS.ANALYTICS}balance-sheet-report/?dateTo=${dateTo}`;

export const createIncomeStatementReportFetchEndpoint = (dateFrom, dateTo) =>
  `${ENDPOINTS.ANALYTICS}income-statement-report/?dateFrom=${dateFrom}&dateTo=${dateTo}`;

export const createTrialBalanceReportFetchEndpoint = (dateFrom, dateTo) =>
  `${ENDPOINTS.ANALYTICS}trial-balance-report/?dateFrom=${dateFrom}&dateTo=${dateTo}`;

export const createExpenseReportFetchEndpoint = (dateFrom, dateTo, ledgerId) =>
  `${ENDPOINTS.ANALYTICS}expense-report/?dateFrom=${dateFrom}&dateTo=${dateTo}&ledgerId=${ledgerId}`;

export const createRevenueReportFetchEndpoint = (dateFrom, dateTo, ledgerId) =>
  `${ENDPOINTS.ANALYTICS}revenue-report/?dateFrom=${dateFrom}&dateTo=${dateTo}&ledgerId=${ledgerId}`;

export const createTransactionsReportFetchEndpoint = (
  dateFrom,
  dateTo,
  ledgerId,
) =>
  `${ENDPOINTS.ANALYTICS}transactions-report/?dateFrom=${dateFrom}&dateTo=${dateTo}&ledgerId=${ledgerId}`;

export const createBudgetReportFetchEndpoint = (academicYearId) =>
  `${ENDPOINTS.ANALYTICS}budget-report/?academicYearId=${academicYearId}`;

export const createLedgerGroupsFetchEndpoint = () =>
  `${ENDPOINTS.LEDGER}groups`;

export const createLedgerCategoriesFetchEndpoint = () =>
  `${ENDPOINTS.LEDGER}ledger-categories`;

export const createLedgerAccountsFetchEndpoint = () =>
  `${ENDPOINTS.LEDGER}ledger-accounts`;

export const createAmountOwedEndpoint = (studentId) =>
  `${ENDPOINTS.BILLING}student-balance-due/?studentId=${studentId}`;

export const createAcademicYearFetchEndpoint = () =>
  `${ENDPOINTS.ACADEMIC_YEAR}list/`;

export default ENDPOINTS;
