import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Plus, Printer, RefreshCw, Upload } from 'react-feather';

import MainHeader from '../../components/main-header/main-header.component';
import Mainmenu from '../../components/menu/main-menu/main-menu.component';
import QuickActionCard from '../../components/card/quick-action-card/quick-action-card.component';
import ReceivePaymentModal from '../../components/modal/receive-payment/receive-payment.component';
import ReceiveOtherPaymentModal from '../../components/modal/receive-other-payment/receive-other-payment.component';
import ReceiptModal from '../../components/modal/receipt/receipt.component';
import OtherPaymentReceiptModal from '../../components/modal/other-payment-receipt/receipt.component';
import ReversePaymentModal from '../../components/modal/reverse-payment/reverse-payment.component';

import Footer from '../../components/footer/footer.component';

import {
  Search,
  Loading,
  Select,
  SelectItem,
  Pagination,
  DatePicker,
  DatePickerInput,
} from 'carbon-components-react';

//import svg icon
import { ReactComponent as Ingots } from '../../assets/images/card-icons/ingots.svg';
import { ReactComponent as DollarGreen } from '../../assets/images/card-icons/013-search 1.svg';

import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../redux/selectors/user.selectors';
import { selectPayments } from '../../redux/selectors/revenue.selectors';
import {
  setPayments,
  removePayment,
} from '../../redux/actions/revenue.actions';
import { selectClasses } from '../../redux/selectors/class.selectors';
import { setClasses } from '../../redux/actions/class.actions';
import { setStudents } from '../../redux/actions/student.actions';
import { selectEnrolledStudents } from '../../redux/selectors/student.selectors';

// import api stuffs
import { getPayments, reversePayment } from '../../api/revenue.service';
import { getClasses } from '../../api/class.service';
import { getStudents } from '../../api/student.service';

import { connect } from 'react-redux';
import { selectCurrentAssetsLedgers } from '../../redux/selectors/chart-of-accounts.selector';
import { formatDate, formatCurrency } from '../../utils/common.util';
import MainLoaderModal from '../../components/modal/main-loader/main-loader.component';
import LoaderPane from '../../components/loader-pane/loader-pane.component';

import './revenue.styles.scss';
import useSWR, { mutate } from 'swr';
import CustomButton from '../../components/custom-button/custom-btn.component';
import TemplateModal from '../../components/modal/template/template.component';

const RevenuePage = (props) => {
  const [state, setState] = useState({
    query: '',
    loading: false,
    payments: [],
    errorMessage: null,
    openReceivePaymentModal: false,
    openReceiveOtherPaymentModal: false,
    openReceiptModal: false,
    openOtherPaymentReceiptModal: false,
    selectedPayment: null,
    selectedOtherPayment: null,
    paymentToRevise: null,
    studentPaymentToRevise: null,
    paymentToReverse: null,
    studentPaymentToReverse: null,
    filter: '',
    mode: null,
    openLoaderModal: false,
    loaderModalDescription: 'Reversing payment...',
    loaderModalErrorMessage: null,
    loaderModaltryAgainAction: () => {},
    reversePaymentDescription: '',
    reversePaymentHeading: 'Reverse Payment',
    reverseAction: () => {},
    openPrintModal: false,
  });

  const [paginate, setPaginate] = useState({
    page: 1,
    pageSize: 10,
  });

  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [type, setType] = useState('');

  useEffect(() => {}, []);

  const fetchPayments = async () => {
    if (props.classes.length === 0) {
      // fetch classes
      getClasses(props.currentUser.authToken).then((response) => {
        if (response.success) {
          props.setClasses(response.classes);
        }
      });
    }

    if (props.students.length === 0) {
      // fetch students
      getStudents(props.currentUser.authToken, 0, 2000).then((response) => {
        if (response.success) {
          props.setStudents(response.students);
        }
      });
    }

    setState({ ...state, loading: true, errorMessage: null });

    let response = await getPayments(
      props.currentUser.authToken,
      0,
      700,
      false,
      false,
    );

    if (!response.success) {
      // else show error message
      setState({ ...state, loading: false, errorMessage: response.message });

      return;
    }

    setState({ ...state, loading: false, payments: response.payments });

    // all good
    //  props.setPayments(response.payments);
  };

  const { data, error, mutate, isValidating } = useSWR(
    `revenue/list/?skip=${(paginate.page - 1) * paginate.pageSize}&take=${
      paginate.pageSize
    }&query=${state.query}${
      dateFrom && dateTo ? `&todate=${dateTo}&fromdate=${dateFrom}` : ''
    }&revised=${state.filter === 'revised' ? 'true' : 'false'}&reversed=${
      state.filter === 'reversed' ? 'true' : 'false'
    }${state?.mode ? `&paymentMode=${state.mode}` : ''}${
      type ? `&type=${type}` : ''
    }`, //&filter=${state.filter}
  );

  const onReversePayment = async (payment) => {
    setState({
      ...state,
      openReversePaymentModal: false,
      openLoaderModal: true,
      loaderModalErrorMessage: null,
      loaderModalDescription: 'Reversing payment...',
    });

    // make request
    const response = await reversePayment(props.currentUser.authToken, payment);

    if (!response.success) {
      setState({
        ...state,
        loaderModalErrorMessage: response.message,
        loaderModaltryAgainAction: onReversePayment,
      });

      return;
    }

    setState({ ...state, openLoaderModal: false });
    mutate();

    props.removePayment(payment.id);

    // Send notification
    if (props.hubConnection) {
      // build object
      const notification = {
        generatedBy: props.currentUser.userId,
        title: 'Payment Received Reversed',
        message: `${props.currentUser.firstName} 
        ${props.currentUser.lastName} reversed 
        payment from ${
          payment.student
            ? `${payment.student?.fullName}`
            : payment?.receivedFrom
        }`,
        redirectUrl: `/payments-received/reversed`,
      };

      props.hubConnection
        .invoke('SendNotification', notification)
        .catch((err) => console.error(err.toString()));
    }
  };

  return (
    <div>
      <MainHeader />

      {state.openLoaderModal && (
        <MainLoaderModal
          openModal={state.openLoaderModal}
          description={state.loaderModalDescription}
          errorMessage={state.loaderModalErrorMessage}
          tryAgainAction={state.loaderModaltryAgainAction}
          cancelAction={() => setState({ ...state, openLoaderModal: false })}
        />
      )}

      {state.openReceivePaymentModal && (
        <ReceivePaymentModal
          openModal={state.openReceivePaymentModal}
          paymentToRevise={state.studentPaymentToRevise}
          showReceipt={(payment) =>
            setState({
              ...state,
              openReceivePaymentModal: false,
              openReceiptModal: true,
              selectedPayment: payment,
            })
          }
          cancelAction={() => {
            setState({ ...state, openReceivePaymentModal: false });
            mutate();
          }}
        />
      )}

      {state.openReceiveOtherPaymentModal && (
        <ReceiveOtherPaymentModal
          openModal={state.openReceiveOtherPaymentModal}
          paymentToRevise={state.paymentToRevise}
          showReceipt={(payment) =>
            setState({
              ...state,
              openReceiveOtherPaymentModal: false,
              openOtherPaymentReceiptModal: true,
              selectedOtherPayment: payment,
            })
          }
          cancelAction={() => {
            setState({ ...state, openReceiveOtherPaymentModal: false });
            mutate();
          }}
        />
      )}

      {state.openReceiptModal && (
        <ReceiptModal
          openModal={state.openReceiptModal}
          selectedPayment={state.selectedPayment}
          cancelAction={() => setState({ ...state, openReceiptModal: false })}
        />
      )}

      {state.openOtherPaymentReceiptModal && (
        <OtherPaymentReceiptModal
          openModal={state.openOtherPaymentReceiptModal}
          selectedPayment={state.selectedOtherPayment}
          cancelAction={() => {
            setState({ ...state, openOtherPaymentReceiptModal: false });
          }}
        />
      )}

      {state?.openReversePaymentModal && (
        <ReversePaymentModal
          openModal={state?.openReversePaymentModal}
          heading={state.reversePaymentHeading}
          description={state.reversePaymentDescription}
          reverseAction={state.reverseAction}
          cancelAction={() => {
            setState({ ...state, openReversePaymentModal: false });
            mutate();
          }}
        />
      )}

      {state.openPrintModal && (
        <TemplateModal
          title={`Cash Received [${dateFrom} - ${dateTo}] ${state?.filter}`}
          school={props.currentUser.school}
          topContent={''}
          content={<TemplateContent data={data} paginate={paginate} />}
          open={true}
          closeAction={() => setState({ ...state, openPrintModal: false })}
        />
      )}

      <div className="revenue page-container">
        <div>
          <span className="h-18">Quick Actions</span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <span className="right">
            <Mainmenu />
          </span>
        </div>

        <div className="mt-20">
          <div className="bx--row">
            <div className="bx--col-md-2">
              <div
                className="card-add-receive pointer"
                onClick={() => {
                  setState({
                    ...state,
                    openReceivePaymentModal: true,
                    studentPaymentToRevise: null,
                  });
                }}>
                <div className="plus-icon">
                  <Plus size="18" strokeWidth="3" />
                </div>
                <div className="text-label">
                  RECORD <br />
                  STUDENT CASH
                </div>
              </div>
            </div>

            <div className="bx--col-md-2">
              <QuickActionCard
                description="Other Cash"
                leftActionText="RECEIVE"
                hideAction2={true}
                actionEvent1={(ev) => {
                  setState({
                    ...state,
                    openReceiveOtherPaymentModal: true,
                    paymentToRevise: null,
                  });
                }}
                actionEvent2={(ev) => {
                  props.history.push(`/revenue/other-payments`);
                }}>
                <Ingots />
              </QuickActionCard>
            </div>

            {/*<div className="bx--col-md-2">
                <QuickActionCard
                  description="Transferred Fund"
                  leftActionText="NEW"
                  rightActionText="VIEW ALL"
                  actionEvent1={ev => {
                     props.history.push(`/revenue/cash-transfer/new`);
                  }}
                  actionEvent2={ev => {
                     props.history.push(`/revenue/cash-transfer`);
                  }}
                >
                  <Calculator />
                </QuickActionCard>
              </div> */}

            <div className="bx--col-md-2">
              <QuickActionCard
                description="Revised/Reversed"
                leftActionText="VIEW REVISED"
                rightActionText="VIEW REVERSED"
                actionEvent1={() => {
                  //props.history.push(`/payments-received/revised`);
                  setState({ ...state, filter: 'revised' });
                }}
                actionEvent2={() => {
                  //props.history.push(`/payments-received/reversed`);
                  setState({ ...state, filter: 'reversed' });
                }}>
                <DollarGreen />
              </QuickActionCard>
            </div>
          </div>
        </div>

        <hr className="grey-hr mt-20" />

        <h3 className="h-20 mt-20">
          {state?.filter === 'revised'
            ? 'Revised Payments'
            : state?.filter === 'reversed'
              ? 'Reversed Payments'
              : 'Cash Received'}{' '}
          &nbsp;&nbsp;&nbsp;
          {!isValidating && (
            <span
              className="text-12 text-primary font-bold pointer"
              onClick={() => mutate()}>
              <RefreshCw size="11" strokeWidth="3" /> REFRESH
            </span>
          )}
        </h3>

        <div className="mt-10">
          <div className="bx--row">
            <div className="bx--col-md-2 mb-20 npr">
              <Select
                noLabel
                onChange={(e) => setState({ ...state, filter: e.target.value })}
                value={state?.filter}
                defaultValue={state?.filter}>
                <SelectItem selected disabled value="" text="Filter" />
                <SelectItem value="" text="All Active" />
                <SelectItem value="revised" text="Revised" />
                <SelectItem value="reversed" text="Reversed" />
                {/* 
                <SelectItem value="Student" text="Student Payments" />
                <SelectItem value="Other" text="Other Payments" /> */}
              </Select>
            </div>
            <div className="bx--col-md-2 mb-20">
              <Select
                noLabel
                onChange={(e) => setState({ ...state, mode: e.target.value })}>
                <SelectItem selected value="" text="Payment Mode " />
                <SelectItem text="Cash" value="0" />
                <SelectItem text="Cheque" value="1" />
                <SelectItem text="Bank Transfer" value="4" />
                <SelectItem text="Bank Draft" value="6" />
                <SelectItem text="Mobile Money" value="2" />
                <SelectItem text="Payroll" value="5" />
              </Select>
            </div>

            <div className="bx--col-md-4 npl mb-20">
              <Search
                id="search"
                name="search"
                labelText=""
                value={state.query}
                onChange={(event) =>
                  setState({ ...state, query: event.target.value })
                }
                placeHolderText="Search by student name or Received from"
              />
            </div>

            <div className="bx--col-md-2 npr">
              <DatePicker
                dateFormat="Y-m-d"
                datePickerType="single"
                id="start-date-picker"
                onClose={(value) => {
                  if (value.length > 0) {
                    setDateFrom(
                      [
                        value[0].getFullYear(),
                        ('0' + (value[0].getMonth() + 1)).slice(-2),
                        ('0' + value[0].getDate()).slice(-2),
                      ].join('-'),
                    );
                  } else setDateFrom('');
                }}>
                <DatePickerInput
                  className="start-date"
                  id="start-date"
                  name="startDate"
                  labelText="Start date"
                  value={dateFrom}
                  pattern="d{1,2}/d{4}"
                  placeholder="YYYY-MM-DD"
                  type="text"
                  onChange={(e) => setDateFrom(e.target.value)}
                />
              </DatePicker>
            </div>
            <div className="bx--col-md-2">
              <DatePicker
                dateFormat="Y-m-d"
                datePickerType="single"
                id="end-date-picker"
                onClose={(value) => {
                  if (value.length > 0) {
                    setDateTo(
                      [
                        value[0].getFullYear(),
                        ('0' + (value[0].getMonth() + 1)).slice(-2),
                        ('0' + value[0].getDate()).slice(-2),
                      ].join('-'),
                    );
                  } else setDateTo('');
                }}>
                <DatePickerInput
                  className="end-date"
                  id="end-date"
                  name="endDate"
                  labelText="End date"
                  value={dateTo}
                  pattern="d{1,2}/d{4}"
                  placeholder="YYYY-MM-DD"
                  type="text"
                  onChange={(e) => setDateTo(e.target.value)}
                />
              </DatePicker>
            </div>
            <div className="bx--col-md-2 mb-20">
              <Select
                labelText="Payment Type"
                onChange={(e) => setType(e.target.value)}>
                <SelectItem selected value="" text="Type" />
                <SelectItem text="Student" value="Student" />
                <SelectItem text="Other" value="Other" />
              </Select>
            </div>
          </div>
        </div>

        <LoaderPane
          loading={(!data && !error) || isValidating}
          error={error?.message}
          onReload={() => mutate()}
          noRecords={!error && data?.data?.length === 0}
        />

        {data?.data?.length > 0 && (
          <div className="mt-30">
            <div className="mt-20">
              <div className="table-container">
                <table className="bx--data-table table-white">
                  <thead>
                    <tr>
                      <th>Unique Id</th>
                      <th>Date</th>
                      <th>Received From</th>
                      <th>Amount</th>
                      <th>Mode</th>
                      <th>Debit Account</th>
                      <th>Payment Type</th>
                      <th>Recorded By</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.data.map((payment, i) => (
                      <tr key={i}>
                        <td>{payment.uniqueId}</td>
                        <td>{formatDate(payment.paymentDate)}</td>
                        <td>
                          {payment?.student?.fullName || payment?.receivedFrom}
                        </td>

                        <td className="font-bold">
                          {payment.totalAmount
                            ? formatCurrency(payment?.totalAmount)
                            : 'N/A'}
                        </td>
                        <td>{payment?.paymentMode}</td>
                        <td>{payment.debitAccount?.description}</td>
                        <td>{`${payment.student ? 'Student' : 'Other'}`}</td>
                        <td>
                          {payment?.recordedBy.firstName}{' '}
                          {payment?.recordedBy.lastName}
                        </td>
                        <td className="text-center">
                          <span
                            className="link"
                            onClick={() => {
                              if (payment.student) {
                                setState({
                                  ...state,
                                  openReceiptModal: true,
                                  selectedPayment: payment,
                                });
                              } else {
                                setState({
                                  ...state,
                                  openOtherPaymentReceiptModal: true,
                                  selectedOtherPayment: payment,
                                });
                              }
                            }}>
                            VIEW
                          </span>

                          {!payment.recordedBy.parent && !payment.depositId && (
                            <>
                              {!payment?.revisedPayment &&
                                !payment?.dateReversed && (
                                  <span
                                    className="link text-orange"
                                    onClick={() => {
                                      if (payment.student) {
                                        setState({
                                          ...state,
                                          openReceivePaymentModal: true,
                                          studentPaymentToRevise: payment,
                                        });
                                      } else {
                                        setState({
                                          ...state,
                                          openReceiveOtherPaymentModal: true,
                                          paymentToRevise: payment,
                                        });
                                      }
                                    }}>
                                    <br />
                                    REVISE
                                  </span>
                                )}

                              {payment?.paymentMode !== 'Payroll' &&
                                !payment.depositId && (
                                  <span>
                                    {!payment?.dateReversed &&
                                      !payment?.revisedPayment && (
                                        <span
                                          className="link text-danger"
                                          onClick={() => {
                                            setState({
                                              ...state,
                                              openReversePaymentModal: true,
                                              reversePaymentHeading:
                                                'Reverse Payment',
                                              reversePaymentDescription: `Reverse Payment from ${
                                                payment.studentName
                                                  ? `Student ${payment.studentName}`
                                                  : payment?.receivedFrom
                                              }`,
                                              reverseAction: () => {
                                                onReversePayment(payment);
                                              },
                                            });
                                          }}>
                                          <br />
                                          REVERSE
                                        </span>
                                      )}
                                  </span>
                                )}
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                    {data &&
                      paginate.page * paginate.pageSize >=
                        data?.pagingInfo.recordsCount && (
                        <tr>
                          <td colSpan={3}>
                            <span className="font-bold text-primary">
                              Total
                            </span>
                          </td>
                          <td colSpan={6}>
                            <span className="text-primary">
                              {formatCurrency(data?.extendedData.totalPayments)}
                            </span>
                          </td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
              {data?.data && (
                <Pagination
                  backwardText="Previous page"
                  forwardText="Next page"
                  page={paginate.page}
                  pageNumberText="Page Number"
                  pageSize={paginate.pageSize}
                  pageSizes={[10, 20, 30, 40, 50, 100]}
                  totalItems={data?.pagingInfo.recordsCount}
                  onChange={(value) => {
                    setPaginate({ ...value });
                  }}
                />
              )}
            </div>
          </div>
        )}

        <div className="bx--row mt-35">
          <div className="bx--col-lg-2">
            <CustomButton
              classes="secondary  f-width font-bold"
              onClick={() => {}}
              disabled>
              <Upload size="11" strokeWidth="5px" />
              &nbsp; EXPORT TO EXCEL
            </CustomButton>
          </div>
          <div className="bx--col-lg-2">
            <CustomButton
              classes="primary f-width font-bold"
              onClick={() => setState({ ...state, openPrintModal: true })}>
              <Printer size="11" strokeWidth="5px" /> &nbsp; PRINT
            </CustomButton>
          </div>
          <div className="bx--col-lg-8"></div>
        </div>

        <Footer fixed={false} showLinks={false} />
      </div>
    </div>
  );
};

const TemplateContent = ({ data, paginate }) => (
  <div className="table-container">
    <table className="bx--data-table table-white">
      <thead>
        <tr>
          <th>Unique Id</th>
          <th>Date</th>
          <th>Received From</th>
          <th>Amount</th>
          <th>Mode</th>
          <th>Debit Account</th>
          <th>Payment Type</th>
          <th>Recorded By</th>
        </tr>
      </thead>
      <tbody>
        {data?.data.map((payment, i) => (
          <tr key={i}>
            <td>{payment.uniqueId}</td>
            <td>{formatDate(payment.paymentDate)}</td>
            <td>{payment?.student?.fullName || payment?.receivedFrom}</td>

            <td className="font-bold">
              {payment.totalAmount
                ? formatCurrency(payment?.totalAmount)
                : 'N/A'}
            </td>
            <td>{payment?.paymentMode}</td>
            <td>{payment.debitAccount?.description}</td>
            <td>{`${payment.student ? 'Student' : 'Other'}`}</td>
            <td>
              {payment?.recordedBy.firstName} {payment?.recordedBy.lastName}
            </td>
          </tr>
        ))}
        {data &&
          paginate.page * paginate.pageSize >=
            data?.pagingInfo.recordsCount && (
            <tr>
              <td colSpan={3}>
                <span className="font-bold text-primary">Total</span>
              </td>
              <td colSpan={5}>
                <span className="text-primary">
                  {formatCurrency(data?.extendedData.totalPayments)}
                </span>
              </td>
            </tr>
          )}
      </tbody>
    </table>
    <div className="text-center mt-30">
      Page {paginate.page}/{paginate.pageSize}
    </div>
  </div>
);

// this will provide us with the state values we need
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  payments: selectPayments,
  classes: selectClasses,
  students: selectEnrolledStudents,
  currentAssets: selectCurrentAssetsLedgers,
});

// this allows us to map our redux actions to our component
const mapDispatchToProps = (dispatch) => ({
  setPayments: (payments) => dispatch(setPayments(payments)),
  removePayment: (paymentId) => dispatch(removePayment(paymentId)),
  setClasses: (classes) => dispatch(setClasses(classes)),
  setStudents: (students) => dispatch(setStudents(students)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RevenuePage),
);
