//trial balance
const trialBalanceData = {
  labels: [`Openning`, `Debit`, `Credit`, `Closing`],
  datasets: [
    {
      label: `Dataset 1`,
      data: [
        {
          date: `${new Date().getFullYear()}-01-01T08:00:00.000Z`,
          value: 0,
        },
        {
          date: `${new Date().getFullYear()}-01-02T08:00:00.000Z`,
          value: 50,
        },
        {
          date: `${new Date().getFullYear()}-01-03T08:00:00.000Z`,
          value: 50,
        },
        {
          date: `${new Date().getFullYear()}-01-06T08:00:00.000Z`,
          value: 0,
        },
      ],
    },
  ],
};
const trialBalanceOptions = {
  title: ``,
  axes: {
    left: {
      primary: true,
    },
    bottom: {
      scaleType: `labels`,
      secondary: true,
    },
  },
  height: `350px`,
};

//income statement
const incomeStatementData = {
  labels: [`Label 1`, `Label 2`],
  datasets: [
    {
      label: `Income`,
      data: [
        {
          date: `${new Date().getFullYear()}-01-01T08:00:00.000Z`,
          value: 0,
        },
        {
          date: `${new Date().getFullYear()}-02-05T08:00:00.000Z`,
          value: 5,
        },
        {
          date: `${new Date().getFullYear()}-03-08T08:00:00.000Z`,
          value: 15,
        },
        {
          date: `${new Date().getFullYear()}-04-08T08:00:00.000Z`,
          value: 9,
        },
      ],
    },
    {
      label: `Expense`,
      data: [
        {
          date: `${new Date().getFullYear()}-01-02T08:00:00.000Z`,
          value: 0,
        },
        {
          date: `${new Date().getFullYear()}-02-06T08:00:00.000Z`,
          value: 10,
        },
        {
          date: `${new Date().getFullYear()}-03-08T08:00:00.000Z`,
          value: 15,
        },
        {
          date: `${new Date().getFullYear()}-04-08T08:00:00.000Z`,
          value: 13,
        },
        {
          date: `${new Date().getFullYear()}-05-08T08:00:00.000Z`,
          value: 25,
        },
      ],
    },
  ],
};
const incomeStatementOptions = {
  title: ``,
  axes: {
    left: {
      secondary: true,
    },
    bottom: {
      scaleType: `time`,
      primary: true,
    },
  },
  curve: `curveMonotoneX`,
  height: `350px`,
};

//barChart
const stdAgeData = {
  labels: [`0-2`, `3-5`, `6-12`, `13-18`, `19-24`],
  datasets: [
    {
      label: `Dataset 1`,
      data: [
        {
          text: `0-2`,
          value: 50,
        },
        {
          text: `3-5`,
          value: 20,
        },
        {
          text: `6-12`,
          value: 80,
        },
        {
          text: `13-18`,
          value: 30,
        },
        {
          text: `19-24`,
          value: 30,
        },
      ],
    },
  ],
};

const stdAgeOptions = {
  title: ``,
  axes: {
    left: {
      primary: true,
    },
    bottom: {
      scaleType: `labels`,
      secondary: true,
    },
  },
  height: `250px`,
};

//employee DonutChart
const employeesData = {
  labels: [`Male`, `Female`],
  datasets: [
    {
      label: `Dataset 1`,
      data: [20, 60],
    },
  ],
};

const employeesOptions = {
  title: ``,
  resizable: true,
  donut: {
    /*  "center": {
        "label": "Browsers"
      } */
  },
  height: `250px`,
};

//barChart
const empAgeData = {
  labels: [`18-30`, `31-38`, `39-44`, `45-54`, `55+`],
  datasets: [
    {
      label: `Dataset 1`,
      data: [
        {
          text: `18-30`,
          value: 50,
        },
        {
          text: `31-38`,
          value: 20,
        },
        {
          text: `39-44`,
          value: 30,
        },
        {
          text: `45-54`,
          value: 30,
        },
        {
          text: `55+`,
          value: 30,
        },
      ],
    },
  ],
};

const empAgeOptions = {
  title: ``,
  axes: {
    left: {
      primary: true,
    },
    bottom: {
      scaleType: `labels`,
      secondary: true,
    },
  },
  height: `250px`,
};

//LineChart BalanceSheet
const balanceSheetData = {
  labels: [`Qty`, `More`, `Sold`],
  datasets: [
    {
      label: `Assets`,
      data: [
        {
          date: `${new Date().getFullYear()}-01-01T08:00:00.000Z`,
          value: 0,
        },
        {
          date: `${new Date().getFullYear()}-02-05T08:00:00.000Z`,
          value: 10,
        },
        {
          date: `${new Date().getFullYear()}-03-08T08:00:00.000Z`,
          value: 15,
        },
        {
          date: `${new Date().getFullYear()}-04-08T08:00:00.000Z`,
          value: 13,
        },
        {
          date: `${new Date().getFullYear()}-05-08T08:00:00.000Z`,
          value: 25,
        },
      ],
    },
    {
      label: `Reserves & Liabilities`,
      data: [
        {
          date: `${new Date().getFullYear()}-01-02T08:00:00.000Z`,
          value: 0,
        },
        {
          date: `${new Date().getFullYear()}-02-06T08:00:00.000Z`,
          value: 10,
        },
        {
          date: `${new Date().getFullYear()}-03-08T08:00:00.000Z`,
          value: 15,
        },
        {
          date: `${new Date().getFullYear()}-04-08T08:00:00.000Z`,
          value: 13,
        },
        {
          date: `${new Date().getFullYear()}-05-08T08:00:00.000Z`,
          value: 25,
        },
      ],
    },
  ],
};
const balanceSheetOptions = {
  title: ``,
  axes: {
    left: {
      secondary: true,
    },
    bottom: {
      scaleType: `time`,
      primary: true,
    },
  },
  curve: `curveMonotoneX`,
  height: `350px`,
};

//LineChart
const lineData = {
  labels: [`Label 1`],
  datasets: [
    {
      label: ``,
      data: [
        {
          date: `${new Date().getFullYear()}-01-01T08:00:00.000Z`,
          value: 0,
        },
        {
          date: `${new Date().getFullYear()}-02-05T08:00:00.000Z`,
          value: 5,
        },
        {
          date: `${new Date().getFullYear()}-03-08T08:00:00.000Z`,
          value: 15,
        },
        {
          date: `${new Date().getFullYear()}-04-08T08:00:00.000Z`,
          value: 9,
        },
      ],
    },
  ],
};
const lineOptions = {
  title: ``,
  axes: {
    left: {
      secondary: true,
    },
    bottom: {
      scaleType: `time`,
      primary: true,
    },
  },
  curve: `curveMonotoneX`,
  height: `350px`,
};

//budget report revenue/expense  LineChart
const revExpLineData = {
  labels: [`Qty`, `More`, `Sold`],
  datasets: [
    {
      label: `Assets`,
      data: [
        {
          date: `${new Date().getFullYear()}-01-01T08:00:00.000Z`,
          value: 0,
        },
        {
          date: `${new Date().getFullYear()}-02-05T08:00:00.000Z`,
          value: 5,
        },
        {
          date: `${new Date().getFullYear()}-03-08T08:00:00.000Z`,
          value: 15,
        },
        {
          date: `${new Date().getFullYear()}-04-08T08:00:00.000Z`,
          value: 9,
        },
      ],
    },
    {
      label: `Reserves & Liabilities`,
      data: [
        {
          date: `${new Date().getFullYear()}-01-02T08:00:00.000Z`,
          value: 0,
        },
        {
          date: `${new Date().getFullYear()}-02-06T08:00:00.000Z`,
          value: 10,
        },
        {
          date: `${new Date().getFullYear()}-03-08T08:00:00.000Z`,
          value: 15,
        },
        {
          date: `${new Date().getFullYear()}-04-08T08:00:00.000Z`,
          value: 13,
        },
        {
          date: `${new Date().getFullYear()}-05-08T08:00:00.000Z`,
          value: 25,
        },
      ],
    },
  ],
};
const revExpLineOptions = {
  title: ``,
  axes: {
    left: {
      secondary: true,
    },
    bottom: {
      scaleType: `time`,
      primary: true,
    },
  },
  curve: `curveMonotoneX`,
  height: `290px`,
};

//budget-report(Revenue&Expenses) DonutChart
const revExpData = {
  labels: [`Transport`, `Utilities`, `Others`],
  datasets: [
    {
      label: `Dataset 1`,
      data: [20, 60, 90],
    },
  ],
};

const revExpOptions = {
  title: ``,
  resizable: true,
  donut: {
    /*  "center": {
          "label": "Browsers"
        } */
  },
  height: `270px`,
};

export {
  lineData,
  lineOptions,
  balanceSheetData,
  balanceSheetOptions,
  trialBalanceData,
  trialBalanceOptions,
  incomeStatementData,
  incomeStatementOptions,
  stdAgeData,
  stdAgeOptions,
  employeesData,
  employeesOptions,
  empAgeData,
  empAgeOptions,
  revExpLineData,
  revExpLineOptions,
  revExpData,
  revExpOptions,
};
