import React from 'react';
import {
  TextInput,
  Form,
  Modal,
  Loading,
  Select,
  SelectItem,
} from 'carbon-components-react';
import CustomButton from '../../custom-button/custom-btn.component';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

// import our redux stuffs
import { selectCurrentUser } from '../../../redux/selectors/user.selectors';

// import api stuffs

import './add-supplier.styles.scss';
import { createSupplier } from '../../../api/supplier.service';
import { setSuppliers } from '../../../redux/actions/supplier.actions';

class AddSupplierModal extends React.Component {
  constructor(props) {
    super(props);

    // set state
    this.state = {
      id: 0,
      loading: false,
      btnText: 'ADD SUPPLIER',
      heading: 'Add New Supplier',
      errorMessage: null,
      openModal: props.openModal,
      name: '',
      location: '',
      phoneNumber: '',
      emailAddress: '',
      serviceType: '',
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (this.props.supplier !== prevProps.supplier) {
      if (this.props.supplier) {
        this.setState({
          ...this.props.supplier,
          loading: false,
          errorMessage: null,
          btnText: 'UPDATE SUPPLIER',
          heading: 'Edit Supplier',
        });
      } else {
        this.setState({
          id: 0,
          name: '',
          location: '',
          phoneNumber: '',
          emailAddress: '',
          serviceType: '',
          loading: false,
          errorMessage: null,
          successMessage: null,
          btnText: 'ADD EMPLOYEE',
        });
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.openModal !== prevState.openModal) {
      return { openModal: nextProps.openModal };
    } else return null;
  }

  // handle onchange event handler for input fields
  handleChange = (event) => {
    const { name, value } = event.target;

    // update state
    this.setState({ [name]: value });
  };

  // validate data entered
  isDataValid = () => {
    return (
      this.state.name.length > 0 &&
      this.state.location.length > 0 &&
      this.state.phoneNumber.length > 0 &&
      this.state.serviceType.length > 0
    );
  };

  addSupplier = async (event) => {
    event.preventDefault();

    this.setState({ loading: true, errorMessage: null, btnText: null });

    const data = {
      ...this.state,
      supplierToEdit: undefined,
      btnText: undefined,
      loading: undefined,
      errorMessage: undefined,
      openModal: undefined,
    };

    const response = await createSupplier(
      data,
      this.props.currentUser.authToken,
    );

    if (!response.success) {
      this.setState({
        loading: false,
        errorMessage: response.message,
        addBtnText: data.id > 0 ? 'UPDATE SUPPLIER' : 'ADD SUPPLIER',
      });

      return;
    }

    let supplier = response.supplier;

    this.setState({
      loading: false,
      errorMessage: null,
      id: 0,
      supplierToEdit: null,
      btnText: 'ADD SUPPLIER',
      name: '',
      location: '',
      emailAddress: '',
      serviceType: '',
      phoneNumber: '',
    });

    // all good, add supplier and close dialog
    this.props.setSuppliers([supplier]);

    // close dialog
    this.props.cancelAction();
  };

  render() {
    return (
      <Modal
        id="add-supplier-modal"
        className="add-supplier-modal"
        hasScrollingContent
        passiveModal={true}
        open={this.state.openModal}
        modalHeading={this.state.heading}
        primaryButtonDisabled={false}>
        <div>
          <Form action="post">
            <div className="bx--row">
              <div className="bx--col-md-8 mt-10">
                <TextInput
                  id="name"
                  name="name"
                  value={this.state.name}
                  onChange={this.handleChange}
                  labelText="Name of Supplier"
                  placeholder="Name"
                />
              </div>
              <div className="bx--col-md-4 mt-35">
                <TextInput
                  id="location"
                  name="location"
                  labelText="Location"
                  placeholder="eg. Madina Estate"
                  value={this.state.location}
                  onChange={this.handleChange}
                />
              </div>
              <div className="bx--col-md-4 mt-35">
                <Select
                  id="serviceType"
                  name="serviceType"
                  labelText="Service Type"
                  onChange={this.handleChange}>
                  <SelectItem
                    selected={this.state.serviceType.length === 0}
                    disabled
                    text="Select Service Type"
                    value=""
                  />
                  <SelectItem
                    text="General Merchant"
                    value="General Merchant"
                  />

                  <SelectItem
                    selected={this.state.serviceType === 'Groceries'}
                    text="Groceries"
                    value="Groceries"
                  />

                  <SelectItem
                    selected={this.state.serviceType === 'Utilities'}
                    text="Utilities"
                    value="Utilities"
                  />

                  <SelectItem
                    selected={this.state.serviceType === 'IT Consultant'}
                    text="IT Consultant"
                    value="IT Consultant"
                  />

                  <SelectItem
                    selected={
                      this.state.serviceType === 'Building Construction'
                    }
                    text="Building Construction"
                    value="Building Construction"
                  />

                  <SelectItem
                    selected={this.state.serviceType === 'Professional Service'}
                    text="Professional Service"
                    value="Professional Service"
                  />

                  <SelectItem
                    selected={this.state.serviceType === 'Other'}
                    text="Other"
                    value="Other"
                  />
                </Select>
              </div>
              <div className="bx--col-md-4 mt-35">
                <TextInput
                  id="phone"
                  name="phoneNumber"
                  labelText="Phone"
                  placeholder="Phone number"
                  value={this.state.phoneNumber}
                  onChange={this.handleChange}
                />
              </div>
              <div className="bx--col-md-4 mt-35">
                <TextInput
                  id="email"
                  name="emailAddress"
                  labelText="Email"
                  placeholder="Email"
                  value={this.state.emailAddress}
                  onChange={this.handleChange}
                />
              </div>
            </div>

            <div className="bx--row mt-60">
              <div className="bx--col-md-5">
                <CustomButton
                  classes="outline danger no-border ml-10 no-hover font-bold"
                  disabled={this.state.loading}
                  onClick={(e) => this.props.cancelAction()}>
                  CANCEL
                </CustomButton>
              </div>
              <div className="bx--col-md-3">
                <CustomButton
                  classes="primary f-width font-bold"
                  onClick={this.addSupplier}
                  disabled={this.state.loading || !this.isDataValid()}>
                  {this.state.btnText}

                  <div hidden={this.state.btnText != null}>
                    <div className="d-flex-center">
                      <Loading
                        withOverlay={false}
                        small={true}
                        description="Please wait..."
                      />
                    </div>
                  </div>
                </CustomButton>
              </div>
            </div>

            <p
              className="text-14 mt-10 text-danger"
              hidden={this.state.errorMessage === null}>
              {this.state.errorMessage}
            </p>
          </Form>
        </div>
      </Modal>
    );
  }
}

// this will provide us with the state values we need
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

// this allows us to map our redux actions to our component
const mapDispatchToProps = (dispatch) => ({
  setSuppliers: (suppliers) => dispatch(setSuppliers(suppliers)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddSupplierModal);
