import React, { useEffect } from 'react';
import { Plus, MoreVertical } from 'react-feather';
import CardMenu, {
  CardMenuItem,
} from '../../menu/card-menu/card-menu.component';

import { getStudentBalanceDue } from '../../../api/billing.service';

import { withRouter } from 'react-router-dom';
import { selectCurrentUser } from '../../../redux/selectors/user.selectors';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { formatCurrency } from '../../../utils/common.util';

import './student-card.styles.scss';

import useSWR, { mutate } from 'swr';
import { useState } from 'reactn';

const StudentCard = (props) => {
  const [state, setState] = useState({
    showCardMenu: false,
    balanceDue: 'loading',
    balanceDueError: null,
  });

  const {
    id,
    firstName,
    lastName,
    gender,
    classDescription,
    classLabelDescription,
    studentId,
    schoolStudentId,
    status,
    photoUrl,
  } = props.student;

  // calculate balance due
  const { data, error } = useSWR(
    `billing/student-balance-due/?studentId=${id}&schoolId=-1`,
  );

  useEffect(() => {});

  /* getAmountOwed = () => {
    setState({ balanceDue: "loading", balanceDueError: null });

    // calculate balance due
    getStudentBalanceDue(props.currentUser.authToken, props.student.id).then(response => {
      if (response.success) {
        setState({ balanceDue: response.amountOwed });

        return;
      }

      // failed
      setState({ balanceDueError: "Failed getting amount owed" });
    });
  }; */

  return (
    <div
      className="student-card bg-white pointer"
      onClick={() => props.history.push(`/student/${id}`)}>
      <div>
        <div className="top-content">
          <div>
            <div className="icon">
              <div
                className="pic"
                style={{ backgroundImage: `url(${photoUrl})` }}></div>

              <MoreVertical
                className="pointer"
                color="#7D819D"
                onClick={(e) => {
                  e.stopPropagation();
                  setState({ ...state, showCardMenu: !state.showCardMenu });
                }}
              />

              {state.showCardMenu ? (
                <CardMenu>
                  <CardMenuItem
                    title="EDIT"
                    color="primary"
                    separator={false}
                    action={(event) => {
                      event.stopPropagation();
                      props.editAction();
                      setState({ ...state, showCardMenu: false });
                    }}
                  />

                  {/*<CardMenuItem
											title="DELETE"
											separator={false}
											color="danger"
											action={event => {
												event.stopPropagation();
												props.deleteAction();
												setState({ showCardMenu: false });
											}}
										/>*/}
                </CardMenu>
              ) : null}
            </div>
          </div>

          <div className=" bx--row">
            <div className="bx--col">
              <h3 className="title text-truncate">
                {firstName} {lastName}
              </h3>
              <div className="flex mt-5">
                <span className="text-label">
                  {schoolStudentId ? schoolStudentId : studentId}
                </span>
              </div>
            </div>

            <div className="bx--col">
              <div></div>
            </div>
          </div>
        </div>

        <hr className="line" />

        <div className="flex f-width">
          <div className="gender">
            <span className="text-label">Gender</span>
            <h4 className="text-value text-truncate">{gender}</h4>
          </div>

          <div className="divider"></div>

          <div className="class">
            <span className="text-label">
              {status === 'Terminated' ? 'Terminated At' : 'Class'}
            </span>
            <h4 className="text-value text-truncate">
              {status === 'Graduated'
                ? 'Graduated'
                : `${classDescription} ${
                    classLabelDescription ? classLabelDescription : ''
                  }`}
            </h4>
          </div>
        </div>

        <hr className="line second" />

        <div className="make-payment">
          <div>
            <span className="font-500" hidden={data || error}>
              Calculating Balance Due...
            </span>

            <span className="text-danger font-500" hidden={!error?.message}>
              {error ? 'Failed getting amount owed' : ''}
            </span>

            <span hidden={!data && !error} className="text-label">
              Balance Due:{' '}
            </span>
            <span hidden={!data && !error} className="text-value">
              {data?.amountOwed ? formatCurrency(data?.amountOwed) : 'N/A'}
            </span>
          </div>
          <div>
            <span
              className="pointer"
              onClick={(e) => {
                e.stopPropagation();
                props.makePayment();
              }}
              hidden={(!data && !error) || data?.amountOwed === 0}>
              <span>
                <Plus size="11" strokeWidth="6px" /> &nbsp;
              </span>
              RECEIVE PAYMENT
            </span>

            <span
              hidden={!state.balanceDueError}
              className="text-primary pointer font-bold"
              onClick={(e) => {
                e.stopPropagation();
                //getAmountOwed();
                mutate();
              }}>
              TRY AGAIN
            </span>
          </div>
        </div>

        <hr className="line" />

        <div className="actions">
          <div className="payments">
            <span
              onClick={(e) => {
                e.stopPropagation();
                props.history.push(`/student/${id}/payments`);
              }}>
              PAYMENTS
            </span>
          </div>

          <div className="divider medium"></div>

          <div className="bills">
            <span>BILLS</span>
          </div>
        </div>
      </div>
    </div>
  );
};

// this will provide us with the state values we need
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default withRouter(connect(mapStateToProps)(StudentCard));
