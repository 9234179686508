import React from 'react';
import { Modal } from 'carbon-components-react';
import CustomButton from '../../custom-button/custom-btn.component';
import { Printer, Download } from 'react-feather';
import SubFooter from '../../footer/sub-footer/sub-footer.component';
import {
  formatCurrency,
  formatDate,
  number2words,
} from '../../../utils/common.util';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../../redux/selectors/user.selectors';
import { connect } from 'react-redux';

import './receipt.styles.scss';
import useSWR, { mutate } from 'swr';
import LoaderPane from '../../loader-pane/loader-pane.component';

const OtherPaymentReceiptModal = ({
  openModal,
  selectedPayment = null,
  cancelAction,
  currentUser: { school },
}) => {
  const { data, error } = useSWR(false && `/revenue/${selectedPayment?.id}`);
  return (
    <Modal
      id="other-payment-receipt-modal"
      className="other-payment-receipt-modal"
      //hasScrollingContent
      passiveModal={true}
      open={openModal}
      primaryButtonDisabled={false}>
      {selectedPayment && (
        <div className="modal-inner">
          <div className="inner-content">
            <div style={{ pageBreakAfter: 'always' }}>
              <div className="mt-10 bx--col--auto mb-10">
                <div
                  className="school-logo center"
                  style={{
                    backgroundImage: `url(${school.logoUrl})`,
                  }}></div>
                <h3 className="h-20 text-primary text-center">{school.name}</h3>
                <p className="text-14 text-grey-dark text-center">
                  {school.location}
                </p>

                <p className="text-center text-15 text-primary font-bold mt-10 text-uppercase">
                  OFFICIAL RECEIPT
                </p>
              </div>

              <div className="payment-info d-flex-space-between mt-10 p-l-r-24-31">
                {selectedPayment?.studentName && (
                  <div>
                    <p className="text-14 text-grey-light">Student</p>
                    <h3 className="h-14">
                      {selectedPayment?.student.fullName}
                    </h3>
                  </div>
                )}

                <div>
                  <p className="text-14 text-grey-light">Receipt No.</p>
                  <h3 className="h-14">{selectedPayment.uniqueId}</h3>
                </div>
                <div>
                  <p className="text-14 text-grey-light">Payment Method</p>
                  <h3 className="h-14">{selectedPayment?.paymentMode}</h3>
                </div>
                {selectedPayment.paymentMode === 'Cheque' && (
                  <div>
                    <p className="text-14 text-grey-light">Cheque Number</p>
                    <h3 className="h-14">{selectedPayment?.chequeNumber}</h3>
                  </div>
                )}
                <div></div>
              </div>

              {/* <LoaderPane
                loading={false && !data && !error}
                onReload={() => {
                  mutate();
                }}
                error={error?.message}
              /> */}

              {/* { data?.revenues.length > 0 && (
                <table className="bx--data-table table-white">
                  <thead>
                    <tr>
                      <th>Account</th>
                      <th>Amount Owed</th>
                      <th>Amount Paid</th>
                      <th>Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.revenues?.map((r, i) => (
                      <tr key={i}>
                        <td>{r?.ledgerAccount?.description}</td>
                        <td>{r?.amountOwed}</td>
                        <td>{r?.amountPaid}</td>
                        <td>{r?.balanceDue}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )} */}

              <div className="mt-40">
                {/*  {selectedPayment?.amountOwed !==0 && (
                  <div className="item-footer p-l-r-24-31">
                    <span className="h-14 text-primary">Amount Owed</span>
                    <span> &nbsp;&nbsp;&nbsp; {number2words(selectedPayment?.amountOwed)}</span>
                    <span className="right h-14 text-primary text-right">
                      {formatCurrency(selectedPayment?.amountOwed) }
                    </span>
                  </div>
                )}  */}

                <div className="item-footer p-l-r-24-31">
                  <span className="h-14 text-primary">Total Amount</span>
                  <span>
                    {' '}
                    &nbsp;&nbsp;&nbsp;{' '}
                    {number2words(selectedPayment?.totalAmount)}
                  </span>
                  <span className="right h-14 text-primary text-right">
                    {formatCurrency(selectedPayment.totalAmount)}
                  </span>
                </div>

                {/* {selectedPayment?.balanceDue && (
                  <div className="item-footer p-l-r-24-31">
                    <span className="h-14 text-primary">Balance Due</span>
                    <span> &nbsp;&nbsp;&nbsp; {number2words(selectedPayment?.balanceDue)}</span>
                    <span className="right h-14 text-primary text-right">
                      {formatCurrency(selectedPayment?.balanceDue)}
                    </span>
                  </div>
                )} */}

                <div className="mt-5 ml-16">
                  <p className="text-14 mt-10">Received From</p>
                  <p className="text-14 text-grey">
                    {selectedPayment?.receivedFrom ||
                      selectedPayment?.student.fullName}
                  </p>
                </div>

                <div className="mt-5 ml-16">
                  <p className="text-14 mt-10">Description</p>
                  <p className="text-14 text-grey">
                    {selectedPayment?.description || 'N/A'}
                  </p>
                </div>

                <div className="mt-5 ml-16">
                  <p className="text-14 mt-10">Date</p>
                  <p className="text-14 text-grey">
                    {formatDate(selectedPayment.paymentDate)}
                  </p>
                </div>

                <div className="mt-5 ml-16">
                  <p className="text-14 mt-10">Recorded By</p>
                  <p className="text-14 text-grey">
                    {`${selectedPayment?.recordedBy?.firstName} ${selectedPayment?.recordedBy?.lastName}`}
                  </p>
                </div>

                {(selectedPayment.dateReversed ||
                  selectedPayment.revisedPayment) && (
                  <h1 className="text-danger mt-10 not-authentic">
                    THIS RECEIPT IS NOT AUTHENTIC
                  </h1>
                )}

                <SubFooter />
              </div>
            </div>
          </div>
          <div className="inner-footer">
            <div className="bx--row mt-35">
              <div className="bx--col-lg-3">
                <CustomButton
                  classes="outline secondary f-width font-bold"
                  onClick={cancelAction}>
                  CLOSE
                </CustomButton>
              </div>
              <div className="bx--col-lg-6"></div>
              <div className="bx--col-lg-3">
                <CustomButton
                  classes="primary f-width font-bold"
                  onClick={() => window.print()}>
                  <Printer size="11" strokeWidth="5px" /> &nbsp; PRINT
                </CustomButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

// this will provide us with the state values we need
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(OtherPaymentReceiptModal);
