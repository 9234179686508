// using reselect
import { createSelector } from 'reselect';

// create select hub connection function
const selectNotification = (state) => state.notification;

// create selectNotifications selector
export const selectNotifications = createSelector(
  [selectNotification],
  (notification) => notification.notifications,
);

export const selectHubConnection = createSelector(
  [selectNotification],
  (notification) => notification.hubConnection,
);
