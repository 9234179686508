// using reselect
import { createSelector } from 'reselect';

// create select student function
const selectStudent = (state) => state.student;

// create selectAllStudents selector
export const selectAllStudents = createSelector(
  [selectStudent],
  (student) => student.students,
);

export const selectActiveWard = createSelector(
  [selectStudent],
  (student) => student.activeWard,
);

export const selectEnrolledStudents = createSelector(
  [selectStudent],
  (student) => student.students.filter((item) => item.status === 'Enrolled'),
);

export const selectGraduatedStudents = createSelector(
  [selectStudent],
  (student) => student.students.filter((item) => item.status === 'Graduated'),
);

export const selectTerminatedStudents = createSelector(
  [selectStudent],
  (student) => student.students.filter((item) => item.status === 'Terminated'),
);
