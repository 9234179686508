// import types
import NotificationActionTypes from '../types/notification.types';

// set initial state
const INITIAL_STATE = {
  notifications: [],
  hubConnection: null,
};

// create reducer with intial state if not state is received
const notificationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NotificationActionTypes.SET_HUB_CONNECTION:
      return {
        // spread in the state
        ...state,
        hubConnection: action.payload,
      };
    case NotificationActionTypes.SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: [...state.notifications, ...action.payload],
      };
    case NotificationActionTypes.DELETE_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications].filter(
          (item) => item.dateGenerated !== action.payload,
        ),
      };
    default:
      // no action matched, just return the state
      return state;
  }
};

// export out notificationReducer as default
export default notificationReducer;
