import React, { useState } from 'react';
import { MoreVertical } from 'react-feather';
import CardMenu, {
  CardMenuItem,
} from '../../menu/card-menu/card-menu.component';

import { withRouter } from 'react-router-dom';

import './employee-card.styles.scss';

const EmployeeCard = ({
  employee: {
    id,
    firstName,
    lastName,
    gender,
    departmentName,
    uniqueId,
    photoUrl = '',
  },
  history,
  editAction,
  deleteAction,
}) => {
  const [showCardMenu, setShowCardMenu] = useState(false);

  return (
    <div
      className="employee-card bg-white pointer"
      onClick={() => history.push(`/employees/${id}`)}>
      <div>
        <div className="top-content">
          <div>
            <div className="icon">
              <div
                className="pic"
                style={{ backgroundImage: `url(${photoUrl})` }}></div>

              <MoreVertical
                className="pointer"
                color="#7D819D"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowCardMenu(showCardMenu ? false : true);
                }}
              />

              {showCardMenu ? (
                <CardMenu>
                  <CardMenuItem
                    title="EDIT"
                    color="primary"
                    action={(event) => {
                      event.stopPropagation();
                      editAction();
                      setShowCardMenu(showCardMenu ? false : true);
                    }}
                  />

                  <CardMenuItem
                    title="DELETE"
                    separator={false}
                    color="secondary danger"
                    action={(event) => {
                      event.stopPropagation();
                      deleteAction();
                      setShowCardMenu(showCardMenu ? false : true);
                    }}
                  />
                </CardMenu>
              ) : null}
            </div>
          </div>

          <h3 className="title text-truncate">
            {firstName} {lastName}
          </h3>
          <div className="mt-5">
            <span className="text-label emp">{uniqueId}</span>
          </div>
        </div>

        <hr className="line" />

        <div className="pos">
          <span className="text-label">Gender</span>
          <h4 className="text-value text-truncate">{gender}</h4>
        </div>

        <hr className="line" />

        <div className="dpt">
          <span className="text-label">Department</span>
          <h4 className="text-value text-truncate">{departmentName}</h4>
        </div>
      </div>
    </div>
  );
};

export default withRouter(EmployeeCard);
