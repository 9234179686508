import { apiBaseUrl, postData, getData, handleGenericError } from './common';

// import axios
const axios = require('axios').default;

const baseUrl = `${apiBaseUrl}student/`;

export const getStudents = async (
  authToken,
  skip,
  take = 100,
  query = '',
  classId = -1,
  gender = '',
  status = '',
) => {
  // build url
  const url = `${baseUrl}list/?skip=${skip}&take=${take}&query=${query}
    &classId=${classId}&gender=${gender}&status=${status}`;

  // set config
  const config = { headers: { Authorization: `Bearer ${authToken}` } };

  // initiate request
  try {
    let response = await axios.get(url, config);
    return { success: true, students: response.data };
  } catch (error) {
    return handleGenericError(error);
  }
};

export const getStudentsNew = async (
  authToken,
  classId = -1,
  classLabelId = -1,
  status = '',
  includeBalanceDue = false,
) => {
  // build url
  const url = `${baseUrl}list-new/?status=${status}&classId=${classId}&classLabelId=${classLabelId}&includeBalanceDue=${includeBalanceDue}`;

  // set config
  const config = { headers: { Authorization: `Bearer ${authToken}` } };

  // initiate request
  try {
    let response = await axios.get(url, config);
    return { success: true, students: response.data };
  } catch (error) {
    return handleGenericError(error);
  }
};

export const getStudentsInDebt = async (authToken, classId = -1) => {
  const url = `${baseUrl}in-debt/?classId=${classId}`;
  const config = { headers: { Authorization: `Bearer ${authToken}` } };

  // initiate request
  try {
    let response = await axios.get(url, config);
    return { success: true, students: response.data };
  } catch (error) {
    return handleGenericError(error);
  }
};

export const createStudent = async (authToken, data) => {
  let response = await postData('student/create-new', data, authToken);
  if (response.success) {
    return {
      success: true,
      student: response.data.student,
      message: `Student ${data.id === 0 ? 'created' : 'updated'} successfully.`,
    };
  }

  return response;
};

export const deleteStudent = async (authToken, data) => {
  return await postData('student/delete', data, authToken);
};

export const terminateStudent = async (authToken, data) => {
  const response = await postData('student/terminate', data, authToken);

  if (response.success) return { success: true, data: response.data.student };

  return response;
};

export const reinstateStudent = async (authToken, data) => {
  const response = await postData('student/reinstate', data, authToken);

  if (response.success) return { success: true, data: response.data.student };

  return response;
};

export const uploadStudentPhoto = async (authToken, data) => {
  const response = await postData('student/upload-photo', data, authToken);

  if (response.success)
    return { success: true, student: response.data.student };

  return response;
};

export const importStudents = async (authToken, data) => {
  return await postData('student/import', data, authToken);
};

export const exportStudents = async (
  authToken,
  status = 'enrolled',
  classId = -1,
  classLabelId = -1,
) => {
  return await getData(
    `student/export?status=${status}&classId=${classId}&classLabelId=${classLabelId}`,
    authToken,
    true,
  );
};
