// import types
import EmployeeActionTypes from '../types/employee.types';
import { addItems, removeItem } from '../utils/common.util';

// set initial state
const INITIAL_STATE = {
  employees: [],
  departments: [],
};

// create user reducer with intial state if not state is received
const employeeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EmployeeActionTypes.SET_EMPLOYEES:
      return {
        // spread in the state
        ...state,

        // set employees
        employees: addItems(state.employees, action.payload),
      };
    case EmployeeActionTypes.SET_DEPARTMENTS:
      return {
        // spread in the state
        ...state,

        // set departments
        departments: addItems(state.departments, action.payload),
      };
    case EmployeeActionTypes.REMOVE_DEPARTMENT:
      return {
        // spread in the state
        ...state,
        departments: removeItem(state.departments, action.payload),
      };
    case EmployeeActionTypes.REMOVE_EMPLOYEE:
      return {
        // spread in the state
        ...state,
        employees: removeItem(state.employees, action.payload),
      };
    default:
      // no action matched, just return the state
      return state;
  }
};

// export out employeeReducer as default
export default employeeReducer;
