import { createSelector } from 'reselect';

const selectLedger = (state) => state.ledger;

export const selectLedgerAccounts = createSelector(
  [selectLedger],
  (ledger) => ledger.accounts,
);

export const selectRevenueLedgerAccounts = createSelector(
  [selectLedgerAccounts],
  (accounts) =>
    accounts.data.filter((item) => item.groupDescription === 'Revenue'),
);

export const selectExpenseLedgerAccounts = createSelector(
  [selectLedgerAccounts],
  (accounts) =>
    accounts.data.filter((item) => item.groupDescription === 'Expenditure'),
);

export const selectNonExpenseAndRevenueLedgerAccounts = createSelector(
  [selectLedger],
  (ledger) =>
    ledger.accounts.data.filter(
      (item) =>
        item.groupDescription !== 'Revenue' &&
        item.groupDescription !== 'Expenditure',
    ),
);

export const selectLedgerCategories = createSelector(
  [selectLedger],
  (ledger) => ledger.categories,
);

export const selectLedgerGroups = createSelector(
  [selectLedger],
  (ledger) => ledger.groups,
);
