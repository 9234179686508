import React from 'react';
import { Modal } from 'carbon-components-react';
import CustomButton from '../../../custom-button/custom-btn.component';
import { Printer } from 'react-feather';

import './employees-print-out.styles.scss';
import { formatDate } from '../../../../utils/common.util';

const EmployeesPrintOutModal = ({
  open,
  department,
  status,
  employees = [],
  school,
  closeAction,
}) => {
  return (
    <Modal
      id="employees-print-out-modal"
      className="employees-print-out-modal"
      hasScrollingContent
      passiveModal={true}
      open={open}
      primaryButtonDisabled={false}>
      <div style={{ pageBreakAfter: 'always' }}>
        <div className="mt-15 bx--col--auto mb-10">
          <div
            className="school-logo center"
            style={{
              backgroundImage: `url(${school.logoUrl})`,
            }}></div>
          <h3 className="h-24 text-primary text-center">{school.name}</h3>
          <p className="text-14 text-grey-dark text-center">
            {school.location}
          </p>

          <p className="text-center text-16 text-primary font-bold mt-20 text-uppercase">
            List of {status} Employees
          </p>
        </div>

        <div className="info mt-15">
          <div>
            <p className="text-14 text-grey-light">Department</p>
            <h3 className="h-14">{department}</h3>
          </div>
          <div>
            <p className="text-14 text-grey-light">Status</p>
            <h3 className="h-14">{status}</h3>
          </div>
          <div>
            <p className="text-14 text-grey-light">Total</p>
            <h3 className="h-14">{employees.length}</h3>
          </div>

          <div>
            <p className="text-14 text-grey-light">Date Generated</p>
            <h3 className="h-14">{formatDate(new Date())}</h3>
          </div>
        </div>

        <div>
          <div>
            <table className="bx--data-table nm">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Employee</th>
                  <th>Gender</th>
                  <th>Department</th>
                  <th>Position</th>
                  <th>Date Hired</th>
                </tr>
              </thead>
              <tbody>
                {employees.map((emp) => (
                  <tr>
                    <td>{emp.uniqueId}</td>
                    <td>{emp.fullName}</td>
                    <td>{emp.gender}</td>
                    <td>{emp.departmentName}</td>
                    <td>{emp.jobDescription}</td>
                    <td>{emp.dateHired ? formatDate(emp.dateHired) : 'N/A'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="bx--row nm mt-35">
            <div className="bx--col-lg-3">
              <CustomButton
                classes="outline secondary f-width font-bold"
                onClick={closeAction}>
                CLOSE
              </CustomButton>
            </div>
            <div className="bx--col-lg-6"></div>
            <div className="bx--col-lg-3">
              <CustomButton
                classes="primary f-width font-bold"
                onClick={() => window.print()}>
                <Printer size="11" strokeWidth="5px" /> &nbsp; PRINT
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EmployeesPrintOutModal;
