import React from 'react';

import './footer.styles.scss';

const Footer = ({ showLinks = true }) => (
  <div className="footer">
    <hr className="grey-hr" hidden={showLinks} />

    <p className="text-12 text-grey">
      &copy; {new Date().getFullYear()} EPOBS Comprehensive School Suite (CSS)
    </p>
    <div className="font-bold mt-10" hidden={!showLinks}>
      <a href className="text-grey-dark">
        Terms
      </a>
      &nbsp;&nbsp;&nbsp;
      <a href className="text-grey-dark">
        Privacy
      </a>
      &nbsp;&nbsp;&nbsp;
      <a href className="text-grey-dark">
        FAQs
      </a>
      &nbsp;&nbsp;&nbsp;
      <a href className="text-grey-dark">
        About us
      </a>
    </div>
  </div>
);

export default Footer;
