import React from 'react';
import { Link } from 'react-router-dom';
import './summary-card.styles.scss';

const SummaryCard = ({
  children,
  description,
  value,
  iconBackgroundColor,
  leftActionText = 'ADD',
  rightActionText = 'VIEW',
  addColor = false,
  hideRightAction = false,
  transform = false,
  dangerValue = false,
  leftAction = () => {},
  rightAction = () => {},
}) => {
  return (
    <div className={`summary-card ${addColor}`}>
      <div>
        <div className={`icon ${iconBackgroundColor} ${transform}`}>
          {children}
        </div>
        <p className="description text-truncate">{description}</p>
        <h3 className={`value text-truncate ${dangerValue}`}>{value}</h3>
      </div>

      <div className={`actions ${hideRightAction}`}>
        <span className="link pointer" onClick={leftAction}>
          {leftActionText}
        </span>

        <div hidden={hideRightAction} className="line"></div>

        <span
          hidden={hideRightAction}
          className="link pointer"
          onClick={rightAction}>
          {rightActionText}
        </span>
      </div>
    </div>
  );
};

export default SummaryCard;
