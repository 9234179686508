import React, { useState } from 'react';
import { MoreVertical } from 'react-feather';

import { ReactComponent as Group } from '../../../assets/images/card-icons/certificate.svg';

import CardMenu, {
  CardMenuItem,
} from '../../menu/card-menu/card-menu.component';

import './class-card.styles.scss';

const ClassCard = ({
  class: { id, description, semester, totalStudents },
  clickAction,
  editAction,
  deleteAction,
  archiveAction,
}) => {
  const [showCardMenu, setShowCardMenu] = useState(false);

  return (
    <div className="class-card bg-white" onClick={clickAction}>
      <div>
        <div className="top-content">
          <div className="icon">
            <Group />

            <MoreVertical
              className="pointer"
              color="#7D819D"
              onClick={(event) => {
                event.stopPropagation();
                setShowCardMenu(showCardMenu ? false : true);
              }}
            />

            {showCardMenu ? (
              <CardMenu>
                <CardMenuItem
                  title="EDIT"
                  color="primary"
                  action={(event) => {
                    event.stopPropagation();
                    editAction();
                    setShowCardMenu(showCardMenu ? false : true);
                  }}
                />
                <CardMenuItem
                  title="DELETE"
                  separator={true}
                  color="danger"
                  action={(event) => {
                    event.stopPropagation();
                    deleteAction();
                    setShowCardMenu(showCardMenu ? false : true);
                  }}
                />
                <CardMenuItem
                  title="ARCHIVE"
                  //color=""
                  separator={false}
                  action={(event) => {
                    event.stopPropagation();
                    archiveAction();
                    setShowCardMenu(showCardMenu ? false : true);
                  }}
                />
              </CardMenu>
            ) : null}
          </div>

          <div className=" bx--row">
            <div className="bx--col">
              <h3 className="title text-truncate">{description}</h3>
              <span className="subtitle">
                Semester: {semester ? 'Yes' : 'No'}
              </span>
              <br />
              <span className="subtitle">
                Import ID: <span className="font-bold">{id}</span>
              </span>
            </div>
          </div>
        </div>

        <hr className="line" />

        <div className="flex">
          <span className="text-truncate text-label">Total Students</span>
          <h4 className="text-value fade-in">{totalStudents}</h4>

          {/*<div className="divider-div">
            <div className="divider"></div>
          </div>

          <div className="position">
            <span className="text-truncate text-label">Position</span>
            <h4 className="text-value fade-in">1</h4>
            </div>*/}
        </div>

        {/*<hr className="line second" />
        <div>
          <p className="text-no-student">
            Uhoh, there are no students in this class
          </p>
          <p className="add-student pointer" onClick={() => {}}>
            <Plus size="11" strokeWidth="6px" />
            &nbsp; ADD NEW STUDENT
          </p>
        </div>

        <hr className="line" />
        <div>
          <p className="text-no-student">
            No fees attached to this class
          </p>
          <p className="add-fees pointer" onClick={()=>{}}>
            <Plus size="11"  strokeWidth="6px"/>&nbsp;
            ADD FEES
          </p>
            </div> */}
      </div>
    </div>
  );
};

export default ClassCard;
