import React from 'react';
import { withRouter } from 'react-router-dom';
import { Plus, RefreshCw, Printer, Upload, Download } from 'react-feather';
import queryString from 'query-string';
import fileDownload from 'js-file-download';

import MainHeader from '../../components/main-header/main-header.component';
import Mainmenu from '../../components/menu/main-menu/main-menu.component';
import CustomButton from '../../components/custom-button/custom-btn.component';
import StudentCard from '../../components/card/student-card/student-card.component';
import AddStudentModal from '../../components/modal/add-student/add-student.component';
import PrintOutModal from '../../components/modal/print-outs/students/students-print-out.component';
import ReceiptModal from '../../components/modal/receipt/receipt.component';
import ReceivePaymentModal from '../../components/modal/receive-payment/receive-payment.component';
import ImportDataModal from '../../components/modal/import-data/import-data.component';

import Footer from '../../components/footer/footer.component';

import {
  Search,
  Select,
  SelectItem,
  Loading,
  Pagination,
  Checkbox,
} from 'carbon-components-react';

//import svg icon
import { ReactComponent as Enrolled } from '../../assets/images/card-icons/enrolled.svg';
import { ReactComponent as Graduated } from '../../assets/images/card-icons/graduated.svg';
import { ReactComponent as Terminated } from '../../assets/images/card-icons/terminated.svg';

import StudentSummaryCard from '../../components/card/student-summary-card/student-summary-card.component';
import ConfirmDeleteModal from '../../components/modal/confirm-delete/confirm-delete.component';

import MainLoaderModal from '../../components/modal/main-loader/main-loader.component';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../redux/selectors/user.selectors';
import {
  selectClasses,
  selectClassLabels,
} from '../../redux/selectors/class.selectors';
import {
  selectAllStudents,
  selectEnrolledStudents,
  selectTerminatedStudents,
  selectGraduatedStudents,
} from '../../redux/selectors/student.selectors';
import {
  setStudents,
  removeStudent,
} from '../../redux/actions/student.actions';
import { connect } from 'react-redux';
import {
  getStudents,
  deleteStudent,
  importStudents,
  getStudentsNew,
  getStudentsInDebt,
  exportStudents,
} from '../../api/student.service';

import { getClasses, getClassLabels } from '../../api/class.service';
import { setClasses, setClassLabels } from '../../redux/actions/class.actions';

import './student.styles.scss';
import { formatDate } from '../../utils/common.util';
import { addItems } from '../../redux/utils/common.util';

class StudentPage extends React.Component {
  constructor(props) {
    super(props);

    let params = queryString.parse(props.history.location.search);

    // set state
    this.state = {
      ref: React.createRef(),
      query: '',
      classId: '',
      classLabelId: '',
      classes: this.props.classes,
      classLabels: this.props.classLabels,
      filter: 'ALL',
      students: this.props.students,
      enrolledStudents: this.props.enrolledStudents,
      graduatedStudents: this.props.graduatedStudents,
      terminatedStudents: this.props.terminatedStudents,
      loading: false,
      errorMessage: null,
      studentsToView: 'Enrolled',
      openAddNewModal: false,
      openConfirmDeleteModal: false,
      openPrintOutModal: false,
      selectedStudent: null,
      deleteDescription:
        'Please confirm student deletion. This action cannot be reversed.',
      deleteAction: () => {},
      deleteSucessAction: () => {},
      selectedPaymentStudent: null,
      openReceiptModal: false,
      selectedPayment: null,
      openReceivePaymentModal: false,
      paymentMade: false,
      openImportDataModal: false,
      openLoaderModal: false,
      loaderModalDescription: 'Importing Students...',
      loaderModalErrorMessage: null,
      loaderModaltryAgainAction: () => {},
      studentsToPrint: [],
      studentsToPrintDescription: params.in_debt ? 'In Debt' : 'Enrolled',
      paginate: { page: 1, pageSize: 30 },
      selectedStudents: [],
      viewType: 'Table',
    };

    if (params.in_debt) {
      this.togglePrintOutModal();
    }
  }

  componentDidMount() {
    if (this.state.students.length === 0) {
      this.fetchStudents();
    }

    if (this.state.classes.length === 0) {
      getClasses(this.props.currentUser.authToken).then((response) => {
        if (response.success) {
          this.props.setClasses(response.classes);
        }
      });
    }

    if (this.state.classLabels.length === 0) {
      getClassLabels(this.props.currentUser.authToken).then((response) => {
        if (response.success) {
          this.props.setClassLabels(response.classLabels);
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.classes.length !== prevProps.classes.length) {
      this.setState({ classes: this.props.classes });
    }

    if (this.props.classLabels.length !== prevProps.classLabels.length) {
      this.setState({ classLabels: this.props.classLabels });
    }

    if (!this.state.loading && this.state.paymentMade) {
      this.setState(
        {
          students: [],
          enrolledStudents: [],
          graduatedStudents: [],
          terminatedStudents: [],
          paymentMade: false,
        },
        () => {
          this.setState({
            students: this.props.students,
            enrolledStudents: this.props.enrolledStudents,
            graduatedStudents: this.props.graduatedStudents,
            terminatedStudents: this.props.terminatedStudents,
            paymentMade: false,
          });
        },
      );
    } else {
      if (this.props.students.length !== prevProps.students.length) {
        this.setState({
          students: this.props.students,
          enrolledStudents: this.props.enrolledStudents,
          graduatedStudents: this.props.graduatedStudents,
          terminatedStudents: this.props.terminatedStudents,
        });
      } else {
        // compare values
        this.props.students.forEach((newStudent) => {
          // find old
          const oldStudent = prevProps.students.find(
            (item) => item.id === newStudent.id,
          );

          if (
            newStudent.firstName !== oldStudent.firstName ||
            newStudent.lastName !== oldStudent.lastName ||
            newStudent.gender !== oldStudent.gender ||
            newStudent.classId !== oldStudent.classId ||
            newStudent.classLabelId !== oldStudent.classLabelId ||
            newStudent.photoUrl !== oldStudent.photoUrl
          ) {
            this.setState({
              students: this.props.students,
              enrolledStudents: this.props.enrolledStudents,
              graduatedStudents: this.props.graduatedStudents,
              terminatedStudents: this.props.terminatedStudents,
            });
          }
        });
      }
    }
  }

  fetchStudents = async () => {
    this.setState({ loading: true, errorMessage: null });

    // get auth token
    const { authToken } = this.props.currentUser;

    const skip = 0; // skip none

    const response = await getStudents(authToken, skip, 2000, '', -1, '');

    if (!response.success) {
      // failed

      // if request failed but redux has a list of students for us already,
      // take first 100 and show that
      const students = this.props.students.slice(0, 100);

      if (students.length > 0) {
        this.setState({ loading: false });
      } else {
        // show error message
        this.setState({
          loading: false,
          errorMessage: response.message,
        });
      }

      return;
    }

    // all good, update state with students
    this.setState({ loading: false });

    // update redux
    this.props.setStudents(response.students);
  };

  toggleImportModal = () => {
    this.setState({
      openImportDataModal: !this.state.openImportDataModal,
      openAddNewModal: this.state.openImportDataModal,
    });
  };

  togglePrintOutModal = async () => {
    const value = !this.state.openPrintOutModal;

    if (value) {
      this.setState({
        openLoaderModal: true,
        loaderModalErrorMessage: null,
        loaderModalDescription: 'Loading...',
      });

      let response = null;
      if (this.state.studentsToPrintDescription === 'In Debt') {
        response = await getStudentsInDebt(
          this.props.currentUser.authToken,
          this.state.classId.length > 0 ? parseInt(this.state.classId) : -1,
        );
      } else {
        response = await getStudentsNew(
          this.props.currentUser.authToken,
          this.state.classId.length > 0 ? parseInt(this.state.classId) : -1,
          this.state.classLabelId.length > 0
            ? parseInt(this.state.classLabelId)
            : -1,
          this.state.studentsToView,
          true,
        );
      }

      if (!response.success) {
        this.setState({
          loaderModalErrorMessage: response.message,
          loaderModaltryAgainAction: this.togglePrintOutModal,
        });

        return;
      }

      this.setState({
        studentsToPrint: response.students,
        openLoaderModal: false,
        openPrintOutModal: true,
      });
    } else {
      this.setState({
        openPrintOutModal: false,
      });
    }
  };

  onImportStudents = async (csvFile) => {
    this.setState({
      openImportDataModal: false,
      openLoaderModal: true,
      loaderModalErrorMessage: null,
      loaderModalDescription: 'Importing Students...',
    });

    const formData = new FormData();
    formData.append('formFile', csvFile);

    // import students
    const response = await importStudents(
      this.props.currentUser.authToken,
      formData,
    );

    if (!response.success) {
      this.setState({
        loaderModalErrorMessage: response.message,
        loaderModaltryAgainAction: this.onImportStudents,
      });

      return;
    }

    this.setState({ openLoaderModal: false });

    this.fetchStudents();
  };

  exportStudents = async () => {
    this.setState({
      openLoaderModal: true,
      loaderModalErrorMessage: null,
      loaderModalDescription: 'Exporting Students...',
    });
    let status = this.state.studentsToView,
      classId = this.state?.classId || -1,
      classLabelId = this.state?.classLabelId || -1;
    const response = await exportStudents(
      this.props.currentUser.authToken,
      status,
      classId,
      classLabelId,
    );

    if (response.success) {
      this.setState({
        openLoaderModal: false,
      });
      console.log(response.data);
      fileDownload(response.data.data, `students-${status}-${new Date()}.xlsx`);
    }

    this.setState({
      loaderModalErrorMessage: response.message,
      loaderModaltryAgainAction: this.exportStudents,
    });
  };

  filter = () => {
    let students =
      this.state.studentsToView === 'Enrolled'
        ? [...this.state.enrolledStudents]
        : this.state.studentsToView === 'Graduated'
          ? [...this.state.graduatedStudents]
          : [...this.state.terminatedStudents];

    // if class is not empty, filter by class
    if (
      this.state.studentsToView === 'Enrolled' &&
      this.state.classId.length > 0
    ) {
      students = students.filter((item) => item.classId == this.state.classId);
    }

    // if class label is not empty, filter by class label
    if (
      this.state.studentsToView === 'Enrolled' &&
      this.state.classLabelId.length > 0
    ) {
      students = students.filter(
        (item) => item.classLabelId == this.state.classLabelId,
      );
    }

    // perform filter, if is male or female
    if (this.state.filter === 'Male' || this.state.filter === 'Female') {
      // filter based on gender
      students = students.filter((item) => item.gender === this.state.filter);
    }

    if (this.state.filter === 'IN_DEBT') students = [];

    // perform query if any
    if (this.state.query.length > 0) {
      const query = this.state.query.toLowerCase();
      students = students.filter(
        (item) =>
          item.studentId.toLowerCase().includes(query) ||
          item.firstName.toLowerCase().includes(query) ||
          item.lastName.toLowerCase().includes(query),
      );
    }

    return students;
  };

  render() {
    const students = this.filter();

    return (
      <div>
        <a style={{ display: 'none' }} href="empty" ref={this.state.ref}>
          ref
        </a>

        <MainHeader />

        <MainLoaderModal
          openModal={this.state.openLoaderModal}
          loading={this.state.loaderModalLoadingStatus}
          description={this.state.loaderModalDescription}
          errorMessage={this.state.loaderModalErrorMessage}
          tryAgainAction={this.state.loaderModaltryAgainAction}
          cancelAction={() => this.setState({ openLoaderModal: false })}
        />

        <AddStudentModal
          openModal={this.state.openAddNewModal}
          student={this.state.selectedStudent}
          importAction={this.toggleImportModal}
          closeAction={() => this.setState({ openAddNewModal: false })}
        />

        <ImportDataModal
          openModal={this.state.openImportDataModal}
          heading="Import Students"
          description="Add Students in bulk through a CSV file. "
          importAction={(csvFile) => this.onImportStudents(csvFile)}
          cancelAction={this.toggleImportModal}
        />

        <ConfirmDeleteModal
          openModal={this.state.openConfirmDeleteModal}
          description={this.state.deleteDescription}
          deleteAction={this.state.deleteAction}
          deleteSuccessAction={this.state.deleteSucessAction}
          cancelAction={() => this.setState({ openConfirmDeleteModal: false })}
        />
        {this.state.openPrintOutModal && (
          <PrintOutModal
            open={this.state.openPrintOutModal}
            school={this.props.currentUser.school}
            studentsToView={this.state.studentsToPrintDescription}
            filter={this.state.filter}
            class_={this.state.classes.find((c) => c.id == this.state.classId)}
            classLabel={this.state.classLabels.find(
              (cl) => cl.id == this.state.classLabelId,
            )}
            students={this.state.studentsToPrint}
            closeAction={this.togglePrintOutModal}
          />
        )}

        {this.state.openReceiptModal && (
          <ReceiptModal
            openModal={this.state.openReceiptModal}
            selectedPayment={this.state.selectedPayment}
            cancelAction={() => this.setState({ openReceiptModal: false })}
          />
        )}

        {this.state.openReceivePaymentModal && (
          <ReceivePaymentModal
            openModal={this.state.openReceivePaymentModal}
            paymentToRevise={null}
            student={this.state.selectedPaymentStudent}
            showReceipt={(payment) => {
              this.setState(
                {
                  openReceivePaymentModal: false,
                  openReceiptModal: true,
                  selectedPayment: payment,
                  paymentMade: true,
                },
                () => {
                  // reload students
                  this.fetchStudents();
                },
              );
            }}
            cancelAction={() =>
              this.setState({ openReceivePaymentModal: false })
            }
          />
        )}

        <div className="student page-container">
          <div>
            <span className="h-18">Students</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span
              className="text-12 text-primary font-bold pointer"
              onClick={() =>
                this.setState({ openAddNewModal: true, selectedStudent: null })
              }>
              <Plus size="11" strokeWidth="5" /> ADD NEW
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span
              className="text-12 text-primary font-bold pointer"
              onClick={() => {
                this.fetchStudents();
                this.setState({ studentsToView: 'Enrolled', filter: 'ALL' });
              }}>
              <RefreshCw size="11" strokeWidth="3" /> REFRESH
            </span>
            <span className="right">
              <Mainmenu />
            </span>
          </div>
          <div className="mt-20">
            <div className="bx--row">
              <div className="bx--col-lg-3">
                <StudentSummaryCard
                  title="Enrolled"
                  value={this.state.enrolledStudents.length}
                  active={this.state.studentsToView === 'Enrolled'}
                  action={() =>
                    this.setState({
                      studentsToView: 'Enrolled',
                      students: this.props.enrolledStudents,
                      paginate: { page: 1, pageSize: 9 },
                    })
                  }>
                  <Enrolled />
                </StudentSummaryCard>
              </div>
              <div className="bx--col-lg-3">
                <StudentSummaryCard
                  title="Graduated"
                  value={this.state.graduatedStudents.length}
                  active={this.state.studentsToView === 'Graduated'}
                  action={() =>
                    this.setState({
                      studentsToView: 'Graduated',
                      studentsToPrintDescription: 'Graduated',
                      students: this.props.graduatedStudents,
                      paginate: { page: 1, pageSize: 9 },
                    })
                  }>
                  <Graduated />
                </StudentSummaryCard>
              </div>
              <div className="bx--col-lg-3">
                <StudentSummaryCard
                  title="Terminated"
                  value={this.state.terminatedStudents.length}
                  active={this.state.studentsToView === 'Terminated'}
                  action={() =>
                    this.setState({
                      studentsToView: 'Terminated',
                      studentsToPrintDescription: 'Terminated',
                      students: this.props.terminatedStudents,
                      paginate: { page: 1, pageSize: 9 },
                    })
                  }>
                  <Terminated />
                </StudentSummaryCard>
              </div>
              <div className="bx--col-lg-3">
                <StudentSummaryCard
                  title="Export"
                  value={'Students'}
                  action={() => {
                    this.exportStudents();
                    //this.props.history.push(`students/attendance`)
                  }}>
                  <Download size="20" strokeWidth="5px" />
                </StudentSummaryCard>
              </div>
            </div>
          </div>

          <div className="mt-30">
            <span className="h-18">{this.state.studentsToView}</span>
            {/*  <span
              className="right text-primary text-12 font-bold pointer"
              onClick={this.exportStudents}
            >
              <Download size="12" strokeWidth="5px" />&nbsp;Export&nbsp; &nbsp; &nbsp;
            </span> */}
            &nbsp; &nbsp; &nbsp;
            <span
              className="right text-primary text-12 font-bold pointer"
              onClick={this.togglePrintOutModal}>
              <Printer size="12" strokeWidth="5px" />
              &nbsp;PRINT&nbsp; &nbsp; &nbsp;
            </span>
          </div>

          <div
            className="mt-20"
            hidden={
              this.state.loading ||
              this.state.errorMessage != null ||
              this.props.students.length === 0
            }>
            <div className="bx--row">
              <div
                className="bx--col-lg-2"
                hidden={this.state.studentsToView !== 'Enrolled'}>
                <Select
                  noLabel
                  onChange={(e) =>
                    this.setState({
                      selectedStudents: [],
                      classId: e.target.value,
                      paginate: { page: 1, pageSize: 9 },
                    })
                  }>
                  <SelectItem selected disabled text="Filter by Class" />
                  <SelectItem text="All" value="" />
                  {this.state.classes.map((item) => (
                    <SelectItem text={item.description} value={item.id} />
                  ))}
                </Select>
              </div>
              <div
                className="bx--col-lg-2"
                hidden={this.state.studentsToView !== 'Enrolled'}>
                <Select
                  noLabel
                  onChange={(e) =>
                    this.setState({
                      selectedStudents: [],
                      classLabelId: e.target.value,
                      paginate: { page: 1, pageSize: 9 },
                    })
                  }>
                  <SelectItem selected disabled text="Filter by Label" />
                  <SelectItem text="All" value="" />
                  {this.state.classLabels.map((item) => (
                    <SelectItem text={item.description} value={item.id} />
                  ))}
                </Select>
              </div>
              <div
                className={`${
                  this.state.studentsToView === 'Enrolled'
                    ? 'bx--col-lg-8'
                    : 'bx--col-lg-12'
                }`}>
                <Search
                  id="search"
                  name="search"
                  labelText=""
                  value={this.state.query}
                  onChange={(event) =>
                    this.setState({
                      query: event.target.value,
                      paginate: { page: 1, pageSize: 9 },
                    })
                  }
                  placeHolderText="Enter student id, first or last name to search"
                />
              </div>
            </div>
          </div>
          <div
            className="mt-30"
            hidden={
              this.state.loading ||
              this.state.errorMessage != null ||
              this.props.students.length === 0
            }>
            <div className="bx--row">
              <span className="bx--col-lg-2">
                <CustomButton
                  classes={`pill text-12 f-width font-bold ${
                    this.state.filter === 'ALL' ? 'primary' : 'ash'
                  }`}
                  onClick={() =>
                    this.setState({
                      selectedStudents: [],
                      filter: 'ALL',
                      paginate: { page: 1, pageSize: 9 },
                    })
                  }>
                  ALL&nbsp;
                  {this.state.filter === 'ALL' ? `(${students.length})` : ''}
                </CustomButton>
              </span>
              <span className="bx--col-lg-2">
                <CustomButton
                  classes={`pill text-12 f-width font-bold ${
                    this.state.filter === 'Male' ? 'primary' : 'ash'
                  }`}
                  onClick={() =>
                    this.setState({
                      selectedStudents: [],
                      filter: 'Male',
                      paginate: { page: 1, pageSize: 9 },
                    })
                  }>
                  MALE&nbsp;
                  {this.state.filter === 'Male' ? `(${students.length})` : ''}
                </CustomButton>
              </span>
              <span className="bx--col-lg-2">
                <CustomButton
                  classes={`pill text-12 f-width font-bold ${
                    this.state.filter === 'Female' ? 'primary' : 'ash'
                  }`}
                  onClick={() =>
                    this.setState({
                      selectedStudents: [],
                      filter: 'Female',
                      paginate: { page: 1, pageSize: 9 },
                    })
                  }>
                  FEMALE&nbsp;
                  {this.state.filter === 'Female' ? `(${students.length})` : ''}
                </CustomButton>
              </span>
              <span className="bx--col-lg-2">
                <CustomButton
                  classes="pill text-12 f-width font-bold ash"
                  onClick={() =>
                    this.setState(
                      { studentsToPrintDescription: 'In Debt' },
                      this.togglePrintOutModal,
                    )
                  }>
                  IN DEBT
                </CustomButton>
              </span>
            </div>
          </div>
          <div className="mt-20" hidden={!this.state.loading}>
            <Loading
              small={true}
              withOverlay={false}
              description="Loading..."
            />
          </div>
          <div className="mt-30" hidden={this.state.errorMessage == null}>
            <span className="mt-10 text-danger">
              {this.state.errorMessage}.
            </span>
            &nbsp;
            <span
              className="text-primary pointer font-bold"
              onClick={() => this.fetchStudents()}>
              TRY AGAIN
            </span>
          </div>
          <div className="mt-10 ml-10">
            <Checkbox
              labelText={`${
                this.state.viewType === 'Table' ? 'Card' : 'Table'
              } View`}
              id="viewType"
              checked={this.state.viewType === 'Card'} //this.state.selectedStudents.length
              onChange={(value) => {
                if (value) {
                  this.setState({
                    viewType: 'Card',
                    paginate: { page: 1, pageSize: 9 },
                  });
                } else {
                  this.setState({
                    viewType: 'Table',
                    paginate: { page: 1, pageSize: 30 },
                  });
                }
              }}
            />
          </div>
          <p
            className="text-14 mt-30 text-grey"
            hidden={
              this.state.students.length > 0 ||
              this.state.loading ||
              this.state.errorMessage !== null
            }>
            There are no {this.state.studentsToView} students
          </p>
          {this.state.selectedStudents.length > 0 && (
            <p className="mt-20">
              <Checkbox
                labelText={`All (${this.state.selectedStudents.length} / ${students?.length})`}
                id="allMainCheckbox"
                checked={
                  students?.length === this.state.selectedStudents.length
                } //this.state.selectedStudents.length
                onChange={(value) => {
                  let selectedStudents = this.state.selectedStudents;
                  if (value) {
                    //let currentShowingStudents=students.slice((this.state.paginate.page - 1 ) * this.state.paginate.pageSize,this.state.paginate.page * this.state.paginate.pageSize)
                    selectedStudents = addItems(
                      this.state.selectedStudents,
                      students,
                    ); //students
                  } else {
                    selectedStudents = [];
                  }

                  this.setState({
                    selectedStudents: selectedStudents,
                  });
                }}
              />
            </p>
          )}
          <div className="mt-30" hidden={this.state.loading === true}>
            {this.state.viewType === 'Table' && (
              <div className="table-container ">
                {students && (
                  <table className="bx--data-table table-white">
                    <thead>
                      <tr>
                        <th>
                          <Checkbox
                            id="sMainCheckbox"
                            checked={
                              this.state.selectedStudents?.slice(
                                (this.state.paginate.page - 1) *
                                  this.state.paginate.pageSize,
                                this.state.paginate.page *
                                  this.state.paginate.pageSize,
                              )?.length ===
                              students.slice(
                                (this.state.paginate.page - 1) *
                                  this.state.paginate.pageSize,
                                this.state.paginate.page *
                                  this.state.paginate.pageSize,
                              )?.length
                            } //this.state.selectedStudents.length
                            onChange={(value) => {
                              let selectedStudents =
                                this.state.selectedStudents;
                              if (value) {
                                let currentShowingStudents = students.slice(
                                  (this.state.paginate.page - 1) *
                                    this.state.paginate.pageSize,
                                  this.state.paginate.page *
                                    this.state.paginate.pageSize,
                                );
                                selectedStudents = addItems(
                                  selectedStudents,
                                  currentShowingStudents,
                                ); //students
                              } else {
                                selectedStudents = [];
                              }

                              this.setState({
                                selectedStudents: selectedStudents,
                              });
                            }}
                          />
                        </th>
                        <th>Student ID</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Gender</th>
                        <th>Date Of Birth</th>
                        <th>Class</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {students
                        .slice(
                          (this.state.paginate.page - 1) *
                            this.state.paginate.pageSize,
                          this.state.paginate.page *
                            this.state.paginate.pageSize,
                        )
                        ?.map((student) => (
                          <tr key={student.id}>
                            <td>
                              <Checkbox
                                id={`s${student.id}`}
                                name={`s${student.id}`}
                                checked={
                                  this.state.selectedStudents.find(
                                    (item) => item.id === student.id,
                                  ) !== undefined
                                }
                                onChange={(value) => {
                                  const selectedStudents =
                                    this.state.selectedStudents;
                                  if (value) {
                                    selectedStudents.push(student);
                                  } else {
                                    // remove
                                    const index = selectedStudents.findIndex(
                                      (item) => item.id === student.id,
                                    );

                                    selectedStudents.splice(index, 1);
                                  }
                                  this.setState({
                                    selectedStudents: selectedStudents,
                                  });
                                }}
                              />
                            </td>
                            <td>
                              {student.schoolStudentId
                                ? student.schoolStudentId
                                : student.studentId}
                            </td>
                            <td>{student.firstName}</td>
                            <td>{student.lastName}</td>
                            <td>{student.gender}</td>
                            <td>
                              {student.dateOfBirth
                                ? formatDate(student.dateOfBirth)
                                : 'N/A'}
                            </td>
                            <td>
                              {student.classDescription}&nbsp;
                              {student.classLabelDescription}
                            </td>
                            <td>
                              <span
                                className="text-primary pointer font-bold"
                                onClick={() =>
                                  this.props.history.push(
                                    `/student/${student.id}`,
                                  )
                                }>
                                View
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </div>
            )}

            {this.state.viewType === 'Card' && (
              <div className="bx--row">
                {students
                  .slice(
                    (this.state.paginate.page - 1) *
                      this.state.paginate.pageSize,
                    this.state.paginate.page * this.state.paginate.pageSize,
                  )
                  ?.map((student) => (
                    <div key={student.id} className="bx--col-lg-4 fade-in">
                      <StudentCard
                        student={student}
                        cardAction={() =>
                          this.props.history.push(`/student/${student.id}`)
                        }
                        makePayment={() => {
                          this.setState({
                            openReceivePaymentModal: true,
                            selectedPaymentStudent: student,
                          });
                        }}
                        editAction={() =>
                          this.setState({
                            openAddNewModal: true,
                            selectedStudent: student,
                          })
                        }
                        deleteAction={() =>
                          this.setState({
                            openConfirmDeleteModal: true,
                            deleteAction: async () => {
                              return await deleteStudent(
                                this.props.currentUser.authToken,
                                {
                                  ...student,
                                },
                              );
                            },
                            deleteSucessAction: () => {
                              this.props.removeStudent(student.id);
                            },
                            deleteDescription: `Confirm deletion of Student ${student.firstName} ${student.lastName} [${student.studentId}]. This action cannot be reversed`,
                          })
                        }
                      />
                    </div>
                  ))}
              </div>
            )}

            <Pagination
              size="sm"
              itemsPerPageText="Items&nbsp;per&nbsp;page"
              backwardText="Previous page"
              forwardText="Next page"
              page={this.state.paginate.page}
              pageNumberText="Page Number"
              pageSize={this.state.paginate.pageSize}
              pageSizes={[9, 15, 30, 60, 50, 100]}
              totalItems={this.filter().length || 0}
              onChange={(value) => {
                console.log(value);
                this.setState({
                  paginate: { page: value.page, pageSize: value.pageSize },
                });
              }}
            />
          </div>
          <Footer fixed={false} showLinks={false} />
        </div>
      </div>
    );
  }
}

// this will provide us with the state values we need
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  classes: selectClasses,
  classLabels: selectClassLabels,
  students: selectAllStudents,
  enrolledStudents: selectEnrolledStudents,
  graduatedStudents: selectGraduatedStudents,
  terminatedStudents: selectTerminatedStudents,
});

// this allows us to map our redux actions to our component
const mapDispatchToProps = (dispatch) => ({
  setStudents: (students) => dispatch(setStudents(students)),
  setClasses: (classes) => dispatch(setClasses(classes)),
  setClassLabels: (classLabels) => dispatch(setClassLabels(classLabels)),
  removeStudent: (studentId) => dispatch(removeStudent(studentId)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StudentPage),
);
