import { call, put, all, takeLatest } from 'redux-saga/effects';
import { postData } from '../../api/api';
import ApiUrls from '../../api/urls';
import UserActionTypes from './user.types';
import { authenticationSuccess, authenticationFailed } from './user.actions';

// authenticate user saga
function* authenticateUser(action) {
  const response = yield call(postData, ApiUrls.AUTHENTICATE, action.payload);
  if (response.success) {
    // clear local storage
    localStorage.clear();
    yield put(authenticationSuccess(response.data.user));
  } else {
    yield put(authenticationFailed(response.message));
  }
}

function* onAuthenticateUser() {
  yield takeLatest(UserActionTypes.AUTHENTICATE_REQUESTED, authenticateUser);
}

export default function* userSagas() {
  yield all([call(onAuthenticateUser)]);
}
