import React, { useState, useGlobal, useEffect } from 'reactn';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  TextInput,
  FormItem,
  Form,
  FormGroup,
  Loading,
} from 'carbon-components-react';

import Slider from '../../../components/slider/slider.component';
import CustomButton from '../../../components/custom-button/custom-btn.component';
import BorderedLogo from '../../../components/bordered-logo/bordered-logo.component';
import Footer from '../../../components/footer/footer.component';
import {
  selectAuthenticating,
  selectAuthenticatingError,
} from '../../../redux/user/user.selectors';
import { authenticate } from '../../../redux/user/user.actions';

import loginBg from '../../../assets/images/login_bg.png';

import './login.styles.scss';

const LoginPage = ({ authenticating, authenticatingError, authenticate }) => {
  // create state
  const [state, setState] = useState({
    username: '',
    password: '',
  });

  // handle change handler
  const handleChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  // destructure credentials
  const { username, password } = state;

  // validate
  const validate = () => username.length > 0 && password.length > 0;

  // on login
  const onLogin = () => {
    const data = {
      username: username,
      password: password,
    };

    authenticate(data);
  };

  const [dashboardMetrics, setDashboardMetrics] = useGlobal('dashboardMetrics');

  useEffect(() => {
    if (dashboardMetrics) {
      setDashboardMetrics(null);
      console.log('summary removed');
    }
  }, []);

  return (
    <div className="login">
      <div className="bx--row">
        <div
          className="bx--col-lg-5"
          style={{ zIndex: 100, background: '#f9f9fb' }}>
          <div
            className=""
            style={{
              width: '500px',
              padding: '0 20px',
              maxWidth: '90%',
              margin: '0 auto',
            }}>
            <div className="mt-60 mb-50">
              <BorderedLogo />
            </div>

            <h3 className="text-primary font-bold text-24 mb-30">
              Login to your account
            </h3>

            <Form action="post">
              <FormGroup legendText="">
                <FormItem>
                  <TextInput
                    id="username"
                    name="username"
                    labelText="Username"
                    value={username}
                    onChange={handleChange}
                    placeholder="Enter username"
                  />
                </FormItem>
              </FormGroup>

              <FormGroup legendText="">
                <FormItem>
                  <TextInput.PasswordInput
                    id="password"
                    name="password"
                    labelText="Password"
                    value={password}
                    onChange={handleChange}
                    placeholder="Enter password"
                  />
                </FormItem>
              </FormGroup>

              <Link
                to="/account/forgot-password"
                className="text-primary text-12 font-bold no-decoration">
                Forgot password?
              </Link>

              <CustomButton
                type="button"
                classes="primary f-width mt-30"
                onClick={onLogin}
                disabled={!validate() || authenticating}>
                {!authenticating && 'LOGIN'}

                <div hidden={!authenticating}>
                  <div className="d-flex-center">
                    <Loading
                      withOverlay={false}
                      small={true}
                      description="Please wait..."
                    />
                  </div>
                </div>
              </CustomButton>

              <p
                className="text-danger mt-10 nmb text-14"
                hidden={!authenticatingError}>
                {authenticatingError}
              </p>

              <div className="mt-50">
                <Link
                  to="/school/register"
                  className="text-primary text-14  font-bold">
                  Register my school?
                </Link>
              </div>
            </Form>

            <Footer />
          </div>
        </div>
        <div className="bx--col-lg-7 fixed-right padding-0">
          <div className="bx--row">
            <Slider />
          </div>
          <img className="img-f-w-obj-cover" src={loginBg} alt="" />
        </div>
      </div>
    </div>
  );
};

// map state values
const mapStateToProps = createStructuredSelector({
  authenticating: selectAuthenticating,
  authenticatingError: selectAuthenticatingError,
});

// map redux actions
const mapDispatchToProps = (dispatch) => ({
  authenticate: (data) => dispatch(authenticate(data)),
});

// connect
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
