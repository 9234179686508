import EmployeeActionTypes from '../types/employee.types';

// set current user action
export const setEmployees = (employees) => ({
  type: EmployeeActionTypes.SET_EMPLOYEES,
  payload: employees,
});

export const setDepartments = (departments) => ({
  type: EmployeeActionTypes.SET_DEPARTMENTS,
  payload: departments,
});

export const removeDepartment = (departmentId) => ({
  type: EmployeeActionTypes.REMOVE_DEPARTMENT,
  payload: departmentId,
});

export const removeEmployee = (employeeId) => ({
  type: EmployeeActionTypes.REMOVE_EMPLOYEE,
  payload: employeeId,
});
