import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { Plus } from 'react-feather';

import { Search, Loading } from 'carbon-components-react';

import MainHeader from '../../components/main-header/main-header.component';
import Mainmenu from '../../components/menu/main-menu/main-menu.component';
import AddClassModal from '../../components/modal/add-class/add-class.component';
import AddTermModal from '../../components/modal/add-term/add-term.component';
import AddStudentModal from '../../components/modal/add-student/add-student.component';
import QuickActionCard from '../../components/card/quick-action-card/quick-action-card.component';
import AddClassLabelModal from '../../components/modal/add-class-label/add-class-label.component';
import ClassCard from '../../components/card/class-card/class-card.component';
import Footer from '../../components/footer/footer.component';
import ConfirmDeleteModal from '../../components/modal/confirm-delete/confirm-delete.component';

// import our redux stuffs
import {
  selectClasses,
  selectClassLabels,
} from '../../redux/selectors/class.selectors';
import { selectCurrentUser } from '../../redux/selectors/user.selectors';
import {
  setClasses,
  removeClass,
  setClassLabels,
} from '../../redux/actions/class.actions';

// import api
import {
  getClasses,
  deleteClass,
  getClassLabels,
  archiveClass,
} from '../../api/class.service';

//import svg icon
import { ReactComponent as Target } from '../../assets/images/card-icons/target.svg';
import { ReactComponent as Teamwork } from '../../assets/images/card-icons/teamwork.svg';

import './classes.styles.scss';

const plusIcon = <Plus size="25" strokeWidth="2px" />;

class ClassesPage extends React.Component {
  constructor(props) {
    super(props);

    // set state
    this.state = {
      query: '',
      loading: props.classes.length === 0,
      classes: props.classes,
      errorMessage: null,
      openAddClassModal: false,
      openAddTermModal: false,
      openAddStudentModal: false,
      openAddClassLabelModal: false,
      openConfirmDeleteModal: false,
      selectedClass: null,
      deleteDescription:
        'Please confirm class deletion. This action cannot be reversed.',
      deleteAction: () => {},
      deleteSucessAction: () => {},
    };
  }

  componentDidMount() {
    // fetch classes only if length is 0
    if (this.state.classes.length === 0) {
      this.fetchClasses();
    }

    if (this.props.classLabels.length === 0) {
      getClassLabels(this.props.currentUser.authToken).then((response) => {
        // save to redux
        this.props.setClassLabels(response.classLabels);
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.classes.length !== prevProps.classes.length) {
      this.setState({ classes: this.props.classes });
    } else {
      // compare values
      this.props.classes.forEach((item) => {
        const oldItem = prevProps.classes.find((i) => i.id === item.id);

        if (item.description !== oldItem.description) {
          this.setState({ classes: this.props.classes });
          return;
        }
      });
    }
  }

  fetchClasses = async () => {
    // show loading if only is not already showing
    if (!this.state.loading)
      this.setState({ loading: true, errorMessage: null });

    // initiate request
    const response = await getClasses(this.props.currentUser.authToken);

    // request failed, show error message
    if (!response.success) {
      this.setState({ loading: false, errorMessage: response.message });

      return;
    }

    // successful
    this.setState({ loading: false });

    // save to redux
    this.props.setClasses(response.classes);
  };

  render() {
    const classes =
      this.state.query.length > 0
        ? this.state.classes.filter(
            (item) =>
              item.description
                .toLowerCase()
                .includes(this.state.query.toLowerCase()) ||
              item.uniqueId
                .toLowerCase()
                .includes(this.state.query.toLowerCase()),
          )
        : this.state.classes;

    return (
      <div>
        <MainHeader />

        <AddClassModal
          openModal={this.state.openAddClassModal}
          class={this.state.selectedClass}
          cancelAction={() => this.setState({ openAddClassModal: false })}
        />

        <AddTermModal
          openModal={this.state.openAddTermModal}
          cancelAction={() => this.setState({ openAddTermModal: false })}
        />

        <AddStudentModal
          openModal={this.state.openAddStudentModal}
          closeAction={() => this.setState({ openAddStudentModal: false })}
        />

        <AddClassLabelModal
          openModal={this.state.openAddClassLabelModal}
          cancelAction={() => this.setState({ openAddClassLabelModal: false })}
        />

        <ConfirmDeleteModal
          openModal={this.state.openConfirmDeleteModal}
          description={this.state.deleteDescription}
          deleteAction={this.state.deleteAction}
          deleteSuccessAction={this.state.deleteSucessAction}
          cancelAction={() => this.setState({ openConfirmDeleteModal: false })}
        />

        <div className="classes page-container">
          <div>
            <span className="h-18">Quick Actions</span>
            <span className="right">
              {' '}
              <Mainmenu />{' '}
            </span>
          </div>

          <div className="bx--row mt-30">
            <div className="bx--col-md-2">
              <div
                className="card-add-class pointer"
                onClick={() => {
                  this.setState({
                    openAddClassModal: true,
                    selectedClass: null,
                  });
                }}>
                <div className="plus-icon">{plusIcon}</div>
                <div className="text-label">ADD A NEW CLASS</div>
              </div>
            </div>

            <div className="bx--col-md-2">
              <QuickActionCard
                description="Class Labels"
                leftActionText="ADD"
                rightActionText="VIEW"
                actionEvent1={() => {
                  this.setState({ openAddClassLabelModal: true });
                }}
                actionEvent2={() => {
                  this.props.history.push('/class-label');
                }}>
                <Target color="#fff" />
              </QuickActionCard>
            </div>

            <div className="bx--col-md-2">
              <QuickActionCard
                description="Students"
                leftActionText="ADD"
                rightActionText="VIEW"
                actionEvent1={() => {
                  this.setState({ openAddStudentModal: true });
                }}
                actionEvent2={() => this.props.history.push('/student')}>
                <Teamwork />
              </QuickActionCard>
            </div>
          </div>

          <hr className="grey-hr mt-30" />

          <div className="mt-20">
            <span className="h-24">Classes</span>&nbsp;
          </div>

          <div className="mt-10">
            <Search
              id="query"
              name="query"
              value={this.state.query}
              placeHolderText="Search by name of class"
              onChange={(event) => this.setState({ query: event.target.value })}
            />
          </div>

          <div className="mt-20" hidden={!this.state.loading}>
            <Loading
              small={true}
              withOverlay={false}
              description="Loading..."
            />
          </div>

          <div className="mt-30" hidden={this.state.errorMessage == null}>
            <span className="mt-10 text-danger">
              {this.state.errorMessage}.
            </span>
            &nbsp;
            <span
              className="text-primary pointer font-bold"
              onClick={() => this.fetchClasses()}>
              TRY AGAIN
            </span>
          </div>

          <p
            className="text-14 mt-30 text-grey"
            hidden={
              this.state.classes.length > 0 ||
              this.state.loading ||
              this.state.errorMessage !== null
            }>
            No class added
          </p>

          <div className="bx--row mb-40 mt-30">
            {classes
              .sort((a, b) => a.position - b.position)
              .map((item) => (
                <div className="bx--col-md-2 fade-in">
                  <ClassCard
                    class={item}
                    clickAction={() =>
                      this.props.history.push(`/class/${item.id}`)
                    }
                    editAction={() =>
                      this.setState({
                        openAddClassModal: true,
                        selectedClass: item,
                      })
                    }
                    deleteAction={() =>
                      this.setState({
                        openConfirmDeleteModal: true,
                        deleteAction: async () => {
                          return await deleteClass(
                            this.props.currentUser.authToken,
                            {
                              id: item.id,
                              description: item.description,
                            },
                          );
                        },
                        deleteSucessAction: () => {
                          this.props.removeClass(item.id);
                        },
                        deleteDescription: `Confirm deletion of ${item.description}. This action cannot be reversed`,
                      })
                    }
                    archiveAction={() =>
                      this.setState({
                        openConfirmDeleteModal: true,
                        deleteAction: async () => {
                          return await archiveClass(
                            this.props.currentUser.authToken,
                            {
                              ...item,
                              archived: true,
                            },
                          );
                        },
                        deleteSucessAction: () => {
                          this.props.removeClass(item.id);
                          this.fetchClasses();
                        },
                        deleteDescription: `Do you want to archive this class ${item.description}. This action cannot be reversed`,
                      })
                    }
                  />
                </div>
              ))}
          </div>

          <Footer fixed={false} showLinks={false} />
        </div>
      </div>
    );
  }
}

// this will provide us with the state values we need
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  classes: selectClasses,
  classLabels: selectClassLabels,
});

// this allows us to map our redux actions to our component
const mapDispatchToProps = (dispatch) => ({
  setClasses: (classes) => dispatch(setClasses(classes)),
  setClassLabels: (classLabels) => dispatch(setClassLabels(classLabels)),
  removeClass: (classId) => dispatch(removeClass(classId)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClassesPage),
);
