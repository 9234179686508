import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { MoreVertical } from 'react-feather';

import { selectLedgerAccounts } from '../../../redux/selectors/chart-of-accounts.selector';
import {
  setLedgerAccounts,
  removeLedgerAccount,
} from '../../../redux/actions/chart-of-accounts.actions';
import { selectCurrentUser } from '../../../redux/selectors/user.selectors';

import LedgerAccountCard from '../ledger-account/ledger-account.component';
import ConfirmDeleteModal from '../../modal/confirm-delete/confirm-delete.component';
import { ReactComponent as Hierarchy } from '../../../assets/images/card-icons/hierarchy.svg';

import CardMenu, {
  CardMenuItem,
} from '../../menu/card-menu/card-menu.component';
import { deleteLedgerAccount } from '../../../api/chart-of-accounts.service';

import './ledger-card.styles.scss';

class LedgerCategoryCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ledgerAccounts: [],
      showCardMenu: false,
      openConfirmDeleteModal: false,
      deleteDescription:
        'Please confirm Ledger deletion. This action cannot be reversed.',
      deleteAction: () => {},
      deleteSucessAction: () => {},
    };
  }

  componentDidMount() {
    const ledgerAccounts = this.props.ledgerAccounts;
    if (ledgerAccounts.length > 0) {
      this.setLedgerAccounts(ledgerAccounts);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.ledgerAccounts.length !== prevProps.ledgerAccounts.length) {
      this.setLedgerAccounts(this.props.ledgerAccounts);
    } else {
      // do a loop and compare each values
      // set ledger accounts and break out
      // if there is a difference in any item
      this.props.ledgerAccounts.forEach((newItem) => {
        const olderItem = prevProps.ledgerAccounts.find(
          (ledgerAccount) => ledgerAccount.id === newItem.id,
        );

        if (
          (olderItem && olderItem.description !== newItem.description) ||
          olderItem.categoryId !== newItem.categoryId
        ) {
          this.setLedgerAccounts(this.props.ledgerAccounts);
          return;
        }
      });
    }
  }

  setLedgerAccounts = (ledgerAccounts) => {
    // filter out the ones that belongs to this category
    const categoryLedgerAccounts = ledgerAccounts.filter((item) => {
      return item.categoryId === this.props.ledgerCategory.id;
    });

    // go ahead and update state
    this.setState({
      ledgerAccounts: categoryLedgerAccounts,
    });
  };

  render() {
    const {
      ledgerCategory: {
        description,
        code,
        groupDescription,
        groupFinancialStatement,
        canManage,
      },
    } = this.props;

    return (
      <div>
        <ConfirmDeleteModal
          openModal={this.state.openConfirmDeleteModal}
          description={this.state.deleteDescription}
          deleteAction={this.state.deleteAction}
          deleteSuccessAction={this.state.deleteSucessAction}
          cancelAction={() => this.setState({ openConfirmDeleteModal: false })}
        />

        <div className="ledger-card bg-white fade-in">
          <div>
            <div className="top-content">
              <div className="icon">
                <Hierarchy />
                {canManage && (
                  <MoreVertical
                    className="pointer"
                    color="#7D819D"
                    onClick={() =>
                      this.setState({ showCardMenu: !this.state.showCardMenu })
                    }
                  />
                )}

                {this.state.showCardMenu ? (
                  <CardMenu>
                    <CardMenuItem
                      title="EDIT"
                      color="primary"
                      action={() => {
                        this.props.editAction();
                        this.setState({ showCardMenu: false });
                      }}
                    />
                    <CardMenuItem
                      title="DELETE"
                      separator={false}
                      color="danger"
                      action={() => {
                        this.props.deleteAction();
                        this.setState({ showCardMenu: false });
                      }}
                    />
                  </CardMenu>
                ) : null}
              </div>
              <h3 className="title text-truncate">{description}</h3>
              <p className="subtitle text-truncate">{code}</p>
            </div>

            <div className="middle-content">
              <hr className="line" />

              <div className="type-fund-content">
                <div className="type">
                  <p className="text-label">Group</p>
                  <h4 className="text-header text-truncate">
                    {groupDescription}
                  </h4>
                </div>

                <div className="divider"></div>

                <div className="fund">
                  <p className="text-label">Financial Report</p>
                  <h4 className="text-header text-truncate">
                    {groupFinancialStatement}
                  </h4>
                </div>
              </div>

              <hr className="line line-2" />

              <p
                className="text-label-empty"
                hidden={this.state.ledgerAccounts.length > 0}>
                Category is empty.
              </p>

              <div
                className={`list-container scrollbar `}
                hidden={this.state.ledgerAccounts.length === 0}>
                {this.state.ledgerAccounts.map((item) => (
                  <LedgerAccountCard
                    key={item.id}
                    ledgerAccount={item}
                    openAddLedgerAccountModal={() =>
                      this.props.openAddLedgerAccountModal(item)
                    }
                    deleteAction={() =>
                      this.setState({
                        openConfirmDeleteModal: true,
                        deleteAction: async () => {
                          return await deleteLedgerAccount(
                            this.props.currentUser.authToken,
                            {
                              id: item.id,
                              description: item.description,
                              categoryId: item.categoryId,
                            },
                          );
                        },
                        deleteSucessAction: () => {
                          this.props.removeLedgerAccount(item.id);
                        },
                        deleteDescription: `Confirm deletion of ${item.description} Ledger. This action cannot be reversed`,
                      })
                    }
                  />
                ))}

                {/*<div hidden={!this.state.loading}>
                <LedgerAccountCard
                  ledgerAccount={null}
                  openAddLedgerAccountModal={null}
                />
                </div>*/}
              </div>

              <div className="add-new-ledger-account">
                <span
                  className="link pointer"
                  onClick={() => this.props.openAddLedgerAccountModal(null)}>
                  + ADD NEW LEDGER
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// this will provide us with the state values we need
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  ledgerAccounts: selectLedgerAccounts,
});

// this allows us to map our redux actions to our component
const mapDispatchToProps = (dispatch) => ({
  setLedgerAccounts: (ledgerAccounts) =>
    dispatch(setLedgerAccounts(ledgerAccounts)),
  removeLedgerAccount: (ledgerAccountId) =>
    dispatch(removeLedgerAccount(ledgerAccountId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LedgerCategoryCard);
