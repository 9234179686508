import { postData, getData } from './common';

export const getBillings = async (authToken) => {
  let response = await getData(`billing/list`, authToken);
  if (response.success) {
    return {
      success: true,
      billings: response.data,
    };
  }

  return response;
};

export const getBilledStudents = async (
  billingId,
  authToken,
  studentId = -1,
) => {
  let response = await getData(
    `billing/billed-students/?billingId=${billingId}&studentId=${studentId}`,
    authToken,
  );
  if (response.success) {
    return {
      success: true,
      billedStudents: response.data,
    };
  }

  return response;
};

export const createBilling = async (authToken, data) => {
  let response = await postData('billing/new', data, authToken);
  if (response.success) {
    return {
      success: true,
      billing: response.data.billing,
      message: `Billing ${data.id === 0 ? 'created' : 'updated'} successfully.`,
    };
  }

  return response;
};

export const getStudentBalanceDue = async (
  authToken,
  studentId,
  schoolId = -1,
) => {
  let response = await getData(
    `billing/student-balance-due/?studentId=${studentId}&schoolId=${schoolId}`,
    authToken,
  );
  if (response.success) {
    return {
      success: true,
      amountOwed: response.data.amountOwed,
    };
  }

  return response;
};

export const generateBilling = async (authToken, data) => {
  let response = await postData('billing/generate', data, authToken);
  if (response.success) {
    return {
      success: true,
      billing: response.data.billing,
    };
  }

  return response;
};

export const voidBilling = async (authToken, data) => {
  let response = await postData('billing/void', data, authToken);
  if (response.success) {
    return {
      success: true,
      billing: response.data.billing,
    };
  }

  return response;
};

export const voidStudentBill = async (authToken, data) => {
  let response = await postData('billing/void-student-bill', data, authToken);
  if (response.success) {
    return {
      success: true,
      studentBill: response.data.studentBill,
    };
  }

  return response;
};

export const deleteBilling = async (authToken, data) => {
  return await postData('billing/delete', data, authToken);
};

export const deleteStudentBill = async (authToken, data) => {
  return await postData('billing/delete-student-bill', data, authToken);
};

export const createBillingFee = async (authToken, data) => {
  let response = await postData('billing/fees/create-new', data, authToken);
  if (response.success) {
    return {
      success: true,
      billingFee: response.data.billingFee,
      message: `Fee ${data.id === 0 ? 'created' : 'updated'} successfully.`,
    };
  }

  return response;
};

export const getBillingFees = async (
  authToken,
  classId = -1,
  includeGeneralFees = false,
) => {
  let response = await getData(
    `billing/fees/?classId=${classId}&includeGeneralFees=${includeGeneralFees}`,
    authToken,
  );
  if (response.success) {
    return {
      success: true,
      billingFees: response.data,
    };
  }

  return response;
};

export const deleteBillingFee = async (authToken, data) => {
  return await postData('billing/fees/delete', data, authToken);
};
