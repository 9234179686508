import StudentActionTypes from '../types/student.types';

export const setStudents = (students) => ({
  type: StudentActionTypes.SET_STUDENTS,
  payload: students,
});

export const setWard = (student) => ({
  type: StudentActionTypes.SET_WARD,
  payload: student,
});

export const removeStudent = (studentId) => ({
  type: StudentActionTypes.REMOVE_STUDENT,
  payload: studentId,
});
