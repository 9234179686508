const BillingActionTypes = {
  SET_BILLINGS: 'SET_BILLINGS',
  SET_BILLED_STUDENTS: 'SET_BILLED_STUDENTS',
  SET_BILLING_FEES: 'SET_BILLING_FEES',
  REMOVE_BILLING: 'REMOVE_BILLING',
  REMOVE_STUDENT_BILL: 'REMOVE_STUDENT_BILL',
  REMOVE_BILLING_FEE: 'REMOVE_BILLING_FEE',
};

export default BillingActionTypes;
