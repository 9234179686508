import React from 'react';
import { Modal } from 'carbon-components-react';
import CustomButton from '../../custom-button/custom-btn.component';
import { Printer } from 'react-feather';
import SubFooter from '../../footer/sub-footer/sub-footer.component';
import { formatCurrency, formatDate } from '../../../utils/common.util';

import './payment-received.styles.scss';

const CashReceivedReportPrintOutModal = ({
  openModal,
  data,
  total,
  dateFrom,
  dateTo,
  ledgerAccount,
  school,
  closeAction,
}) => {
  return (
    <Modal
      id="payment-received-modal"
      className="payment-received-modal print-modal"
      //hasScrollingContent
      passiveModal={true}
      open={openModal}
      primaryButtonDisabled={false}>
      <div className="modal-inner">
        <div className="inner-content">
          <div style={{ breakAfter: 'avoid' }}>
            <div className="mt-15 bx--col--auto mb-10">
              <div
                className="school-logo center"
                style={{ backgroundImage: `url(${school.logoUrl})` }}></div>
              <h3 className="h-24 text-primary text-center">{school.name}</h3>
              <p className="text-14 text-grey-dark text-center">
                {school.location}
              </p>

              <p className="text-center text-16 text-primary font-bold mt-15 text-uppercase">
                CASH RECEIVED REPORT
              </p>
            </div>

            <div className="info d-flex-space-between mt-10">
              <div>
                <p className="text-14 text-grey-light">Ledger Account</p>
                <h3 className="h-14">
                  {ledgerAccount ? ledgerAccount.description : 'ALL'}
                </h3>
              </div>

              <div>
                <p className="text-14 text-grey-light">Start Date</p>
                <h3 className="h-14">
                  {dateFrom && formatDate(new Date(dateFrom))}
                </h3>
              </div>
              <div>
                <p className="text-14 text-grey-light">End Date</p>
                <h3 className="h-14">
                  {dateTo && formatDate(new Date(dateTo))}
                </h3>
              </div>
            </div>

            {data && (
              <div>
                <table className="bx--data-table table-white">
                  <thead>
                    <tr>
                      <th>Date Recorded</th>
                      <th>Payment Date</th>
                      <th>Payment ID</th>
                      <th>Credit Account</th>
                      <th>Debit Account</th>
                      <th>Amount</th>
                      <th>Payment Mode</th>
                      <th>Received from</th>
                      <th>Reversed/Revised</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((revenue) => (
                      <tr>
                        <td>
                          {formatDate(
                            revenue.payment.dateCreated ??
                              revenue.payment.paymentDate,
                          )}
                        </td>
                        <td>{formatDate(revenue.payment.paymentDate)}</td>
                        <td className="font-bold">
                          {revenue.payment.uniqueId}
                        </td>
                        <td>{revenue.ledgerAccount.description}</td>
                        <td>{revenue.payment.debitAccount.description}</td>
                        <td className="font-bold">
                          {formatCurrency(revenue.amountPaid)}
                        </td>
                        <td>{revenue.payment?.paymentMode}</td>
                        <td>
                          {revenue.payment.student
                            ? revenue.payment.student.fullName
                            : revenue.payment.receivedFrom}
                        </td>
                        <td>
                          {revenue.payment.dateReversed ? (
                            <span className="text-danger font-bold">
                              {`REVERSED ON ${formatDate(
                                revenue.payment.dateReversed,
                              )}`.toUpperCase()}
                            </span>
                          ) : revenue.payment.revisedPayment ? (
                            <span
                              style={{ color: '#FF7F00' }}
                              className="font-bold">
                              {`REVISED ON ${formatDate(
                                revenue.payment.revisedPayment.paymentDate,
                              )} WITH ${
                                revenue.payment.revisedPayment.uniqueId
                              }`.toUpperCase()}
                            </span>
                          ) : (
                            'NO'
                          )}
                        </td>
                      </tr>
                    ))}
                    {data && (
                      <tr>
                        <td colSpan={5}>
                          <div className="text-primary font-bold">Total</div>
                        </td>
                        <td colSpan={4}>
                          <div className="text-primary font-bold text-20">
                            {formatCurrency(total)}
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <SubFooter />
              </div>
            )}
          </div>
        </div>
        <div className="inner-footer">
          <div className="bx--row">
            <div className="bx--col-lg-8"></div>

            <div className="bx--col-lg-2">
              <CustomButton
                classes="outline secondary f-width font-bold mt-10"
                onClick={closeAction}>
                CLOSE
              </CustomButton>
            </div>

            <div className="bx--col-lg-2">
              <CustomButton
                classes="primary f-width font-bold mt-10"
                onClick={() => window.print()}>
                <Printer size="11" strokeWidth="5px" /> &nbsp; PRINT
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CashReceivedReportPrintOutModal;
