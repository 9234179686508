const INITIAL_STATE = {
  fetching: false,
  fetchError: null,
  data: [],
};

const academicYearReducerNew = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'FETCH_ACADEMIC_YEARS_REQUESTED':
      return {
        ...state,
        fetching: true,
        fetchError: null,
      };
    case 'FETCH_ACADEMIC_YEARS_SUCCEEDED':
      return {
        ...state,
        fetching: false,
        data: action.payload,
      };
    case 'FETCH_ACADEMIC_YEARS_FAILED':
      return {
        ...state,
        fetching: false,
        fetchError: action.payload,
      };
    default:
      return state;
  }
};

export default academicYearReducerNew;
