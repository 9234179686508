// Establish a global connection here that all other can use

const signalR = require('@aspnet/signalr');

export const getSignalRConnection = async () => {
  // create hub connection
  const hubConnection = new signalR.HubConnectionBuilder()
    .withUrl('https://epobsfmsv2.azurewebsites.net/notification-hub')
    .build();

  // start connection
  // establish connection with server
  try {
    await hubConnection.start({ xdomain: true });
    return hubConnection;
  } catch (err) {
    console.log(err);

    return null;
  }
};
