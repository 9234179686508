import React from 'react';
import { Form, Modal, Loading } from 'carbon-components-react';
import CustomButton from '../../custom-button/custom-btn.component';

import './confirm-delete.styles.scss';

class ConfirmDeleteModal extends React.Component {
  constructor(props) {
    super(props);

    // set state
    this.state = {
      openModal: props.openModal,
      loading: false,
      errorMessage: null,
      btnText: 'CONFIRM',
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.openModal !== prevState.openModal) {
      return { openModal: nextProps.openModal };
    } else return null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.description !== prevProps.description) {
      this.setState({
        description: this.props.description,
        errorMessage: null,
      });
    }
  }

  delete = () => {
    // set loading state
    this.setState({ loading: true, btnText: null, errorMessage: null });

    // make request
    this.props.deleteAction().then((response) => {
      if (!response.success) {
        // request failed
        this.setState({
          loading: false,
          btnText: 'CONFIRM',
          errorMessage: response.message,
        });

        return;
      }

      this.setState({ loading: false, btnText: 'CONFIRM' });

      // close dialog
      this.props.cancelAction();

      // on success
      this.props.deleteSuccessAction();
    });
  };

  render() {
    return (
      <Modal
        id="confirm-delete-modal"
        className="confirm-delete-modal"
        hasScrollingContent
        passiveModal={true}
        open={this.state.openModal}
        modalHeading={!this.props.heading ? 'Confirm' : this.props.heading}
        primaryButtonDisabled={false}>
        <div>
          <Form action="post">
            <p className="text-label">{this.props.description}</p>

            <CustomButton
              classes="danger f-width mt-40 font-bold"
              disabled={this.state.loading}
              onClick={this.delete}>
              {this.state.btnText}

              <div hidden={this.state.btnText != null}>
                <div className="d-flex-center">
                  <Loading
                    withOverlay={false}
                    small={true}
                    description="Deleting..."
                  />
                </div>
              </div>
            </CustomButton>

            <p
              className="text-14 mt-10 text-danger"
              hidden={this.state.errorMessage === null}
              disabled={this.state.loading}>
              {this.state.errorMessage}
            </p>

            <CustomButton
              classes="outline secondary mt-15 f-width font-bold"
              disabled={this.state.loading}
              onClick={this.props.cancelAction}>
              CANCEL
            </CustomButton>
          </Form>
        </div>
      </Modal>
    );
  }
}

export default ConfirmDeleteModal;
