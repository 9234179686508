import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { Plus } from 'react-feather';

import { Search, Loading } from 'carbon-components-react';

import MainHeader from '../../components/main-header/main-header.component';
import Mainmenu from '../../components/menu/main-menu/main-menu.component';
import AddAcademicYearModal from '../../components/modal/add-academic-year/add-academic-year.component';
import AcademicYearCard from '../../components/card/academic-year-card/academic-year-card.component';
import Footer from '../../components/footer/footer.component';
import AddLedgerAccountModal from '../../components/modal/add-ledger-account/add-ledger-account.component';
import AddLedgerCategoryModal from '../../components/modal/add-ledger-category/add-ledger-category.component';
import ConfirmDeleteModal from '../../components/modal/confirm-delete/confirm-delete.component';

// import our redux stuffs
import { selectAcademicYears } from '../../redux/selectors/academic-year.selectors';
import { selectCurrentUser } from '../../redux/selectors/user.selectors';

import {
  setAcademicYears,
  removeAcademicYear,
} from '../../redux/actions/academic-year.actions';

// import api
import {
  getAcademicYears,
  deleteAcademicYear,
} from '../../api/academic-year.service';

//import svg icon
import { ReactComponent as Invoice } from '../../assets/images/card-icons/invoice.svg';

import './academic-year.styles.scss';

const plusIcon = <Plus size="14" strokeWidth="3px" />;

class AcademicYearPage extends React.Component {
  constructor(props) {
    super(props);

    // set state
    this.state = {
      query: '',
      authToken: props.currentUser.authToken,
      loading: props.academicYears.length === 0,
      academicYears: props.academicYears,
      errorMessage: null,
      openAcademicYearModal: false,
      openAddTermModal: false,
      openLedgerAccountModal: false,
      openLedgerCategoryModal: false,
      openConfirmDeleteModal: false,
      selectedAcademicYear: null,
      deleteDescription:
        'Please confirm academic year deletion. This action cannot be reversed.',
      deleteAction: () => {},
      deleteSucessAction: () => {},
    };
  }

  componentDidMount() {
    // fetch academic years only if length is 0
    if (this.state?.academicYears?.length === 0) {
      this.fetchAcademicYears();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props?.academicYears?.length !== prevProps?.academicYears?.length
    ) {
      this.setState({ academicYears: this.props?.academicYears });
    } else {
      // compare values
      this.props.academicYears.forEach((newItem) => {
        // find same item in prevProps
        const academicYear = prevProps.academicYears?.find(
          (oldItem) => oldItem.id === newItem.id,
        );

        if (
          academicYear.description !== newItem.description ||
          academicYear.startDate !== newItem.startDate ||
          academicYear.endDate !== newItem.endDate
        ) {
          // set state
          this.setState({ academicYears: this.props?.academicYears });

          // jump out of loop
          return;
        }
      });
    }
  }

  fetchAcademicYears = async () => {
    // show loading if only is not already showing
    if (!this.state.loading)
      this.setState({ loading: true, errorMessage: null });

    // destructure needed values from state
    const { authToken, query, academicYears } = this.state;

    // set skip and sate values
    let take = 50;
    let skip = academicYears.length;

    // initiate request
    const response = await getAcademicYears(authToken, skip, take, query);

    // request failed, show error message
    if (!response.success) {
      this.setState({ loading: false, errorMessage: response.message });

      return;
    }

    // successful
    this.setState({ loading: false });

    // save to redux
    this.props.setAcademicYears(response.academicYears);
  };

  render() {
    const academicYears =
      this.state.query.length > 0
        ? this.state.academicYears.filter((academicYear) =>
            academicYear.description
              .toLowerCase()
              .includes(this.state.query.toLowerCase()),
          )
        : this.state.academicYears;

    return (
      <div>
        <MainHeader />

        <AddAcademicYearModal
          openModal={this.state.openAcademicYearModal}
          academicYear={this.state.selectedAcademicYear}
          cancelAction={() => this.setState({ openAcademicYearModal: false })}
        />

        <AddLedgerAccountModal
          openModal={this.state.openLedgerAccountModal}
          selectedLedgerCategory={null}
          selectedLedgerAccount={null}
          cancelAction={() => this.setState({ openLedgerAccountModal: false })}
        />

        <AddLedgerCategoryModal
          openModal={this.state.openLedgerCategoryModal}
          cancelAction={() => this.setState({ openLedgerCategoryModal: false })}
        />

        <ConfirmDeleteModal
          openModal={this.state.openConfirmDeleteModal}
          description={this.state.deleteDescription}
          deleteAction={this.state.deleteAction}
          deleteSuccessAction={this.state.deleteSucessAction}
          cancelAction={() => this.setState({ openConfirmDeleteModal: false })}
        />

        <div className="academic-year page-container">
          <span className="right">
            <Mainmenu />
          </span>

          <h3 className="h-24 mt-30">
            Academic Years&nbsp;
            <span
              className="create-new pointer"
              onClick={() => {
                this.setState({
                  openAcademicYearModal: true,
                  selectedAcademicYear: null,
                });
              }}>
              {plusIcon} CREATE NEW
            </span>
          </h3>
          <div className="mt-10">
            <Search
              id="query"
              name="query"
              value={this.state.query}
              placeHolderText="Search by description"
              onChange={(event) => this.setState({ query: event.target.value })}
            />
          </div>

          <div className="mt-20 fade-in" hidden={!this.state.loading}>
            <Loading
              small={true}
              withOverlay={false}
              description="Loading..."
            />
          </div>

          <div
            className="mt-30 fade-in"
            hidden={this.state.errorMessage == null}>
            <span className="mt-10 text-danger">
              {this.state.errorMessage}.
            </span>
            &nbsp;
            <span
              className="text-primary pointer font-bold"
              onClick={() => this.fetchAcademicYears()}>
              TRY AGAIN
            </span>
          </div>

          <p
            className="text-14 mt-30 text-grey fade-in"
            hidden={
              this.state.academicYears.length > 0 ||
              this.state.loading ||
              this.state.errorMessage !== null
            }>
            There are no academic years, please&nbsp;
            <span
              className="text-primary font-bold pointer"
              onClick={() =>
                this.setState({
                  openAcademicYearModal: true,
                  selectedAcademicYear: null,
                })
              }>
              create a new one
            </span>
          </p>

          <div className="bx--row mt-30">
            {academicYears.map((academicYear) => (
              <div key={academicYear.id} className="bx--col-md-2 fade-in">
                <AcademicYearCard
                  academicYear={academicYear}
                  editAction={() =>
                    this.setState({
                      openAcademicYearModal: true,
                      selectedAcademicYear: academicYear,
                    })
                  }
                  deleteAction={() =>
                    this.setState({
                      openConfirmDeleteModal: true,
                      deleteAction: async () => {
                        return await deleteAcademicYear(
                          this.props.currentUser.authToken,
                          {
                            id: academicYear.id,
                            description: academicYear.description,
                          },
                        );
                      },
                      deleteSucessAction: () => {
                        this.props.removeAcademicYear(academicYear.id);
                      },
                      deleteDescription: `Confirm deletion of ${academicYear.description} academic year. This action cannot be reversed`,
                    })
                  }
                  btnAction={() =>
                    this.props.history.push(`/academic-year/${academicYear.id}`)
                  }>
                  <Invoice />
                </AcademicYearCard>
              </div>
            ))}
          </div>

          <Footer fixed={false} showLinks={false} />
        </div>
      </div>
    );
  }
}

// this will provide us with the state values we need
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  academicYears: selectAcademicYears,
});

// this allows us to map our redux actions to our component
const mapDispatchToProps = (dispatch) => ({
  setAcademicYears: (academicYears) =>
    dispatch(setAcademicYears(academicYears)),
  removeAcademicYear: (academicYearId) =>
    dispatch(removeAcademicYear(academicYearId)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AcademicYearPage),
);
