import { getData, postData, deleteData } from './common';

export const getPayrolls = async (authToken) => {
  let response = await getData(`payroll/list`, authToken);
  if (response.success) {
    return {
      success: true,
      payrolls: response.data,
    };
  }

  return response;
};

export const getPayroll = async (authToken, payrollId) => {
  let response = await getData(`payroll/select/${payrollId}`, authToken);
  if (response.success) {
    return {
      success: true,
      payroll: response.data,
    };
  }

  return response;
};

export const newPayroll = async (authToken, data) => {
  let response = await postData('payroll/new', data, authToken);
  if (response.success) {
    return {
      success: true,
      payroll: response.data,
    };
  }

  return response;
};

export const setPayrollStatus = async (authToken, data) => {
  let response = await postData('payroll/set-status', data, authToken);
  if (response.success) {
    return {
      success: true,
      payroll: response.data,
    };
  }

  return response;
};

export const generatePayroll = async (authToken, data) => {
  let response = await postData('payroll/generate', data, authToken);
  if (response.success) {
    return {
      success: true,
      payroll: response.data,
    };
  }

  return response;
};

export const payPayroll = async (authToken, data) => {
  let response = await postData('payroll/pay', data, authToken);
  if (response.success) {
    return {
      success: true,
      payroll: response.data,
    };
  }

  return response;
};

export const voidPayroll = async (authToken, data) => {
  let response = await postData('payroll/void', data, authToken);
  if (response.success) {
    return {
      success: true,
      payroll: response.data,
    };
  }

  return response;
};

export const getAllowances = async (authToken) => {
  let response = await getData(`payroll/allowance/list`, authToken);
  if (response.success) {
    return {
      success: true,
      allowances: response.data,
    };
  }

  return response;
};

export const newAllowance = async (authToken, data) => {
  let response = await postData('payroll/allowance/new', data, authToken);
  if (response.success) {
    return {
      success: true,
      allowance: response.data,
    };
  }

  return response;
};

export const getDeductions = async (authToken) => {
  let response = await getData(`payroll/deduction/list`, authToken);
  if (response.success) {
    return {
      success: true,
      deductions: response.data,
    };
  }

  return response;
};

export const newDeduction = async (authToken, data) => {
  let response = await postData('payroll/deduction/new', data, authToken);
  if (response.success) {
    return {
      success: true,
      deduction: response.data,
    };
  }

  return response;
};

export const reviewEmployee = async (authToken, data) => {
  let response = await postData('payroll/review-employee', data, authToken);
  if (response.success) {
    return {
      success: true,
      paymentInfo: response.data,
    };
  }

  return response;
};

export const deletePayroll = async (authToken, payrollId) => {
  return await deleteData(`payroll/delete/${payrollId}`, authToken);
};

export const deleteAllowance = async (authToken, allowanceId) => {
  return await deleteData(`payroll/allowance/delete/${allowanceId}`, authToken);
};

export const deleteDeduction = async (authToken, deductionId) => {
  return await deleteData(`payroll/deduction/delete/${deductionId}`, authToken);
};
