import { call, put, all, takeLatest } from 'redux-saga/effects';
import { fetchData } from '../../api/api';
import {
  createLedgerGroupsFetchEndpoint,
  createLedgerCategoriesFetchEndpoint,
  createLedgerAccountsFetchEndpoint,
} from '../../api/endpoints';

function* fetchLedgerGroups(action) {
  const response = yield call(
    fetchData,
    createLedgerGroupsFetchEndpoint(),
    action.payload.authToken,
  );

  yield onResponse(
    response,
    'FETCH_LEDGER_GROUPS_SUCCEEDED',
    'FETCH_LEDGER_GROUPS_FAILED',
  );
}

function* fetchLedgerCategories(action) {
  const response = yield call(
    fetchData,
    createLedgerCategoriesFetchEndpoint(),
    action.payload.authToken,
  );

  yield onResponse(
    response,
    'FETCH_LEDGER_CATEGORIES_SUCCEEDED',
    'FETCH_LEDGER_CATEGORIES_FAILED',
  );
}

function* fetchLedgerAccounts(action) {
  const response = yield call(
    fetchData,
    createLedgerAccountsFetchEndpoint(),
    action.payload.authToken,
  );

  yield onResponse(
    response,
    'FETCH_LEDGER_ACCOUNTS_SUCCEEDED',
    'FETCH_LEDGER_ACCOUNTS_FAILED',
  );
}

function* onResponse(response, actionTypeSuccess, actionTypeFailed) {
  if (response.success) {
    yield put({
      type: actionTypeSuccess,
      payload: response.data,
    });
  } else {
    yield put({
      type: actionTypeFailed,
      payload: response.message,
    });
  }
}

function* onFetchLedgerGroups() {
  yield takeLatest('FETCH_LEDGER_GROUPS_REQUESTED', fetchLedgerGroups);
}

function* onFetchLedgerCategories() {
  yield takeLatest('FETCH_LEDGER_CATEGORIES_REQUESTED', fetchLedgerCategories);
}

function* onFetchLedgerAccounts() {
  yield takeLatest('FETCH_LEDGER_ACCOUNTS_REQUESTED', fetchLedgerAccounts);
}

export default function* ledgerSagas() {
  yield all([
    call(onFetchLedgerGroups),
    call(onFetchLedgerAccounts),
    call(onFetchLedgerCategories),
  ]);
}
