import React from 'react';

import './sub-component/notification-item.component';

// import styles
import './notifications.styles.scss';
import NotificationItem from './sub-component/notification-item.component';
import { getSignalRConnection } from '../../api/signalr/common';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import {
  selectHubConnection,
  selectNotifications,
} from '../../redux/selectors/notification.selectors';
import {
  setHubConnection,
  setNotifications,
  deleteNotification,
} from '../../redux/actions/notification.actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class Notifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      refreshNotifications: false,
    };
  }

  componentDidMount() {
    if (!this.props.hubConnection) {
      this.createSignalRHubConnection();
      return;
    }

    this.listenForHubIncomingMessages();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.notifications.filter(
        (item) => item.generatedBy !== this.props.currentUser.userId,
      ).length !==
      prevProps.notifications.filter(
        (item) => item.generatedBy !== this.props.currentUser.userId,
      ).length
    ) {
      this.setState({ refreshNotifications: true });
    }

    if (!this.props.hubConnection) {
      this.createSignalRHubConnection();
    } else if (this.props.hubConnection && !prevProps.hubConnection) {
      this.listenForHubIncomingMessages();
    }
  }

  createSignalRHubConnection = () => {
    // create signalRHub connection
    getSignalRConnection().then((hubConnection) => {
      if (hubConnection) {
        // update state
        this.props.setHubConnection(hubConnection);
      }
    });
  };

  listenForHubIncomingMessages = () => {
    // make sure there are not duplicates
    this.props.hubConnection.off('ReceiveNotification');

    // listen for incoming messages
    this.props.hubConnection.on('ReceiveNotification', (data) => {
      if (data.schoolId === this.props.currentUser.schoolId) {
        this.props.setNotifications([{ ...data, status: '' }]);
      }
    });

    // register onClose handler
    this.props.hubConnection.onclose((error) => {
      this.props.setHubConnection(null);
    });
  };

  render() {
    return (
      <div className="notifications scrollbar">
        <p className="heading">
          {this.props.notifications.length > 0
            ? 'Notifications'
            : 'No new notifications available'}
        </p>
        {this.props.notifications
          .filter((item) => item.generatedBy !== this.props.currentUser.userId)
          .map((notification) => (
            <NotificationItem
              notification={notification}
              openNotification={() => {
                // delete notification
                this.props.deleteNotification(notification.dateGenerated);

                // navigate
                this.props.history.push(notification.redirectUrl);
              }}
            />
          ))}
      </div>
    );
  }
}

// this will provide us with the state values we need
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  hubConnection: selectHubConnection,
  notifications: selectNotifications,
});

// this allows us to map our redux actions to our component
const mapDispatchToProps = (dispatch) => ({
  setHubConnection: (hubConnection) =>
    dispatch(setHubConnection(hubConnection)),
  setNotifications: (notifications) =>
    dispatch(setNotifications(notifications)),
  deleteNotification: (dateGenerated) =>
    dispatch(deleteNotification(dateGenerated)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Notifications),
);
