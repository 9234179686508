import { createSelector } from 'reselect';

const selectAcademicYear = (state) => state.academicYearNew;

export const selectFetchingAcademicYear = createSelector(
  [selectAcademicYear],
  (academicYear) => academicYear.fetching,
);

export const selectAcademicYearFetchError = createSelector(
  [selectAcademicYear],
  (academicYear) => academicYear.fetchError,
);

export const selectAcademicYearData = createSelector(
  [selectAcademicYear],
  (academicYear) => academicYear.data,
);
