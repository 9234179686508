import { call, put, all, takeLatest } from 'redux-saga/effects';
import { fetchData } from '../../api/api';
import ENDPOINTS from '../../api/endpoints';

function* calculateExpensesInMonth(action) {
  const response = yield call(
    fetchData,
    `${ENDPOINTS.EXPENDITURE}expenses-in-month`,
    action.payload.authToken,
  );

  yield onResponse(
    response,
    'CALCULATE_EXPENSES_IN_MONTH_SUCCEEDED',
    'CALCULATE_EXPENSES_IN_MONTH_FAILED',
  );
}

function* onResponse(response, actionTypeSuccess, actionTypeFailed) {
  if (response.success) {
    yield put({
      type: actionTypeSuccess,
      payload: response.data,
    });
  } else {
    yield put({
      type: actionTypeFailed,
      payload: response.message,
    });
  }
}

function* onCalculateExpensesInMonth() {
  yield takeLatest(
    'CALCULATE_EXPENSES_IN_MONTH_REQUESTED',
    calculateExpensesInMonth,
  );
}

export default function* expenseSagas() {
  yield all([call(onCalculateExpensesInMonth)]);
}
