import React from 'react';
import { ReactComponent as Telescope } from '../../assets/images/telescope.svg';
import './not-found.styles.scss';

const NotFoundPage = (props) => {
  return (
    <div className="not-found-page">
      <div>
        <Telescope />
      </div>
      <div className="sorry-div">
        <div className="sorry">Sorry</div>
        <div className="textline">
          we could not find what you were looking for Click here to go to the
          <span
            onClick={() => {
              props.history.push('/');
            }}>
            {' '}
            homepage
          </span>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
