import React, { useState } from 'react';
import { SelectItem, Select } from 'carbon-components-react';
import CustomButton from '../../custom-button/custom-btn.component';

import { StyledModal } from './add-budget.styles';
import { withRouter } from 'react-router-dom';

const AddBudgetModal = ({ open, academicYears, cancelAction, history }) => {
  const [academicYear, setAcademicYear] = useState('');

  const handleSelectChange = (event) => {
    setAcademicYear(event.target.value);
  };

  const handleSubmit = () => {
    history.push(`/academic-year/${academicYear}/budget`);
  };

  return (
    <StyledModal
      hasScrollingContent
      passiveModal={true}
      open={open}
      modalHeading="Add Budget"
      primaryButtonDisabled={false}>
      <div className="bx--row">
        <div className="bx--col-md-8">
          <Select labelText="Academic Year" onChange={handleSelectChange}>
            <SelectItem
              selected
              disabled
              text="Select Academic Year"
              value=""
            />
            {academicYears.map((academicYear, i) => (
              <SelectItem
                key={i}
                text={academicYear.description}
                value={academicYear.id}
              />
            ))}
          </Select>
        </div>

        <div className="bx--col-md-8 mt-30">
          <CustomButton
            classes="primary f-width font-bold"
            disabled={academicYear.length === 0}
            onClick={handleSubmit}>
            SUBMIT
          </CustomButton>
        </div>
        <div className="bx--col-md-8 mt-20 text-center">
          <span
            className="text-danger font-bold pointer"
            onClick={cancelAction}>
            CANCEL
          </span>
        </div>
      </div>
    </StyledModal>
  );
};

export default withRouter(AddBudgetModal);
