import React from 'react';
import {
  TextInput,
  Form,
  Modal,
  Loading,
  Select,
  SelectItem,
  DatePicker,
  DatePickerInput,
} from 'carbon-components-react';
import CustomButton from '../../custom-button/custom-btn.component';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

// import our redux stuffs
import { selectCurrentUser } from '../../../redux/selectors/user.selectors';

// import api stuffs

import './expense-payment.styles.scss';
import {
  selectExpenditureLedgerAccounts,
  selectCurrentAssetsLedgers,
} from '../../../redux/selectors/chart-of-accounts.selector';
import {
  setPayments,
  removePayment,
} from '../../../redux/actions/expense.actions';
import { setLedgerAccounts } from '../../../redux/actions/chart-of-accounts.actions';
import { getLedgerAccounts } from '../../../api/chart-of-accounts.service';
import { newPayment } from '../../../api/expense.service';

class ExpensePaymentModal extends React.Component {
  constructor(props) {
    super(props);

    // set state
    this.state = {
      id: 0,
      loading: false,
      saveAsDraftBtnText: 'SAVE AS DRAFT',
      submitForApprovalBtnText: 'SUBMIT FOR APPROVAL',
      heading: 'New General Expenditure',
      message: null,
      messageClass: '',
      openModal: props.openModal,
      paymentDate: '',
      debitAccount: '',
      creditAccount: '',
      amount: '',
      paidTo: '',
      description: '',
      refreshPage: false,
      submitForApproval: false,
    };
  }

  componentDidMount() {
    // fetch ledger accounts
    getLedgerAccounts(this.props.currentUser.authToken, 0, 2000).then(
      (response) => {
        if (response.success) {
          this.props.setLedgerAccounts(response.ledgerAccounts);
        }
      },
    );
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.currentAssets.length !== prevProps.currentAssets.length ||
      this.props.expenseLedgerAccounts.length !==
        prevProps.expenseLedgerAccounts.length
    ) {
      this.setState({ refreshPage: true });
    }

    if (this.props.payment !== prevProps.payment) {
      if (this.props.payment) {
        // update
        let payment = this.props.payment;

        this.setState({
          id: payment.id,
          paymentDate: payment?.paymentDate,
          debitAccount: payment.debitAccount.id.toString(),
          creditAccount: payment.creditAccount.id.toString(),
          amount: payment.amount.toString(),
          paidTo: payment.paidTo,
          description: payment.description,
          heading: 'Edit Expenditure',
        });
      } else {
        // new
        this.setState({
          id: 0,
          paymentDate: '',
          debitAccount: '',
          creditAccount: '',
          amount: '',
          paidTo: '',
          description: '',
          heading: 'New Expenditure',
        });
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.openModal !== prevState.openModal) {
      return { openModal: nextProps.openModal };
    } else return null;
  }

  // handle onchange event handler for input fields
  handleChange = (event) => {
    const { name, value } = event.target;

    // update state
    this.setState({ [name]: value });
  };

  // validate data entered
  isDataValid = () =>
    this.state.debitAccount.length > 0 &&
    this.state.creditAccount.length > 0 &&
    this.state.paymentDate.length > 0 &&
    this.state.amount.length > 0 &&
    this.state.paidTo.length > 0;

  makePayment = async (submitForApproval) => {
    if (submitForApproval) {
      this.setState({
        loading: true,
        submitForApprovalBtnText: null,
        errorMessage: null,
      });
    } else {
      this.setState({
        loading: true,
        saveAsDraftBtnText: null,
        errorMessage: null,
      });
    }

    // build data
    const data = {
      id: this.state.id,
      amount: parseFloat(this.state.amount),
      paidTo: this.state.paidTo,
      paymentDate: this.state.paymentDate,
      description: this.state.description,
      debitAccount: this.props.expenseLedgerAccounts.find(
        (ledgerAccount) => ledgerAccount.id == this.state.debitAccount,
      ),
      creditAccount: this.props.currentAssets.find(
        (currentAsset) => currentAsset.id == this.state.creditAccount,
      ),
      submitForApproval: submitForApproval,
    };

    // execute request
    const response = await newPayment(this.props.currentUser.authToken, data);

    if (!response.success) {
      // failed, update state
      this.setState({
        loading: false,
        saveAsDraftBtnText: 'SAVE AS DRAFT',
        submitForApprovalBtnText: 'SUBMIT FOR APPROVAL',
        errorMessage: response.message,
      });

      return;
    }

    // all good, update state
    this.setState({
      loading: false,
      saveAsDraftBtnText: 'SAVE AS DRAFT',
      submitForApprovalBtnText: 'SUBMIT FOR APPROVAL',
      id: 0,
      paymentDate: '',
      debitAccount: '',
      creditAccount: '',
      amount: '',
      paidTo: '',
      description: '',
      heading: 'New Expenditure',
    });

    // update redux
    this.props.setPayments([response.payment]);

    // close dialog
    this.props.cancelAction();
  };

  render() {
    return (
      <Modal
        id="expense-payment-modal"
        className="expense-payment-modal"
        hasScrollingContent
        passiveModal={true}
        open={this.state.openModal}
        modalHeading={this.state.heading}
        primaryButtonDisabled={false}>
        <div>
          <Form action="post">
            <div className="mt-10 mb-10">
              <DatePicker
                dateFormat="Y-m-d"
                datePickerType="single"
                id="start-date-picker"
                onClose={(value) => {
                  if (value.length > 0) {
                    this.setState({
                      paymentDate: [
                        value[0].getFullYear(),
                        ('0' + (value[0].getMonth() + 1)).slice(-2),
                        ('0' + value[0].getDate()).slice(-2),
                      ].join('-'),
                    });
                  }
                }}>
                <DatePickerInput
                  className="payment-date"
                  id="payment-date"
                  name="paymentDate"
                  value={this.state.paymentDate}
                  onChange={this.handleChange}
                  labelText="Payment Date"
                  pattern="d{1,2}/d{4}"
                  placeholder="YYYY-MM-DD"
                />
              </DatePicker>
            </div>
            <div className="mt-30">
              <Select
                id="debitAccount"
                labelText="Debit Account (Expenditure)"
                onChange={(e) =>
                  this.setState({ debitAccount: e.target.value })
                }>
                <SelectItem
                  selected={this.state.debitAccount === ''}
                  disabled
                  text="Select Expense"
                  value=""
                />
                {this.props.expenseLedgerAccounts.map((ledgerAccount, i) => (
                  <SelectItem
                    key={i}
                    selected={this.state.debitAccount == ledgerAccount.id}
                    text={ledgerAccount.description}
                    value={ledgerAccount.id}
                  />
                ))}
              </Select>
            </div>

            <div className="mt-30">
              <Select
                id="creditAccount"
                labelText="Credit Account (Cash or Bank Account)"
                onChange={(e) =>
                  this.setState({ creditAccount: e.target.value })
                }>
                <SelectItem
                  selected={this.state.creditAccount === ''}
                  disabled
                  text="Select Credit Account"
                  value=""
                />
                {this.props.currentAssets
                  .filter(
                    (currentAsset) =>
                      currentAsset.categoryDescription === 'Bank Accounts' ||
                      currentAsset.categoryDescription === 'Cash Accounts',
                  )
                  .map((currentAsset, j) => (
                    <SelectItem
                      key={j}
                      selected={this.state.creditAccount == currentAsset.id}
                      text={currentAsset.description}
                      value={currentAsset.id}
                    />
                  ))}
              </Select>
            </div>

            <div className="mt-30">
              <TextInput
                name="amount"
                type="number"
                step="0.01"
                labelText="Amount Paid"
                placeholder="eg 100"
                value={this.state.amount}
                onChange={this.handleChange}
              />
            </div>

            <div className="mt-30">
              <TextInput
                name="paidTo"
                labelText="Paid to"
                placeholder="Who was the money paid to"
                value={this.state.paidTo}
                onChange={this.handleChange}
              />
            </div>

            <div className="mt-30">
              <TextInput
                name="description"
                labelText="Description"
                placeholder="A brief description"
                value={this.state.description}
                onChange={this.handleChange}
              />
            </div>

            <p
              className={`text-14 mt-10 ${this.state.messageClass}`}
              hidden={this.state.message === null}>
              {this.state.message}
            </p>

            <div className="bx--row mt-40">
              <div className="bx--col-lg-5">
                <CustomButton
                  classes="secondary f-width font-bold"
                  disabled={this.state.loading || !this.isDataValid()}
                  onClick={() => this.makePayment(false)}>
                  {this.state.saveAsDraftBtnText}

                  <div hidden={this.state.saveAsDraftBtnText != null}>
                    <div className="d-flex-center">
                      <Loading
                        withOverlay={false}
                        small={true}
                        description="Making payment..."
                      />
                    </div>
                  </div>
                </CustomButton>
              </div>
              <div className="bx--col-lg-7">
                <CustomButton
                  classes="primary f-width font-bold"
                  disabled={this.state.loading || !this.isDataValid()}
                  onClick={() => this.makePayment(true)}>
                  {this.state.submitForApprovalBtnText}
                  <div hidden={this.state.submitForApprovalBtnText != null}>
                    <div className="d-flex-center">
                      <Loading
                        withOverlay={false}
                        small={true}
                        description="Making payment..."
                      />
                    </div>
                  </div>
                </CustomButton>
              </div>
            </div>

            <div className="mt-20">
              <CustomButton
                classes="outline danger no-border no-hover font-bold"
                disabled={this.state.loading}
                onClick={(e) => this.props.cancelAction()}>
                CANCEL
              </CustomButton>
            </div>

            <p
              className={`text-14 mt-10 text-danger`}
              hidden={this.state.errorMessage === null}>
              {this.state.errorMessage}
            </p>
          </Form>
        </div>
      </Modal>
    );
  }
}

// this will provide us with the state values we need
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  expenseLedgerAccounts: selectExpenditureLedgerAccounts,
  currentAssets: selectCurrentAssetsLedgers,
});

// this allows us to map our redux actions to our component
const mapDispatchToProps = (dispatch) => ({
  setPayments: (payments) => dispatch(setPayments(payments)),
  removePayment: (paymentId) => dispatch(removePayment(paymentId)),
  setLedgerAccounts: (ledgers) => dispatch(setLedgerAccounts(ledgers)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExpensePaymentModal);
