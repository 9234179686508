import React from 'react';

import './card-menu.styles.scss';

const CardMenu = ({
  children,
  editAction = () => {},
  deleteAction = () => {},
}) => {
  return <div className="card-menu">{children}</div>;
};

export const CardMenuItem = ({
  title,
  color = '',
  separator = true,
  action = () => {},
}) => {
  return (
    <div>
      <div className={`menu-item ${color} pointer`} onClick={action}>
        {title}
      </div>
      {separator ? <hr className="separator" /> : null}
    </div>
  );
};

export default CardMenu;
