import React from 'react';
import MainHeader from '../main-header/main-header.component';

const SuspenseLoader = ({ children }) => {
  return (
    <div className="suspense-loader">
      <div class="page-loader-div">
        <MainHeader />
        <span class="main-loader">
          <span>LOA</span>
          <span>DIN</span>
          <span>G...</span>
        </span>
      </div>
    </div>
  );
};

export default SuspenseLoader;
