import ClassActionTypes from '../types/class.types';

export const setClasses = (classes) => ({
  type: ClassActionTypes.SET_CLASSES,
  payload: classes,
});

export const setClassLabels = (classLabels) => ({
  type: ClassActionTypes.SET_CLASS_LABELS,
  payload: classLabels,
});

export const removeClass = (classId) => ({
  type: ClassActionTypes.REMOVE_CLASS,
  payload: classId,
});

export const removeClassLabel = (classLabelId) => ({
  type: ClassActionTypes.REMOVE_CLASS_LABEL,
  payload: classLabelId,
});
