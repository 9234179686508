import React from 'react';
import { Modal } from 'carbon-components-react';
import CustomButton from '../../../custom-button/custom-btn.component';
import { Printer } from 'react-feather';

import { formatCurrency, formatDate } from '../../../../utils/common.util';

import './students-print-out.styles.scss';

const StudentsPrintOutModal = ({
  open,
  studentsToView,
  students = [],
  class_,
  classLabel,
  school,
  closeAction,
}) => {
  return (
    <Modal
      id="students-list-modal"
      className="students-list-modal print-modal"
      //hasScrollingContent
      passiveModal={true}
      open={open}
      primaryButtonDisabled={false}>
      <div className="modal-inner">
        <div className="inner-content">
          <div style={{ breakAfter: 'avoid' }}>
            <div className="mt-15 bx--col--auto mb-10">
              <div
                className="school-logo center"
                style={{
                  backgroundImage: `url(${school.logoUrl})`,
                }}></div>
              <h3 className="h-20 text-primary text-center">{school.name}</h3>
              <p className="text-13 text-grey-dark text-center">
                {school.location}
              </p>

              <p className="text-center text-16 text-primary font-bold mt-20 text-uppercase">
                List of {studentsToView} Students
              </p>
            </div>

            <div className="info mt-15">
              <div>
                <p className="text-14 text-grey-light">Class</p>
                <h3 className="h-14">
                  {studentsToView === 'Enrolled' || studentsToView === 'In Debt'
                    ? class_
                      ? class_.description
                      : 'ALL'
                    : 'N/A'}
                </h3>
              </div>

              <div>
                <p className="text-14 text-grey-light">Class Label</p>
                <h3 className="h-14">
                  {studentsToView === 'Enrolled'
                    ? classLabel
                      ? classLabel.description
                      : 'ALL'
                    : 'N/A'}
                </h3>
              </div>
              <div>
                <p className="text-14 text-grey-light">Total</p>
                <h3 className="h-14">{students.length}</h3>
              </div>
              <div>
                <p className="text-14 text-grey-light">Date Generated</p>
                <h3 className="h-14">{formatDate(new Date())}</h3>
              </div>
            </div>

            <div>
              <div>
                <table className="bx--data-table nm">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Student</th>
                      <th>Gender</th>
                      <th>Class</th>
                      <th>Guardian's Phone</th>
                      <th>Balance Due</th>
                    </tr>
                  </thead>
                  <tbody>
                    {students.map((student) => (
                      <tr>
                        <td>{student.studentId}</td>
                        <td>{student.fullName}</td>
                        <td>{student.gender}</td>
                        <td>
                          {student.status === 'Graduated' ||
                          student.status === 'Terminated'
                            ? 'N/A'
                            : `${student.classDescription} ${
                                student.classLabelDescription
                                  ? student.classLabelDescription
                                  : ''
                              }`}
                        </td>
                        <td>
                          {student.parentPhoneNumber
                            ? student.parentPhoneNumber
                            : 'N/A'}
                        </td>
                        <td>{formatCurrency(student.balanceDue)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="inner-footer">
          <div className="bx--row nm mt-10">
            <div className="bx--col-lg-3">
              <CustomButton
                classes="outline secondary f-width font-bold"
                onClick={closeAction}>
                CLOSE
              </CustomButton>
            </div>
            <div className="bx--col-lg-6"></div>
            {/* <div className="bx--col-lg-4">
              <CustomButton
                classes="secondary  f-width font-bold"
                onClick={downloadAction}
              >
                <Download size="11" strokeWidth="5px" />
                &nbsp; DOWNLOAD
              </CustomButton>
                      </div> */}
            <div className="bx--col-lg-3">
              <CustomButton
                classes="primary f-width font-bold"
                onClick={() => window.print()}>
                <Printer size="11" strokeWidth="5px" /> &nbsp; PRINT
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default StudentsPrintOutModal;
