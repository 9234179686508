import React from 'react';
import { Link } from 'react-router-dom';

import {
  TextInput,
  FormItem,
  Form,
  FormGroup,
  Tag,
  Loading,
} from 'carbon-components-react';

import CustomButton from '../../../components/custom-button/custom-btn.component';
import BorderedLogo from '../../../components/bordered-logo/bordered-logo.component';
import Footer from '../../../components/footer/footer.component';

import registerBg from '../../../assets/images/register_bg.png';
import Slider from '../../../components/slider/slider.component';

import './register.styles.scss';

import { registerSchool } from '../../../api/school.service';

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      location: '',
      emailAddress: '',
      firstName: '',
      lastName: '',
      requestInProgress: false,
      showSuccess: false,
      errorMessage: null,
      btnText: 'REGISTER',
    };
  }

  handleChange = (event) => {
    // destructure value and name of event.target
    const { value, name } = event.target;

    // update state
    this.setState({ [name]: value });
  };

  // validate data entered
  validateData = () =>
    this.state.name.length > 0 &&
    this.state.location.length > 0 &&
    this.state.firstName.length > 0 &&
    this.state.lastName.length > 0 &&
    this.state.emailAddress.length > 2 &&
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
      this.state.emailAddress,
    );

  // Register event handler
  register = async (event) => {
    event.preventDefault();

    // update state to set request in progress to true and error occurred to false
    this.setState({
      requestInProgress: true,
      errorMessage: null,
      btnText: null,
    });

    // initiate request
    const response = await registerSchool(this.state);

    // check response
    if (!response.success) {
      // registration failed, change state to show error message
      this.setState({
        requestInProgress: false,
        btnText: 'REGISTER',
        errorMessage: response.message,
      });

      return;
    }

    // set state to show registration success
    this.setState({ showSuccess: true });
  };

  render() {
    return (
      <div className="register">
        <div className="bx--row">
          <div
            className="bx--col-md-5"
            style={{ zIndex: 100, background: '#f9f9fb' }}>
            <div
              className=""
              style={{
                width: '700px',
                padding: '0 20px',
                maxWidth: '95%',
                margin: '0 auto',
              }}>
              <div className="mt-60 mb-50">
                <BorderedLogo />
              </div>
              <div hidden={this.state.showSuccess}>
                <h3 className="text-primary font-bold text-24 mb-30">
                  Register your school
                </h3>

                <Form action="post">
                  <FormGroup legendText="">
                    <FormItem>
                      <TextInput
                        id="name"
                        name="name"
                        labelText="Name of your school"
                        placeholder="eg. Abbys International Academy"
                        value={this.state.name}
                        onChange={this.handleChange}
                      />
                    </FormItem>
                  </FormGroup>
                  <div className="bx--row">
                    <div className="bx--col-md-4">
                      <FormGroup legendText="">
                        <FormItem>
                          <TextInput
                            id="location"
                            name="location"
                            labelText="School location"
                            placeholder="Where your school is located"
                            value={this.state.location}
                            onChange={this.handleChange}
                          />
                        </FormItem>
                      </FormGroup>
                    </div>

                    <div className="bx--col-md-4">
                      <FormGroup legendText="">
                        <FormItem>
                          <TextInput
                            id="email"
                            name="emailAddress"
                            labelText="Email address"
                            placeholder="Your school or your personal email address"
                            value={this.state.emailAddress}
                            onChange={this.handleChange}
                          />
                        </FormItem>

                        <p className="text-grey-darker text-12 nmb">
                          A valid working email is required
                        </p>
                      </FormGroup>
                    </div>
                  </div>
                  <div className="bx--row">
                    <div className="bx--col-md-4">
                      <FormGroup legendText="">
                        <FormItem>
                          <TextInput
                            id="firstname"
                            name="firstName"
                            labelText="First name"
                            placeholder="Enter your first name"
                            value={this.state.firstName}
                            onChange={this.handleChange}
                          />
                        </FormItem>

                        <p className="text-12 text-grey-dark">
                          An account will be created for you as the school's
                          administrator
                        </p>
                      </FormGroup>
                    </div>

                    <div className="bx--col-md-4">
                      <FormGroup legendText="">
                        <FormItem>
                          <TextInput
                            id="lastname"
                            name="lastName"
                            labelText="Last name"
                            placeholder="Enter your last name"
                            value={this.state.lastName}
                            onChange={this.handleChange}
                          />
                        </FormItem>
                      </FormGroup>
                    </div>
                  </div>
                  <div className="bx--row">
                    <div className="bx--col-md-4">
                      <CustomButton
                        classes="primary f-width mt-10 mb-40"
                        onClick={this.register}
                        type="submit"
                        disabled={
                          !this.validateData() || this.state.requestInProgress
                        }>
                        {this.state.btnText}

                        <div hidden={this.state.btnText != null}>
                          <div className="d-flex-center">
                            <Loading
                              withOverlay={false}
                              small={true}
                              description="Please wait..."
                            />
                          </div>
                        </div>
                      </CustomButton>
                    </div>
                  </div>
                  <Link
                    to="/account/login"
                    className="text-primary text-14 font-bold">
                    Log in
                  </Link>
                  &nbsp;
                  <span className="text-grey-darker text-14">
                    to existing account
                  </span>
                  <div hidden={this.state.errorMessage == null}>
                    <Tag className="tag-f-w mt-30" type="red">
                      {this.state.errorMessage}
                    </Tag>
                  </div>
                </Form>
              </div>

              <div className="success" hidden={!this.state.showSuccess}>
                <h3 className="text-primary font-bold text-24 mt-100 mb-30">
                  Registration Successful!
                </h3>
                <p className="text-16 text-grey-dark mt-20">
                  Thank you for registering your school on&nbsp;
                  <span className="font-bold">
                    EPOBS Comprehensive School Suite (CSS).
                  </span>
                  <br />A confirmation email is sent to&nbsp;
                  <span className="text-primary">
                    {this.state.emailAddress.toLowerCase()}.
                  </span>
                  <br />
                  Follow the link in the email to verify your account. Thank
                  you!
                </p>
                <div className="bx--row">
                  <div className="bx--col-md-4">
                    <CustomButton classes="primary f-width mt-50 mb-40">
                      <a
                        href="https://www.epobssuite.com/"
                        className="no-decoration text-white">
                        VISIT OUR WEBSITE TO KNOW MORE ABOUT EPOBS CSS
                      </a>
                    </CustomButton>
                  </div>
                </div>
              </div>

              <Footer />
            </div>
          </div>
          <div className="bx--col-md-3 fixed-right padding-0">
            <div className="bx--row">
              <Slider />
            </div>
            <img className="img-f-w-obj-cover" src={registerBg} alt="" />
          </div>
        </div>
      </div>
    );
  }
}

export default RegisterPage;
