import { apiBaseUrl, postData, getData } from './common';
import { handleGenericError } from './common';

// import axios
const axios = require('axios').default;

const baseUrl = `${apiBaseUrl}academic-year/`;

export const getAcademicYears = async (
  authToken,
  skip,
  take = 100,
  query = '',
) => {
  // build url
  const url = `${baseUrl}list/?skip=${skip}&take=${take}&query=${query}`;

  // set config
  const config = { headers: { Authorization: `Bearer ${authToken}` } };

  // initiate request
  try {
    let response = await axios.get(url, config);
    return { success: true, academicYears: response.data };
  } catch (error) {
    return handleGenericError(error);
  }
};

export const getTerms = async (authToken, academicYearId) => {
  // build url
  const url = `${baseUrl}terms/?academicYearId=${academicYearId}`;

  // set config
  const config = { headers: { Authorization: `Bearer ${authToken}` } };

  // initiate request
  try {
    let response = await axios.get(url, config);
    return { success: true, terms: response.data };
  } catch (error) {
    return handleGenericError(error);
  }
};

export const getBudget = async (authToken, academicYearId) => {
  // build url
  const url = `${baseUrl}budget/?academicYearId=${academicYearId}`;

  // set config
  const config = { headers: { Authorization: `Bearer ${authToken}` } };

  // initiate request
  try {
    let response = await axios.get(url, config);
    return { success: true, budget: response.data.budget };
  } catch (error) {
    return handleGenericError(error);
  }
};

export const getBudgets = async (authToken) => {
  // build url
  const url = `${baseUrl}budgets`;

  // set config
  const config = { headers: { Authorization: `Bearer ${authToken}` } };

  // initiate request
  try {
    let response = await axios.get(url, config);
    return { success: true, budgets: response.data.budgets };
  } catch (error) {
    return handleGenericError(error);
  }
};

export const getActiveAcademicYear = async (authToken) => {
  // build url
  const url = `${baseUrl}get-active`;

  // set config
  const config = { headers: { Authorization: `Bearer ${authToken}` } };

  // initiate request
  try {
    let response = await axios.get(url, config);
    return { success: true, academicYear: response.data };
  } catch (error) {
    return handleGenericError(error);
  }
};

export const addOrUpdateAcademicYear = async (authToken, data) => {
  // build url
  const url = `${baseUrl}create-new`;

  // set config
  const config = { headers: { Authorization: `Bearer ${authToken}` } };

  // initiate request
  try {
    let response = await axios.post(url, data, config);
    return {
      success: true,
      academicYear: response.data.academicYear,
      message: `Academic year ${data.id === 0 ? 'created' : 'updated'} successfully.`,
    };
  } catch (error) {
    return handleGenericError(error);
  }
};

export const addOrUpdateTerm = async (authToken, data) => {
  // build url
  const url = `${baseUrl}new-term`;

  // set config
  const config = { headers: { Authorization: `Bearer ${authToken}` } };

  // initiate request
  try {
    let response = await axios.post(url, data, config);
    return {
      success: true,
      term: response.data.term,
      message: `Term ${data.id === 0 ? 'created' : 'updated'} successfully.`,
    };
  } catch (error) {
    return handleGenericError(error);
  }
};

export const addBudget = async (authToken, data) => {
  // build url
  const url = `${baseUrl}add-budget`;

  // set config
  const config = { headers: { Authorization: `Bearer ${authToken}` } };

  // initiate request
  try {
    let response = await axios.post(url, data, config);
    return {
      success: true,
      data: response.data.data,
      budgetExisted: response.data.budgetExisted,
      message: `Budget ${data.budgetItemId === 0 ? 'added' : 'updated'} successfully.`,
    };
  } catch (error) {
    return handleGenericError(error);
  }
};

export const setBudgetStatus = async (authToken, data) => {
  // build url
  const url = `${baseUrl}set-budget-status`;

  // set config
  const config = { headers: { Authorization: `Bearer ${authToken}` } };

  // initiate request
  try {
    let response = await axios.post(url, data, config);
    return {
      success: true,
      data: response.data.status,
    };
  } catch (error) {
    return handleGenericError(error);
  }
};

export const deleteAcademicYear = async (authToken, data) => {
  return await postData('academic-year/delete', data, authToken);
};

export const deleteTerm = async (authToken, data) => {
  return await postData('academic-year/delete-term', data, authToken);
};

export const deleteBudgetItem = async (authToken, data) => {
  return await postData('academic-year/delete-budget-item', data, authToken);
};

export const deleteBudgetItems = async (authToken, data) => {
  return await postData('academic-year/delete-budget-items', data, authToken);
};

export const closeAcademicYear = async (authToken, data) => {
  const response = await postData('academic-year/set-status', data, authToken);

  if (response.success) {
    return {
      success: true,
      academicYear: response.data.academicYear,
    };
  }

  return response;
};

export const getBudgetCategoryPeriodActual = async (
  authToken,
  categoryId,
  budgetId,
) => {
  let response = await getData(
    `academic-year/budget-category-period-actual/?categoryId=${categoryId}&budgetId=${budgetId}`,
    authToken,
  );
  if (response.success) {
    return {
      success: true,
      periodActual: response.data.periodActual,
    };
  }

  return response;
};

export const getBudgetLedgerAccountPeriodActual = async (
  authToken,
  ledgerAccountId,
  budgetId,
) => {
  let response = await getData(
    `academic-year/budget-ledger-account-period-actual/?ledgerAccountId=${ledgerAccountId}&budgetId=${budgetId}`,
    authToken,
  );
  if (response.success) {
    return {
      success: true,
      periodActual: response.data.periodActual,
    };
  }

  return response;
};
