import React from 'react';
import epobsLogo from '../../assets/images/EPOBS png 1.png';
import './bordered-logo.styles.scss';

const BorderedLogo = ({ reduceVersionTextMargin = false }) => (
  <div className="logo-div">
    <img className="logo" src={epobsLogo} alt="EPOBS LOGO" />
  </div>
);

export default BorderedLogo;
