// using reselect
import { createSelector } from 'reselect';

// create select billing function
const selectBilling = (state) => state.billing;

export const selectBillings = createSelector(
  [selectBilling],
  (billing) => billing.billings,
);

export const selectBilledStudents = createSelector(
  [selectBilling],
  (billing) => billing.billedStudents,
);

// create selectBillingFees selector
export const selectBillingFees = createSelector(
  [selectBilling],
  (billing) => billing.fees,
);
