import React from 'react';
import { withRouter } from 'react-router-dom';
import { Plus, Upload } from 'react-feather';

import MainHeader from '../../components/main-header/main-header.component';
import Mainmenu from '../../components/menu/main-menu/main-menu.component';
import CustomButton from '../../components/custom-button/custom-btn.component';
import QuickActionCard from '../../components/card/quick-action-card/quick-action-card.component';
import PayrollCard from '../../components/card/payroll-card/payroll-card.component';
import AddPayrollModal from '../../components/modal/add-payroll/add-payroll.component';
import Footer from '../../components/footer/footer.component';

import { Search, Loading } from 'carbon-components-react';

//import svg icon
import { ReactComponent as Dollar } from '../../assets/images/card-icons/014-sales 1.svg';
import { ReactComponent as AllowanceIcon } from '../../assets/images/card-icons/018-chart-1 1.svg';
import { ReactComponent as DeductionIcon } from '../../assets/images/card-icons/018-chart-1 2.svg';

import CreateAllowanceModal from '../../components/modal/create-allowance/create-allowance.component';
import CreateDeductionModal from '../../components/modal/create-deduction/create-deduction.component';
import ConfirmDeleteModal from '../../components/modal/confirm-delete/confirm-delete.component';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../redux/selectors/user.selectors';

import { connect } from 'react-redux';
import { getPayrolls, deletePayroll } from '../../api/payroll.service';

import './payroll.styles.scss';

class PayrollPage extends React.Component {
  constructor(props) {
    super(props);

    // set state
    this.state = {
      query: '',
      filter: 'ALL',
      payrolls: [],
      loading: true,
      errorMessage: null,
      openAddpayrollModal: false,
      payrollHeading: 'Add New Payroll',
      payrollBtnText: 'CREATE PAYROLL',
      openCreateAllowanceModal: false,
      openCreateDeductionModal: false,
      openConfirmDeleteModal: false,
      deleteDescription: 'Do you want to delete this payroll?',
    };
  }

  componentDidMount() {
    this.fetchPayrolls();
  }

  fetchPayrolls = async () => {
    this.setState({ loading: true, errorMessage: null });

    // get auth token
    const { authToken } = this.props.currentUser;

    const response = await getPayrolls(authToken);

    // failed
    if (!response.success) {
      // show error message
      this.setState({
        loading: false,
        errorMessage: response.message,
      });
      return;
    }

    // all good
    this.setState({ loading: false, payrolls: response.payrolls });
  };

  filter = () => {
    let payrolls = this.state.payrolls;

    if (this.state.filter === 'Voided') {
      payrolls = payrolls.filter((po) => po.dateVoided);
    } else if (this.state.filter === 'Approved') {
      payrolls = payrolls.filter(
        (po) =>
          !po.dateVoided &&
          !po.dateGenerated &&
          po.status === this.state.filter,
      );
    } else if (this.state.filter !== 'ALL') {
      payrolls = payrolls.filter((po) => po.status === this.state.filter);
    }

    // Perform additional filter
    if (this.state.query.length > 0) {
      payrolls = payrolls.filter((po) =>
        po.uniqueId.toLowerCase().includes(this.state.query.toLowerCase()),
      );
    }

    return payrolls;
  };

  render() {
    const payrolls = this.filter();

    return (
      <div>
        <MainHeader />

        <AddPayrollModal
          openModal={this.state.openAddPayrollModal}
          heading={this.state.payrollHeading}
          btnText={this.state.payrollBtnText}
          refreshPage={() => {
            this.setState({ openAddPayrollModal: false });
            this.fetchPayrolls();
          }}
          cancelAction={() => this.setState({ openAddPayrollModal: false })}
        />

        <ConfirmDeleteModal
          openModal={this.state.openConfirmDeleteModal}
          description={this.state.deleteDescription}
          deleteAction={this.state.deleteAction}
          deleteSuccessAction={this.state.deleteSucessAction}
          cancelAction={() => this.setState({ openConfirmDeleteModal: false })}
        />

        <CreateAllowanceModal
          openModal={this.state.openCreateAllowanceModal}
          cancelAction={() =>
            this.setState({ openCreateAllowanceModal: false })
          }
        />

        <CreateDeductionModal
          openModal={this.state.openCreateDeductionModal}
          cancelAction={() =>
            this.setState({ openCreateDeductionModal: false })
          }
        />

        <div className="payroll page-container">
          <div>
            <span className="h-18">Quick Actions</span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span className="right">
              <Mainmenu />
            </span>
          </div>

          <div className="mt-20">
            <div className="bx--row">
              <div className="bx--col-md-2">
                <div
                  className="card-add-payroll pointer"
                  onClick={() => {
                    this.setState({
                      openAddPayrollModal: true,
                    });
                  }}>
                  <div className="plus-icon mt-10">
                    <Plus size="18" strokeWidth="3" />
                  </div>
                  <div className="text-label">CREATE NEW PAYROLL</div>
                </div>
              </div>

              <div className="bx--col-md-2">
                <QuickActionCard
                  description="Allowances"
                  actionEvent1={(ev) => {
                    this.setState({
                      openCreateAllowanceModal: true,
                    });
                  }}
                  actionEvent2={() => {
                    this.props.history.push(`/payroll/allowances`);
                  }}>
                  <AllowanceIcon />
                </QuickActionCard>
              </div>

              <div className="bx--col-md-2">
                <QuickActionCard
                  description="Deductions"
                  actionEvent1={(ev) => {
                    this.setState({
                      openCreateDeductionModal: true,
                    });
                  }}
                  actionEvent2={() => {
                    this.props.history.push(`/payroll/deductions`);
                  }}>
                  <DeductionIcon />
                </QuickActionCard>
              </div>

              <div className="bx--col-md-2">
                <QuickActionCard
                  description="Employees Payment Info"
                  leftActionText="REVIEW"
                  hideAction2
                  actionEvent1={() => {
                    this.props.history.push(`/payroll/review-salary`);
                  }}>
                  <Dollar />
                </QuickActionCard>
              </div>
            </div>
          </div>

          <hr className="grey-hr mt-30" />

          <div className="mt-20">
            <div>
              <span className="h-20">Payrolls </span>
              <span
                className="text-primary text-14 font-bold ml-20 pointer"
                onClick={() => this.props.history.push(`/payroll/reports`)}>
                VIEW REPORTS
              </span>
            </div>
            <div className="bx--row">
              <div
                className="bx--col-md-8"
                hidden={
                  this.state.loading ||
                  this.state.errorMessage ||
                  this.state.payrolls.length === 0
                }>
                <div className="mt-10">
                  <div className="bx--row">
                    <span className="bx--col-lg-2">
                      <CustomButton
                        classes={`pill text-12 f-width font-bold ${
                          this.state.filter === 'ALL' ? 'primary' : 'ash'
                        }`}
                        onClick={() =>
                          this.setState({
                            filter: 'ALL',
                          })
                        }>
                        ALL&nbsp;
                        {this.state.filter === 'ALL'
                          ? `(${payrolls.length})`
                          : ''}
                      </CustomButton>
                    </span>
                    <span className="bx--col-lg-2">
                      <CustomButton
                        classes={`pill text-12 f-width font-bold ${
                          this.state.filter === 'Draft' ? 'primary' : 'ash'
                        }`}
                        onClick={() =>
                          this.setState({
                            filter: 'Draft',
                          })
                        }>
                        DRAFT&nbsp;
                        {this.state.filter === 'Draft'
                          ? `(${payrolls.length})`
                          : ''}
                      </CustomButton>
                    </span>
                    <span className="bx--col-lg-2">
                      <CustomButton
                        classes={`pill text-12 f-width font-bold ${
                          this.state.filter === 'Pending' ? 'primary' : 'ash'
                        }`}
                        onClick={() =>
                          this.setState({
                            filter: 'Pending',
                          })
                        }>
                        PENDING&nbsp;
                        {this.state.filter === 'Pending'
                          ? `(${payrolls.length})`
                          : ''}
                      </CustomButton>
                    </span>
                    <span className="bx--col-lg-2">
                      <CustomButton
                        classes={`pill text-12 f-width font-bold ${
                          this.state.filter === 'Approved' ? 'primary' : 'ash'
                        }`}
                        onClick={() =>
                          this.setState({
                            filter: 'Approved',
                          })
                        }>
                        APPROVED&nbsp;
                        {this.state.filter === 'Approved'
                          ? `(${payrolls.length})`
                          : ''}
                      </CustomButton>
                    </span>
                    <span className="bx--col-lg-2">
                      <CustomButton
                        classes={`pill text-12 f-width font-bold ${
                          this.state.filter === 'Generated' ? 'primary' : 'ash'
                        }`}
                        onClick={() =>
                          this.setState({
                            filter: 'Generated',
                          })
                        }>
                        GENERATED&nbsp;
                        {this.state.filter === 'Generated'
                          ? `(${payrolls.length})`
                          : ''}
                      </CustomButton>
                    </span>
                    <span className="bx--col-lg-2">
                      <CustomButton
                        classes={`pill text-12 f-width font-bold text-orange ${
                          this.state.filter === 'Voided' ? 'primary' : 'ash'
                        }`}
                        onClick={() =>
                          this.setState({
                            filter: 'Voided',
                          })
                        }>
                        VOIDED&nbsp;
                        {this.state.filter === 'Voided'
                          ? `(${payrolls.length})`
                          : ''}
                      </CustomButton>
                    </span>
                  </div>
                </div>
                <div className="mt-20">
                  <Search
                    id="search"
                    name="search"
                    labelText=""
                    value={this.state.query}
                    onChange={(event) =>
                      this.setState({
                        query: event.target.value,
                      })
                    }
                    placeHolderText="Search by ID, Supplier name, Description or Date Created"
                  />
                </div>
              </div>
            </div>

            <div className="mt-20 fade-in" hidden={!this.state.loading}>
              <Loading
                small={true}
                withOverlay={false}
                description="Loading..."
              />
            </div>

            <div
              className="mt-30 fade-in"
              hidden={this.state.errorMessage == null}>
              <span className="mt-10 text-danger">
                {this.state.errorMessage}.
              </span>
              &nbsp;
              <span
                className="text-primary pointer font-bold"
                onClick={this.fetchPayrolls}>
                TRY AGAIN
              </span>
            </div>

            <p
              className="text-14 mt-30 text-grey"
              hidden={
                this.state.payrolls.length > 0 ||
                this.state.loading ||
                this.state.errorMessage !== null
              }>
              No payrolls created yet
            </p>

            <div
              hidden={
                this.state.loading ||
                this.state.errorMessage ||
                this.state.payrolls.length === 0
              }>
              <div className="bx--row mt-20">
                {Array.isArray(payrolls) &&
                  payrolls.map((payroll) => (
                    <div key={payroll.id} className="bx--col-lg-3">
                      <PayrollCard
                        payroll={payroll}
                        clickAction={() =>
                          this.props.history.push(
                            `/payroll/details/${payroll.id}}`,
                          )
                        }
                        deleteAction={() =>
                          this.setState({
                            openConfirmDeleteModal: true,
                            deleteAction: async () => {
                              return await deletePayroll(
                                this.props.currentUser.authToken,
                                payroll.id,
                              );
                            },
                            deleteSucessAction: () => {
                              this.fetchPayrolls();
                            },
                            deleteDescription: `Confirm deletion of ${payroll.description}. This action cannot be reversed`,
                          })
                        }
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <Footer fixed={false} showLinks={false} />
        </div>
      </div>
    );
  }
}

// this will provide us with the state values we need
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default withRouter(connect(mapStateToProps)(PayrollPage));
