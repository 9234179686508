import ERROR_MESSAGES from './error-messages';
// import axios
const axios = require('axios').default;

export const apiBaseUrl =
  process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000/api/';

export const getData = async (url, authToken, file = false) => {
  // set config
  const config = { headers: { Authorization: `Bearer ${authToken}` } };
  if (file) {
    config.responseType = 'blob';
  }

  try {
    let response = await axios.get(`${apiBaseUrl}${url}`, config);
    if (file) {
      return {
        success: true,
        data: response,
      };
    }
    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    return handleGenericError(error);
  }
};

export const postData = async (url, data, authToken) => {
  // set config
  const config = { headers: { Authorization: `Bearer ${authToken}` } };

  // initiate request
  try {
    let response = await axios.post(`${apiBaseUrl}${url}`, data, config);
    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    return handleGenericError(error);
  }
};

export const putData = async (url, data, authToken) => {
  // set config
  const config = { headers: { Authorization: `Bearer ${authToken}` } };

  // initiate request
  try {
    let response = await axios.put(`${apiBaseUrl}${url}`, data, config);
    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    return handleGenericError(error);
  }
};

export const deleteData = async (url, authToken) => {
  // set config
  const config = { headers: { Authorization: `Bearer ${authToken}` } };

  // initiate request
  try {
    let response = await axios.delete(`${apiBaseUrl}${url}`, config);
    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    return handleGenericError(error);
  }
};

export const handleGenericError = (error) => {
  if (error.response) {
    // authentication error
    if (error.response.status === 401) {
      return {
        success: false,
        message:
          'Your login is expired... Please logout and login again. This is done to keep you account secure.',
      };
    }

    if (error.response.status === 404) {
      return { success: false, message: 404 };
    }

    if (error.response.status === 403) {
      return {
        success: false,
        message:
          'You do not have access to this resource. Contact your system administrator to grant you access.',
      };
    }

    // missing info or invalid info, or conflict occurred
    if (error.response.status === 400 || error.response.status === 409) {
      return { success: false, message: error.response.data.message };
    }

    // unknown server error
    return { success: false, message: ERROR_MESSAGES.UNKNOWN_SERVER_ERROR };
  }

  // could not contact server or could not receive a response
  return { success: false, message: ERROR_MESSAGES.UNKNOWN_ERROR };
};

export const fetchService = async (path, authToken, file = true) => {
  return await getData(path, authToken, file);
};
