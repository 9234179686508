import React, { useEffect, useGlobal } from 'reactn';
import { withRouter } from 'react-router';
import { fetchData, SWRFetcher } from '../../../api/api';
import Img from '../../../assets/images/cake2.png';
import useSWR from 'swr';

import './birthday.styles.scss';
import { Tag } from 'carbon-components-react';

const BirthdayCelebrants = (props) => {
  const [birthDays, setBirthDays] = useGlobal('birthDays');
  const date = new Date().toLocaleDateString().replaceAll('/', '-');

  const { data, error } = useSWR(
    birthDays.student?.dateChecked != date &&
      `BirthdayCelebrants/today/students?take=200&skip=0`,
    {
      fetcher: SWRFetcher(props.currentUser?.authToken),
      errorRetryCount: 2,
      errorRetryInterval: 80000,
      revalidateOnFocus: false,
      onSuccess: (data) => {
        setBirthDays({
          ...birthDays,
          student: {
            dateChecked: date,
            data: data,
          },
        });
      },
      onError: () => {
        /*  setBirthDays({
          ...birthDays,
          student: {
            dateChecked: null,
            data: null,
          },
        }); */
      },
    },
  );

  useEffect(() => {
    //reset date
    if (!(!data && !error)) {
      if (birthDays.student.data && birthDays.student?.dateChecked != date) {
        setBirthDays({
          ...birthDays,
          student: {
            dateChecked: null,
            data: null,
          },
        });
      }
    }
  }, [date, birthDays.student?.dateChecked]);

  return (
    <div className="birthday">
      {birthDays.student.data?.pagingInfo.recordsCount > 0 && (
        <div onClick={() => props.history.push(`/birthdays`)}>
          <img src={Img} alt="" />
        </div>
      )}
    </div>
  );
};

export default withRouter(BirthdayCelebrants);
