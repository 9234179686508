import React from 'react';
import {
  TextInput,
  FormItem,
  Form,
  Select,
  SelectItem,
  DatePicker,
  DatePickerInput,
  FormGroup,
  Modal,
  Tabs,
  Tab,
  Loading,
  FileUploaderButton,
} from 'carbon-components-react';
import { Download, Camera } from 'react-feather';
import CustomButton from '../../custom-button/custom-btn.component';

import './add-student.styles.scss';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../../redux/selectors/user.selectors';
import {
  selectClasses,
  selectClassLabels,
} from '../../../redux/selectors/class.selectors';
import { connect } from 'react-redux';
import { setStudents } from '../../../redux/actions/student.actions';
import {
  createStudent,
  uploadStudentPhoto,
} from '../../../api/student.service';

class AddStudentModal extends React.Component {
  constructor(props) {
    super(props);

    // set state
    this.state = {
      openModal: props.openModal,
      openSiblings: false,
      selectedTabIndex: 0,
      id: 0,
      studentToEdit: null,
      schoolStudentId: '',
      firstName: '',
      lastName: '',
      gender: '',
      dateOfBirth: '',
      classId: props.classDescription ? props.classId : '',
      classDescription: props.classDescription ? props.classDescription : '',
      classLabelId: '',
      classLabelDescription: '',
      dateEnrolled: null,
      religion: '',
      nationality: '',
      parentName: '',
      parentPhoneNumber: '',
      parentEmailAddress: '',
      emergencyContactName: '',
      emergencyContactPhoneNumber: '',
      emergencyContactEmailAddress: '',
      comments: '',
      classes: this.props.classes,
      classLabels: this.props.classLabels,
      loading: false,
      errorMessage: null,
      successMessage: null,
      addBtnText: 'ADD STUDENT',
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.openModal !== prevState.openModal) {
      return { openModal: nextProps.openModal };
    } else return null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.classes.length !== prevProps.classes.length) {
      this.setState({ classes: this.props.classes });
    }

    if (this.props.classLabels.length !== prevProps.classLabels.length) {
      this.setState({ classLabels: this.props.classLabels });
    }

    if (this.props.student !== prevProps.student) {
      if (this.props.student) {
        //console.log("\nview student: ", this.props.student);
        const dateOfBirth = new Date(this.props.student.dateOfBirth);

        const parsedDateOfBirth = [
          dateOfBirth.getFullYear(),
          ('0' + (dateOfBirth.getMonth() + 1)).slice(-2),
          ('0' + dateOfBirth.getDate()).slice(-2),
        ].join('-');

        this.setState({
          ...this.props.student,
          selectedTabIndex: 0,
          dateOfBirth: parsedDateOfBirth,
          studentToEdit: this.props.class,
          loading: false,
          errorMessage: null,
          successMessage: null,
          addBtnText: 'UPDATE STUDENT',
        });
      } else {
        this.setState({
          selectedTabIndex: 0,
          id: 0,
          openSiblings: false,
          studentToEdit: null,
          studentPhoto: null,
          schoolStudentId: '',
          firstName: '',
          lastName: '',
          gender: '',
          dateOfBirth: '',
          classId: '',
          classDescription: '',
          classLabelId: '',
          classLabelDescription: '',
          parentName: '',
          religion: '',
          nationality: '',
          dateEnrolled: null,
          parentPhoneNumber: '',
          parentEmailAddress: '',
          emergencyContactName: '',
          emergencyContactPhoneNumber: '',
          emergencyContactEmailAddress: '',
          comments: '',
          loading: false,
          errorMessage: null,
          successMessage: null,
          addBtnText: 'ADD STUDENT',
        });
      }
    }
  }

  handleChange = (event) => {
    // destructure values
    const { name, value } = event.target;

    // update state
    this.setState({ [name]: value, successMessage: null });
  };

  validateBasicInfo = () => {
    return (
      this.state?.firstName.length > 0 &&
      this.state?.lastName.length > 0 &&
      this.state?.gender.length > 0 &&
      this.state?.dateOfBirth.length > 0 &&
      this.state?.classId?.toString().length > 0
    );
  };

  addStudent = async (event) => {
    event.preventDefault();

    this.setState({ loading: true, errorMessage: null, addBtnText: null });

    // get class
    const studentClass = this.state.classes.find(
      (item) => item.id == this.state.classId,
    );

    const classLabel = this.state.classLabels.find(
      (item) => item.id == this.state.classLabelId,
    );

    const data = {
      ...this.state,
      openSiblings: undefined,
      studentPhoto: undefined,
      //classId: studentClass?.id,
      //classDescription: studentClass?.description,
      //classLabelId: classLabel ? classLabel?.id : undefined,
      classes: undefined,
      classLabels: undefined,
      addBtnText: undefined,
      loading: undefined,
      errorMessage: undefined,
      openModal: undefined,
      selectedTabIndex: undefined,
    };

    const response = await createStudent(
      this.props.currentUser.authToken,
      data,
    );

    if (!response.success) {
      this.setState({
        loading: false,
        errorMessage: response.message,
        addBtnText: data.id > 0 ? 'UPDATE STUDENT' : 'ADD STUDENT',
      });

      return;
    }

    let student = response.student;

    // upload photo if selected
    if (this.state.studentPhoto) {
      const formData = new FormData();
      formData.append('formFile', this.state.studentPhotoFile);
      formData.append('studentId', response.student.id);

      // upload photo
      const uploadResponse = await uploadStudentPhoto(
        this.props.currentUser.authToken,
        formData,
      );

      if (uploadResponse.success) {
        student = uploadResponse.student;
      }
    }

    this.setState({
      loading: false,
      errorMessage: null,
      id: 0,
      studentToEdit: null,
      addBtnText: 'ADD STUDENT',
      selectedTabIndex: 0,
      studentPhoto: null,
      studentPhotoFile: null,
      schoolStudentId: '',
      firstName: '',
      lastName: '',
      gender: '',
      dateOfBirth: '',
      parentName: '',
      parentPhoneNumber: '',
      parentEmailAddress: '',
      emergencyContactName: '',
      emergencyContactPhoneNumber: '',
      emergencyContactEmailAddress: '',
      comments: '',
      successMessage: `Student [${student.firstName} ${student.lastName}] added successfully.`,
    });

    //console.log("\nupdated student", student);

    // all good, add student and close dialog
    this.props.setStudents([student]);

    // close dialog if it was edit
    if (data.id > 0) {
      this.props.closeAction();
    }
  };

  render() {
    return (
      <div>
        <Modal
          id="add-student-modal"
          className="add-student-modal"
          hasScrollingContent
          passiveModal={true}
          open={this.state.openModal && !this.state.openSiblings}
          modalHeading={this.state.id > 0 ? 'Edit Student' : 'Add New Student'}
          primaryButtonDisabled={false}
          onRequestClose={() => this.setState({ openModal: false })}
          focusTrap={false}
          iconDescription="Close the modal">
          <div>
            <Form action="post">
              <Tabs
                ariaLabel="listbox"
                role="navigation"
                selected={this.state.selectedTabIndex}
                tabContentClassName="tab-content"
                triggerHref="#"
                type="default">
                <Tab
                  href="#"
                  label="Upload Photo"
                  role="presentation"
                  tabIndex={0}>
                  <div className="tab1">
                    <p
                      className="text-success text-center text-16 mt-10"
                      hidden={this.state.successMessage === null}>
                      <span>{this.state.successMessage}</span>
                      <br />
                      Add new student or close this dialog.
                    </p>

                    <div
                      className="photo center pointer"
                      style={{
                        backgroundImage: `url(${this.state.studentPhoto})`,
                        backgroundSize: 'cover',
                      }}>
                      <Camera size="18" strokeWidth="2px" className="camera" />
                    </div>

                    <div className="upload-btn">
                      <FileUploaderButton
                        accept={['image/*']}
                        buttonKind="primary"
                        disableLabelChanges={false}
                        disabled={false}
                        labelText="Select Photo"
                        multiple={false}
                        onChange={(e) =>
                          this.setState({
                            studentPhoto: URL.createObjectURL(
                              e.target.files[0],
                            ),
                            studentPhotoFile: e.target.files[0],
                          })
                        }
                        onClick={function noRefCheck() {}}
                        role="button"
                        tabIndex={0}
                      />
                    </div>

                    <div className="text-14 text-grey">
                      <p className="photo-text center text-center">
                        <span className="text-dark">.jpg </span> or{' '}
                        <span className="text-dark"> .png </span>
                        formats only.
                        <br />
                        Maximum file size is 1MB.
                      </p>
                    </div>

                    <div className="btn-actions mt-40">
                      <CustomButton
                        classes="secondary font-bold"
                        onClick={this.props.importAction}>
                        <Download size="11" strokeWidth="5px" />
                        &nbsp; IMPORT
                      </CustomButton>

                      <div className="">
                        <CustomButton
                          classes="outline danger  font-bold"
                          onClick={this.props.closeAction}>
                          CANCEL
                        </CustomButton>

                        <CustomButton
                          classes="primary font-bold ml-20"
                          onClick={() =>
                            this.setState({ selectedTabIndex: 1 })
                          }>
                          NEXT
                        </CustomButton>
                      </div>
                    </div>
                  </div>
                </Tab>

                <Tab
                  href="#"
                  label="Basic Information"
                  role="presentation"
                  tabIndex={1}>
                  <div className="tab2 mt-30">
                    <div className="bx--row">
                      <FormGroup legendText="" className="bx--col-md-4">
                        <FormItem>
                          <TextInput
                            id="schoolStudentId"
                            name="schoolStudentId"
                            labelText="Student ID"
                            placeholder="Provide existing student ID if any"
                            value={this.state.schoolStudentId}
                            onChange={this.handleChange}
                          />
                        </FormItem>
                      </FormGroup>

                      <FormGroup legendText="" className="bx--col-md-4">
                        <FormItem>
                          <TextInput
                            id="firstName"
                            name="firstName"
                            labelText="First Name"
                            placeholder="First name"
                            value={this.state.firstName}
                            onChange={this.handleChange}
                          />
                        </FormItem>
                      </FormGroup>

                      <FormGroup legendText="" className="bx--col-md-4">
                        <FormItem>
                          <TextInput
                            id="lastName"
                            name="lastName"
                            labelText="Last Name"
                            placeholder="Last name"
                            value={this.state.lastName}
                            onChange={this.handleChange}
                          />
                        </FormItem>
                      </FormGroup>

                      <FormGroup legendText="" className="bx--col-md-4">
                        <FormItem>
                          <Select
                            id="gender"
                            className="f-width"
                            name="gender"
                            labelText="Gender"
                            value={this.state.gender}
                            onChange={this.handleChange}>
                            <SelectItem
                              disabled
                              selected={this.state.studentToEdit == null}
                              text="Select Gender"
                              value=""
                            />
                            <SelectItem
                              text="Male"
                              selected={this.state.gender === 'Male'}
                              value="Male"
                            />
                            <SelectItem
                              text="Female"
                              selected={this.state.gender === 'Female'}
                              value="Female"
                            />
                          </Select>
                        </FormItem>
                      </FormGroup>

                      <FormGroup className="bx--col-md-4">
                        <FormItem>
                          <DatePicker
                            dateFormat="Y-m-d"
                            datePickerType="single"
                            id="dtae-of-birth"
                            onClose={(value) => {
                              if (value.length > 0) {
                                this.setState({
                                  dateOfBirth: [
                                    value[0].getFullYear(),
                                    ('0' + (value[0].getMonth() + 1)).slice(-2),
                                    ('0' + value[0].getDate()).slice(-2),
                                  ].join('-'),
                                });
                              }
                            }}>
                            <DatePickerInput
                              className=""
                              id="date"
                              name="startDate"
                              labelText="Date Of Birth"
                              value={this.state.dateOfBirth}
                              onChange={() => {}}
                              pattern="d{1,2}/d{4}"
                              placeholder="YYYY-MM-DD"
                            />
                          </DatePicker>
                        </FormItem>
                      </FormGroup>

                      <FormGroup legendText="" className="bx--col-md-4">
                        <FormItem>
                          <Select
                            id="class"
                            className="f-width"
                            name="class"
                            labelText="Class"
                            onChange={(e) =>
                              this.setState({ classId: e.target.value })
                            }>
                            <SelectItem
                              selected={this.state.studentToEdit == null}
                              disabled
                              text="Select Class"
                              value=""
                            />

                            {this.state.classes.map((item) => (
                              <SelectItem
                                selected={this.state.classId == item.id}
                                text={item.description}
                                value={item.id}
                              />
                            ))}
                          </Select>
                        </FormItem>
                      </FormGroup>

                      <FormGroup legendText="" className="bx--col-md-4">
                        <FormItem>
                          <Select
                            id="classLabel"
                            className="f-width"
                            name="classLabel"
                            labelText="Class Label"
                            onChange={(e) =>
                              this.setState({ classLabelId: e.target.value })
                            }>
                            <SelectItem
                              selected={this.state.studentToEdit == null}
                              //disabled
                              text="Select class label"
                              value=""
                            />
                            {this.state.classLabels.map((item) => (
                              <SelectItem
                                selected={this.state.classLabelId == item.id}
                                text={item.description}
                                value={item.id}
                              />
                            ))}
                          </Select>
                        </FormItem>
                      </FormGroup>
                    </div>

                    <div className="btn-actions mt-40">
                      <CustomButton
                        classes="secondary font-bold"
                        onClick={this.props.importAction}>
                        <Download size="11" strokeWidth="5px" />
                        &nbsp; IMPORT
                      </CustomButton>

                      <div className="">
                        <CustomButton
                          classes="outline danger font-bold"
                          onClick={this.props.closeAction}>
                          CANCEL
                        </CustomButton>

                        <CustomButton
                          classes="outline secondary ml-20 font-bold"
                          onClick={() =>
                            this.setState({ selectedTabIndex: 0 })
                          }>
                          BACK
                        </CustomButton>

                        <CustomButton
                          classes="primary font-bold ml-20"
                          disabled={!this.validateBasicInfo()}
                          onClick={() =>
                            this.setState({ selectedTabIndex: 2 })
                          }>
                          NEXT
                        </CustomButton>
                      </div>
                    </div>
                  </div>
                </Tab>

                {/* <Tab href="#" label="Siblings" role="presentation" tabIndex={4}>
                  <CustomButton
                    classes="primary font-bold"
                    onClick={() => {
                      this.setState({ openSiblings: true });
                    }}
                  >
                    ADD SIBLINGS
                  </CustomButton>
                  <SiblingsTabcontent id={this.state.id} />
                </Tab> */}

                <Tab
                  href="#"
                  label="Guardian Info."
                  role="presentation"
                  tabIndex={2}>
                  <div className="tab3 mt-30">
                    <div className="bx--row">
                      <FormGroup legendText="" className="bx--col-md-4">
                        <FormItem>
                          <TextInput
                            id="parentName"
                            name="parentName"
                            labelText="Guardian Name"
                            placeholder="Guardian Full name"
                            value={this.state.parentName}
                            onChange={this.handleChange}
                          />
                        </FormItem>
                      </FormGroup>

                      <FormGroup legendText="" className="bx--col-md-4">
                        <FormItem>
                          <TextInput
                            id="parentPhoneNumber"
                            name="parentPhoneNumber"
                            type="tel"
                            labelText="Guardian Phone Number"
                            placeholder="Guardian phone number"
                            value={this.state.parentPhoneNumber}
                            onChange={this.handleChange}
                          />
                        </FormItem>
                      </FormGroup>

                      <FormGroup legendText="" className="bx--col-md-4">
                        <FormItem>
                          <TextInput
                            id="parentEmailAddress"
                            name="parentEmailAddress"
                            type="email"
                            labelText="Guardian Email Address"
                            placeholder="Guardian email address"
                            value={this.state.parentEmailAddress}
                            onChange={this.handleChange}
                          />
                        </FormItem>
                      </FormGroup>

                      <FormGroup legendText="" className="bx--col-md-4">
                        <FormItem>
                          <TextInput
                            id="emergencyContactName"
                            name="emergencyContactName"
                            labelText="Emergency Contact Name"
                            placeholder="Emergency contact full name"
                            value={this.state.emergencyContactName}
                            onChange={this.handleChange}
                          />
                        </FormItem>
                      </FormGroup>

                      <FormGroup legendText="" className="bx--col-md-4">
                        <FormItem>
                          <TextInput
                            id="emergencyContactPhoneNumber"
                            name="emergencyContactPhoneNumber"
                            type="tel"
                            labelText="Emergency Contact Phone Number"
                            placeholder="Emergency contact phone number"
                            value={this.state.emergencyContactPhoneNumber}
                            onChange={this.handleChange}
                          />
                        </FormItem>
                      </FormGroup>

                      <FormGroup legendText="" className="bx--col-md-4">
                        <FormItem>
                          <TextInput
                            id="emergencyContactEmailAddress"
                            name="emergencyContactEmailAddress"
                            type="email"
                            labelText="Emergency Contact Email Address"
                            placeholder="Emergency contact email addres"
                            value={this.state.emergencyContactEmailAddress}
                            onChange={this.handleChange}
                          />
                        </FormItem>
                      </FormGroup>
                    </div>
                    <div className="btn-actions mt-40">
                      <CustomButton
                        classes="secondary font-bold"
                        onClick={this.props.importAction}>
                        <Download size="11" strokeWidth="5px" />
                        &nbsp; IMPORT
                      </CustomButton>

                      <div className="">
                        <CustomButton
                          classes="outline danger font-bold"
                          onClick={this.props.closeAction}>
                          CANCEL
                        </CustomButton>

                        <CustomButton
                          classes="outline secondary ml-20 font-bold"
                          onClick={() =>
                            this.setState({ selectedTabIndex: 1 })
                          }>
                          BACK
                        </CustomButton>

                        <CustomButton
                          classes="primary font-bold ml-20"
                          onClick={() =>
                            this.setState({ selectedTabIndex: 3 })
                          }>
                          NEXT
                        </CustomButton>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab
                  href="#"
                  label="Other Info"
                  role="presentation"
                  tabIndex={3}>
                  <div className="tab4 mt-30">
                    <div className="bx--row">
                      <FormGroup className="bx--col-md-4">
                        <FormItem>
                          <DatePicker
                            dateFormat="Y-m-d"
                            datePickerType="single"
                            id="end-date-picker"
                            onClose={(value) => {
                              if (value.length > 0) {
                                this.setState({
                                  dateEnrolled: [
                                    value[0].getFullYear(),
                                    ('0' + (value[0].getMonth() + 1)).slice(-2),
                                    ('0' + value[0].getDate()).slice(-2),
                                  ].join('-'),
                                });
                              }
                            }}>
                            <DatePickerInput
                              className="end-date"
                              id="end-date"
                              labelText="Date Enrolled"
                              name="dateEnrolled"
                              value={this.state.dateEnrolled}
                              onChange={(e) =>
                                this.setState({ dateEnrolled: e.target.value })
                              }
                              pattern="d{1,2}/d{4}"
                              placeholder="YYYY-MM-DD"
                            />
                          </DatePicker>
                        </FormItem>
                      </FormGroup>

                      <FormGroup legendText="" className="bx--col-md-4">
                        <FormItem>
                          <Select
                            id="religion"
                            className="f-width"
                            name="religion"
                            labelText="Religion"
                            onChange={(e) =>
                              this.setState({ religion: e.target.value })
                            }>
                            <SelectItem
                              selected={this.state.studentToEdit == null}
                              disabled
                              text="Select student religion"
                              value=""
                            />
                            <SelectItem
                              selected={this.state.religion === 'Christian'}
                              text="Christian"
                              value="Christian"
                            />
                            <SelectItem
                              selected={this.state.religion === 'Islam'}
                              text="Islam"
                              value="Islam"
                            />
                            <SelectItem
                              selected={this.state.religion === 'Other'}
                              text="Other"
                              value="Other"
                            />
                          </Select>
                        </FormItem>
                      </FormGroup>

                      <FormGroup legendText="" className="bx--col-md-4">
                        <FormItem>
                          <Select
                            id="religion"
                            className="f-width"
                            name="nationality"
                            labelText="Nationality"
                            onChange={(e) =>
                              this.setState({ nationality: e.target.value })
                            }>
                            <SelectItem
                              selected={this.state.studentToEdit == null}
                              disabled
                              text="Select student nationality"
                              value=""
                            />
                            <SelectItem
                              selected={this.state.nationality === 'Ghanaian'}
                              text="Ghanaian"
                              value="Ghanaian"
                            />
                            <SelectItem
                              selected={this.state.nationality === 'Foreigner'}
                              text="Foreigner"
                              value="Foreigner"
                            />
                            <SelectItem
                              selected={this.state.nationality === 'Other'}
                              text="Other"
                              value="Other"
                            />
                          </Select>
                        </FormItem>
                      </FormGroup>

                      <FormGroup legendText="" className="bx--col-md-4">
                        <FormItem>
                          <TextInput
                            id="comments"
                            name="comments"
                            className="f-width"
                            labelText="Comment"
                            value={this.state.comments}
                            onChange={this.handleChange}
                            placeholder="Add comment"
                          />
                        </FormItem>
                      </FormGroup>
                    </div>

                    <div className="btn-actions mt-40">
                      <CustomButton
                        classes="secondary font-bold"
                        onClick={this.props.importAction}>
                        <Download size="11" strokeWidth="5px" />
                        &nbsp; IMPORT
                      </CustomButton>

                      <div className="">
                        <CustomButton
                          classes="outline danger font-bold"
                          onClick={this.props.closeAction}>
                          CANCEL
                        </CustomButton>

                        <CustomButton
                          classes="outline secondary ml-20 font-bold"
                          onClick={() =>
                            this.setState({ selectedTabIndex: 2 })
                          }>
                          BACK
                        </CustomButton>

                        <CustomButton
                          classes="primary font-bold ml-20"
                          disabled={
                            !this.validateBasicInfo() || this.state.loading
                          }
                          onClick={this.addStudent}>
                          {this.state.addBtnText}
                          <div hidden={this.state.addBtnText != null}>
                            <div className="d-flex-center">
                              <Loading
                                withOverlay={false}
                                small={true}
                                description="Adding student..."
                              />
                            </div>
                          </div>
                        </CustomButton>
                      </div>
                    </div>

                    <div
                      className="mt-30"
                      hidden={this.state.errorMessage == null}>
                      <span className="mt-10 text-danger">
                        {this.state.errorMessage}.
                      </span>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </Form>
          </div>
        </Modal>
      </div>
    );
  }
}

// this will provide us with the state values we need
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  classes: selectClasses,
  classLabels: selectClassLabels,
});

// this allows us to map our redux actions to our component
const mapDispatchToProps = (dispatch) => ({
  setStudents: (students) => dispatch(setStudents(students)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddStudentModal);
