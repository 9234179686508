// using reselect
import { createSelector } from 'reselect';

// create select chart of accounts function
const selectChartOfAccounts = (state) => state.chartOfAccounts;

// create selectGroups selector
export const selectGroups = createSelector(
  [selectChartOfAccounts],
  (chartOfAccounts) => chartOfAccounts.groups,
);

// create selectCategories selector
export const selectCategories = createSelector(
  [selectChartOfAccounts],
  (chartOfAccounts) => chartOfAccounts.ledgerCategories,
);

// create selectLedgerAccounts selector
export const selectLedgerAccounts = createSelector(
  [selectChartOfAccounts],
  (chartOfAccounts) => chartOfAccounts.ledgerAccounts,
);

export const selectRevenueLedgerAccounts = createSelector(
  [selectChartOfAccounts],
  (chartOfAccounts) =>
    chartOfAccounts.ledgerAccounts.filter(
      (item) => item.groupDescription === 'Revenue',
    ),
);

export const selectStudentRevenueAccounts = createSelector(
  [selectRevenueLedgerAccounts],
  (accounts) =>
    accounts.filter((item) => item.categoryDescription === 'Student Revenue'),
);

export const selectExpenditureLedgerAccounts = createSelector(
  [selectChartOfAccounts],
  (chartOfAccounts) =>
    chartOfAccounts.ledgerAccounts.filter(
      (item) => item.groupDescription === 'Expenditure',
    ),
);

export const selectCurrentAssetsLedgers = createSelector(
  [selectChartOfAccounts],
  (chartOfAccounts) =>
    chartOfAccounts.ledgerAccounts.filter(
      (item) => item.groupDescription === 'Current Assets',
    ),
);

export const selectFixedAssetsLedgers = createSelector(
  [selectChartOfAccounts],
  (chartOfAccounts) =>
    chartOfAccounts.ledgerAccounts.filter(
      (item) => item.groupDescription === 'Fixed Assets',
    ),
);

// HINT: Add additional selectors below
