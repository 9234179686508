// using reselect
import { createSelector } from 'reselect';

// create select user function
const selectUser = (state) => state.user;

// create selectCurrentUser selector
export const selectCurrentUser = createSelector(
  [selectUser],
  (user) => user.currentUser,
);

// create selectUsers selector
export const selectUsers = createSelector([selectUser], (user) => user.users);
