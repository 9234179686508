import React from 'react';

import './notification-item.styles.scss';
import { X } from 'react-feather';

const NotificationItem = ({
  notification: { title, message, status },
  openNotification,
}) => {
  return (
    <div className={`notification-item ${status}`} onClick={openNotification}>
      <div>
        <p className="title">{title}</p>
        <p className="message">{message}</p>
      </div>

      <X color="#565656" size={12} />
    </div>
  );
};

export default NotificationItem;
