import React from 'react';
import {
  FormItem,
  Form,
  FormGroup,
  Modal,
  Loading,
  DatePicker,
  DatePickerInput,
  TextInput,
} from 'carbon-components-react';
import { connect } from 'react-redux';
import CustomButton from '../../custom-button/custom-btn.component';
import { createStructuredSelector } from 'reselect';
import { newPayroll } from '../../../api/payroll.service';
import { selectCurrentUser } from '../../../redux/selectors/user.selectors';

import './add-payroll.styles.scss';

class AddPayrollModal extends React.Component {
  constructor(props) {
    super(props);

    // set state
    this.state = {
      openModal: props.openModal,
      id: 0,
      description: '',
      month: '',
      loading: false,
      errorMessage: null,
      btnText: 'CREATE PAYROLL',
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.openModal !== prevState.openModal) {
      return { openModal: nextProps.openModal };
    } else return null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.payroll !== prevProps.payroll) {
      if (this.props.payroll) {
        if (this.props.payroll.id !== prevProps.payroll.id) {
          const monthDate = new Date(this.props.payroll.month);
          const parsedMonthDate = [
            monthDate.getFullYear(),
            ('0' + (monthDate.getMonth() + 1)).slice(-2),
            ('0' + monthDate.getDate()).slice(-2),
          ].join('-');

          this.setState({
            id: this.props.payroll.id,
            month: parsedMonthDate,
            description: this.props.payroll.description,
            errorMessage: null,
            btnText: 'UPDATE PAYROLL',
          });
        }
      } else {
        this.setState({
          id: 0,
          month: '',
          description: '',
          errorMessage: null,
          btnText: 'CREATE PAYROLL',
        });
      }
    }
  }

  onCreatePayroll = async (event) => {
    // prevent default
    event.preventDefault();

    // update state to set request in progress
    // to true and error occurred to false
    this.setState({
      loading: true,
      btnText: null,
      errorMessage: null,
    });

    // build data
    const data = {
      id: this.state.id,
      month: this.state.month,
      description: this.state.description,
    };

    // initiate request
    const response = await newPayroll(this.props.currentUser.authToken, data);

    if (!response.success) {
      this.setState({
        loading: false,
        errorMessage: response.message,
        btnText: this.state.id > 0 ? 'UPDATE PAYROLL' : 'CREATE PAYROLL',
      });

      return;
    }

    // on success
    if (this.props.refreshPage) {
      // navigate
      this.props.refreshPage();
    } else {
      // close dialog
      this.props.history.push('/payroll');
    }
  };

  render() {
    return (
      <Modal
        id="add-payroll-modal"
        className="add-payroll-modal"
        hasScrollingContent
        passiveModal={true}
        open={this.state.openModal}
        modalHeading={
          this.state.payrollToEdit ? 'Edit payroll' : 'Create New Payroll'
        }
        primaryButtonDisabled={false}>
        <div>
          <Form action="post">
            <FormGroup legendText="">
              <FormItem>
                <TextInput
                  id="description"
                  name="description"
                  labelText="Description"
                  value={this.state.description}
                  onChange={(e) =>
                    this.setState({ description: e.target.value })
                  }
                  placeholder="Description"
                />
              </FormItem>
            </FormGroup>

            <FormGroup legendText="">
              <FormItem>
                <DatePicker
                  dateFormat="Y-m-d"
                  datePickerType="single"
                  id="month-date-picker"
                  onClose={(value) => {
                    if (value.length > 0) {
                      this.setState({
                        month: [
                          value[0].getFullYear(),
                          ('0' + (value[0].getMonth() + 1)).slice(-2),
                          ('0' + value[0].getDate()).slice(-2),
                        ].join('-'),
                      });
                    }
                  }}>
                  <DatePickerInput
                    className="month"
                    id="month"
                    name="month"
                    labelText="Pick any date in the month up to today for the Payroll month"
                    value={this.state.startDate}
                    onChange={this.handleChange}
                    pattern="d{1,2}/d{4}"
                    placeholder="YYYY-MM-DD"
                  />
                </DatePicker>
              </FormItem>
            </FormGroup>

            <p
              className="text-grey text-14"
              hidden={this.state.payrollToEdit !== null}></p>

            <CustomButton
              classes="primary f-width mt-20 font-bold"
              disabled={
                this.state.description.length === 0 ||
                this.state.month.length === 0 ||
                this.state.loading
              }
              onClick={this.onCreatePayroll}>
              {this.state.btnText}

              <div hidden={this.state.btnText != null}>
                <div className="d-flex-center">
                  <Loading
                    withOverlay={false}
                    small={true}
                    description="Creating payroll..."
                  />
                </div>
              </div>
            </CustomButton>

            <p
              className="text-14 mt-10 text-danger"
              hidden={this.state.errorMessage === null}>
              {this.state.errorMessage}
            </p>

            <CustomButton
              classes="outline danger f-width font-bold mt-20"
              onClick={this.props.cancelAction}>
              CANCEL
            </CustomButton>
          </Form>
        </div>
      </Modal>
    );
  }
}

// this will provide us with the state values we need
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(AddPayrollModal);
