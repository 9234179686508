import UserActionTypes from './user.types';

// authenticate action
export const authenticate = (data) => ({
  type: UserActionTypes.AUTHENTICATE_REQUESTED,
  payload: data,
});

// authentication success action
export const authenticationSuccess = (user) => ({
  type: UserActionTypes.AUTHENTICATE_SUCCEEDED,
  payload: user,
});

// authenticate failed action
export const authenticationFailed = (error) => ({
  type: UserActionTypes.AUTHENTICATE_FAILED,
  payload: error,
});

export const setUsers = (users) => ({
  type: UserActionTypes.SET_USERS,
  payload: users,
});

export const logoutUser = () => ({
  type: UserActionTypes.LOGOUT_USER,
  payload: null,
});
