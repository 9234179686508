import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Printer, Upload } from 'react-feather';
import { Select, SelectItem } from 'carbon-components-react';
import fileDownload from 'js-file-download';

import MainHeader from '../../../components/main-header/main-header.component';
import Mainmenu from '../../../components/menu/main-menu/main-menu.component';
import CustomButton from '../../../components/custom-button/custom-btn.component';
import Footer from '../../../components/footer/footer.component';
import { DonutChart, LineChart } from '@carbon/charts-react';
import '@carbon/charts/styles.css';
import {
  revExpLineData,
  revExpLineOptions,
  revExpData,
  revExpOptions,
} from '../analytics.data';
import { ReactComponent as Sort } from '../../../assets/images/card-icons/sort.svg';
import MainLoaderModal from '../../../components/modal/main-loader/main-loader.component';
import './budget-report.styles.scss';
import { selectCurrentUser } from '../../../redux/selectors/user.selectors';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  selectFetchingAcademicYear,
  selectAcademicYearData,
  selectAcademicYearFetchError,
} from '../../../redux/academic-year/academic-year.selectors';
import { selectBudgetReport } from '../../../redux/analytics/analytics.selectors';
import BudgetReportModal from '../../../components/modal/budget-report/budget-report.component';
import { BudgetExpenseExpandable } from '../../../components/expandable/budget-expandable/pane.component';
import { formatCurrency } from '../../../utils/common.util';
import { fetchService } from '../../../api/common';

const BudgetReportPage = ({
  budgetReport: { fetching, fetchError, data },
  fetchBudgetReport,
  academicYears,
  fetchingAcademicYears,
  academicYearsFetchError,
  fetchAcademicYears,
  currentUser: { authToken, school },
}) => {
  const [openLoaderModal, setOpenLoaderModal] = useState(false);
  const [academicYearId, setAcademicYearId] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [openPrintOutModal, setOpenPrintOutModal] = useState(false);

  const [openExportLoaderModal, setOpenExportLoaderModal] = useState(false);
  const [exportLoaderModalErrorMessage, setExportLoaderModalErrorMessage] =
    useState(null);

  if (
    !fetchingAcademicYears &&
    academicYears.length === 0 &&
    !academicYearsFetchError
  ) {
    fetchAcademicYears(authToken);
  }

  const exportReport = async () => {
    setExportLoaderModalErrorMessage(null);
    setOpenExportLoaderModal(true);

    const response = await fetchService(
      `analytics/budget-report/export?academicYearId=${academicYearId}`,
      authToken,
    );

    if (response.success) {
      setOpenExportLoaderModal(false);
      fileDownload(response.data.data, `budget-report-${new Date()}.xlsx`);

      return;
    }

    setExportLoaderModalErrorMessage(response.message);
  };

  let academicYear = academicYears.find((i) => i.id == academicYearId);

  return (
    <div>
      <MainHeader />

      <MainLoaderModal
        openModal={openExportLoaderModal}
        description="Exporting Budget Report"
        errorMessage={exportLoaderModalErrorMessage}
        tryAgainAction={exportReport}
        cancelAction={() => setOpenExportLoaderModal(false)}
      />

      <MainLoaderModal
        openModal={openLoaderModal && (fetching || fetchError)}
        loading={fetching}
        description={'Generating report...'}
        errorMessage={fetchError}
        tryAgainAction={() => {
          setOpenLoaderModal(true);
          fetchBudgetReport(academicYearId, authToken);
        }}
        cancelAction={() => setOpenLoaderModal(false)}
      />

      <BudgetReportModal
        openModal={openPrintOutModal}
        data={data}
        school={school}
        academicYear={academicYear}
        closeAction={() => setOpenPrintOutModal(false)}
      />

      <span className="right">
        <Mainmenu />
      </span>

      <div className="budget-report-page page-container">
        <div>
          <p>
            <span className="text-12 font-bold text-grey">You are here</span>
            &nbsp;&nbsp;
            <Link to="/analytics" className="text-12 text-dark">
              Analytics
            </Link>
            &nbsp;<span className="text-12">&gt;</span>&nbsp;
            <span className="text-12 text-primary font-bold">
              Budget Report
            </span>
          </p>
        </div>

        <hr className="grey-hr mt-20" />

        <div className="h-24 uppercase mt-15">
          Budget Report {academicYear && ` ${academicYear.description}`}
        </div>

        <div className="mt-20">
          <div className="bx--row">
            <div className="bx--col-md-3 npr">
              <Select
                labelText="Academic Year"
                onChange={(e) => setAcademicYearId(e.target.value)}>
                <SelectItem
                  disabled
                  selected
                  value=""
                  text="Select Academic Year"
                />
                {academicYears.map((academicYear) => (
                  <SelectItem
                    value={academicYear.id}
                    text={academicYear.description}
                  />
                ))}
              </Select>
            </div>

            <div className="bx--col-md-2">
              <CustomButton
                classes="f-width primary mt-25"
                disabled={academicYearId === ''}
                onClick={() => {
                  setOpenLoaderModal(true);
                  fetchBudgetReport(academicYearId, authToken);
                }}>
                RUN REPORT
              </CustomButton>
            </div>
          </div>
        </div>

        {data && (
          <div className="mt-20">
            {/*<div className="bx--row">
              <div className="bx--col-md-4">
                <div className="chart-box">
                  <h3 className="h-18 text-primary mb-10">Revenue &amp; Expense</h3>
                  <div className= "text-14 text-grey-light">
                    <Sort />
                    <span> Aug 24, 2019 - Jan 31, 2020</span>
                  </div>
                  <hr className="grey-hr mb-15 mt-10" />
                  <LineChart data={revExpLineData} options={revExpLineOptions} />
                </div>
              </div>
              <div className="bx--col-md-4">
                <div className="chart-box hide-legend">
                  <h3 className="h-18 text-primary mb-10">Revenue &amp; Expense</h3>
                  <div className="d-flex-space-between">
                    <span className="text-14 text-grey-light">Total: 600</span>
                    <div className="text-14 text-grey-light">
                      <Sort />
                      <span> Aug 24, 2019 - Jan 31, 2020</span>
                    </div>
                  </div>
                  <hr className="grey-hr" />
                  <div>
                    <div className="donut-container">
                      <DonutChart className="d-flex-center" data={revExpData} options={revExpOptions} />
                    </div>
                  </div>
                  <div className="bx--row chart-tabs text-center text-grey text-14">
                    <div
                      className={`bx--col-lg-4 pointer chart-tab-item ${activeTab === 0}`}
                      onClick={() => setActiveTab(0)}>
                      Revenue &amp; Expense
                    </div>
                    <div
                      className={`bx--col-lg-4 pointer chart-tab-item ${activeTab === 1}`}
                      onClick={() => setActiveTab(1)}>
                      Revenue
                    </div>
                    <div
                      className={`bx--col-lg-4 pointer chart-tab-item ${activeTab === 3}`}
                      onClick={() => setActiveTab(3)}>
                      Expense
                    </div>
                  </div>
                </div>
              </div>
        </div>*/}

            <div>
              <BudgetExpenseExpandable
                title="Revenue"
                isTree={true}
                expand={true}
                percentageLabel="Percentage Met">
                {data.revenues.data.reverse().map((category) => (
                  <BudgetExpenseExpandable
                    title={category.description}
                    expand={true}>
                    {category.accounts.map((account) => (
                      <tr>
                        <td>{account.ledgerAccountDescription}</td>
                        <td>{formatCurrency(account.totalBudgetAmount)}</td>
                        <td>{formatCurrency(account.periodActual)}</td>
                        <td>{formatCurrency(account.variance)}</td>
                        <td colSpan="2">{account.percentage}%</td>
                      </tr>
                    ))}
                  </BudgetExpenseExpandable>
                ))}

                <div className="table-container">
                  <table className="bx--data-table budget-expandable mt-10">
                    <tbody>
                      <tr className="total-expense">
                        <td className="text-16">Total</td>
                        <td>
                          {formatCurrency(data.revenues.totalBudgetAmount)}
                        </td>
                        <td>
                          {formatCurrency(data.revenues.totalPeriodActual)}
                        </td>
                        <td>{formatCurrency(data.revenues.totalVariance)}</td>
                        <td colSpan="2">
                          {data.revenues.totalPeriodActual > 0
                            ? Math.round(
                                (data.revenues.totalPeriodActual /
                                  data.revenues.totalBudgetAmount) *
                                  100,
                              )
                            : 0}
                          %
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </BudgetExpenseExpandable>

              <BudgetExpenseExpandable
                title="Expenses"
                isTree={true}
                expand={true}>
                {data.expenses.data.map((category) => (
                  <BudgetExpenseExpandable
                    title={category.description}
                    expand={true}>
                    {category.accounts.map((account) => (
                      <tr>
                        <td>{account.ledgerAccountDescription}</td>
                        <td>{formatCurrency(account.totalBudgetAmount)}</td>
                        <td>{formatCurrency(account.periodActual)}</td>
                        <td>{formatCurrency(account.variance)}</td>
                        <td colSpan="2">{account.percentage}%</td>
                      </tr>
                    ))}
                  </BudgetExpenseExpandable>
                ))}
                <div className="table-container">
                  <table className="bx--data-table budget-expandable mt-10">
                    <tbody>
                      <tr className="total-expense">
                        <td className="text-16">Total</td>
                        <td>
                          {formatCurrency(data.expenses.totalBudgetAmount)}
                        </td>
                        <td>
                          {formatCurrency(data.expenses.totalPeriodActual)}
                        </td>
                        <td>{formatCurrency(data.expenses.totalVariance)}</td>
                        <td colSpan="2">
                          {data.expenses.totalPeriodActual > 0
                            ? Math.round(
                                (data.expenses.totalPeriodActual /
                                  data.expenses.totalBudgetAmount) *
                                  100,
                              )
                            : 0}
                          %
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </BudgetExpenseExpandable>

              <BudgetExpenseExpandable
                title="Fixed Assets"
                isTree={true}
                expand={true}>
                {data.fixedAssets.data.map((category) => (
                  <BudgetExpenseExpandable
                    title={category.description}
                    expand={true}>
                    {category.accounts.map((account) => (
                      <tr>
                        <td>{account.ledgerAccountDescription}</td>
                        <td>{formatCurrency(account.totalBudgetAmount)}</td>
                        <td>{formatCurrency(account.periodActual)}</td>
                        <td>{formatCurrency(account.variance)}</td>
                        <td colSpan="2">{account.percentage}%</td>
                      </tr>
                    ))}
                  </BudgetExpenseExpandable>
                ))}
                <div className="table-container">
                  <table className="bx--data-table budget-expandable mt-10">
                    <tbody>
                      <tr className="total-expense">
                        <td className="text-16">Total</td>
                        <td>
                          {formatCurrency(data.fixedAssets.totalBudgetAmount)}
                        </td>
                        <td>
                          {formatCurrency(data.fixedAssets.totalPeriodActual)}
                        </td>
                        <td>
                          {formatCurrency(data.fixedAssets.totalVariance)}
                        </td>
                        <td colSpan="2">
                          {data.fixedAssets.totalPeriodActual > 0
                            ? Math.round(
                                (data.fixedAssets.totalPeriodActual /
                                  data.fixedAssets.totalBudgetAmount) *
                                  100,
                              )
                            : 0}
                          %
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </BudgetExpenseExpandable>
            </div>

            <div className="bx--row mt-35">
              <div className="bx--col-lg-2">
                <CustomButton
                  classes="secondary f-width font-bold"
                  onClick={exportReport}>
                  <Upload size="11" strokeWidth="5px" />
                  &nbsp; EXPORT TO EXCEL
                </CustomButton>
              </div>
              <div className="bx--col-lg-2">
                <CustomButton
                  classes="primary f-width font-bold"
                  onClick={() => setOpenPrintOutModal(true)}>
                  <Printer size="11" strokeWidth="5px" /> &nbsp; PRINT
                </CustomButton>
              </div>
              <div className="bx--col-lg-8"></div>
            </div>
          </div>
        )}
        <Footer fixed={false} showLinks={false} />
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  budgetReport: selectBudgetReport,
  academicYears: selectAcademicYearData,
  academicYearsFetchError: selectAcademicYearFetchError,
  fetchingAcademicYears: selectFetchingAcademicYear,
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAcademicYears: (token) =>
    dispatch({
      type: 'FETCH_ACADEMIC_YEARS_REQUESTED',
      payload: { token },
    }),
  fetchBudgetReport: (academicYearId, authToken) =>
    dispatch({
      type: 'FETCH_BUDGET_REPORT_REQUESTED',
      payload: { academicYearId, authToken },
    }),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BudgetReportPage),
);
