// import types
import SupplierActionTypes from '../types/supplier.types';
import { addItems, removeItem } from '../utils/common.util';

// set initial state
const INITIAL_STATE = {
  suppliers: [],
};

// create supplier reducer with intial state if not state is received
const supplierReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SupplierActionTypes.SET_SUPPLIERS:
      return {
        // spread in the state
        ...state,

        // set suppliers
        suppliers: addItems(state.suppliers, action.payload),
      };
    case SupplierActionTypes.REMOVE_SUPPLIER:
      return {
        // spread in the state
        ...state,
        suppliers: removeItem(state.suppliers, action.payload),
      };
    default:
      // no action matched, just return the state
      return state;
  }
};

// export out supplierReducer as default
export default supplierReducer;
