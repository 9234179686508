import React from 'react';
import { Modal } from 'carbon-components-react';
import CustomButton from '../../custom-button/custom-btn.component';
import { Printer, Download } from 'react-feather';
import SubFooter from '../../footer/sub-footer/sub-footer.component';
import { formatCurrency, formatDate } from '../../../utils/common.util';

import './cash-spent.styles.scss';

const CashSpentModal = ({
  open,
  data,
  total,
  dateFrom,
  dateTo,
  ledgerAccount,
  school,
  closeModal,
}) => {
  return (
    <Modal
      id="cash-spent-modal"
      className="cash-spent-modal"
      hasScrollingContent
      passiveModal={true}
      open={open}
      primaryButtonDisabled={false}>
      <div style={{ pageBreakAfter: 'always' }}>
        <div className="mt-15 bx--col--auto mb-10">
          <div
            className="school-logo center"
            style={{ backgroundImage: `url(${school.logoUrl})` }}></div>
          <h3 className="h-24 text-primary text-center">{school.name}</h3>
          <p className="text-14 text-grey-dark text-center">
            {school.location}
          </p>

          <p className="text-center text-16 text-primary font-bold mt-20 text-uppercase">
            EXPENDITURE REPORT
          </p>
        </div>

        <div className="info d-flex-space-between mt-15">
          <div>
            <p className="text-14 text-grey-light">Ledger Account</p>
            <h3 className="h-14">
              {ledgerAccount ? ledgerAccount.description : 'ALL'}
            </h3>
          </div>
          <div>
            <p className="text-14 text-grey-light">Start Date</p>
            <h3 className="h-14">
              {dateFrom && formatDate(new Date(dateFrom))}
            </h3>
          </div>
          <div>
            <p className="text-14 text-grey-light">End Date</p>
            <h3 className="h-14">{dateTo && formatDate(new Date(dateTo))}</h3>
          </div>
        </div>

        {data && (
          <div>
            <table className="bx--data-table table-transparent">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Debit Account</th>
                  <th>Credit Account</th>
                  <th>Amount</th>
                  <th>Paid To</th>
                  <th>Reversed</th>
                </tr>
              </thead>
              <tbody>
                {data.map((expense) => (
                  <tr>
                    <td>{formatDate(expense.dateCreated)}</td>
                    <td>{expense.debitAccount.description}</td>
                    <td>{expense.creditAccount.description}</td>
                    <td className="font-bold">
                      {formatCurrency(expense.amount)}
                    </td>
                    <td>{expense.paidTo}</td>
                    <td>
                      {expense.dateReversed ? (
                        <span className="text-danger font-bold">
                          {`YES ON ${formatDate(
                            expense.dateReversed,
                          )}`.toUpperCase()}
                        </span>
                      ) : (
                        'NO'
                      )}
                    </td>
                  </tr>
                ))}
                {data && (
                  <tr>
                    <td colSpan={3}>
                      <div className="text-primary font-bold">Total</div>
                    </td>
                    <td colSpan={3}>
                      <div className="text-primary font-bold text-20">
                        {formatCurrency(total)}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <div className="mt-20 p-l-r-61">
              <SubFooter />
            </div>

            <div className="bx--row nm mt-35 p-l-r-61">
              <div className="bx--col-lg-8"></div>
              <div className="bx--col-lg-2">
                <CustomButton
                  classes="outline secondary f-width font-bold"
                  onClick={closeModal}>
                  CLOSE
                </CustomButton>
              </div>

              <div className="bx--col-lg-2">
                <CustomButton
                  classes="primary f-width font-bold"
                  onClick={() => window.print()}>
                  <Printer size="11" strokeWidth="5px" /> &nbsp; PRINT
                </CustomButton>
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CashSpentModal;
