import React from 'react';
import './sub-footer.styles.scss';

const SubFooter = () => {
  return (
    <div className="sub-footer">
      <p className="text-12 text-dark no-decoration text-center">
        www.epobssuite.com
      </p>
    </div>
  );
};

export default SubFooter;
