import { putData } from './api';
import { apiBaseUrl, postData } from './common';
import { handleGenericError } from './common';

// import axios
const axios = require('axios').default;

const baseUrl = `${apiBaseUrl}class/`;

export const getClasses = async (authToken) => {
  // build url
  const url = `${baseUrl}list`;

  // set config
  const config = { headers: { Authorization: `Bearer ${authToken}` } };

  // initiate request
  try {
    let response = await axios.get(url, config);
    return { success: true, classes: response.data };
  } catch (error) {
    return handleGenericError(error);
  }
};

export const getClassLabels = async (authToken) => {
  // build url
  const url = `${baseUrl}labels`;

  // set config
  const config = { headers: { Authorization: `Bearer ${authToken}` } };

  // initiate request
  try {
    let response = await axios.get(url, config);
    return { success: true, classLabels: response.data };
  } catch (error) {
    return handleGenericError(error);
  }
};

export const createClass = async (authToken, data) => {
  let response = await postData('class/create-new', data, authToken);
  if (response.success) {
    return {
      success: true,
      class: response.data.class,
      message: `Class ${data.id === 0 ? 'created' : 'updated'} successfully.`,
    };
  }

  return response;
};

export const createLabel = async (authToken, data) => {
  let response = await postData('class/new-label', data, authToken);
  if (response.success) {
    return {
      success: true,
      classLabel: response.data.classLabel,
      message: `Class Label ${
        data.id === 0 ? 'created' : 'updated'
      } successfully.`,
    };
  }

  return response;
};

export const promoteStudents = async (authToken, data) => {
  return await postData('class/promote', data, authToken);
};

export const promoteSelectedStudents = async (authToken, data) => {
  return await postData('class/promote/v2', data, authToken);
};

export const deleteClass = async (authToken, data) => {
  return await postData('class/delete-class', data, authToken);
};

export const deleteClassLabel = async (authToken, data) => {
  return await postData('class/delete-label', data, authToken);
};

export const archiveClass = async (authToken, data) => {
  return await putData('class/archive', data, authToken);
};

export const archiveClassLabel = async (authToken, data) => {
  return await putData('class/archive-label', data, authToken);
};

// Archived
