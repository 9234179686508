// import types
import SubjectActionTypes from '../types/subject.types';
import { addItems, removeItem } from '../utils/common.util';

// set initial state
const INITIAL_STATE = {
  subjects: [],
};

// create reducer with intial state if not state is received
const subjectReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SubjectActionTypes.SET_SUBJECTS:
      return {
        // spread in the state
        ...state,
        subjects: addItems(state.subjects, action.payload),
      };

    case SubjectActionTypes.REMOVE_SUBJECT:
      return {
        ...state,
        subjects: removeItem(state.subjects, action.payload),
      };

    default:
      // no action matched, just return the state
      return state;
  }
};

// export out subjectReducer as default
export default subjectReducer;
