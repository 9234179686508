import React from 'react';
import { Edit, Trash2 } from 'react-feather';

import './fees-mini-bar.styles.scss';
import { formatCurrency } from '../../../utils/common.util';

const FeesMiniBar = ({
  fee: { id, amount, ledgerAccountDescription, classDescription },
  editAction,
  deleteAction,
}) => (
  <div className="fees-mini-bar">
    <div className="t-c text-truncate">
      <p className="name">
        {ledgerAccountDescription}
        {classDescription ? `, ${classDescription}` : ''}
      </p>
      <h3 className="value">{formatCurrency(amount)}</h3>
    </div>

    <div className="right">
      <span className="edit pointer" onClick={editAction}>
        <span className="icon-edit">
          <Edit color="#164D9D" size={15} />
        </span>
      </span>
      &nbsp;&nbsp;
      <span className="delete pointer" onClick={deleteAction}>
        <Trash2 color="#DA1E28" size={15} />
      </span>
    </div>
  </div>
);

export default FeesMiniBar;
