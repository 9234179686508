import React from 'react';
export const isDateValid = (dateString) => {
  var regex = /^\d{4}-\d{2}-\d{2}$/;

  if (!dateString.match(regex)) return false;

  var d = new Date(dateString);
  var dNum = d.getTime();
  if (!dNum && dNum !== 0) return false;

  return d.toISOString().slice(0, 10) === dateString;
};

export const getMonthName = (date) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return monthNames[new Date(date).getMonth()].toUpperCase();
};

export const getMonthsInDates = (startDate, endDate) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  let dateFrom = new Date(startDate);
  var dateTo = new Date(endDate);

  let fromYear = dateFrom.getFullYear();
  let toYear = dateTo.getFullYear();

  if (fromYear === toYear) {
    return monthNames.slice(dateFrom.getMonth(), dateTo.getMonth() + 1);
  } else {
    let months = addYear(
      monthNames.slice(dateFrom.getMonth(), new Date('1 December ' + fromYear)),
      fromYear,
    );
    for (let i = 1; i < toYear - fromYear; i++) {
      months = months.concat(addYear(monthNames, fromYear + i));
    }
    return months.concat(
      addYear(
        monthNames.slice(
          new Date('1 January ' + fromYear).getMonth(),
          dateTo.getMonth() + 1,
        ),
        toYear,
      ),
    );
  }
};

function addYear(months, year) {
  let updatedMonths = [];
  for (let i = 0; i < months.length; i++) {
    updatedMonths[i] = months[i] + ' ' + year;
  }

  return updatedMonths;
}

export const getBudgetCategories = (budget) => {
  // group budget items into categories
  const categories = [];

  budget.budgetItems.forEach((item) => {
    const categoryExists = categories.find(
      (category) => category.id === item.ledgerCategoryId,
    );

    if (categoryExists === undefined) {
      // item category does not exists, add it
      categories.push({
        id: item.ledgerCategoryId,
        description: item.ledgerCategoryDescription,
        uniqueId: item.ledgerCategoryUniqueId,
        groupDescription: item.ledgerCategoryGroupDescription,
        totalBudgetAmount: item.totalBudgetAmount,
        totalPeriodActual: 'N/A',
        variance: 'N/A',
        budgetItems: [item],
      });
    } else {
      // item exists, update and replace
      // find index of category
      const categoryIndex = categories.findIndex(
        (category) => category.id === categoryExists.id,
      );
      const updatedCategory = {
        ...categoryExists,
        totalBudgetAmount:
          categoryExists.totalBudgetAmount + item.totalBudgetAmount,
        budgetItems: [...categoryExists.budgetItems, item],
      };

      // replace category with updated one
      categories.splice(categoryIndex, 1, updatedCategory);
    }
  });

  return categories;
};

export const formatDate = (date) => {
  if (date) {
    if (typeof date === 'string') {
      return new Date(date).toDateString().replace(/^\S+\s/, '');
    }
    return date.toDateString().replace(/^\S+\s/, '');
  }

  return 'N/A';
};

export const formatCurrency = (amount) => {
  return `${amount.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;
};

export const sum = (lines, key = 'amount') => {
  let c = 0.0;
  lines.forEach((line) => {
    c = c + parseFloat(line[key]);
  });
  return formatCurrency(c);
};

var th_val = ['', 'thousand', 'million', 'billion', 'trillion'];
// System for uncomment this line for Number of English
// var th_val = ['','thousand','million', 'milliard','billion'];

var dg_val = [
  'zero',
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
];
var tn_val = [
  'Ten',
  'Eleven',
  'Twelve',
  'Thirteen',
  'Fourteen',
  'Fifteen',
  'Sixteen',
  'Seventeen',
  'Eighteen',
  'Nineteen',
];
var tw_val = [
  'Twenty',
  'Thirty',
  'Forty',
  'Fifty',
  'Sixty',
  'Seventy',
  'Eighty',
  'Ninety',
];
export function number2words(s) {
  return '';
  s = s.toString();
  s = s.replace('-', ' ');
  s = s.replace(/[\, ]/g, '');
  if (s != parseFloat(s)) return 'not a number ';
  var x_val = s.indexOf('.');
  if (x_val == -1) x_val = s.length;
  if (x_val > 15) return 'too big';
  var n_val = s.split('');
  var str_val = '';
  var sk_val = 0;
  for (var i = 0; i < x_val; i++) {
    if ((x_val - i) % 3 == 2) {
      if (n_val[i] == '1') {
        str_val += tn_val[Number(n_val[i + 1])] + ' ';
        i++;
        sk_val = 1;
      } else if (n_val[i] != 0) {
        str_val += tw_val[n_val[i] - 2] + ' ';
        sk_val = 1;
      }
    } else if (n_val[i] != 0) {
      str_val += dg_val[n_val[i]] + ' ';
      if ((x_val - i) % 3 == 0) str_val += 'hundred ';
      sk_val = 1;
    }
    if ((x_val - i) % 3 == 1) {
      if (sk_val) str_val += th_val[(x_val - i - 1) / 3] + ' ';
      sk_val = 0;
    }
  }

  if (x_val != s.length) {
    var y_val = s.length;
    str_val += ', ';
    for (var i = x_val + 1; i < y_val; i++) str_val += dg_val[n_val[i]] + ' ';
  }
  return str_val.replace(/\s+/g, ' ');
}
