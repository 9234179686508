// import error messages
import ERROR_MESSAGES from './error-messages';

import { apiBaseUrl, postData, getData, deleteData } from './common';
// import axios
const axios = require('axios').default;

export const authenticate = async (username, password) => {
  // initiate request
  try {
    let response = await axios.post(`${apiBaseUrl}account/authenticate`, {
      username,
      password,
    });
    return {
      success: true,
      user: response.data.user,
      message: response.data.message,
    };
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        return { success: false, message: error.response.data.message };
      }

      if (error.response.status === 400) {
        return {
          success: false,
          message: ERROR_MESSAGES.REQUIRED_INFO_MISSING,
        };
      }

      return { success: false, message: ERROR_MESSAGES.UNKNOWN_SERVER_ERROR };
    }

    // could not contact server or could not receive a response
    return { success: false, message: ERROR_MESSAGES.UNKNOWN_ERROR };
  }
};

// contact server to initiate a password reset process
export const resetPassword = async (schoolEmailAddress) => {
  // build url
  const url = `${apiBaseUrl}account/reset-password/?schoolEmailAddress=${schoolEmailAddress}`;

  try {
    let response = await axios.get(url);
    return {
      success: response.data.success,
      message: response.data.message,
    };
  } catch (error) {
    if (error.response) {
      if (error.response.status === 400) {
        return {
          success: false,
          message: ERROR_MESSAGES.REQUIRED_INFO_MISSING,
        };
      }

      if (error.response.status === 404) {
        return {
          success: false,
          message: error.response.data.message,
        };
      }

      return { success: false, message: ERROR_MESSAGES.UNKNOWN_SERVER_ERROR };
    }

    // could not contact server or could not receive a response
    return { success: false, message: ERROR_MESSAGES.UNKNOWN_ERROR };
  }
};

export const changePassword = async (authToken, data) => {
  const response = await postData('account/change-password', data, authToken);

  if (response.success) {
    return {
      success: response.data.success,
      message: response.data.message,
    };
  }

  return response;
};

export const resetUserPassword = async (authToken, data) => {
  const response = await postData(
    'account/reset-user-password',
    data,
    authToken,
  );

  if (response.success) {
    return {
      success: response.data.success,
      message: response.data.message,
    };
  }

  return response;
};

export const deactivateUser = async (authToken, data) =>
  await postData('account/deactivate', data, authToken);

export const deleteUser = async (authToken, id) =>
  await deleteData(`account/${id}`, authToken);
