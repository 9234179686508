import React, { useState, useEffect, useGlobal } from 'reactn';
import { withRouter } from 'react-router-dom';
import {
  TrendingUp,
  PieChart,
  Users,
  Plus,
  FileText,
  BarChart2,
} from 'react-feather';

import MainHeader from '../../components/main-header/main-header.component';
//import SummaryCard from "../../components/card/summary-card/summary-card.component";
import NavigationCard from '../../components/card/navigation-card/navigation-card.component';
import Footer from '../../components/footer/footer.component';
import AddLedgerCategoryModal from '../../components/modal/add-ledger-category/add-ledger-category.component';
import AddLedgerAccountModal from '../../components/modal/add-ledger-account/add-ledger-account.component';
import AddAcademicYearModal from '../../components/modal/add-academic-year/add-academic-year.component';
import AddClassModal from '../../components/modal/add-class/add-class.component';
import AddStudentModal from '../../components/modal/add-student/add-student.component';
import AddEmployeeModal from '../../components/modal/add-employee/add-employee.component';
import ReceivePaymentModal from '../../components/modal/receive-payment/receive-payment.component';
import ReceiveOtherPaymentModal from '../../components/modal/receive-other-payment/receive-other-payment.component';
import ReceiptModal from '../../components/modal/receipt/receipt.component';
import OtherPaymentReceiptModal from '../../components/modal/other-payment-receipt/receipt.component';
import AddSupplierModal from '../../components/modal/add-supplier/add-supplier.component';
import ExpensePaymentModal from '../../components/modal/expense-payment/expense-payment.component';
import AddBudgetModal from '../../components/modal/add-budget/add-budget.component';
import AddFeesModal from '../../components/modal/add-fees/add-fees.component';
import BillStudentsModal from '../../components/modal/bill-students/bill-students.component';
import AddPayrollModal from '../../components/modal/add-payroll/add-payroll.component';
import RegisterAssetModal from '../../components/modal/register-asset/register-asset.component';

//import svg icon
import { ReactComponent as Invoice } from '../../assets/images/card-icons/invoice.svg';
import { ReactComponent as Teamwork } from '../../assets/images/card-icons/teamwork.svg';
import { ReactComponent as Certificate } from '../../assets/images/card-icons/certificate.svg';
import { ReactComponent as Briefcase } from '../../assets/images/card-icons/briefcase.svg';
import { ReactComponent as Group } from '../../assets/images/card-icons/group.svg';
import { ReactComponent as Contact } from '../../assets/images/card-icons/contact.svg';
import { ReactComponent as Statistics } from '../../assets/images/card-icons/statistics.svg';
import { ReactComponent as Statistics2 } from '../../assets/images/card-icons/statistics2.svg';
import { ReactComponent as Sales } from '../../assets/images/card-icons/sales.svg';
import { ReactComponent as Handshake } from '../../assets/images/card-icons/handshake.svg';
import { ReactComponent as Justice } from '../../assets/images/card-icons/justice.svg';
import { ReactComponent as Presentation } from '../../assets/images/card-icons/presentation.svg';
import { ReactComponent as Global } from '../../assets/images/card-icons/global.svg';
import { ReactComponent as International } from '../../assets/images/card-icons/international.svg';
import { ReactComponent as Chart } from '../../assets/images/card-icons/chart.svg';
import { ReactComponent as Budget } from '../../assets/images/card-icons/budget.svg';
import { ReactComponent as Message } from '../../assets/images/card-icons/messaging.svg';

import './dashboard.styles.scss';
import { selectCurrentUser } from '../../redux/selectors/user.selectors';
import {
  selectClasses,
  selectClassLabels,
} from '../../redux/selectors/class.selectors';
import { selectEnrolledStudents } from '../../redux/selectors/student.selectors';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getStudents, importStudents } from '../../api/student.service';
import { setStudents } from '../../redux/actions/student.actions';
import { setClasses, setClassLabels } from '../../redux/actions/class.actions';
import { getClasses, getClassLabels } from '../../api/class.service';
import { setEmployees } from '../../redux/actions/employee.actions';
import { selectActiveEmployees } from '../../redux/selectors/employee.selectors';
import { getEmployees, importEmployees } from '../../api/employee.service';

import { selectAmountOwedByStudents } from '../../redux/billing/billing.selectors';
import { selectExpensesInMonth } from '../../redux/expense/expense.selectors';
import { selectRevenueInMonth } from '../../redux/revenue/revenue.selectors';
import { formatCurrency } from '../../utils/common.util';
import { selectAcademicYears } from '../../redux/selectors/academic-year.selectors';
import {
  setAcademicYears,
  setTerms,
} from '../../redux/actions/academic-year.actions';
import {
  getAcademicYears,
  getActiveAcademicYear,
  getTerms,
} from '../../api/academic-year.service';
import MainLoaderModal from '../../components/modal/main-loader/main-loader.component';
import ImportDataModal from '../../components/modal/import-data/import-data.component';

//import { fetchData } from "../../api/api";
import SummaryPane from './summary.component';
import { postData } from '../../api/common';
import RegisterSMSModal from '../../components/modal/register-sms/register-sms.component';

const plusIcon = <Plus size="13" className="link-icon" />;
const fileIcon = <FileText size="12" className="link-icon" />;
const barChart = <BarChart2 size="12" className="link-icon" />;

const DashboardPage = (props) => {
  const [dashboardMetrics] = useGlobal('dashboardMetrics');

  const [state, setState] = useState({
    openLedgerAccountModal: false,
    openLedgerCategoryModal: false,
    openAcademicYearModal: false,
    openAddClassModal: false,
    openAddStudentModal: false,
    openAddEmployeeModal: false,
    openImportDataModal: false,
    importEmployees: false,
    importStudents: false,
    openReceivePaymentModal: false,
    openReceiveOtherPaymentModal: false,
    openReceiptModal: false,
    openOtherPaymentReceiptModal: false,
    selectedPayment: null,
    selectedOtherPayment: null,
    openAddSupplierModal: false,
    refreshPage: false,
    openExpensesPaymentModal: false,
    openAddBudgetModal: false,
    openAddFeesModal: false,
    openBillStudentsModal: false,
    openAddPayrollModal: false,
    openRegisterAssetModal: false,
    timeoutSet: false,
    openLoaderModal: false,
    loaderModalDescription: 'Importing Employees...',
    loaderModalErrorMessage: null,
    loaderModaltryAgainAction: () => {},
    openSMSModal: false,
  });

  useEffect(() => {
    if (props.enrolledStudents.length === 0) {
      getStudents(props.currentUser.authToken, 0, 2000, '').then((response) => {
        if (response.success) {
          props.setStudents(response.students);
        }
      });
    }

    if (props.academicYears.length === 0) {
      getAcademicYears(props.currentUser.authToken, 0, 2000).then(
        (response) => {
          if (response.success) {
            props.setAcademicYears(response.academicYears);
          }
        },
      );
    }

    if (props.activeEmployees.length === 0) {
      getEmployees(props.currentUser.authToken).then((response) => {
        if (response.success) {
          props.setEmployees(response.employees);
        }
      });
    }

    if (props.classes.length === 0) {
      getClasses(props.currentUser.authToken).then((response) => {
        if (response.success) {
          props.setClasses(response.classes);
        }
      });
    }

    //if(props.terms.length === 0){
    getTerms(props.currentUser.authToken, -1).then((response) => {
      if (response.success) {
        props.setTerms(response.terms);
        //setState({...state,terms: response.terms})
      }
    });
    //}

    if (props.classLabels.length === 0) {
      getClassLabels(props.currentUser.authToken).then((response) => {
        if (response.success) {
          props.setClassLabels(response.classLabels);
        }
      });
    }

    // fetch active academic year
    getActiveAcademicYear(props.currentUser.authToken).then((response) => {
      if (response.success) {
        setState({ ...state, activeAcademicYear: response.academicYear });
      }
    });
  }, []);

  const onImportEmployees = async (csvFile) => {
    setState({
      ...state,
      openImportEmployeeDataModal: false,
      openLoaderModal: true,
      loaderModalErrorMessage: null,
      loaderModalDescription: 'Importing Employees...',
    });

    const formData = new FormData();
    formData.append('formFile', csvFile);

    // import employees
    const response = await importEmployees(
      props.currentUser.authToken,
      formData,
    );

    if (!response.success) {
      setState({
        ...state,
        loaderModalErrorMessage: response.message,
        loaderModaltryAgainAction: () => {
          setState({
            ...state,
            openLoaderModal: false,
            openImportDataModal: true,
          });
        },
      });

      return;
    }

    setState({ ...state, openLoaderModal: false });

    props.history.push('/employees');
  };

  const onImportStudents = async (csvFile) => {
    setState({
      ...state,
      openImportDataModal: false,
      openLoaderModal: true,
      loaderModalErrorMessage: null,
      loaderModalDescription: 'Importing Students...',
    });

    const formData = new FormData();
    formData.append('formFile', csvFile);

    // import students
    const response = await importStudents(
      props.currentUser.authToken,
      formData,
    );

    if (!response.success) {
      setState({
        ...state,
        loaderModalErrorMessage: response.message,
        loaderModaltryAgainAction: onImportStudents,
      });

      return;
    }

    setState({ ...state, openLoaderModal: false });

    props.history.push('/student');
  };

  const {
    /* amountOwedByStudents,
    calculateAmountOwedByStudents,
    expensesInMonth,
    revenueInMonth,
    calculateRevenueInMonth,
    calculateExpensesInMonth, */
    currentUser: { authToken },
  } = props;

  /* if (
      !amountOwedByStudents.calculating &&
      !amountOwedByStudents.error &&
      !amountOwedByStudents.data &&
      props.currentUser.identityRoles[0] === "SchoolAdministrator"
    ) {
      calculateAmountOwedByStudents(authToken);
    }

    if (
      !expensesInMonth.calculating &&
      !expensesInMonth.error &&
      !expensesInMonth.data &&
      props.currentUser.identityRoles[0] === "SchoolAdministrator"
    ) {
      calculateExpensesInMonth(authToken);
    }

    if (
      !revenueInMonth.calculating &&
      !revenueInMonth.error &&
      !revenueInMonth.data &&
      props.currentUser.identityRoles[0] === "SchoolAdministrator"
    ) {
      calculateRevenueInMonth(authToken);
    }
 */
  const registerSMS = () => {
    postData(`sms/register`, {}, authToken).then((resp) => {
      if (resp.success) {
        return;
      }
    });
  };

  return (
    <div>
      <MainHeader />

      <MainLoaderModal
        openModal={state.openLoaderModal}
        loading={state.loaderModalLoadingStatus}
        description={state.loaderModalDescription}
        errorMessage={state.loaderModalErrorMessage}
        tryAgainAction={state.loaderModaltryAgainAction}
        cancelAction={() => setState({ ...state, openLoaderModal: false })}
      />

      <ImportDataModal
        openModal={state.openImportDataModal}
        heading={state.importEmployees ? 'Import Employees' : 'Import Students'}
        description={
          state.importEmployees
            ? 'Add Employees in bulk through a CSV file. '
            : 'Add Students in bulk through a CSV file. '
        }
        importAction={(csvFile) => {
          if (state.importEmployees) {
            onImportEmployees(csvFile);
          } else {
            onImportStudents(csvFile);
          }
        }}
        cancelAction={() => {
          if (state.importEmployees) {
            setState({
              ...state,
              importEmployees: false,
              openImportDataModal: false,
              openAddEmployeeModal: true,
            });
          } else {
            setState({
              ...state,
              importEmployees: false,
              openImportDataModal: false,
              openAddStudentModal: true,
            });
          }
        }}
      />

      <AddFeesModal
        openModal={state.openAddFeesModal}
        general={true}
        classId={-1}
        cancelAction={() => setState({ ...state, openAddFeesModal: false })}
      />

      <AddPayrollModal
        openModal={state.openAddPayrollModal}
        heading="Create New Payroll"
        btnText="CREATE PAYROLL"
        refreshPage={() => props.history.push('/payroll')}
        cancelAction={() => setState({ ...state, openAddPayrollModal: false })}
      />

      <RegisterAssetModal
        openModal={state.openRegisterAssetModal}
        refreshPage={() => props.history.push('/fixed-asset')}
        cancelAction={() =>
          setState({ ...state, openRegisterAssetModal: false })
        }
      />

      <BillStudentsModal
        openModal={state.openBillStudentsModal}
        cancelAction={() =>
          setState({ ...state, openBillStudentsModal: false })
        }
      />

      <ExpensePaymentModal
        openModal={state.openExpensesPaymentModal}
        cancelAction={() =>
          setState({ ...state, openExpensesPaymentModal: false })
        }
      />

      <AddAcademicYearModal
        openModal={state.openAcademicYearModal}
        cancelAction={() =>
          setState({ ...state, openAcademicYearModal: false })
        }
      />

      <AddLedgerAccountModal
        openModal={state.openLedgerAccountModal}
        selectedLedgerCategory={null}
        selectedLedgerAccount={null}
        cancelAction={() =>
          setState({ ...state, openLedgerAccountModal: false })
        }
      />

      <AddLedgerCategoryModal
        openModal={state.openLedgerCategoryModal}
        cancelAction={() =>
          setState({ ...state, openLedgerCategoryModal: false })
        }
      />

      <AddClassModal
        openModal={state.openAddClassModal}
        cancelAction={() => setState({ ...state, openAddClassModal: false })}
      />

      <AddStudentModal
        openModal={state.openAddStudentModal}
        importAction={() =>
          setState({
            ...state,
            importStudents: true,
            openImportDataModal: true,
            openAddStudentModal: false,
          })
        }
        closeAction={() => setState({ ...state, openAddStudentModal: false })}
      />

      <AddEmployeeModal
        openModal={state.openAddEmployeeModal}
        importAction={() =>
          setState({
            ...state,
            importEmployees: true,
            openImportDataModal: true,
            openAddEmployeeModal: false,
          })
        }
        closeAction={() => setState({ ...state, openAddEmployeeModal: false })}
      />

      <ReceivePaymentModal
        openModal={state.openReceivePaymentModal}
        paymentToRevise={state.studentPaymentToRevise}
        showReceipt={(payment) =>
          setState({
            ...state,
            openReceivePaymentModal: false,
            openReceiptModal: true,
            selectedPayment: payment,
          })
        }
        cancelAction={() =>
          setState({ ...state, openReceivePaymentModal: false })
        }
      />

      <ReceiveOtherPaymentModal
        openModal={state.openReceiveOtherPaymentModal}
        paymentToRevise={state.paymentToRevise}
        showReceipt={(payment) =>
          setState({
            ...state,
            openReceiveOtherPaymentModal: false,
            openOtherPaymentReceiptModal: true,
            selectedOtherPayment: payment,
          })
        }
        cancelAction={() =>
          setState({ ...state, openReceiveOtherPaymentModal: false })
        }
      />

      <AddSupplierModal
        openModal={state.openAddSupplierModal}
        supplier={null}
        cancelAction={() => setState({ ...state, openAddSupplierModal: false })}
      />

      <ReceiptModal
        openModal={state.openReceiptModal}
        selectedPayment={state.selectedPayment}
        cancelAction={() => setState({ ...state, openReceiptModal: false })}
      />

      <OtherPaymentReceiptModal
        openModal={state.openOtherPaymentReceiptModal}
        selectedPayment={state.selectedOtherPayment}
        cancelAction={() =>
          setState({ ...state, openOtherPaymentReceiptModal: false })
        }
      />

      <AddBudgetModal
        open={state.openAddBudgetModal}
        academicYears={props.academicYears}
        cancelAction={() => setState({ ...state, openAddBudgetModal: false })}
      />

      <RegisterSMSModal
        open={state.openSMSModal}
        cancelAction={() => setState({ ...state, openSMSModal: false })}
      />

      <div className="dashboard page-container">
        <SummaryPane
          {...props}
          onAddCashReceive={() => {
            setState({ ...state, openReceivePaymentModal: true });
          }}
          onAddExpenses={() => {
            setState({ ...state, openExpensesPaymentModal: true });
          }}
        />

        <h3 className="text-dark text-24 font-bold mt-30">Navigate</h3>
        <p className="text-14 text-grey mt-10">Explore features and modules</p>
        {/* <div className="mt-20">
            <Search
              id="search"
              name="search"
              labelText=""
              placeHolderText="Enter name of feature or module then press enter to search"
            />
        </div> */}

        <div className="bx--row mb-40 mt-30">
          <div className="bx--col-md-2 fade-in">
            <NavigationCard
              title="Academic Year"
              subtitle={
                state.activeAcademicYear
                  ? state.activeAcademicYear.description
                  : 'N/A'
              }
              linkIcon1={plusIcon}
              linkIcon2={fileIcon}
              linkText1="CREATE NEW"
              linkText2="VIEW LEDGERS"
              linkAction1={() => {
                setState({ ...state, openAcademicYearModal: true });
              }}
              linkAction2={() => props.history.push('/chart-of-accounts')}
              btnText="VIEW"
              btnAction={() => props.history.push('/academic-year')}>
              <Invoice />
            </NavigationCard>
          </div>

          <div className="bx--col-md-2 fade-in">
            <NavigationCard
              title="Budgets"
              subtitle={
                state.activeAcademicYear
                  ? state.activeAcademicYear.description
                  : 'N/A'
              }
              linkIcon1={plusIcon}
              linkIcon2={barChart}
              linkText1="CREATE NEW"
              linkText2="GENERATE REPORTS"
              linkAction2={() => props.history.push('analytics/budget-report')}
              btnText="VIEW"
              linkAction1={() =>
                setState({ ...state, openAddBudgetModal: true })
              }
              btnAction={() => props.history.push('/budget')}>
              <Budget />
            </NavigationCard>
          </div>
          <div className="bx--col-md-2 fade-in">
            <NavigationCard
              title="Cash Received"
              subtitle={
                dashboardMetrics?.totalRevenuesForTheMonth &&
                props.currentUser.identityRoles[0] === 'SchoolAdministrator'
                  ? formatCurrency(
                      dashboardMetrics?.totalRevenuesForTheMonth || 0,
                    )
                  : 'N/A'
              }
              linkIcon1={plusIcon}
              linkIcon2={plusIcon}
              linkText1="RECEIVE STUDENT CASH"
              linkText2="RECEIVE OTHER CASH"
              linkAction1={() =>
                setState({ ...state, openReceivePaymentModal: true })
              }
              linkAction2={() =>
                setState({ ...state, openReceiveOtherPaymentModal: true })
              }
              btnText="VIEW"
              btnAction={() => props.history.push('/payments-received')}>
              <Statistics2 />
            </NavigationCard>
          </div>

          <div className="bx--col-md-2 fade-in">
            <NavigationCard
              title="Expenses"
              subtitle={
                dashboardMetrics?.totalExpensesForTheMonth &&
                props.currentUser.identityRoles[0] === 'SchoolAdministrator'
                  ? formatCurrency(
                      dashboardMetrics?.totalExpensesForTheMonth || 0,
                    ) //expensesInMonth.data.expensesInMonth
                  : 'N/A'
              }
              linkIcon1={plusIcon}
              linkIcon2={fileIcon}
              linkText1="RECORD NEW"
              btnAction={() => props.history.push('/payments-made')}
              linkAction1={() =>
                setState({ ...state, openExpensesPaymentModal: true })
              }
              linkAction2={() => props.history.push('/suppliers/purchases')}
              linkText2="PURCHASES"
              btnText="VIEW">
              <Statistics />
            </NavigationCard>
          </div>
          <div className="bx--col-md-2 fade-in">
            <NavigationCard
              title="Students"
              subtitle={`${
                dashboardMetrics?.studentsCount?.totalEnrolled || ''
              } Enrolled`} //props.enrolledStudents.length
              linkIcon1={plusIcon}
              linkIcon2={fileIcon}
              linkAction1={() =>
                setState({ ...state, openAddStudentModal: true })
              }
              linkAction2={() =>
                props.history.push('analytics/student-statement')
              }
              linkText1="ADD NEW"
              linkText2="ACCOUNT STATEMENT"
              btnText="VIEW"
              btnAction={() => props.history.push('/student')}>
              <Teamwork />
            </NavigationCard>
          </div>

          <div className="bx--col-md-2 fade-in">
            <NavigationCard
              title="Class"
              subtitle={`${props.classes.length} Classes`}
              linkIcon1={plusIcon}
              linkIcon2={fileIcon}
              linkText1="ADD NEW"
              linkText2="CLASS LABEL"
              linkAction1={() =>
                setState({ ...state, openAddClassModal: true })
              }
              linkAction2={() => {
                props.history.push('/class-label');
              }}
              btnText="VIEW"
              btnAction={() => {
                props.history.push('/class');
              }}>
              <Certificate />
            </NavigationCard>
          </div>
          <div className="bx--col-md-2 fade-in">
            <NavigationCard
              title="Billing"
              subtitle="&nbsp;"
              linkIcon1={plusIcon}
              linkIcon2={fileIcon}
              linkText1="ADD BILLING FEE"
              linkText2="BILL STUDENTS"
              btnText="VIEW"
              linkAction1={() => setState({ ...state, openAddFeesModal: true })}
              linkAction2={() =>
                setState({ ...state, openBillStudentsModal: true })
              }
              btnAction={() => props.history.push('/billing')}>
              <Contact />
            </NavigationCard>
          </div>
          <div className="bx--col-md-2 fade-in">
            <NavigationCard
              title="Employees"
              subtitle={`${props.activeEmployees.length} ACTIVE`}
              linkIcon1={plusIcon}
              linkIcon2={fileIcon}
              linkText1="ADD NEW"
              linkText2="VIEW TERMINATED"
              linkAction1={() =>
                setState({ ...state, openAddEmployeeModal: true })
              }
              linkAction2={() => props.history.push('/employees/?v=Terminated')}
              btnText="VIEW"
              btnAction={() => props.history.push('/employees')}>
              <Briefcase />
            </NavigationCard>
          </div>
          <div className="bx--col-md-2 fade-in">
            <NavigationCard
              title="Payroll"
              linkIcon1={plusIcon}
              linkIcon2={fileIcon}
              linkText1="NEW PAYROLL"
              linkText2="REPORTS"
              btnText="VIEW"
              linkAction1={() =>
                setState({ ...state, openAddPayrollModal: true })
              }
              linkAction2={() => props.history.push('/payroll/reports')}
              btnAction={() => props.history.push('/payroll')}>
              <Sales />
            </NavigationCard>
          </div>
          <div className="bx--col-md-2 fade-in">
            <NavigationCard
              title="Suppliers"
              linkIcon1={plusIcon}
              linkText1="ADD NEW"
              linkText2="---"
              linkAction1={() =>
                setState({ ...state, openAddSupplierModal: true })
              }
              btnText="VIEW"
              btnAction={() => props.history.push('/suppliers')}>
              <Handshake />
            </NavigationCard>
          </div>
          <div className="bx--col-md-2 fade-in">
            <NavigationCard
              title="Fixed Assets"
              linkIcon1={plusIcon}
              linkText1="REGISTER ASSET"
              linkText2="---"
              btnText="VIEW"
              linkAction1={() =>
                setState({ ...state, openRegisterAssetModal: true })
              }
              btnAction={() => props.history.push('/fixed-asset')}>
              <Justice />
            </NavigationCard>
          </div>
          <div className="bx--col-md-2 fade-in">
            <NavigationCard
              title="Analytics"
              linkIcon1={plusIcon}
              linkText1="RUN REPORT"
              linkIcon2={fileIcon}
              linkText2="CHART OF ACCOUNTS"
              btnText="VIEW"
              linkAction1={() => props.history.push('/analytics')}
              linkAction2={() =>
                props.history.push('/analytics/chart-of-accounts')
              }
              btnAction={() => props.history.push('/analytics')}>
              <Presentation />
            </NavigationCard>
          </div>

          <div className="bx--col-md-2 fade-in">
            <NavigationCard
              title="Ledgers"
              linkIcon1={plusIcon}
              linkIcon2={plusIcon}
              linkText1="CREATE LEDGER"
              linkText2="CREATE CATEGORY"
              linkAction1={() => {
                setState({ ...state, openLedgerAccountModal: true });
              }}
              linkAction2={() => {
                setState({ ...state, openLedgerCategoryModal: true });
              }}
              btnText="VIEW"
              btnAction={() => props.history.push('/chart-of-accounts')}>
              <Group />
            </NavigationCard>
          </div>

          <div className="bx--col-md-2 fade-in">
            <NavigationCard
              title="Journal"
              linkIcon1={plusIcon}
              linkText1="CREATE JOURNAL"
              linkText2="---"
              btnText="VIEW"
              linkAction1={() => props.history.push('/journal/new')}
              btnAction={() => props.history.push('/journal')}>
              <Justice />
            </NavigationCard>
          </div>

          <div className="bx--col-md-2 fade-in">
            <NavigationCard
              title="Academics"
              textLabel="0"
              linkIcon1={fileIcon}
              linkIcon2={fileIcon}
              linkText1="ATTENDANCE"
              linkText2="PRE SCHOOL"
              linkAction1={() => props.history.push('/students/attendance')}
              linkAction2={() => props.history.push('/pre-school/grading')}
              btnText="VIEW"
              btnAction={() => props.history.push('/academic/grading')}>
              <Chart />
            </NavigationCard>
          </div>

          <div className="bx--col-md-2 fade-in">
            <NavigationCard
              title="Messaging"
              linkText1={
                props.currentUser.school.epobsSmsAccountId == null
                  ? 'CONFIGURE SMS'
                  : 'SMS CONFIGURED'
              }
              linkText2="---"
              btnText="VIEW"
              btnAction={(e) => props.history.push('/messaging')}
              linkAction1={() => {
                if (props.currentUser.school.epobsSmsAccountId == null) {
                  setState({ ...state, openSMSModal: true });
                }
              }}>
              <Message />
            </NavigationCard>
          </div>

          <div className="bx--col-md-2 fade-in">
            <NavigationCard
              title="Settings"
              linkIcon1={fileIcon}
              linkIcon2={fileIcon}
              linkText1="USERS"
              linkText2="DEPARTMENTS"
              linkAction1={() => props.history.push('/settings/users')}
              linkAction2={() => props.history.push('/settings/departments')}
              btnText="VIEW"
              btnAction={() => props.history.push('/settings')}>
              <Global />
            </NavigationCard>
          </div>

          <div className="bx--col-md-2 fade-in">
            <NavigationCard
              title="Help & Support"
              linkIcon1={fileIcon}
              linkIcon2={fileIcon}
              linkText1="FAQs"
              linkText2="USER DOCUMENTATION"
              linkAction2={() => window.open('/user-documentation', '_blank')}
              btnText="OUR WEBSITE"
              btnAction={(e) => {
                window.open('https://epobssuite.com', '_blank');
              }}>
              <International />
            </NavigationCard>
          </div>
        </div>

        <Footer fixed={false} showLinks={false} />
      </div>
    </div>
  );
};

// this will provide us with the state values we need
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  classes: selectClasses,
  classLabels: selectClassLabels,
  enrolledStudents: selectEnrolledStudents,
  activeEmployees: selectActiveEmployees,
  amountOwedByStudents: selectAmountOwedByStudents,
  expensesInMonth: selectExpensesInMonth,
  revenueInMonth: selectRevenueInMonth,
  academicYears: selectAcademicYears,
});

// this allows us to map our redux actions to our component
const mapDispatchToProps = (dispatch) => ({
  setStudents: (students) => dispatch(setStudents(students)),
  setEmployees: (employees) => dispatch(setEmployees(employees)),
  setClasses: (classes) => dispatch(setClasses(classes)),
  setClassLabels: (classLabels) => dispatch(setClassLabels(classLabels)),
  /* calculateAmountOwedByStudents: (authToken) =>
    dispatch({
      type: "CALCULATE_AMOUNT_OWED_BY_STUDENTS_REQUESTED",
      payload: { authToken },
    }),
  calculateExpensesInMonth: (authToken) =>
    dispatch({
      type: "CALCULATE_EXPENSES_IN_MONTH_REQUESTED",
      payload: { authToken },
    }),
  calculateRevenueInMonth: (authToken) =>
    dispatch({
      type: "CALCULATE_REVENUE_IN_MONTH_REQUESTED",
      payload: { authToken },
    }), */
  setAcademicYears: (academicYears) =>
    dispatch(setAcademicYears(academicYears)),
  setTerms: (terms) => dispatch(setTerms(terms)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(React.memo(DashboardPage)),
);
