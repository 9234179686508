import PurchaseOrderActionTypes from '../types/purchase-order.types';
import { addItems, removeItem } from '../utils/common.util';

// set initial state
const INITIAL_STATE = {
  purchaseOrders: [],
  reversedPurchaseOrders: [],
};

// create reducer with intial state if not state is received
const purchaseOrderReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PurchaseOrderActionTypes.SET_PURCHASE_ORDERS:
      return {
        // spread in the state
        ...state,
        purchaseOrders: addItems(state.purchaseOrders, action.payload),
      };
    case PurchaseOrderActionTypes.REMOVE_PURCHASE_ORDER:
      return {
        // spread in the state
        ...state,
        purchaseOrders: removeItem(state.purchaseOrders, action.payload),
      };
    case PurchaseOrderActionTypes.SET_REVERSED_PURCHASE_ORDERS:
      return {
        // spread in the state
        ...state,
        reversedPurchaseOrders: addItems(
          state.reversedPurchaseOrders,
          action.payload,
        ),
      };
    default:
      // no action matched, just return the state
      return state;
  }
};

// export out purchaseOrderReducer as default
export default purchaseOrderReducer;
