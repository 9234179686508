import React, { useState } from 'react';
import { Menu } from 'react-feather';

import './main-menu.styles.scss';
import { withRouter } from 'react-router-dom';

const MenuItem = ({ text, action }) => {
  return (
    <div className="menu-item" onClick={action}>
      {text}
      <hr className="separator" />
    </div>
  );
};

const MainMenu = ({ history }) => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div className={`nav-menu`}>
      <p
        className={`heading ${showMenu}`}
        onClick={() => {
          /* if (!showMenu) {
							setTimeout(() => setShowMenu(false), 2000);
						} */
          setShowMenu(!showMenu);
        }}>
        <Menu size={20} /> MAIN MENU
      </p>

      {showMenu ? (
        <div className="menu-items">
          <MenuItem text="DASHBOARD" action={() => history.push('/')} />
          <MenuItem
            text="ACADEMIC YEAR"
            action={() => history.push('/academic-year')}
          />
          <MenuItem text="BUDGETS" action={() => history.push('/budget')} />
          <MenuItem text="STUDENTS" action={() => history.push('/student')} />
          <MenuItem text="CLASS" action={() => history.push('/class')} />
          <MenuItem
            text="EMPLOYEES"
            action={() => history.push('/employees')}
          />
          <MenuItem
            text="SUPPLIERS"
            action={() => history.push('/suppliers')}
          />
          <MenuItem
            text="PURCHASES"
            action={() => history.push('/suppliers/purchases')}
          />
          <MenuItem
            text="LEDGERS"
            action={() => history.push('/chart-of-accounts')}
          />
          <MenuItem text="JOURNAL" action={() => history.push('/journal')} />
          <MenuItem text="BILLING" action={() => history.push('/billing')} />
          <MenuItem
            text="CASH RECEIVED"
            action={() => history.push('/payments-received')}
          />
          <MenuItem
            text="EXPENSES"
            action={() => history.push('/payments-made')}
          />
          <MenuItem text="PAYROLL" action={() => history.push('/payroll')} />

          <MenuItem
            text="FIXED ASSET"
            action={() => history.push('/fixed-asset')}
          />

          <MenuItem
            text="JOURNAL VOUCHER"
            action={() => history.push('/transaction')}
          />

          <MenuItem
            text="ACADEMICS"
            action={() => history.push('/academic/grading')}
          />
          <MenuItem
            text="MESSAGING"
            action={() => history.push('/messaging')}
          />
          <MenuItem
            text="ANALYTICS"
            action={() => history.push('/analytics')}
          />
          <MenuItem text="SETTINGS" action={() => history.push('/settings')} />
        </div>
      ) : null}
    </div>
  );
};

export default withRouter(MainMenu);
