import React, { useState } from 'reactn';
import useSWR from 'swr';
import {
  Search,
  Select,
  SelectItem,
  Pagination,
} from 'carbon-components-react';
import { formatDate } from '../../../utils/common.util';
import LoaderPane from '../../../components/loader-pane/loader-pane.component';
import { withRouter } from 'react-router-dom';

const FetchBillings = ({ onViewBilling, onSetBillings, onSetBillingFees }) => {
  const [state, setState] = useState({
    query: '',
    status: '',
  });

  const [paginate, setPaginate] = useState({
    page: 1,
    pageSize: 20,
  });

  const param = () => {
    let p = '';
    if (state.query) p += `&query=${state.query}`;
    if (state.status) p += `&status=${state.status}`;
    return p;
  };

  const { data, error, mutate } = useSWR(
    `billing/list?skip=${paginate.pageSize * (paginate.page - 1)}&take=${
      paginate.pageSize
    }&query=${state?.query}&status=${state?.status}`,
    {
      onSuccess: (d) => {
        //console.log(d);
        if (d?.length > 0) onSetBillings(d);
      },
    },
  );

  const { dataBillingFees } = useSWR(
    data && `billing/fees/?classId=${-1}&includeGeneralFees=${false}`,
    {
      onSuccess: (d) => {
        //console.log("\n billing fees", d);
        if (d) onSetBillingFees(d);
      },
    },
  );

  return (
    <div className="mt-30">
      <div className="bx--row mb-30">
        <div className="bx--col-lg-3">
          <Select
            noLabel
            onChange={(e) => setState({ ...state, status: e.target.value })}>
            <SelectItem
              disabled
              selected
              value="Filter by Status"
              text="Filter by Status"
            />

            <SelectItem value="" text="All" />
            <SelectItem value="Blank" text="Blank" />
            <SelectItem value="Generated" text="Generated" />
            <SelectItem value="Voided" text="Voided" />
          </Select>
        </div>
        <div className="bx--col-lg-9">
          <Search
            id="search"
            name="search"
            labelText=""
            value={state.query}
            onChange={(event) =>
              setState({ ...state, query: event.target.value })
            }
            placeHolderText="Search by Class, Academic Year or Term"
          />
        </div>
      </div>

      <LoaderPane
        loading={!data && !error}
        error={error?.message}
        noRecords={!error && data?.length === 0}
        onReload={() => {
          mutate();
        }}
      />

      {data?.length > 0 && (
        <div className="table-container">
          <table className="bx--data-table mt-10 table-white">
            <thead>
              <tr>
                <th>ID</th>
                <th>Date Billed</th>
                <th>Academic Year</th>
                <th>Term</th>
                <th>Class</th>
                <th>Total Bills</th>
                {/*<th>Total Amount Billed</th>*/}
                <th>Status</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((billing) => (
                <tr>
                  <td>{billing.uniqueId}</td>
                  <td>{formatDate(billing.dateCreated)}</td>
                  <td>{billing.academicYear.description}</td>
                  <td>{billing.term.description}</td>
                  <td>{billing.class.description}</td>
                  <td>{billing.totalBillsCount}</td>
                  {/*<td>N/A</td>*/}
                  <td className={billing.status.toLowerCase()}>
                    {billing.status.toUpperCase()}
                  </td>
                  <td className="text-center">
                    <span
                      className="link"
                      onClick={() => {
                        onViewBilling(billing);
                        //props.history.push(`/billing/${billing.id}`);
                      }}>
                      VIEW
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <Pagination
        backwardText="Previous page"
        forwardText="Next page"
        page={paginate.page}
        pageNumberText="Page Number"
        pageSize={paginate.pageSize}
        pageSizes={[10, 20, 30, 40, 50, 100]}
        totalItems={1000}
        onChange={(value) => setPaginate(value)}
      />
    </div>
  );
};

export default withRouter(FetchBillings);
